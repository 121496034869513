import { getLocaleParameters, currenciesSymbols } from "./localeUtils.mjs"
import { replaceASCII } from "./stringUtils.mjs"
import { ROUND } from "./financeUtils.mjs"

const ibanCountryLengths = {
  AD: 24,
  AE: 23,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BR: 29,
  CD: 27,
  CH: 21,
  CR: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  HU: 28,
  IE: 22,
  IL: 23,
  IS: 26,
  IT: 27,
  JO: 30,
  KW: 30,
  KZ: 20,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MA: 28,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  QX: 34,
  RO: 24,
  RS: 22,
  SA: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  TN: 24,
  TR: 26,
}

/**
 * @param {Number|String} input
 * @returns Returns 1 if the IBAN is valid. False if the IBAN's length is not as it should be (for CY the IBAN should be 28 chars long starting with CY). Any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIban(input) {
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, "") // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest

  // check syntax and length
  if (!code || iban.length !== ibanCountryLengths[code[1]]) return false

  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })

  // final check
  return mod97(digits) === 1
}

/**
 * @param {Number|String} input
 * @param {string} country
 * @returns Returns 1 if the RIB is valid. False if the RIB's length is not as it should be (for MA the RIB should be 24 chars long). Any other number (checksum) when the RIB is invalid (check digits do not match)
 * This function was developed for arrawaj therefore for Moroccan RIB only!
 * TODO: function to be evolved so that it is operational for French RIB for example
 */
function isValidRib(input, country) {
  if (!/^\d+$/.test(input)) return false
  if (country === "MA" && input.length !== 24) return false
  const accountNumber = input.substring(0, 22)
  const bankKey = input.substring(22)
  // eslint-disable-next-line no-undef
  const calculatedKey = String(BigInt(97) - ((BigInt(accountNumber) * BigInt(100)) % BigInt(97))).padStart(2, "0")
  return bankKey === calculatedKey
}

function mod97(string) {
  let checksum = string.slice(0, 2)
  let fragment

  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }

  return checksum
}

/**
 * @param {number} number
 * @param {string} locale
 * @returns Returns the number rounded to the number decimal places according to the locale, or 2 by default.
 */
function roundCurrency(number, locale) {
  if (!number) return number
  number = Number(number)
  const decimalPlace = getLocaleParameters(locale).decimalPlace || 2
  const result = ROUND(number, decimalPlace)
  return result === 0 ? 0 : result // to avoid -0
}

/**
 * @param {string} number
 * @param {string} locale
 * @returns Returns the numeric part of the currency string.
 */
function unformatCurrency(number, locale) {
  if (!number || typeof number !== "string") return number
  number = replaceASCII(number).replace(/\s/g, "")

  const {
    delimiters: { thousands, decimal },
    currency: { symbol, code },
  } = getLocaleParameters(locale)

  // Currency
  number = number.replace(symbol, "").replace(code, "")

  if (decimal !== ".") {
    // handle special case : even for countries using "," as decimal separator, accept "." if and only if followed by 2 figures
    const s1 = number.substring(number.length - 3, number.length - 2)
    const s2 = number.substring(number.length - 2, number.length - 1)
    const s3 = number.substring(number.length - 1, number.length - 0)
    if (s1 === "." && s2 >= "0" && s2 <= "9" && s3 >= "0" && s3 <= "9") {
      let left = number.substring(0, number.length - 3)
      left = left.replace(/,/g, "")
      number = left + decimal + s2 + s3
    } else if (number.indexOf(".") < 0) {
      // there is no decimal part, but let's support 1,000,000 also
      const s0 = number.substring(number.length - 4, number.length - 3)
      const s1 = number.substring(number.length - 3, number.length - 2)
      const s2 = number.substring(number.length - 2, number.length - 1)
      const s3 = number.substring(number.length - 1, number.length - 0)
      if (s0 === "," && s1 >= "0" && s1 <= "9" && s2 >= "0" && s2 <= "9" && s3 >= "0" && s3 <= "9" && decimal != ",") {
        number = number.replace(/,/g, "")
      }
    }
  }

  // Thousand separators
  let decimals = ""
  if (number.indexOf(decimal) > 0) decimals = number.substr(number.indexOf(decimal), number.length)
  if (decimals) number = number.replace(decimals, "")
  if (number.includes(thousands)) number = number.replace(thousands === "." ? /\./g : new RegExp(thousands, "g"), "")

  // Decimal
  if (decimals) number = number + decimals
  number = number.replace(decimal, ".")

  return Number(number)
}

/**
 * @param {number} number
 * @param {string} locale
 * @param {object} options
 * @returns Returns the string currency representation of the number according to the locale. See [Intl.NumberFormat()](https://v8.dev/features/intl-numberformat) for details regarding the option field.
 */
function formatCurrency(number, locale, options) {
  if (!number && number !== 0) return number

  // this is a hack to make it simpler when we want no digits
  //TODO : bug on option mutation
  if (options === 0) options = { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  else if (typeof options === "string") options = { currency: options }
  else if (!options) options = {}

  const hasStyleNone = options.style === "none"

  const localeParameters = getLocaleParameters(locale)
  if (!options?.currency) options.currency = localeParameters.currency.code
  options.style = hasStyleNone ? undefined : "currency"
  if (localeParameters.useGrouping) options.useGrouping = true
  if (options.maximumFractionDigits === undefined && options.minimumFractionDigits === undefined) {
    options.minimumFractionDigits = localeParameters.decimalPlace || 2
    options.maximumFractionDigits = options.minimumFractionDigits
  }

  // avoid -0.00
  if (Object.is(Math.round(number * 10 ** (options.minimumFractionDigits || 2)), -0)) number = 0

  let formattedCurrency = new Intl.NumberFormat(locale, options).format(number)

  if (["fr-GN", "lt-LT", "fr-BE"].includes(locale)) {
    formattedCurrency = replaceASCII(formattedCurrency)
    if (hasStyleNone) formattedCurrency = formattedCurrency.replace(/ /g, ".")
    else formattedCurrency = formattedCurrency.replace(/[ ](?=.*[ ])/g, ".")
  }
  if (locale === "en-LT") {
    formattedCurrency = formattedCurrency.replace(new RegExp(/,/g), "#").replace(new RegExp(/\./), ",").replace(new RegExp(/#/g), ".")
  }
  if (locale === "mx-MX") {
    formattedCurrency = formattedCurrency.replace("MX", "")
  }
  return formattedCurrency
}

/**
 * WARNING : this function is obsolete and should not be used anymore.
 * Use formatCurrency instead.
 * @param {number} number
 * @param {string} currencyCodeOrLocale
 * @param {object} options
 * @returns Returns the string currency representation of the number according to the locale. See [Intl.NumberFormat()](https://v8.dev/features/intl-numberformat) for details regarding the option field.
 */
function getFormattedCurrency(number, currencyCodeOrLocale, options = {}) {
  if (currencyCodeOrLocale?.includes("-")) {
    return formatCurrency(number, currencyCodeOrLocale, options)
  }

  options.currency = currencyCodeOrLocale //TODO : bug on option mutation
  return formatCurrency(number, undefined, options)
}

/**
 * @param {string} locale In the form "fr-FR"
 * @param {string} [currencyCode] For instance "EUR". If provided, the function will first use it to look up and fallback to the locale if there is no match.
 * @param {object} [options]
 * @param {string} [options.currencyDisplay] If value is "code" then return the provided currency code instead of a symbol from the list.
 * @returns Returns the symbol of the currency, for instance "€".
 */
function getCurrencySymbol(locale, currencyCode, options = {}) {
  const codeDisplay = options.currencyDisplay === "code"
  const { symbol: localeCurrencySymbol, code } = getLocaleParameters(locale).currency || {}
  const currencyFromCode = currenciesSymbols[currencyCode]
  return currencyCode
    ? codeDisplay
      ? currencyFromCode?.code || code
      : currencyFromCode?.symbol || localeCurrencySymbol
    : codeDisplay
    ? code
    : localeCurrencySymbol
}

function coerceStringToJsNumber(string, locale) {
  if (typeof string !== "string" || string === "") return string
  const { delimiters } = getLocaleParameters(locale)
  const localeDecimalDelimiter = delimiters.decimal
  const localeThousandsDelimiter = delimiters.thousands
  const currencySymbol = getCurrencySymbol(locale)
  const replaceRegex = new RegExp(
    `\\s|%${currencySymbol ? `|${currencySymbol}` : ""}${localeThousandsDelimiter ? `|\\${localeThousandsDelimiter}` : ""}`,
    "g",
  )
  const lintedString = string.replace(replaceRegex, "").replace(localeDecimalDelimiter, ".")
  return isNaN(lintedString) ? string : parseFloat(lintedString)
}

function formatIban(iban) {
  return iban
    .replace(/[^\dA-Z]/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim()
}

export {
  ibanCountryLengths,
  isValidIban,
  isValidRib,
  roundCurrency,
  unformatCurrency,
  formatCurrency,
  getFormattedCurrency,
  getCurrencySymbol,
  coerceStringToJsNumber,
  formatIban,
}
