import { getFilterFunction } from "basikon-common-utils"
import React, { createRef } from "react"
import { Col, Grid, Row } from "react-bootstrap"

import Card from "@/_components/Card"
import EntityTransitionsButton from "@/_components/EntityTransitionsButton"
import LayoutCard from "@/_components/LayoutCard"
import SearchRowComponent from "@/_components/SearchRowComponent"
import Table, { filterColumns } from "@/_components/Table"

import { getEntityDisplay, handleEntitiesPageRowClick, handleEntitiesPageSelectAll } from "@/_services/entity"
import { getLabel, getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { fetchPinnedEntities, getPageConfig } from "@/_services/userConfiguration"
import {
  applyPageAdvancedSearchConfig,
  applyPageConfigFields,
  formatCurrency,
  getClientRouteKey,
  getEntities,
  hasQueryParamsChanged,
  mergeQueryParams,
  searchParamToObject,
} from "@/_services/utils"

class AmendmentsPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { pathname: clientRoute },
    } = props

    const pageConfigKey = getClientRouteKey(clientRoute) || "AmendmentsPage"
    const pageConfig = getPageConfig(pageConfigKey)

    this.state = {
      entityName: "Amendment",
      pinnedData: [],
      amendments: [],
      loading: false,
      transitions: [],
      transitionsLoading: false,
      selectedEntities: new Set(),
      pageConfigKey,
      pageConfig,
      columns: filterColumns(
        [
          { title: "Registration", name: "registration", linkTo: "/amendment/{registration}" },
          { title: "Organization", name: "organization.name", hidden: true },
          { title: "Type", name: "type", select: "amendmentType" },
          { title: "Status", name: "status", select: "amendmentStatus", badge: true },
          { title: "Contract", name: "contract", linkTo: "/contract/{contractRegistration}" },
          { title: "Client", name: "client", linkTo: "/person/{clientRegistration}" },
          { title: "Partner", name: "partner", linkTo: "/person/{partnerRegistration}" },
          { title: "Date", name: "date", type: "date" },
          { title: "Validity date", name: "validityDate", type: "date" },
          { title: "Financed amount", name: "financedAmountExclTax", type: "currency" },
        ],
        pageConfig?.columns,
      ),
    }

    this.preventAutoRefresh = createRef()
  }

  componentDidMount() {
    const { pageConfigKey } = this.state
    this.getAmendments()

    getList("amendmentStatus", () => this.setState({ loaded: true }))
    getList("amendmentType", () => this.setState({ loaded: true }))

    applyPageAdvancedSearchConfig(pageConfigKey, amendmentSearchFields)
  }

  componentDidUpdate(prevProps) {
    if (!this.preventAutoRefresh.current && hasQueryParamsChanged(this.props, prevProps)) {
      this.getAmendments()
    }
  }

  getAmendments = async params => {
    const { pageConfig, columns, entityName } = this.state
    const { location, history } = this.props
    const { search, pathname } = location

    const fetchPersonsName = pageConfig?.fetchPersonsName
    const include = [
      fetchPersonsName === false ? undefined : "personsNames",
      columns.find(it => it.name === "organization.name" && !it.hidden) && "organizationName",
    ].filter(it => it)

    let queryParams = { ...searchParamToObject(search), ...params, include }
    const allQueryParams = {
      ...pageConfig?.defaultQuery,
      ...Object.entries(queryParams).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {}),
      ...pageConfig?.query,
      include: pageConfig?.query?.include || queryParams.include,
      projection: pageConfig?.query?.projection || queryParams.projection,
    }

    this.setState({ loading: true })
    const [amendments, pinnedData] = await Promise.all([
      getEntities("amendment", allQueryParams),
      fetchPinnedEntities({ entityName, queryParams: allQueryParams }),
    ])

    this.setState({
      amendments: applyPageConfigFields(pageConfig, amendments),
      pinnedData: this.formatTableData(applyPageConfigFields(pageConfig, pinnedData)),
      loading: false,
      selectedEntities: new Set(), // Reset selected entities after getting new entities
    })

    if (params) {
      this.preventAutoRefresh.current = true
      queryParams.page = 1
      queryParams = mergeQueryParams(search, queryParams)
      history.replace(`${pathname}${queryParams}`)
      this.preventAutoRefresh.current = false
    }
  }

  formatTableData = data => {
    return data.map(amendment => {
      const { persons = [] } = amendment

      const { personRegistration: clientRegistration, person: client } = persons.find(p => p.role === "CLIENT") || {}
      const { personRegistration: partnerRegistration, person: partner } = persons.find(p => p.role === "PARTNER") || {}

      return {
        ...amendment,
        id: amendment.registration,
        contract: amendment.contractRegistration,
        client: client?.name || clientRegistration,
        clientRegistration,
        partner: partner?.name || partnerRegistration,
        partnerRegistration,
        financedAmountExclTax: amendment?.quotations?.[0]?.financedAmountExclTax,
      }
    })
  }

  render() {
    const {
      entityName,
      amendments = [],
      selectedEntities,
      transitions = [],
      loading,
      transitionsLoading,
      pageConfig,
      columns,
      pinnedData,
    } = this.state
    const { location } = this.props
    const title = loc(pageConfig?.title || "Amendments")
    const queryParams = searchParamToObject(location.search)
    const filteredAmendments = queryParams.filter?.trim() ? filterAmendments(amendments, queryParams.filter) : amendments

    const action = selectedEntities.size ? (
      <EntityTransitionsButton
        entities={amendments}
        entityName={entityName}
        transitions={transitions}
        selectedEntities={selectedEntities}
        transitionsLoading={transitionsLoading}
        handleSelectAll={async selectedEntities => await handleEntitiesPageSelectAll(entityName, this, selectedEntities)}
      />
    ) : null

    const data = this.formatTableData(filteredAmendments)

    return (
      <Grid fluid className="amendments-page">
        {!pageConfig?.hideKpis && (
          <LayoutCard noCard rows={[{ type: "content", props: { getContentOnUrlChange: false, name: "amendments-kpi", noCard: false } }]} />
        )}
        <Row>
          <Col xs={12}>
            <Card title={title} action={action}>
              <SearchRowComponent
                loading={loading}
                entityName={entityName}
                getEntities={this.getAmendments}
                searchFields={amendmentSearchFields}
                advancedSearch={pageConfig?.advancedSearch}
                hideAutocomplete={pageConfig?.hideAutocomplete}
                exportData={{ data, columns, fileName: title, ...(pageConfig?.exportData || {}) }}
              />
              <Row>
                <Col xs={12}>
                  <Table
                    data={data}
                    pinnedData={pinnedData}
                    entityName={entityName}
                    columns={columns}
                    loading={loading}
                    selectedEntities={selectedEntities}
                    onRowClick={!pageConfig?.transitionsButton?.hidden && (async row => await handleEntitiesPageRowClick(entityName, this, row))}
                    sideView={pageConfig?.sideView}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default AmendmentsPage

export function filterAmendments(amendments = [], filter) {
  if (!filter) return amendments

  const locale = getLocale()
  const includes = getFilterFunction(filter, locale)

  return amendments.filter(amendment => {
    const typeLabel = getLabel("amendmentType", amendment.type)
    const statusLabel = getLabel("amendmentStatus", amendment.status)

    const { personRegistration: clientRegistration, person: client } = (amendment.persons || []).find(p => p.role === "CLIENT") || {}
    const { personRegistration: partnerRegistration, person: partner } = (amendment.persons || []).find(p => p.role === "PARTNER") || {}

    return (
      includes(typeLabel) ||
      includes(statusLabel) ||
      includes(client?.name) ||
      includes(partner?.name) ||
      includes(amendment.type) ||
      includes(amendment.name) ||
      includes(amendment.date) ||
      includes(amendment.status) ||
      includes(clientRegistration) ||
      includes(partnerRegistration) ||
      includes(amendment.description) ||
      includes(amendment.registration) ||
      includes(amendment.validityDate) ||
      includes(getEntityDisplay(amendment)) ||
      includes(amendment.contractRegistration) ||
      includes(amendment?.quotations?.[0]?.financedAmountExclTax) ||
      includes(formatCurrency(amendment?.quotations?.[0]?.financedAmountExclTax, locale))
    )
  })
}

export const amendmentSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 8 }, regex: true },
  ],
  [
    { field: "status", type: "multiple", select: "amendmentStatus", colProps: { xs: 12, sm: 4 } },
    { field: "type", type: "multiple", select: "amendmentType", colProps: { xs: 12, sm: 8 } },
  ],
  [
    {
      field: "contract",
      searchEntityName: "Contract",
      placeholder: "Type to search for a contract",
      actionHidden: true,
      colProps: { xs: 12, sm: 4 },
    },
    { field: "fromDate", type: "date", colProps: { xs: 12, sm: 4 } },
    { field: "toDate", type: "date", colProps: { xs: 12, sm: 4 } },
  ],
]
