import axios from "axios"
import React from "react"
import { withRouter } from "react-router-dom"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import Card from "@/_components/Card"
import TaskModal, { defaultModalRows } from "@/_components/TaskModal"
import TasksComponent from "@/_components/TasksComponent"

import { loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { isOffline } from "@/_services/offlineService"
import { getOptions } from "@/_services/userConfiguration"

class EntityTasksCard extends React.Component {
  state = {
    tasks: [],
    fetched: false,
    showTaskModal: false,
    isLoading: false,
  }

  componentDidMount = () => {
    const { collapse } = this.props
    if (collapse === false) this.getTasks()
  }

  componentDidUpdate(prevProps) {
    const { entity, range } = this.props
    const { entity: prevEntity, range: prevRange } = prevProps
    let { fetched } = this.state
    if (prevEntity?.status !== entity?.status || prevRange !== range) fetched = false
    if (!fetched) {
      this.setState({ fetched: true }, this.getTasks)
    }
  }

  getTasks = async () => {
    let { range = "" } = this.props
    const { entityRegistration, entityName } = this.props
    if (entityRegistration) {
      try {
        this.setState({ isLoading: true })
        if (range) range = "range=" + range + "&"
        const tasks = (await axios.get(`/api/core/tasks?${range}entityRegistration=${entityRegistration}&entityType=${entityName}`)).data
        this.setState({ tasks, fetched: true })
      } catch (error) {
        addOops(error)
      } finally {
        this.setState({ isLoading: false })
      }
    }
  }

  handleSetExpanded = expanded => {
    const { fetched } = this.state
    if (expanded && !fetched) this.getTasks()
  }

  handleShowTaskModal = id => {
    this.setState({ showTaskModal: id })
  }

  handleOpenTasks = () => {
    const { entityRegistration, entityName, history } = this.props
    history.push(`/tasks?entityRegistration=${entityRegistration}&entityType=${entityName}`)
  }

  handleTaskModalClose = task => {
    this.setState({ showTaskModal: null })
    if (task) this.getTasks()
  }

  handleOpenCalendar = () => {
    this.props.history.push("/calendar")
  }

  render() {
    const { tasks = [], showTaskModal, isLoading } = this.state
    const {
      debugInfo,
      collapse = true,
      title = "Tasks",
      entityRegistration,
      entityName,
      readOnly,
      canAddTask = true,
      modalRows,
      ...remainingProps
    } = this.props
    const showCalendar = getOptions("showCalendar")

    return (
      <Card
        debugInfo={{ ...debugInfo, defaultModalRows }}
        title={loc(title)}
        collapse={collapse}
        onSetExpanded={this.handleSetExpanded}
        className="entity-tasks-card buttons-with-no-margin"
        action={
          <>
            {!isOffline() && showCalendar && (
              <ButtonWithTooltip
                bsStyle="default"
                simple
                pullRight
                bsSize="sm"
                btnClassName="pdl-0"
                tooltip="Calendar"
                onClick={this.handleOpenCalendar}
              >
                <i className="icn-calendar icn-xs" />
              </ButtonWithTooltip>
            )}

            <ButtonWithTooltip bsStyle="default" simple pullRight bsSize="sm" btnClassName="pdl-0" tooltip="Tasks" onClick={this.handleOpenTasks}>
              <i className="icn-tasks icn-xs" />
            </ButtonWithTooltip>

            <ButtonWithTooltip
              bsStyle="default"
              bsSize="small"
              btnClassName="btn-simple font-theme pdl-0"
              tooltip="Refresh"
              pullRight
              onClick={this.getTasks}
              disabled={isLoading}
            >
              <i className={`icn-reload icn-xs ${isLoading ? "icn-spin" : ""}`} />
            </ButtonWithTooltip>

            {canAddTask && <ButtonWithTooltip bsStyle="default" pullRight className="icn-plus icn-xs" onClick={() => this.handleShowTaskModal(-1)} />}
          </>
        }
      >
        {!isLoading && (
          <TasksComponent
            tasks={tasks}
            getTasks={this.getTasks}
            handleShowTaskModal={this.handleShowTaskModal}
            readOnly={readOnly}
            striped={false}
            {...remainingProps}
          />
        )}

        {showTaskModal && (
          <TaskModal
            id={showTaskModal === -1 ? null : showTaskModal}
            project={entityRegistration}
            entityRegistration={entityRegistration}
            entityType={entityName}
            onClose={this.handleTaskModalClose}
            readOnly={readOnly}
            rows={modalRows}
          />
        )}
      </Card>
    )
  }
}

export default withRouter(EntityTasksCard)
