import axios from "axios"

import { getAuthorizationToken, resetHeaderAuthorization } from "@/_services/axios"
import { resetFirebaseToken } from "@/_services/firebase-messaging"
import { addNotification, addOops } from "@/_services/notification"
import { logout as oidcLogout } from "@/_services/oidc"
import { darkReader } from "@/_services/theming"
import { resetServices } from "@/_services/utils"

const logoutRedirectionRoute = "/authentication/login-page"

const state = {
  isAuthenticated: false,
  redirect: null,
  totpToken: null,
  totpUsername: null,
  mfaUserMethods: [], // unused for now, but there already for when we would want to add more MFA methods
  history: null,
  userLocale: null,
  termsOfServices: null,
  loginPayload: null,
}

function isAuthenticated() {
  return state?.isAuthenticated
}

function userHasLoggedOut(shouldRedirect) {
  state.shouldRedirect = shouldRedirect
  state.totpToken = null
  state.totpUsername = null
  state.isAuthenticated = false
}

function userHasLoggedIn({ history, userLocale }) {
  delete state.shouldRedirect
  state.isAuthenticated = true
  state.history = history
  // to do unset it if not supplied
  if (userLocale) state.userLocale = userLocale
  return { userLocale: state.userLocale }
}

function setTermsOfServices({ termsOfServices, loginPayload }) {
  state.termsOfServices = termsOfServices
  state.loginPayload = loginPayload
}

function getTermsOfServices() {
  return state.termsOfServices
}

function removeLoginPayload() {
  state.loginPayload = null
}

function recallLoginPayload() {
  const { loginPayload } = state
  state.loginPayload = null
  return loginPayload
}

function setTotpInfo({ totpToken, totpUsername }) {
  state.totpToken = totpToken
  state.totpUsername = totpUsername
}

function getTotpInfo() {
  return {
    totpToken: state.totpToken,
    totpUsername: state.totpUsername,
  }
}

/**
 * Watch out for the order of calls due to OIDC logout process.
 *
 * We don't await the logout API call to visually logout faster.
 * Also we explicitly reset the header authorization without waiting for the axios interceptor to do it
 * so that we can check the its presence synchronously in the login page.
 */
async function logout({ errorMessage, callLogoutApi = true } = {}) {
  try {
    const authToken = getAuthorizationToken()
    resetHeaderAuthorization()
    resetFirebaseToken()

    // Before logging out with OIDC we must make sure that have deleted from localStorage when we need to.
    // After that, if the user has been redirect to an external page by the OIDC,
    // then we don't bother with resetting the internal state of the app.
    // In short: make sure to remove localStorage data before this call, and put other call after it.
    await oidcLogout()

    if (callLogoutApi) axios.get("/api/core/auth/logout", { headers: { authorization: `Bearer ${authToken}` } })
    if (errorMessage) addNotification({ message: errorMessage, level: "error" })
    userHasLoggedOut()
    resetServices({ logout: true })
    state.history.replace(logoutRedirectionRoute)

    darkReader.disable()
  } catch (error) {
    addOops(error)
  }
}

function shouldRedirect() {
  return state.shouldRedirect
}

export {
  getTermsOfServices,
  getTotpInfo,
  isAuthenticated,
  logout,
  logoutRedirectionRoute,
  recallLoginPayload,
  removeLoginPayload,
  setTermsOfServices,
  setTotpInfo,
  shouldRedirect,
  userHasLoggedIn,
  userHasLoggedOut,
}
