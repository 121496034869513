import React from "react"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import FormInput from "@/_components/FormInput"

import { handleAccessibleOnKeyDown } from "@/_services/utils"

class FormInputInline extends React.Component {
  editInput = event => {
    const { editFunction } = this.props
    const { currentTarget } = event
    event.stopPropagation()
    editFunction(event)
    setTimeout(() => currentTarget.querySelector("input")?.focus())
  }

  render() {
    const {
      readOnly,
      readOnlyDisplayValue,
      addon,
      field,
      postValue,
      wrapperClassName = "",
      newValue,
      originalValue,
      cancelFunction,
      confirmFunction,
      updateValueFunction,
      disabled,
      inArray,
      useTextarea,
      placeholder,
      autoFocus,
    } = this.props

    const className =
      "form-input-inline " + (readOnly ? "form-input-inline-read-only " : "") + (inArray ? "form-input-in-array " : "") + wrapperClassName

    return (
      <div
        className={className}
        onClick={event => this.editInput(event)}
        onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: () => this.editInput(event) })}
      >
        {addon?.position === "left" && addon.value !== undefined && <div className="mr-2">{addon.value}</div>}

        {readOnly ? (
          <div className="flex-center">
            {readOnlyDisplayValue || newValue || originalValue}
            {!disabled && <ButtonWithTooltip className="icn-pencil icn-xs text-success" btnClassName="flex-center pd-0 m-0" tooltip="Edit" />}
            {postValue}
          </div>
        ) : (
          <FormInput
            inArray
            value={newValue || originalValue}
            field={field}
            onSetState={patch => updateValueFunction(patch)}
            onEnter={() => confirmFunction()}
            onEscape={() => cancelFunction()}
            readOnly={readOnly}
            // we allow displaying a textarea when the line is long so that all text is shown,
            // BUT we don't add support for using Enter because we don't want a real textarea (remember we are inline here)
            type={useTextarea ? "textarea" : undefined}
            placeholder={placeholder}
            autoFocus={autoFocus}
          />
        )}

        {!readOnly && (
          <div className="form-input-inline-controls flex-center">
            {cancelFunction && (
              <ButtonWithTooltip
                className="icn-xmark icn-xs"
                btnClassName="flex-center pd-0"
                tooltip="Cancel"
                onClick={event => cancelFunction(event)}
              />
            )}
            <ButtonWithTooltip
              className="icn-check icn-xs"
              btnClassName="flex-center text-success pd-0"
              tooltip="Confirm"
              onClick={event => confirmFunction(event)}
            />
          </div>
        )}
      </div>
    )
  }
}

export default FormInputInline
