import { dateNoTime, getFilterFunction, roundCurrency, truncate } from "basikon-common-utils"
import Chartist from "chartist"
import React, { createRef } from "react"
import { Col, Grid, Row } from "react-bootstrap"
import ChartistGraph from "react-chartist"

import { countSearchParams } from "@/_components/AdvancedSearch"
import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import EntityTransitionsButton from "@/_components/EntityTransitionsButton"
import LayoutCard from "@/_components/LayoutCard"
import PersonLink from "@/_components/PersonLink"
import SearchRowComponent from "@/_components/SearchRowComponent"
import Table, { filterColumns } from "@/_components/Table"

import GeneratePaymentFileButton from "@/billing/GeneratePaymentFileButton"

import { getEntityDisplay, handleEntitiesPageRowClick, handleEntitiesPageSelectAll } from "@/_services/entity"
import { getLabel, getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { getPayerPayeeValues } from "@/_services/personUtils"
import { fetchPinnedEntities, getPageConfig } from "@/_services/userConfiguration"
import {
  applyPageAdvancedSearchConfig,
  applyPageConfigFields,
  getClientRouteKey,
  getEntities,
  hasQueryParamsChanged,
  mergeQueryParams,
  searchEntities,
  searchParamToObject,
} from "@/_services/utils"

class CashflowsPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { pathname: clientRoute },
    } = props

    const pageConfigKey = getClientRouteKey(clientRoute) || "CashflowsPage"
    const pageConfig = getPageConfig(pageConfigKey)

    this.state = {
      entityName: "Cashflow",
      pinnedData: [],
      cashflows: [],
      transitions: [],
      loading: false,
      transitionsLoading: false,
      selectedEntities: new Set(),
      pageConfigKey,
      pageConfig,
      columns: filterColumns(
        [
          { title: "Registration", name: "registration", linkTo: "/cashflow/{registration}" },
          { title: "Organization", name: "organization.name", hidden: true },
          { title: "Status", name: "status", select: "cashflowStatus", badge: true },
          { title: "Date", name: "paymentDate", type: "date" },
          { title: "Type", name: "type", select: "cashflowType" },
          { title: "Invoice", name: "invoice", linkTo: "/invoice/{invoiceRegistration}" },
          { title: "Contract", name: "contractRegistration", linkTo: "/contract/{contractRegistration}" },
          { title: "ContractLot", name: "contractLot", linkTo: "/contract-lot/{contractLotRegistration}", hidden: true },
          { title: "Asset", name: "asset", linkTo: "/asset/{assetRegistration}", hidden: true },
          { title: "AssetLot", name: "assetLot", linkTo: "/asset-lot/{assetLotRegistration}", hidden: true },
          { title: "Payer", name: "payer" },
          { title: "Payee", name: "payee" },
          { title: "Person", name: "payerOrPayee" },
          { title: "Method", name: "method", select: "paymentMode" },
          { title: "Iban", name: "bankIban" },
          { title: "Amount", name: "amount", excelFormat: "currency", type: "currency", currencyPath: "currency" },
        ],
        pageConfig?.columns,
      ),
    }

    this.preventAutoRefresh = createRef()
  }

  componentDidMount() {
    const { pageConfigKey } = this.state
    this.getCashflows()

    getList("paymentMode", () => this.setState({ loaded: true }))
    getList("cashflowType", () => this.setState({ loaded: true }))
    getList("cashflowStatus", () => this.setState({ loaded: true }))

    applyPageAdvancedSearchConfig(pageConfigKey, cashflowSearchFields)
  }

  componentDidUpdate(prevProps) {
    if (!this.preventAutoRefresh.current && hasQueryParamsChanged(this.props, prevProps)) {
      this.getCashflows()
    }
  }

  getCashflows = async params => {
    const { location, history } = this.props
    const { pageConfig, columns, entityName } = this.state
    let queryParams = { ...searchParamToObject(location.search), ...(params || {}) }

    this.setState({ loading: true })
    const allQueryParams = {
      ...queryParams,
      ...(pageConfig?.query || {}),
      include,
      projection: pageConfig?.query?.projection || pageConfig?.projection || queryParams.projection,
    }
    const include =
      pageConfig?.query?.include || "contractNames" + (columns.find(it => it.name === "organization.name" && !it.hidden) ? ",organizationName" : "")
    let [cashflows, pinnedData] = await Promise.all([getEntities("cashflow", allQueryParams), fetchPinnedEntities({ entityName, queryParams })])

    cashflows = applyPageConfigFields(pageConfig, cashflows)
    pinnedData = applyPageConfigFields(pageConfig, pinnedData)

    if (params) {
      this.preventAutoRefresh.current = true
      queryParams.page = 1
      queryParams = mergeQueryParams(location.search, queryParams)
      history.replace(`/cashflows${queryParams}`)
      this.preventAutoRefresh.current = false
    }

    // Fetch persons in order to show their names in table
    const personRegistrations = Array.from(
      new Set(
        cashflows
          .map(cashflow => [!cashflow.payeeName && cashflow.payeeRegistration, !cashflow.payerName && cashflow.payerRegistration])
          .flat()
          .filter(r => r && r !== "TENANT"),
      ),
    )

    if (personRegistrations.length) {
      const persons = await getEntities("person", { registration: personRegistrations.join(","), projection: "registration,name" })

      const personsMap = new Map()
      for (const person of persons) personsMap.set(person.registration, person.name)

      for (const cashflow of cashflows) {
        if (!cashflow.payerName && cashflow.payerRegistration) cashflow.payerName = personsMap.get(cashflow.payerRegistration)
        if (!cashflow.payeeName && cashflow.payeeRegistration) cashflow.payeeName = personsMap.get(cashflow.payeeRegistration)
      }
    }

    this.setState(
      {
        cashflows,
        pinnedData,
        loading: false,
        selectedEntities: new Set(), // Reset selected entities after getting new entities
      },
      this.computeDataBar,
    )
  }

  computeDataBar = () => {
    let cashflows = this.state.cashflows.filter(it => it.paymentDate)

    let minDate = new Date()
    let maxDate = new Date()
    cashflows.forEach(it => {
      if (minDate > it.paymentDate) minDate = it.paymentDate
      if (maxDate < it.paymentDate) maxDate = it.paymentDate
    })

    let maxYear = maxDate.getFullYear()
    let maxMonth = maxDate.getMonth()
    let year = minDate.getFullYear()
    let month = minDate.getMonth()
    let yearlyMonths = {}
    let flatMonths = []
    for (;;) {
      let strYear = year.toString()
      yearlyMonths[strYear] = yearlyMonths[strYear] || {}
      let obj = {
        date: dateNoTime(new Date(year, month, 1)),
        amount: 0,
        balance: 0,
      }
      yearlyMonths[strYear][month.toString()] = obj
      flatMonths.push(obj)
      month++
      if (month === 12) {
        month = 0
        year++
      }
      if (year > maxYear || (year === maxYear && month > maxMonth)) break
    }
    for (let cashflow of cashflows) {
      let obj = yearlyMonths[cashflow.paymentDate.getFullYear().toString()][cashflow.paymentDate.getMonth().toString()]
      if (cashflow.pr === "P") obj.amount -= cashflow.amount
      else obj.amount += cashflow.amount || 0
    }
    let balance = 0
    flatMonths.forEach(it => {
      balance += it.amount || 0
      it.balance = balance
    })

    let dataBar = {
      labels: [],
      series: [
        {
          name: "series-1",
          data: flatMonths.map(it => it.balance),
        },
        {
          name: "series-2",
          data: flatMonths.map(it => it.amount),
        },
      ],
    }
    let optionsBar = {
      fullWidth: true,
      // Within the series options you can use the series names
      // to specify configuration that will only be used for the
      // specific series.
      series: {
        "series-1": {
          lineSmooth: Chartist.Interpolation.simple(),
          showPoint: false,
        },
        "series-2": {
          lineSmooth: Chartist.Interpolation.step(),
          showPoint: false,
          showLine: false,
          showArea: true,
        },
      },
    }
    let responsiveBar = [
      // [
      // "screen and (max-width: 640px)",
      // {
      // seriesBarDistance: 5,
      // axisX: {
      // labelInterpolationFnc: function(value) {
      // return value[0]
      // }
      // }
      // }
      // ]
    ]

    this.setState({ dataBar, optionsBar, responsiveBar })
  }

  computeDataBar2 = () => {
    let dataBar = {
      labels: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      series: [
        {
          name: "series-1",
          data: [542, 443, -320, 780, 553, -453, 326, 434, 568, 610, 756, 895],
        },
        {
          name: "series-2",
          data: [412, 243, -280, 580, -453, 353, 300, 364, 368, 410, 636, 695],
        },
      ],
    }
    let optionsBar = {
      fullWidth: true,
      // Within the series options you can use the series names
      // to specify configuration that will only be used for the
      // specific series.
      series: {
        "series-1": {
          lineSmooth: Chartist.Interpolation.simple(),
          showPoint: false,
        },
        "series-2": {
          lineSmooth: Chartist.Interpolation.step(),
          showPoint: false,
          showLine: false,
          showArea: true,
        },
      },
    }
    let responsiveBar = [
      [
        "screen and (max-width: 640px)",
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0]
            },
          },
        },
      ],
    ]

    this.setState({ dataBar, optionsBar, responsiveBar })
  }

  formatTableData = data => {
    return data.map(cashflow => {
      const { payerRegistration, payerName, payerBankAccount = {}, payeeRegistration, payeeName, payeeBankAccount = {} } = cashflow

      const payerLink = <PersonLink registration={payerRegistration}>{truncate(payerName, 17) || payerRegistration}</PersonLink>
      const payeeLink = <PersonLink registration={payeeRegistration}>{truncate(payeeName, 17) || payeeRegistration}</PersonLink>
      let invoiceRegistration = cashflow.invoiceRegistration || cashflow.allocations?.[0]?.invoiceRegistration
      let invoice = invoiceRegistration
      if (!cashflow.allocations?.every(it => it.invoiceRegistration === invoiceRegistration)) {
        invoiceRegistration = null
        invoice = loc`Multiple`
      }

      return {
        ...cashflow,
        id: cashflow.registration,
        invoice,
        invoiceRegistration,
        payer: payerLink,
        payee: payeeLink,
        payerOrPayee: cashflow.pr === "R" ? payerLink : payeeLink,
        bankIban: cashflow.pr === "R" ? payeeBankAccount.bankIban : payerBankAccount.bankIban,
      }
    })
  }

  render() {
    const {
      entityName,
      cashflows = [],
      selectedEntities,
      transitions = [],
      optionsBar,
      dataBar,
      responsiveBar,
      loading,
      transitionsLoading,
      pageConfig,
      columns,
      pinnedData,
    } = this.state
    const { location, history } = this.props

    const title = loc(pageConfig?.title || "Cashflows")
    const queryParams = searchParamToObject(location.search)
    const searchParamsNb = countSearchParams(queryParams, cashflowSearchFields)
    const isAdvancedSearch = searchParamsNb > 0

    const { filter = "" } = queryParams
    const filteredCashflows = filter?.trim() ? filterCashflows(cashflows, filter) : cashflows

    const action = selectedEntities.size ? (
      <EntityTransitionsButton
        entities={cashflows}
        entityName={entityName}
        transitions={transitions}
        selectedEntities={selectedEntities}
        transitionsLoading={transitionsLoading}
        handleSelectAll={async selectedEntities => await handleEntitiesPageSelectAll(entityName, this, selectedEntities)}
      />
    ) : (
      <CustomButton
        fill
        pullRight
        bsSize="small"
        bsStyle="primary"
        className="inline-flex-center"
        hidden={pageConfig?.addButton?.hidden}
        onClick={() => history.push(pageConfig?.addButton?.linkTo || "/cashflow")}
      >
        <i className="icn-plus icn-xs mr-5px" />
        {loc(pageConfig?.addButton?.label || "Add")}
      </CustomButton>
    )

    return (
      <Grid fluid className="cashflows-page">
        {!pageConfig?.hideKpis && (
          <LayoutCard noCard rows={[{ type: "content", props: { getContentOnUrlChange: false, name: "cashflows-kpi", noCard: false } }]} />
        )}

        <Row>
          <Col xs={12}>
            <Card title={title} action={action}>
              <SearchRowComponent
                loading={loading}
                entityName={entityName}
                getEntities={this.getCashflows}
                searchFields={cashflowSearchFields}
                advancedSearch={pageConfig?.advancedSearch}
                hideAutocomplete={pageConfig?.hideAutocomplete}
                exportData={{ data: filteredCashflows, columns, fileName: title, ...(pageConfig?.exportData || {}) }}
              />
              <Row>
                <Col xs={12}>
                  <Table
                    data={this.formatTableData(filteredCashflows)}
                    columns={columns}
                    loading={loading}
                    selectedEntities={selectedEntities}
                    rowsCount={(isAdvancedSearch || queryParams.range) && cashflows.length}
                    onRowClick={!pageConfig?.transitionsButton?.hidden && (async row => await handleEntitiesPageRowClick(entityName, this, row))}
                    sideView={pageConfig?.sideView}
                    entityName={entityName}
                    pinnedData={pinnedData}
                  />
                </Col>
              </Row>
              {!pageConfig?.hideGeneratePaymentFileButton && (
                <Row>
                  <Col xs={12}>
                    <GeneratePaymentFileButton />
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card title={loc`History`}>
              <Row>
                <Col xs={12}>
                  <ChartistGraph data={dataBar} type="Line" options={optionsBar} responsiveOptions={responsiveBar} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default CashflowsPage

export function filterCashflows(cashflows = [], filter) {
  if (!filter) return cashflows

  const locale = getLocale()
  const includes = getFilterFunction(filter, locale)

  return cashflows.filter(cashflow => {
    const typeLabel = getLabel("cashflowType", cashflow.type)
    const statusLabel = getLabel("cashflowStatus", cashflow.status)
    const methodLabel = getLabel("paymentMode", cashflow.method)

    return (
      includes(typeLabel) ||
      includes(statusLabel) ||
      includes(methodLabel) ||
      includes(cashflow.type) ||
      includes(cashflow.name) ||
      includes(cashflow.method) ||
      includes(cashflow.status) ||
      includes(cashflow.payeeName) ||
      includes(cashflow.payerName) ||
      includes(cashflow.paymentDate) ||
      includes(cashflow.description) ||
      includes(cashflow.registration) ||
      includes(cashflow.contractName) ||
      includes(cashflow.sepaRegistration) ||
      includes(cashflow.payeeRegistration) ||
      includes(cashflow.payerRegistration) ||
      includes(cashflow.internalReference) ||
      includes(cashflow.externalReference) ||
      includes(cashflow.assetRegistration) ||
      includes(cashflow.assetLotRegistration) ||
      includes(getEntityDisplay(cashflow)) ||
      includes(cashflow.personRegistration) ||
      includes(cashflow.invoiceRegistration) ||
      includes(cashflow.contractRegistration) ||
      includes(cashflow.contractLotRegistration) ||
      includes(cashflow.payerBankAccount?.bankIban) ||
      includes(cashflow.payeeBankAccount?.bankIban) ||
      includes(roundCurrency(cashflow.amount || 0, locale).toString())
    )
  })
}

async function _quickSearchPersons(search) {
  let persons = await searchEntities("person", search, { roles: "-SALES" })
  return getPayerPayeeValues(
    null,
    persons.map(it => ({ personRegistration: it.registration, person: it })),
    { richValuesList: true },
  )
}

export const cashflowSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "description", colProps: { xs: 12, sm: 8 }, regex: true },
  ],
  [
    { field: "invoice", colProps: { xs: 12, sm: 4 } },
    { field: "status", type: "multiple", select: "cashflowStatus", colProps: { xs: 12, sm: 8 } },
  ],
  [
    {
      field: "contract",
      searchEntityName: "Contract",
      placeholder: "Type to search for a contract",
      actionHidden: true,
      regex: true,
      colProps: { xs: 12, sm: 3 },
    },
    { field: "contractLot", colProps: { xs: 12, sm: 3 } },
    { field: "asset", colProps: { xs: 12, sm: 3 } },
    { field: "assetLot", colProps: { xs: 12, sm: 3 } },
  ],
  [
    { field: "sepa", label: loc`SEPA`, colProps: { xs: 12, sm: 4 } },
    { field: "method", type: "multiple", select: "paymentMode", colProps: { xs: 12, sm: 4 } },
    { field: "type", type: "multiple", select: "cashflowType", colProps: { xs: 12, sm: 4 } },
  ],
  [
    { field: "internalReference", colProps: { xs: 12, sm: 6 } },
    { field: "externalReference", colProps: { xs: 12, sm: 6 } },
  ],
  [
    { field: "payer", select: _quickSearchPersons, colProps: { xs: 12, sm: 12, md: 6, lg: 6 } },
    { field: "payee", select: _quickSearchPersons, colProps: { xs: 12, sm: 12, md: 6, lg: 6 } },
  ],
  [
    { field: "pr", label: loc`Payable/Receivable`, select: "invoicePr", colProps: { xs: 12, sm: 12, md: 3 } },
    { field: "bankIban", label: loc`IBAN`, colProps: { xs: 12, sm: 12, md: 9 } },
  ],
  [
    { field: "minAmount", type: "currency", label: loc`Amount (min)`, colProps: { xs: 12, sm: 3 } },
    { field: "maxAmount", type: "currency", label: loc`Amount (max)`, colProps: { xs: 12, sm: 3 } },
    { field: "fromPaymentDate", type: "date", label: loc`From date`, colProps: { xs: 12, sm: 3 } },
    { field: "toPaymentDate", type: "date", label: loc`To date`, colProps: { xs: 12, sm: 3 } },
  ],
]
