import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import CustomButton from "@/_components/CustomButton"

class ButtonWithErrors extends React.Component {
  render() {
    const { children, errors, ...buttonProps } = this.props

    return (
      <CustomButton {...{ bsStyle: "primary", fill: true, ...buttonProps }}>
        {children}

        {errors && errors.length > 0 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="errors">
                {errors
                  .filter(e => e)
                  .map(it => (
                    <li key={it.value || it}>{it.value || it}</li>
                  ))}
              </Tooltip>
            }
          >
            <span className="notification">{errors.length}</span>
          </OverlayTrigger>
        )}
      </CustomButton>
    )
  }
}

export default ButtonWithErrors
