import React, { Component } from "react"
import { Modal } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import DocumentsComponent from "@/_components/DocumentsComponent"

import { loc } from "@/_services/localization"

class DocumentsModal extends Component {
  handleClose = () => {
    const { onClose } = this.props
    if (onClose) onClose()
  }

  render() {
    const { serverRoute, entity, entityRegistration, documentTypesList, roles = [], title, entityName, canUpload } = this.props

    return (
      <Modal show={true} onHide={this.handleClose} bsSize="lg" className="documents-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc(title || "Documents")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DocumentsComponent
            roles={roles}
            entity={entity}
            canUpload={canUpload}
            entityName={entityName}
            serverRoute={serverRoute}
            documentTypesList={documentTypesList}
            entityRegistration={entityRegistration}
            noCard
            inModal
          />
        </Modal.Body>

        <Modal.Footer>
          <CustomButton simple onClick={() => this.handleClose()}>
            {loc`Close`}
          </CustomButton>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default DocumentsModal
