import React from "react"

import axios from "axios"
import cloneDeep from "lodash.clonedeep"
import { Col, Row } from "react-bootstrap"

import Card from "@/_components/Card"
import FormInput from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"
import PanelInner from "@/_components/PanelInner"
import { filterColumns } from "@/_components/Table"
import TableExtended from "@/_components/TableExtended"

import { getTaxes } from "@/_services/coreUtils"
import { getList, getRichValues, getValues } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { getSchemesRolesValues } from "@/_services/personUtils"
import { getConfigAtPath } from "@/_services/userConfiguration"
import { generateRows } from "@/_services/utils"

class ProductElementCard extends React.Component {
  state = {
    taxes: [],
    productTables: [],
    productTablesCodeValues: [],
  }

  componentDidMount() {
    getList("personRole", () => this.setState({ personRoleListLoaded: true }))
    getList("costCalculationBasis", () => this.setState({ costCalculationBasisListLoaded: true }))

    getTaxes().then(taxes => this.setState({ taxes }))
    this.getProductTablesCodes()
  }

  getProductTablesCodes = async () => {
    const productTables = (await axios.get("/api/financing/product-tables?projection=code,registration")).data
    const productTablesCodeValues = Array.from(new Set(productTables.map(it => it.code)))

    this.setState({ productTables, productTablesCodeValues })
  }

  getProductTableLink = code => {
    const { productTables = [] } = this.state
    if (!code || productTables.length === 0) return
    const registration = productTables.find(it => it.code === code)?.registration
    if (!registration) return
    return `/product-table/${registration}`
  }

  handleSetProductElementState = async patch => {
    if (!patch) return
    const { handleSetProductElementState } = this.props

    if ("reversalPersonRegistration" in patch) {
      patch.reversalPerson = (await axios.get("/api/person/persons/" + patch.reversalPersonRegistration)).data
    }

    // Reset "principalTaxCode" && "interestTaxCode" when adding a "taxCode"
    if (patch.taxCode) {
      patch.interestTaxCode = null
      patch.principalTaxCode = null
    }

    // Reset tax codes when unchecking "taxable"
    if ("taxable" in patch && !patch.taxable) {
      patch.taxCode = null
      patch.interestTaxCode = null
      patch.principalTaxCode = null
    }

    // If an element basis is selected then "costCalculationBasis" must be "elementCostAmount"
    if (patch.elementCodeBasis) patch.costCalculationBasis = "elementCostAmount"

    handleSetProductElementState(patch)
  }

  handleSetOptionState = async (index, patch) => {
    const { productElement = {} } = this.props
    const options = [...(productElement.options || [])]
    options[index] = { ...(options[index] || {}), ...patch }
    this.handleSetProductElementState({ options })
  }

  handleAddOption = async () => {
    const { productElement = {} } = this.props
    const options = [...(productElement.options || [])]
    options.push({})
    this.handleSetProductElementState({ options })
  }

  handleDeleteOption = async index => {
    const { productElement = {} } = this.props
    const options = [...(productElement.options || [])]
    options.splice(index, 1)
    this.handleSetProductElementState({ options })
  }

  handleDuplicateOption = index => {
    const { productElement = {} } = this.props
    const options = [...(productElement.options || [])]
    const option = options[index]
    options.splice(index, 0, cloneDeep(option))
    this.handleSetProductElementState({ options })
  }

  handleSetGaapState = async (index, patch) => {
    const { productElement = {} } = this.props
    const gaaps = [...(productElement.gaaps || [])]
    gaaps[index] = { ...(gaaps[index] || {}), ...patch }
    this.handleSetProductElementState({ gaaps })
  }

  handleAddGaap = async () => {
    const { productElement = {} } = this.props
    const gaaps = [...(productElement.gaaps || [])]
    gaaps.push({})
    this.handleSetProductElementState({ gaaps })
  }

  handleDeleteGaap = async index => {
    const { productElement = {} } = this.props
    const gaaps = [...(productElement.gaaps || [])]
    gaaps.splice(index, 1)
    this.handleSetProductElementState({ gaaps })
  }

  handleDuplicateGaap = index => {
    const { productElement = {} } = this.props
    const gaaps = [...(productElement.gaaps || [])]
    const gaap = gaaps[index]
    gaaps.splice(index, 0, cloneDeep(gaap))
    this.handleSetProductElementState({ gaaps })
  }

  getField = (col, element = {}, onSetState) => {
    const { name, colProps, formInputProps, hidden, columns, title, collapse, readOnly: colReadOnly, showAdd, showDelete, showDuplicate } = col
    if (hidden) return
    let { readOnly } = this.props
    const { modelPath, elementTypeBasisSelect = [], elementCodeBasisSelect = [] } = this.props
    const { taxes, productTablesCodeValues } = this.state
    readOnly = typeof colReadOnly === "boolean" ? colReadOnly : typeof readOnly === "boolean" ? readOnly : element.readOnly
    const props = {
      key: name,
      readOnly,
      obj: element,
      onSetState,
      modelPath,
    }

    let taxesValues
    if (["interestTaxCode", "principalTaxCode", "taxCode"].includes(name)) {
      taxesValues = taxes?.map(tax => ({ value: tax.code, label: tax.label || loc(tax.code) }))
    }

    let schemeRoleValues
    if (["payeeRole", "payerRole"].includes(name)) {
      schemeRoleValues = getSchemesRolesValues()
    }

    let basisValues
    if (["costCalculationBasis", "costMaxCalculationBasis"].includes(name)) {
      basisValues = getValues("costCalculationBasis")
      if (basisValues && element.elementCodeBasis) {
        basisValues = basisValues.filter(it =>
          [
            "elementCostAmount",
            "elementCostAmountInclTax",
            "elementCostAmountAsIs",
            "elementPaymentExclTax",
            "elementPaymentInclTax",
            "elementTotalPaymentsExclTax",
            "elementTotalPaymentsInclTax",
          ].includes(it.value),
        )
      }
    }

    let showCostMax
    if (["costMaxRatio", "costMaxCalculationBasis", "costMaxType"].includes(name)) showCostMax = getConfigAtPath("ProductPage.showCostMax")

    if (name === "type")
      return (
        <FormInput field="type" richValuesList select="elementType" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
      )
    else if (name === "code") return <FormInput field="code" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
    else if (name === "name") return <FormInput field="name" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
    else if (name === "pr")
      return (
        <FormInput
          field="pr"
          label="Payable/Receivable"
          select="invoicePr"
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "occurrenceMode")
      return (
        <FormInput
          field="occurrenceMode"
          richValuesList
          select="occurrenceMode"
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "occurrenceOffset")
      return (
        <>
          <FormInput
            field="occurrenceOffset"
            select="occurrenceOffset"
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
          {element.occurrenceOffset === "focs" && (
            <>
              <FormInput
                field="firstOccurrenceOtherInstallmentOffset"
                select="occurrenceOffset2"
                colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
                {...props}
                {...formInputProps}
              />
              <FormInput
                field="firstOccurrencePaymentMode"
                select="paymentMode"
                colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
                {...props}
                {...formInputProps}
              />
            </>
          )}
        </>
      )
    else if (name === "occurrenceOffset2")
      return (
        element.occurrenceOffset && (
          <FormInput
            field="occurrenceOffset2"
            label="Offset 2"
            select="occurrenceOffset2"
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "paidDuringGracePeriod")
      return (
        element.occurrenceMode === "recurringAsContract" && (
          <FormInput
            field="paidDuringGracePeriod"
            type="checkbox"
            showPlaceholder={false}
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "frequency")
      return (
        element.occurrenceMode === "independentOccurrence" && (
          <FormInput field="frequency" select="frequency" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
        )
      )
    else if (name === "duration")
      return (
        ["recurringAsContract", "independentOccurrence"].includes(element.occurrenceMode) && (
          <FormInput field="duration" type="integer" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
        )
      )
    else if (name === "editable")
      return (
        <FormInput
          field="editable"
          type="checkbox"
          showPlaceholder={false}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "optional")
      return (
        <FormInput
          field="optional"
          type="checkbox"
          showPlaceholder={false}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "hidden")
      return (
        <FormInput
          field="hidden"
          type="checkbox"
          showPlaceholder={false}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "deactivated")
      return (
        <FormInput
          field="deactivated"
          type="checkbox"
          showPlaceholder={false}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "inApr")
      return (
        <FormInput
          field="inApr"
          value={element.inApr == null ? true : element.inApr}
          type="checkbox"
          showPlaceholder={false}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "payerRole")
      return (
        <FormInputExtended
          field="payerRole"
          select={schemeRoleValues}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "payerPerson")
      return (
        element.payerRole !== "TENANT" && (
          <FormInputExtended
            field="payerPerson"
            searchEntityName="Person"
            query={
              (!element.payerRole || (getRichValues("personRole") || []).find(it => it.value === element.payerRole)) && {
                role: element.payerRole,
              }
            }
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "payerIban")
      return <FormInputExtended field="payerIban" type="iban" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
    else if (name === "payeeRole")
      return (
        <FormInputExtended
          field="payeeRole"
          select={schemeRoleValues}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "payeePerson")
      return (
        element.payeeRole !== "TENANT" && (
          <FormInputExtended
            field="payeePerson"
            searchEntityName="Person"
            query={
              (!element.payeeRole || (getRichValues("personRole") || []).find(it => it.value === element.payeeRole)) && {
                role: element.payeeRole,
              }
            }
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "payeeIban")
      return <FormInputExtended field="payeeIban" type="iban" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
    else if (name === "groupKeyInvoiceType")
      return (
        <FormInput
          field="groupKeyInvoiceType"
          richValuesList
          select="invoiceType"
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "groupKeyEntity")
      return (
        <FormInput
          field="groupKeyEntity"
          select={["contractRegistration", "payerRegistration", "payeeRegistration", "creditLineRegistration", "elementRegistration"]}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "paymentMode")
      return <FormInput field="paymentMode" select="paymentMode" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
    else if (name === "paymentCondition")
      return (
        <FormInput
          field="paymentCondition"
          select="paymentCondition"
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "currency")
      return <FormInput field="currency" select="currency" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
    else if (name === "costType")
      return <FormInput field="costType" select="costType" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
    else if (name === "elementTypeBasis")
      return (
        ["EQUAL", "RATIO"].includes(element?.costType) && (
          <FormInput
            field="elementTypeBasis"
            label="Element type"
            select={elementTypeBasisSelect}
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "elementCodeBasis")
      return (
        ["EQUAL", "RATIO"].includes(element?.costType) && (
          <FormInput
            field="elementCodeBasis"
            label="Base element"
            select={elementCodeBasisSelect}
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costCalculationBasis")
      return (
        element.costType !== "FIXEDAMOUNT" && (
          <FormInput
            field="costCalculationBasis"
            select={basisValues}
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costRatio")
      return (
        (!element.costType || element.costType === "RATIO") && (
          <FormInput
            field="costRatio"
            minimumFractionDigits={3}
            type="percentage"
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "additionalCost")
      return (
        element.costType !== "FIXEDAMOUNT" &&
        element.costType !== "EQUAL" && (
          <FormInput field="additionalCost" type="number" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
        )
      )
    else if (name === "defaultAmount")
      return (
        element.costType !== "EQUAL" && (
          <FormInput field="defaultAmount" type="currency" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
        )
      )
    else if (name === "taxInclusive")
      return (
        element.costType !== "EQUAL" && (
          <FormInput
            field="taxInclusive"
            showPlaceholder={false}
            type="checkbox"
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costPerYear")
      return (
        element.costType !== "EQUAL" && (
          <FormInput
            label="Cost per year"
            field="costPerYear"
            showPlaceholder={false}
            type="checkbox"
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costMinimumOrFixedAmount")
      return (
        element.costType !== "EQUAL" && (
          <FormInput
            field="costMinimumOrFixedAmount"
            label="Minimum or fixed amount (deprecated)"
            type="currency"
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costMinimum")
      return (
        element.costType !== "EQUAL" && (
          <FormInput
            field="costMinimum"
            label="Minimum amount"
            type="currency"
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costPerInstallment")
      return (
        ["recurringAsContract", "independentOccurrence"].includes(element.occurrenceMode) && (
          <FormInput
            field="costPerInstallment"
            showPlaceholder={false}
            type="checkbox"
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costProductTableCode")
      return (
        element.costType === "PRODUCTTABLE" && (
          <FormInput
            field="costProductTableCode"
            select={productTablesCodeValues}
            labelLinkTo={this.getProductTableLink(element.costProductTableCode)}
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costMaxType")
      return (
        showCostMax && (
          <FormInput field="costMaxType" select="costType" colProps={colProps ?? { xs: 12, sm: 4, md: 3, lg: 2 }} {...props} {...formInputProps} />
        )
      )
    else if (name === "costMaxCalculationBasis")
      return (
        showCostMax &&
        element.costMaxType !== "FIXEDAMOUNT" && (
          <FormInput
            field="costMaxCalculationBasis"
            select={basisValues}
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costMaxRatio")
      return (
        showCostMax &&
        (!element.costMaxType || element.costMaxType === "RATIO") && (
          <FormInput
            field="costMaxRatio"
            minimumFractionDigits={3}
            type="percentage"
            colProps={colProps ?? { xs: 12, sm: 4, md: 3, lg: 2 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costTableType")
      return (
        element.costType === "RATESTABLE" && (
          <FormInputExtended
            field="costTableType"
            select="costProductTableType"
            colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "costRatesTable")
      return (
        element.costType === "RATESTABLE" && (
          <FormInputExtended
            field="costRatesTable"
            type="ratesTable"
            currency={element.currency}
            {...(element.costTableType === "ASR-AGE-DUR" && {
              headerRowFormat: "number",
              headerColFormat: "number",
              format: "percentage",
              options: {
                formatPercentageOptions: {
                  style: "decimal",
                  maximumFractionDigits: 20,
                },
              },
            })}
            {...(element.costTableType === "AMOUNTPERCOST" && {
              headerCol: [0],
              headerRow: ["Amount"],
              headerRowFormat: "string",
              headerColFormat: "currency",
              colFormat: ["currency", "currency"],
            })}
            {...(element.costTableType === "RATEPERCOST" && {
              headerCol: [1000],
              headerRow: ["Rate"],
              headerRowFormat: "string",
              headerColFormat: "currency",
              colFormat: ["currency", "percentage"],
            })}
            {...(element.costTableType === "DURATIONINMONTHS" && {
              headerCol: [12],
              headerRow: [1000],
              headerRowFormat: "currency",
              headerColFormat: "number",
              colFormat: ["currency", "percentage"],
            })}
            colProps={colProps ?? { xs: 12 }}
            {...props}
            {...formInputProps}
          />
        )
      )
    else if (name === "options") {
      return (
        <Col xs={12}>
          <PanelInner title={loc(title ?? "Options")} collapse={collapse}>
            <Row className="mb-10px">
              <Col xs={12}>
                <TableExtended
                  columns={filterColumns(
                    [
                      { title: "Code", name: "code", readOnly, type: "text" },
                      { title: "Name", name: "name", readOnly, type: "text" },
                      { title: "Description", name: "description", readOnly, type: "text" },
                      { title: "Cost ratio", name: "costRatio", readOnly, type: "percentage", minimumFractionDigits: 3 },
                      { title: "Minimum or fixed amount", name: "costMinimumOrFixedAmount", readOnly, type: "currency" },
                    ],
                    columns,
                  )}
                  readOnly={readOnly}
                  data={element.options}
                  modelPath={modelPath} //TODO : to test
                  entity={element}
                  showAdd={showAdd ?? true}
                  showDelete={showDelete ?? true}
                  showDuplicate={showDuplicate ?? false}
                  onSetState={() => {}} //Needed to trigger onRowChange on columns inArray=false !
                  onRowChange={({ row, patch }) => this.handleSetOptionState(row._index, patch)}
                  onRowAdd={() => this.handleAddOption()}
                  onRowDelete={({ row }) => this.handleDeleteOption(row._index)}
                  onRowDuplicate={({ row }) => this.handleDuplicateOption(row._index)}
                />
              </Col>
            </Row>
          </PanelInner>
        </Col>
      )
    } else if (name === "taxable")
      return (
        <FormInput
          field="taxable"
          type="checkbox"
          showPlaceholder={false}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "taxCode")
      return (
        <FormInputExtended
          field="taxCode"
          select={taxesValues}
          hidden={props.obj?.principalTaxCode || props.obj?.interestTaxCode || !props.obj?.taxable}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "principalTaxCode")
      return (
        <FormInputExtended
          field="principalTaxCode"
          select={taxesValues}
          hidden={props.obj?.taxCode || !props.obj?.taxable}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "interestTaxCode")
      return (
        <FormInputExtended
          field="interestTaxCode"
          select={taxesValues}
          hidden={props.obj?.taxCode || !props.obj?.taxable}
          colProps={colProps ?? { xs: 12, sm: 4, md: 3 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "gaaps") {
      return (
        <Col xs={12}>
          <PanelInner title={loc(title ?? "Gaaps")} collapse={collapse}>
            <Row className="mb-10px">
              <Col xs={12}>
                <TableExtended
                  columns={filterColumns(
                    [
                      { title: "Gaap", name: "gaap", readOnly, select: "accountingGaap" },
                      { title: "Journal", name: "journal", readOnly, select: "accountingJournal" },
                      { title: "Has deferred", name: "hasDeferred", readOnly, type: "checkbox" },
                      { title: "Has reversal", name: "hasDeferredReversal", readOnly, type: "checkbox" },
                      { title: "Deferral method", name: "deferralMethod", readOnly, select: "accountingDeferralMethod" },
                      { title: "Has EOM", name: "hasEom", readOnly, type: "checkbox" },
                      { title: "Has EOM reversal", name: "hasEomReversal", readOnly, type: "checkbox" },
                      { title: "EOM method", name: "eomMethod", readOnly, select: "accountingEomMethod" },
                      { title: "In commitment", name: "inCommitment", readOnly, type: "checkbox" },
                      {
                        name: "thirdPartyAccount",
                        readOnly,
                        select: "accountingLedger",
                        colProps: { xs: 12, sm: 4, md: 3 },
                        formInputProps: { inArray: false },
                      },
                      {
                        name: "plAccount",
                        readOnly,
                        select: "accountingLedger",
                        colProps: { xs: 12, sm: 4, md: 3 },
                        formInputProps: { inArray: false },
                      },
                      {
                        name: "deferredAccount",
                        readOnly,
                        select: "accountingLedger",
                        colProps: { xs: 12, sm: 4, md: 3 },
                        formInputProps: { inArray: false },
                      },
                      {
                        name: "pivotAccount",
                        readOnly,
                        select: "accountingLedger",
                        colProps: { xs: 12, sm: 4, md: 3 },
                        formInputProps: { inArray: false },
                      },
                      {
                        name: "secondaryAccountType",
                        readOnly,
                        select: "accountingSecondaryAccountType",
                        colProps: { xs: 12, sm: 4, md: 3 },
                        formInputProps: { inArray: false },
                      },
                    ],
                    columns,
                  )}
                  readOnly={readOnly}
                  data={element.gaaps}
                  modelPath={modelPath} //TODO : to test
                  entity={element}
                  showAdd={showAdd ?? true}
                  showDelete={showDelete ?? true}
                  showDuplicate={showDuplicate ?? true}
                  onSetState={() => {}} //Needed to trigger onRowChange on columns inArray=false !
                  onRowChange={({ row, patch }) => this.handleSetGaapState(row._index, patch)}
                  onRowAdd={() => this.handleAddGaap()}
                  onRowDelete={({ row }) => this.handleDeleteGaap(row._index)}
                  onRowDuplicate={({ row }) => this.handleDuplicateGaap(row._index)}
                />
              </Col>
            </Row>
          </PanelInner>
        </Col>
      )
    } else if (name) {
      return <FormInputExtended key={name} field={name} colProps={colProps ?? { xs: 12 }} {...props} {...formInputProps} />
    }
  }

  render() {
    const { title, collapse, noCard, productElement, rows, debugInfo, modelPath, readOnly } = this.props

    const defaultRows = [
      {
        title: loc`Information`,
        collapse: true,
        rows: [
          ["type", "code", "name", "pr"],
          ["occurrenceMode", "occurrenceOffset", "occurrenceOffset2", "paidDuringGracePeriod", "frequency", "duration"],
          ["editable", "optional", "hidden", "deactivated"],
        ],
      },
      {
        title: loc`Persons`,
        collapse: true,
        rows: [
          ["payerRole", "payerPerson", "payerIban"],
          ["payeeRole", "payeePerson", "payeeIban"],
        ],
      },
      {
        title: loc`Invoicing`,
        collapse: true,
        rows: [
          ["groupKeyInvoiceType", "groupKeyEntity"],
          ["paymentMode", "paymentCondition"],
        ],
      },
      {
        title: loc`Cost`,
        collapse: true,
        rows: [
          ["currency"],
          [
            "costType",
            "elementTypeBasis",
            "elementCodeBasis",
            "costCalculationBasis",
            "costRatio",
            "additionalCost",
            "defaultAmount",
            "taxInclusive",
            "costPerYear",
            "costMinimumOrFixedAmount",
            "costPerInstallment",
            "costProductTableCode",
          ],
          ["costMaxType", "costMaxCalculationBasis", "costMaxRatio"],
          ["costTableType", "costRatesTable"],
        ],
      },
      // "options",
      {
        name: "options",
        collapse: true,
        showAdd: true,
        showDelete: true,
        showDuplicate: false,
        columns: ["code", "name", "description", "costRatio", "costMinimumOrFixedAmount"],
      },
      {
        title: loc`Taxes`,
        collapse: true,
        rows: [["taxable"], ["taxCode", "principalTaxCode", "interestTaxCode"]],
      },
      // "gaaps",
      {
        name: "gaaps",
        collapse: true,
        showAdd: true,
        showDelete: true,
        showDuplicate: true,
        columns: [
          "gaap",
          "journal",
          "hasDeferred",
          "hasDeferredReversal",
          "deferralMethod",
          "hasEom",
          "hasEomReversal",
          "eomMethod",
          "inCommitment",
          "thirdPartyAccount",
          "plAccount",
          "deferredAccount",
          "pivotAccount",
          "secondaryAccountType",
        ],
      },
    ].filter(it => it)

    return (
      <Card debugInfo={{ ...debugInfo, defaultRows }} title={title ? loc(title) : undefined} noCard={noCard} collapse={collapse}>
        {generateRows({
          getField: this.getField,
          rows: rows?.length ? rows : defaultRows,
          entity: productElement,
          handleSetState: this.handleSetProductElementState,
          modelPath,
          readOnly,
        })}
      </Card>
    )
  }
}

export default ProductElementCard
