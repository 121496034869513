import axios from "axios"
import { evalDbQueryMatch, formatDateTime } from "basikon-common-utils"
import debounce from "lodash.debounce"
import React from "react"
import { Col, Dropdown, Row } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"
import FormInputInline from "@/_components/FormInputInline"

import { getLocale, loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { hasPermission } from "@/_services/userConfiguration"
import { handleAccessibleOnKeyDown, onDropdownMenuButtonKeyDown, onDropdownToggleKeyDown, openDocInTab } from "@/_services/utils"

class DocumentComponent extends React.Component {
  constructor(props) {
    super(props)

    const { doc } = props
    const { containerName, fileStorageName, isFileReference } = doc || {}

    this.state = {
      uploading: false,
      showIsOpeningBox: false,
      showDeleteConfirmation: false,
      showDropBox: false,
      originalMetaString: [],
      currentMeta: [],
      metaSaveIsDisabled: true,
      showReferenceControls: false,
      containerName,
      fileStorageName,
      isFileReference,
    }
  }

  componentDidMount = () => {
    const { doc } = this.props
    const { meta = [] } = doc || {}
    this.setState({ originalMetaString: JSON.stringify(meta), currentMeta: meta })
  }

  setMeta = patch => {
    const { originalMetaString, currentMeta } = this.state
    const metaKey = Object.keys(patch)[0]
    const metaText = patch[metaKey]

    if (metaText === "") {
      const newMetaIndex = currentMeta.findIndex(metaItem => metaItem.value === metaKey)
      currentMeta.splice(newMetaIndex, 1)
    } else {
      let isNewMeta = true
      for (let i = 0; i < currentMeta.length; i++) {
        const currentMetaItem = currentMeta[i]
        if (currentMetaItem.value === metaKey) {
          isNewMeta = false
          currentMeta[i].text = metaText
          break
        }
      }

      if (isNewMeta) currentMeta.push({ value: metaKey, text: metaText })
    }

    const metaSaveIsDisabled = originalMetaString === JSON.stringify(currentMeta)
    this.setState({ currentMeta, metaSaveIsDisabled })
  }

  saveMeta = async () => {
    const { currentMeta = [] } = this.state
    const { doc, serverRoute, entityRegistration } = this.props
    this.setState({ metaSaveIsDisabled: true })
    await axios.patch(`${serverRoute}/${entityRegistration}/documents/${doc._id}`, { meta: currentMeta })
  }

  getIconClassName = doc => {
    if (!doc) return
    const ext = doc.name?.split(".").pop()
    let iconClassName = "font-1-5 icn-xs"
    iconClassName += doc.error ? " text-danger" : " text-dark"
    switch (ext) {
      case "jpeg":
      case "png":
      case "gif":
      case "jpg":
        return (iconClassName += " icn-image")
      case "pdf":
        return (iconClassName += " icn-pdf-file")
      case "doc":
      case "docx":
        return (iconClassName += " icn-doc-file")
      case "xls":
      case "xlsx":
        return (iconClassName += " icn-excel-file")
      case "zip":
        return (iconClassName += " icn-zip-file")
      default:
        return (iconClassName += " icn-file")
    }
  }

  previewDocument = async (event, id, docName) => {
    event.preventDefault()
    const { serverRoute, entityRegistration } = this.props
    this.setState({ showIsOpeningBox: true })
    try {
      const blob = (
        await axios.get(`${serverRoute}/${entityRegistration}/documents/${id}/content`, {
          responseType: "blob",
        })
      ).data
      openDocInTab({ blob, docName })
    } catch (error) {
      addOops(error)
    } finally {
      this.setState({ showIsOpeningBox: false })
    }
  }

  confirmDelete = event => {
    event.stopPropagation()
    this.setState({ showDeleteConfirmation: true })
  }

  validateDelete = async _id => {
    const { deleteDocument } = this.props
    await deleteDocument(_id)
    this.setState({
      showDeleteConfirmation: false,
      originalMetaString: "",
      currentMeta: [],
      containerName: "",
      fileStorageName: "",
    })
  }

  handleDragEnter = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleDragLeave = event => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ showDropBox: false })
  }

  handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
    event.dataTransfer.dropEffect = "copy"
    this.setState({ showDropBox: true })
  }

  handleDragDrop = (event, type, _id, groupIndex) => {
    event.preventDefault()
    event.stopPropagation()

    // kind of hacky
    event.target = event.dataTransfer

    this.setState({ showDropBox: false })
    this.handleUploadDocument({ event, type, _id, groupIndex })
  }

  handleUploadDocument = async args => {
    const { uploadDocument } = this.props
    this.setState({ uploading: true })
    await uploadDocument(args)
    this.setState({ uploading: false })
  }

  cancelEditDocumentName = event => {
    event?.stopPropagation()
    this.setState({ documentChangingName: null, documentNewName: null })
  }

  saveDocumentNewName = async event => {
    event?.stopPropagation()
    const { documentNewName } = this.state
    const { doc, patchDocument } = this.props

    await patchDocument(doc._id, { name: documentNewName })
    this.cancelEditDocumentName()
  }

  selectContainer = patch => {
    const { containerName } = this.state
    if (patch.containerName !== containerName) patch.fileStorageName = ""
    this.setState(patch)
  }

  selectFile = patch => {
    patch.isFileReference = true
    this.setState(patch)
  }

  getSelectFileList = async query => {
    const { containerName } = this.state
    if (!containerName || !query) return []
    const { data } = await axios.get(`/api/external/object-storage/documents?containerName=${containerName}&limit=30&prefix=${query}`)
    return data.map(({ name }) => {
      return {
        value: name,
        label: name,
      }
    })
  }

  render() {
    const {
      uploading,
      currentMeta,
      metaSaveIsDisabled,
      documentNewName,
      documentChangingName,
      showReferenceControls,
      containerName,
      fileStorageName,
      isFileReference,
      showIsOpeningBox,
      showDeleteConfirmation,
      showDropBox,
    } = this.state
    const {
      colProps = { xs: 12 },
      btnColProps,
      title,
      type,
      typeLabel,
      description,
      groupIndex,
      doc = {},
      documentStatuses,
      listDocumentMeta,
      showMetadata,
      patchDocument,
      downloadDocument,
      uploadProgressStatus,
      canUpload = true,
      isMandatory,
      entityRegistration,
      showFileReferenceButton,
      objectStorageContainers,
      inModal,
      documentsPermissions,
    } = this.props

    const { _id, name, status = "DRAFT", error, _insertDate } = doc

    // In most cases the back-end will not send the documents
    // that users don't have read access to if documents permissions are defined,
    // so in these case this code is superfluous.
    let hasReadDocumentPermission = true
    if (documentsPermissions?.read?.filter !== undefined) {
      if (documentsPermissions.read.filter === false) {
        hasReadDocumentPermission = false
      } else if (typeof documentsPermissions.read.filter === "object") {
        hasReadDocumentPermission = evalDbQueryMatch({ type, status }, documentsPermissions.read.filter)
      }
    }
    if (!hasReadDocumentPermission) return null

    let readOnly = this.props.readOnly
    let allowedNewStatus = null
    if (documentsPermissions?.write?.filter !== undefined) {
      if (documentsPermissions.write.filter === false) {
        readOnly = true
      } else if (typeof documentsPermissions.write.filter === "object") {
        readOnly = !evalDbQueryMatch({ type, status }, documentsPermissions.write.filter)
        if (documentsPermissions.write.filter.status) {
          if (typeof documentsPermissions.write.filter.status === "string") {
            allowedNewStatus = [documentsPermissions.write.filter.status]
          } else if (Array.isArray(documentsPermissions.write.filter.status)) {
            allowedNewStatus = documentsPermissions.write.filter.status
          }
        }
      }
    }
    const canPatchStatus = !readOnly && hasPermission("core.patchDocumentStatus")

    let hasDeletePermission = status === "DRAFT"
    if (documentsPermissions?.delete?.filter !== undefined) {
      if (documentsPermissions.delete.filter === false) {
        hasDeletePermission = false
      } else if (typeof documentsPermissions.delete.filter === "object") {
        hasDeletePermission = evalDbQueryMatch({ type, status }, documentsPermissions.delete.filter)
      }
    }

    const locale = getLocale()
    const extension = name?.split(".").pop()
    const canPreview = ["jpg", "jpeg", "png", "pdf"].includes(extension?.toLowerCase())

    const canDelete = _id && !readOnly && hasDeletePermission
    const formattedTitle = typeof title === "string" && title.length > 30 ? `${title.substring(0, 26)}...${extension}` : title
    const documentStatusIcons = documentStatuses && documentStatuses.filter(s => s.icon)
    const documentStatusBtns = documentStatuses && documentStatuses.filter(s => s.btn)
    const documentStatusOptions = documentStatuses && documentStatuses.filter(s => !s.icon && !s.btn)
    const isDocumentLoaded = doc._id
    const docStatus = (documentStatuses && documentStatuses.find(s => s.value === status)) || { label: status }

    let inputFileId = `document-${type}`
    if (groupIndex !== undefined) inputFileId += `-${groupIndex}`
    if (_id) inputFileId += `-${_id}`
    if (entityRegistration) inputFileId += `-${entityRegistration}`

    const canEditMedata = !readOnly && doc._id

    let listDocumentMetaFiltered = []
    if (Array.isArray(listDocumentMeta))
      listDocumentMetaFiltered = (listDocumentMeta || []).filter(listDocumentMetaItem => typeof listDocumentMetaItem === "object")

    const onWrapperClick = event => {
      if (!canUpload || showDeleteConfirmation || error) return
      if (_id) {
        if (canPreview) this.previewDocument(event, _id, formattedTitle)
        else downloadDocument(event, _id)
      } else {
        // inModal is needed when the same documents are displayed several times in the same screen
        // like one instance in a workflow transition modal and another in a layout card,
        // otherwise inputFileId would not be unique and the click on the hidden form input would mess things up
        document.querySelector(`${inModal ? ".documents-modal " : ""}#${inputFileId}`).click()
      }
    }

    return (
      <Col {...(btnColProps || colProps)} className="document">
        <div
          tabIndex={isDocumentLoaded ? "0" : "-1"}
          onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: () => onWrapperClick(event) })}
          className={"document-wrapper " + (canUpload ? "" : "document-upload-disabled")}
          // Seems like adding data-model-field-path itnt sufficient to show the `?` icon in debug mode, but at least,
          // we can point errors to missing mandatory documents. I don't get why it isnt sufficient for the `?` icon tho.
          data-model-field-path={`documents.${type}`}
          data-show-drop-area-hint={canUpload && showDropBox}
          onDrop={event => canUpload && this.handleDragDrop(event, type, _id, groupIndex)}
          onDragOver={event => canUpload && this.handleDragOver(event)}
          onDragEnter={event => canUpload && this.handleDragEnter(event)}
          onDragLeave={event => canUpload && this.handleDragLeave(event)}
          onClick={onWrapperClick}
        >
          <input
            id={inputFileId}
            type="file"
            multiple
            onChange={event => this.handleUploadDocument({ event, type, _id, groupIndex })}
            style={{ display: "none" }}
          />

          <div className="document-identification">
            <div className="document-type flex align-items-center" data-is-document-loaded={isDocumentLoaded}>
              <span className={typeLabel?.split()?.length === 1 ? `overflow-hidden text-ellipsis` : ""} title={typeLabel}>
                {typeLabel}
                {isMandatory ? <i className="icn-asterisk icn-xxs text-danger" /> : ""}
              </span>
              <i className={`${this.getIconClassName(doc)} d-inline-block ${isMandatory ? "" : "ml-5px"}`} />
            </div>
            {(isDocumentLoaded || description) && (
              <div className="document-name">
                <FormInputInline
                  field="documentNewName"
                  originalValue={isDocumentLoaded ? formattedTitle : description}
                  wrapperClassName="height-auto"
                  readOnly={readOnly || !isDocumentLoaded || documentChangingName !== _id || (doc.containerName && doc.fileStorageName)}
                  disabled={readOnly || !isDocumentLoaded || (doc.containerName && doc.fileStorageName)}
                  confirmFunction={this.saveDocumentNewName}
                  cancelFunction={this.cancelEditDocumentName}
                  newValue={isDocumentLoaded ? (documentChangingName === _id ? documentNewName : formattedTitle || "<empty>") : description}
                  updateValueFunction={({ documentNewName }) => this.setState({ documentNewName })}
                  editFunction={() => this.setState({ documentChangingName: _id, documentNewName: title })}
                />
                {isDocumentLoaded && _id && showMetadata && (
                  <FormInputInline
                    field="_insertDate"
                    originalValue={formatDateTime(_insertDate, locale)}
                    readOnly={true}
                    wrapperClassName="height-auto"
                  />
                )}
              </div>
            )}
          </div>

          {uploading || uploadProgressStatus === true || showIsOpeningBox ? (
            <CustomButton bsStyle="success" bsSize="xs" simple>
              <i className="icn-circle-notch icn-spin icn-xs text-success" /> {showIsOpeningBox ? loc`Opening` : ""}
            </CustomButton>
          ) : (
            <div className="document-controls">
              {showDeleteConfirmation ? (
                <div className="document-delete-confirmation">
                  <span className="mr-5px text-danger">{loc`Delete`}</span>
                  <div>
                    <CustomButton
                      bsStyle="danger"
                      bsSize="xs"
                      fill
                      className="mr-5px min-w-40px"
                      onClick={() => this.validateDelete(_id)}
                    >{loc`Yes`}</CustomButton>
                    <CustomButton
                      bsStyle="info"
                      bsSize="xs"
                      fill
                      className="min-w-40px"
                      onClick={() => this.setState({ showDeleteConfirmation: false })}
                    >{loc`No`}</CustomButton>
                  </div>
                </div>
              ) : (
                <>
                  {uploadProgressStatus && uploadProgressStatus !== true ? (
                    uploadProgressStatus
                  ) : !doc._id && canUpload ? (
                    <CustomButton bsStyle="info" bsSize="xs" simple>
                      <i className="icn-upload icn-xs text-info" />
                    </CustomButton>
                  ) : null}

                  {/* we need the div here because the SplitButton does not propagate correctly the props to its parent container */}
                  {_id && (
                    <div onClick={event => event.stopPropagation()}>
                      {canPatchStatus && documentStatuses && documentStatusOptions.length > 0 && (
                        <Dropdown
                          id={_id}
                          bsSize="xs"
                          onClick={event => {
                            const { target } = event
                            target.focus()
                            target.nextSibling?.click()
                          }}
                        >
                          <CustomButton bsStyle={`${docStatus.style || "primary"} btn-fill`} className="grow" tabIndex="-1">
                            {docStatus?.label}
                          </CustomButton>
                          <Dropdown.Toggle
                            bsStyle={`${docStatus.style || "primary"} btn-fill`}
                            onKeyDown={onDropdownToggleKeyDown}
                            aria-label={loc("Document status")}
                          />
                          <Dropdown.Menu className="br-theme overflow-hidden border-0">
                            {documentStatusOptions
                              .filter(documentStatusOption => {
                                return (
                                  documentStatusOption.value !== status &&
                                  (allowedNewStatus ? allowedNewStatus.includes(documentStatusOption.value) : true)
                                )
                              })
                              .map((status, key) => (
                                <CustomButton
                                  key={key}
                                  tabIndex="0"
                                  bsStyle="info"
                                  bsSize="small"
                                  fill
                                  className="btn-simple white-space-normal w-100 min-w-200px text-left br-0 m-0 inline-flex align-items-center outline-accessible-inset"
                                  onClick={event => {
                                    event.stopPropagation()
                                    patchDocument(_id, { status: status.value })
                                  }}
                                  onKeyDown={onDropdownMenuButtonKeyDown}
                                >
                                  {status.label}
                                </CustomButton>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}

                      {canPatchStatus && documentStatuses && documentStatusOptions.length === 0 && docStatus && (
                        <CustomButton bsStyle={docStatus.style || "info"} bsSize="xs" fill round>
                          {docStatus.label}
                        </CustomButton>
                      )}

                      {!canPatchStatus && (
                        <CustomButton bsStyle={docStatus.style || "info"} bsSize="xs" fill round>
                          {docStatus.label}
                        </CustomButton>
                      )}

                      {canPatchStatus &&
                        documentStatuses &&
                        documentStatusBtns
                          .filter(s => s.value !== status)
                          .map((status, key) => (
                            <CustomButton
                              key={key}
                              bsSize="xs"
                              pullRight
                              bsStyle={status.style || "primary"}
                              className="mr-5px btn-fill pull-right inline-flex-center"
                              onClick={() => patchDocument(_id, { status: status.value })}
                            >
                              {status.label}
                            </CustomButton>
                          ))}

                      {canPatchStatus &&
                        documentStatuses &&
                        documentStatusIcons
                          .filter(s => s.value !== status)
                          .map((status, key) => (
                            <CustomButton
                              key={key}
                              bsStyle={status.style || "primary"}
                              bsSize="xs"
                              simple
                              className="mr-5px pull-right btn-fill inline-flex-center"
                              onClick={() => patchDocument(_id, { status: status.value })}
                            >
                              <i className={"icn-xs " + (status.icon || "icn-asterisk")} />
                            </CustomButton>
                          ))}
                    </div>
                  )}

                  {canDelete && (
                    <CustomButton bsStyle="danger" className="flex-center ml-2" bsSize="xs" simple onClick={e => this.confirmDelete(e)}>
                      <i className="icn-xmark icn-xs" />
                    </CustomButton>
                  )}

                  {_id && !error && (
                    <CustomButton
                      bsStyle="info"
                      bsSize="xs"
                      className={"flex-center " + (canDelete ? "" : "ml-2")}
                      simple
                      onClick={e => downloadDocument(e, _id)}
                    >
                      <i className="icn-download icn-xs" />
                    </CustomButton>
                  )}

                  {showFileReferenceButton && (!isDocumentLoaded || (isDocumentLoaded && doc.containerName && doc.fileStorageName)) && (
                    <CustomButton
                      bsStyle="info"
                      bsSize="xs"
                      simple
                      onClick={event => {
                        event.stopPropagation()
                        this.setState({ showReferenceControls: !showReferenceControls })
                      }}
                    >
                      <i className="icn-link icn-xs text-info" />
                    </CustomButton>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {showReferenceControls && (
          <Row className="mt-5px">
            <FormInput
              label="Container"
              select={objectStorageContainers}
              colProps={{ xs: 12 }}
              value={containerName}
              field="containerName"
              formGroupClassName="mb-5px"
              onSetState={this.selectContainer}
            />
            <FormInput
              label="Document"
              colProps={{ xs: 12 }}
              value={fileStorageName}
              field="fileStorageName"
              formGroupClassName="mb-5px"
              onSetState={this.selectFile}
              select={debounce((query, callback) => {
                this.getSelectFileList(query).then(resp => callback(resp))
              }, 300)}
            />

            <Col xs={12}>
              <CustomButton
                bsStyle="primary"
                bsSize="small"
                pullRight
                fill
                disabled={!containerName || !fileStorageName}
                onClick={() =>
                  this.handleUploadDocument({
                    _id,
                    type,
                    containerName,
                    fileStorageName,
                    groupIndex,
                    isFileReference,
                  })
                }
              >
                {loc`Save`}
              </CustomButton>
            </Col>
          </Row>
        )}

        {showMetadata && (
          <Row className="mt-5px">
            {listDocumentMetaFiltered?.map(listItem => {
              const metaText = currentMeta?.find(metaItem => metaItem.value === listItem.value)?.text
              return (
                <FormInput
                  key={listItem.value}
                  label={listItem.label}
                  value={metaText}
                  colProps={{ xs: 12 }}
                  field={listItem.value}
                  onSetState={patch => this.setMeta(patch)}
                  formGroupClassName="mb-5px"
                  disabled={!canEditMedata}
                />
              )
            })}

            <Col xs={12}>
              <CustomButton bsStyle="primary" bsSize="small" pullRight fill disabled={metaSaveIsDisabled} onClick={this.saveMeta}>
                {loc`Save`}
              </CustomButton>
            </Col>
          </Row>
        )}
      </Col>
    )
  }
}

export default DocumentComponent
