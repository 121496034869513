const accessLog = {
  // <EntitiesPage/>
  pageConfig: {
    hideKpis: true,
    addButton: { hidden: true },
  },
  columns: [
    { title: "Date", name: "date", type: "datetime" },
    { title: "Status", name: "status", select: "accessLogStatus", badge: true },
    { title: "Method", name: "method" },
    { title: "URL", name: "url" },
    { title: "Server", name: "server" },
    { title: "Tenant", name: "tenant" },
    { title: "Username", name: "username" },
    { title: "Duration", name: "duration", type: "milliseconds" },
    { title: "Size", name: "size", type: "bytes" },
  ],
  searchFields: [
    [
      { field: "server", colProps: { xs: 12, md: 3 }, regex: true },
      { field: "tenant", colProps: { xs: 12, md: 6 }, type: "multiple", select: "/api/core/tenants?list=true" },
      { field: "username", colProps: { xs: 12, md: 3 }, regex: true },
    ],
    [
      { field: "status", select: "accessLogStatus", multiple: true, colProps: { xs: 12, md: 3 } },
      { field: "url", colProps: { xs: 12, md: 6 } },
      { field: "method", select: "httpMethod", multiple: true, colProps: { xs: 12, md: 3 } },
    ],
    [
      { field: "fromDate", type: "datetime", timeFormat: "HH:mm:ss", colProps: { xs: 12, md: 3 } },
      { field: "toDate", type: "datetime", timeFormat: "HH:mm:ss", colProps: { xs: 12, md: 3 } },
      { field: "minDuration", select: "accessLogDuration", creatable: true, colProps: { xs: 12, md: 3 } },
      { field: "maxDuration", select: "accessLogDuration", creatable: true, colProps: { xs: 12, md: 3 } },
    ],
    [
      { field: "sort", select: "accessLogSort", multiple: true, colProps: { xs: 12, md: 6 } },
      { field: "limit", type: "number", colProps: { xs: 12, md: 3 } },
    ],
  ],
}

export default accessLog
