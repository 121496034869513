import axios from "axios"
import { objectToSearchParam } from "basikon-common-utils"

import { getUriAndCacheResponse } from "@/_services/cache"
import { addOops } from "@/_services/notification"

export async function getRootCategories() {
  try {
    const rootCategories = await getUriAndCacheResponse("/api/external/lectura/root-categories?list=true")
    return rootCategories
  } catch (error) {
    addOops(error)
  }
}

export async function getSubCategories(categoryId) {
  try {
    const subCategories = await getUriAndCacheResponse(`/api/external/lectura/categories/${categoryId}/sub-categories?list=true`)
    return subCategories
  } catch (error) {
    addOops(error)
  }
}

export async function getManufacturers(categoryId) {
  try {
    const manufacturers = await getUriAndCacheResponse(`/api/external/lectura/categories/${categoryId}/manufacturers?list=true`)
    return manufacturers
  } catch (error) {
    addOops(error)
  }
}

export async function getModels(categoryId, manufacturerId) {
  try {
    const models = await getUriAndCacheResponse(`/api/external/lectura/categories/${categoryId}/manufacturers/${manufacturerId}/models?list=true`)
    return models
  } catch (error) {
    addOops(error)
  }
}

export async function getModelDetails(modelId) {
  try {
    const model = await getUriAndCacheResponse(`/api/external/lectura/models/${modelId}`)
    return model
  } catch (error) {
    addOops(error)
  }
}

export async function getManufacturerDetails(manufacturerId) {
  try {
    const manufacturer = await getUriAndCacheResponse(`/api/external/lectura/manufacturers/${manufacturerId}`)
    return manufacturer
  } catch (error) {
    addOops(error)
  }
}

export async function search(search, list = true) {
  if (!(search || "").trim()) return []
  try {
    const models = await getUriAndCacheResponse(`/api/external/lectura/search/${search}` + objectToSearchParam({ list }))
    return models
  } catch (error) {
    addOops(error)
  }
}

export async function getRegressorCurves(modelName, manufacturerName, year, usage) {
  if (!modelName || !manufacturerName) return []

  try {
    const regressor = await getUriAndCacheResponse(
      "/api/external/lectura/v2/regressor" + objectToSearchParam({ modelName, manufacturerName, year, usage }),
    )
    return regressor?.curves || []
  } catch (error) {} // eslint-disable-line
}

export async function lecturaToAsset(payload) {
  try {
    const asset = (await axios.post("/api/script/runs/lectura-to-asset", payload)).data
    return asset
  } catch (error) {
    addOops(error)
    return {}
  }
}
