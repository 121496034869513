import { formatAddress } from "basikon-common-utils"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { Map, Marker, Popup, TileLayer } from "react-leaflet"

import Card from "@/_components/Card"
import FormInput from "@/_components/FormInput"
import PersonLink from "@/_components/PersonLink"

import { getLabel, getList } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { getPersonDisplay } from "@/_services/personUtils"
import { getMapMarkerIcon } from "@/_services/utils"

/**
 * @prop {String}   role1         Role of the first person in the entity "persons" array        Default to "CLIENT"
 * @prop {String}   role2         Role of the second person in the entity "persons" array        Default to "SIGNER"
 * @prop {String}   person1       The first person to be displayed in the card
 * @prop {String}   person2       The second person to be displayed in the card
 */
class PersonsReadOnlyCard extends React.Component {
  componentDidMount() {
    getList("personRole", () => this.setState({ personRoleListLoaded: true }))
    getList("companyRegistrationType", () => this.setState({ companyRegistrationTypeListloaded: true }))
    getList("individualRegistrationType", () => this.setState({ individualRegistrationTypeListloaded: true }))
    getList("professionalRegistrationType", () => this.setState({ professionalRegistrationTypeListloaded: true }))
  }

  getPerson1 = () => {
    const { persons = [], role1 = "CLIENT", person1 } = this.props
    if (person1) return person1
    if (role1) return persons.find(p => p?.role === role1)?.person
  }

  getPerson2 = () => {
    const { persons = [], role2 = "SIGNER", person2 } = this.props
    if (person2) return person2
    if (role2) return persons.find(p => p?.role === role2)?.person
  }

  render() {
    const { debugInfo, title, collapse, role2 = "SIGNER" } = this.props

    const person1 = this.getPerson1()
    const person2 = this.getPerson2()

    const hasPerson1Coordinates = person1?.addresses?.[0]?.location?.coordinates

    return (
      <Card debugInfo={debugInfo} title={loc(title)} collapse={collapse} className="persons-read-only-card">
        <Row>
          <Col xs={12} sm={hasPerson1Coordinates ? 6 : 12}>
            {
              <FormInput
                inline
                readOnly
                label="Name"
                hidden={!person1?.registration}
                value={getPersonDisplay(person1)}
                linkTo={`/person/${person1?.registration}`}
              />
            }
            {(person1?.registrations || [])
              .filter(r => r.number)
              .map(registration => (
                <FormInput
                  inline
                  readOnly
                  key={registration.type}
                  value={registration.number}
                  label={getLabel(
                    person1?.type === "I"
                      ? "individualRegistrationType"
                      : person1?.type === "P"
                      ? "professionalRegistrationType"
                      : "companyRegistrationType",
                    registration.type,
                  )}
                />
              ))}
            {person1?.legalForm && <FormInput readOnly inline label="Legal form" value={person1?.legalForm} select="legalForm" />}
            {person1?.addresses?.[0] && <FormInput readOnly inline label="Address" value={formatAddress(person1?.addresses?.[0])} />}
            {person1?.email && <FormInput readOnly inline label="Email" value={person1?.email} type="email" />}
            {person1?.mobile && <FormInput readOnly inline label="Mobile" value={person1?.mobile} type="phone" />}
            {person1?.phone && <FormInput readOnly inline label="Phone" value={person1?.phone} type="phone" />}
            {person2 && (
              <>
                {role2 && <legend className="bt-0">{loc(getLabel("personRole", role2))}</legend>}
                <FormInput inline readOnly label="Name" value={getPersonDisplay(person2)} linkTo={`/person/${person2.registration}`} />
                {person2.email && <FormInput readOnly inline label="Email" value={person2.email} type="email" />}
                {person2.mobile && <FormInput readOnly inline label="Mobile" value={person2.mobile} type="phone" />}
              </>
            )}
          </Col>
          {hasPerson1Coordinates && (
            <Col xs={12} sm={6}>
              <Map style={{ height: "100%" }} center={person1?.addresses?.[0]?.location?.coordinates} zoom={13}>
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {person1?.addresses?.[0]?.location && (
                  <Marker position={person1?.addresses?.[0]?.location.coordinates} icon={getMapMarkerIcon("red")}>
                    <Popup>
                      <PersonLink registration={person1?.registration}>
                        <b>{person1?.name}</b>
                      </PersonLink>
                      <br />
                      {formatAddress(person1?.addresses?.[0])}
                      <br />
                      {person1?.addresses?.[0]?.location.coordinates.join(",")}
                    </Popup>
                  </Marker>
                )}
              </Map>
            </Col>
          )}
        </Row>
      </Card>
    )
  }
}

export default PersonsReadOnlyCard
