import React from "react"
import get from "lodash.get"
import Datasheet from "react-datasheet/lib/DataSheet"
import { formatDate, formatDecimal, coerceStringToJsNumber, formatPercentage, roundAccurately } from "basikon-common-utils"

import CustomButton from "@/_components/CustomButton"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import DateEditor from "@/_components/DateEditor"
import SelectEditor from "@/_components/SelectEditor"
import TextAreaEditor from "@/_components/TextAreaEditor"

import { loc, getLocale } from "@/_services/localization"
import { formatCurrency } from "@/_services/utils"

// * V0: carte "Score"
// - elle utilise MathSheet, et stocke le résultat dans une collection Sheet
// - utilise le handler deprecated onSetSheetState(), tout comme les editors dans les catalogues
// - une fois qu'on a enlevé la carte "Score", et modifié les editors de catalogue, on pourra virer ce mode
// * V1: carte EntityExtension.jsx
// - Génère des FormInput d'après la collection extension
// - ou optionnellement affiche une MathSheet, avec handler onSetState(), et appelle la methode execute() du sheet template (script.shee.js)
// - utilisé sur Finadev
// - défault: chez Finadev on a plusieurs sous extension qui deviennent "legendes" dans ExtensionCard.jsx
// * V2: BPIGRT PoC 0
// - j'ai ajouté la capacité d'afficher une ExtensionCard par sous-extension de collection (éventuellement via une sheet)
// * V3: PE
// - sheet, mais dnas un mode sans template, très proche de FormInput

function camelCase(s) {
  if (!s) return s
  s = s
    .replace(/\//g, " ")
    .replace(/\./g, "")
    .replace(/_/g, " ")
    .replace(/:/g, "")
    .replace(/%/g, "Percent")
    .replace(/\+/g, "And")
    .replace(/>/g, "After")
    .replace(/</g, "Before")
    .replace(/&/g, "And")
  s = s.replace(/-/g, " ")
  s = s.replace(/'/g, "")
  s = s.replace(/\(/g, "")
  s = s.replace(/\)/g, "")
  let words = s.split(" ")
  if (words.length === 0) return ""
  let name = words[0].toLowerCase()
  for (let i = 1; i < words.length; i++) if (words[i].length > 0) name += words[i][0].toUpperCase() + words[i].substring(1).toLowerCase()
  return name
}

function fixTemplateDatapoints(sheetTemplateDatapoints) {
  sheetTemplateDatapoints?.forEach(datapoint => {
    datapoint.expr = datapoint.formula
    datapoint.name = datapoint.name || camelCase(datapoint.label)
    datapoint.regExp = new RegExp("\\b" + datapoint.name + "\\b", "g")
  })
}

class MathSheet extends React.Component {
  state = {
    locale: getLocale(),
  }

  initState = () => {
    try {
      let sheetTemplate = this.props.sheetTemplate
      if (sheetTemplate) {
        fixTemplateDatapoints(sheetTemplate.datapoints || this.props.datapoints)
        let grid = this.generateInitialGrid(sheetTemplate, this.props.sheet)
        //this.triggerOnSetSheetState(grid)
        this.setState({ grid })
      } else {
        // not template, just a grid
        let grid = this.props.sheet && this.props.sheet.grid
        if (Array.isArray(grid)) {
          grid.forEach(line =>
            line.forEach(cell => {
              if (cell.disabled) cell.disableEvents = true
              cell.className = this.getClassName(cell)
            }),
          )
        }

        this.initEditors(grid)
        this.setState({ grid })
      }
    } catch (error) {
      console.log(loc`Error in template: ` + error.message, error)
    }
  }

  componentDidMount() {
    this.initState()
  }

  componentDidUpdate(prevProps) {
    const { obj, sheet, sheetTemplate } = this.props

    if (prevProps.sheetTemplate !== sheetTemplate || prevProps.sheet !== sheet || prevProps.obj !== obj) {
      this.initState()
    } else if ((prevProps.sheet && prevProps.sheet.values) !== (sheet && sheet.values)) {
      this.initState()
    } else if (!sheetTemplate && (prevProps.sheet && prevProps.sheet.grid) !== (sheet && sheet.grid)) {
      this.initState()
    }
  }

  generateInitialGrid(sheetTemplate, sheet) {
    let grid = sheet && sheet.grid

    if (sheetTemplate.multipleColumns) {
      // a grid with one record spread on multiple columns such as "retailScoringTemplate"
      grid = this.generateMultipleColumnsGrid(sheetTemplate, grid)
    } else if (sheetTemplate.nbYears) {
      // a grid with N record for N years organized in columns such as "balanceSheetTemplate"
      grid = this.generateMultiYearGrid(sheetTemplate, grid)
    } else {
      // just a grid
      grid = this.generateSimpleGrid(sheetTemplate, grid)
    }
    let values = this.props.sheet?.values
    if (values) {
      grid.forEach(row =>
        row.forEach(cell => {
          if (cell.name && cell.name in values) {
            cell.value = values[cell.name]
            cell.readOnly = true
            cell.className = this.getClassName(cell)
          }
        }),
      )
    }
    this.initEditors(grid)
    return grid
  }

  initEditors(grid) {
    const { obj, readOnly } = this.props
    const globalReadOnly = readOnly || obj?.readOnly

    if (Array.isArray(grid)) {
      for (const line of grid) {
        for (const cell of line) {
          if (!cell) continue

          if (cell.disabled) cell.disableEvents = true
          if (globalReadOnly) cell.readOnly = true

          const format = cell.format || cell.type
          if (format === "date" && !cell.readOnly) {
            cell.dataEditor = DateEditor
          } else if (format === "textarea" && !cell.readOnly) {
            cell.dataEditor = TextAreaEditor
          } else if (cell.select) {
            cell.dataEditor = props => <SelectEditor {...{ ...props, select: cell.select }} />
          }
        }
      }
    }
  }

  generateSimpleGrid(sheetTemplate) {
    const { obj } = this.props
    const sheetTemplateDatapoints = sheetTemplate.datapoints || this.props.datapoints
    if (!sheetTemplateDatapoints) return []

    const grid = []
    for (let i = 0; i < sheetTemplateDatapoints?.length; i++) {
      const line = sheetTemplateDatapoints[i]
      const gridLine = []
      grid.push(gridLine)

      for (let j = 0; j < line.length; j++) {
        const cell = line[j]
        const gridCell = { ...cell }
        gridLine.push(gridCell)
        const cellPositionCss = ` cell-x-${j} cell-y-${i}`
        gridCell.className = this.getClassName(cell) + cellPositionCss

        if (obj && cell.name) {
          const value = get(obj, cell.name)
          gridCell.value = value
          if (gridCell.computeClassName) {
            gridCell.className = this.getClassName(gridCell) + cellPositionCss
          }
        }
      }
    }
    return grid
  }

  generateMultipleColumnsGrid(sheetTemplate, cells) {
    const sheetTemplateDatapoints = sheetTemplate.datapoints || this.props.datapoints
    let multipleColumns = sheetTemplate.multipleColumns
    let firstLine = ["", "A"]
    for (let i = 0; i < multipleColumns; i++) {
      firstLine.push(String.fromCharCode("A".charCodeAt(0) + 3 * i + 1))
      firstLine.push(String.fromCharCode("A".charCodeAt(0) + 3 * i + 2))
      if (i < multipleColumns - 1) firstLine.push(String.fromCharCode("B".charCodeAt(0) + 3 * i + 3))
    }
    let currentIndex = []
    for (let i = 0; i < firstLine.length; i++) {
      currentIndex.push(0)
    }
    let datapoints = []
    sheetTemplateDatapoints?.forEach(dp => {
      let column = dp.column || 1
      let keyColumn = 3 * (column - 1) + 2
      currentIndex[column]++

      let keyLine = currentIndex[column]
      let keyColumnChar = String.fromCharCode("A".charCodeAt(0) + keyColumn)
      let key = keyColumnChar + keyLine

      let cell = cells && cells[keyLine][keyColumn]
      cell = { ...cell, ...dp }
      cell.keyColumn = keyColumn
      cell.keyLine = keyLine
      cell.key = key
      cell.className = this.getClassName(cell)

      dp.key = key

      datapoints.push(cell)
    })

    // fix formula
    datapoints.forEach(datapoint => {
      for (let dp of datapoints) {
        datapoint.expr = datapoint.expr && datapoint.expr.replace(dp.regExp, dp.key)
      }
    })

    let maxLine = 0
    currentIndex.forEach(i => {
      if (i > maxLine) maxLine = i
    })
    let grid = []
    grid.push(
      firstLine.map((col, i) => {
        return {
          readOnly: true,
          width: i === 0 ? "3%" : i % 3 === 1 ? "20%" : i % 3 === 2 ? "10%" : "1%",
          value: col,
        }
      }),
    )
    for (let i = 0; i < maxLine; i++) {
      let line = [{ readOnly: true, value: i + 1 }]
      for (let j = 1; j < firstLine.length; j++) line.push({})
      grid.push(line)
    }
    datapoints.forEach(dp => {
      grid[dp.keyLine][dp.keyColumn - 1] = {
        readOnly: true,
        className: this.getLabelClassName(dp),
        value: dp.label,
      }
      grid[dp.keyLine][dp.keyColumn] = dp
    })
    return grid
  }

  generateMultiYearGrid(sheetTemplate, cells) {
    const sheetTemplateDatapoints = sheetTemplate.datapoints || this.props.datapoints
    let nbYears = sheetTemplate.nbYears || 5

    let firstLine = ["", "A"]
    for (let i = 0; i < nbYears; i++) {
      firstLine.push(String.fromCharCode("B".charCodeAt(0) + i))
    }
    let columnDatapoints = firstLine.map((col, i) => {
      if (i >= 2) {
        // copy
        let datapoints = [...sheetTemplateDatapoints]
        // set key
        for (let j = 0; j < datapoints.length; j++) {
          let key = col + (j + 2)
          let cell = cells && cells[j + 2][i]
          cell = { ...datapoints[j], ...cell }
          cell.key = key
          cell.className = this.getClassName(cell)
          datapoints[j] = cell
        }

        // fix formula
        datapoints = datapoints.map(datapoint => {
          for (let dp of datapoints) {
            datapoint.expr = datapoint.expr && datapoint.expr.replace(dp.regExp, dp.key)
          }
          return datapoint
        })
        return datapoints
      }
      return null
    })

    let grid = []
    grid.push(
      firstLine.map((col, i) => {
        return {
          readOnly: true,
          width: i === 0 ? "3%" : i === 1 ? "30%" : "10%",
          value: col,
        }
      }),
    )

    let secondLine = ["1", ""]
    for (let i = 2; i < 2 + nbYears; i++) {
      secondLine.push("" + new Date().getFullYear() - nbYears - 1 + i)
    }
    grid.push(
      secondLine.map(col => {
        return { readOnly: true, value: col }
      }),
    )

    for (let i = 0; i < sheetTemplateDatapoints.length; i++) {
      let line = [
        { readOnly: true, value: i + 2 },
        {
          readOnly: true,
          className: this.getLabelClassName(sheetTemplateDatapoints[i]),
          value: sheetTemplateDatapoints[i].label,
        },
      ]
      for (let j = 2; j < firstLine.length; j++) {
        let datapoint = columnDatapoints[j][i]
        line.push(datapoint)
      }
      grid.push(line)
    }
    return grid
  }

  getClassName(cell) {
    let cn = cell.labelClassName || ""
    if (cell.expr && cell.expr.startsWith && cell.expr.startsWith("=")) {
      cn += " equation"
    }
    if (cell.format === "date") cn += " cell-date"
    if (cell.select) cn += " cell-select"
    // if (cell.hasSeparator) cn += " separator"
    // if (cell.hasPaddingTop) cn += " paddingtop"
    if (cell.totalLevel) cn += " total" + cell.totalLevel
    if (cell.comment) cn += " relative"
    if (cell.comment?.showIconOnCellHover) cn += " cell-comment-hover"
    // if (cell.isItalic) cn += " italic"
    // if (cell.textAlign === "left") {
    //   cn += " left-align"
    // } else if (cell.textAlign === "right") {
    //   cn += " right-align"
    // } else if (cell.textAlign === "center") {
    //   cn += " align-center"
    // } else {
    //   let value = cell.value || (cell.obj && cell.field && cell.obj[cell.field])
    //   if (typeof value === "string") {
    //     cn += " left-align text-left"
    //   } else if (typeof value === "number") {
    //     cn += " right-align text-right"
    //   }
    // }
    // if (cell.verticalAlign === "top") {
    //   cn += " top-align"
    // }
    if (cell.computeClassName) {
      let value = cell.value || (cell.obj && cell.field && cell.obj[cell.field])
      const ccn = cell.computeClassName(value, cell)
      if (ccn) cn += " " + ccn
    }
    return cn.trim()
  }

  getLabelClassName(cell) {
    let cn = cell.labelClassName
    if (cell.hasSeparator) cn += " separator"
    if (cell.hasPaddingTop) cn += " paddingtop"
    if (cell.totalLevel) cn += " total" + cell.totalLevel
    if (cell.isItalic) cn += " italic"
    if (cell.textAlign === "left") cn += " left-align"
    if (cell.textAlign === "right") cn += " right-align"
    if (cell.textAlign === "center") cn += " align-center"
    return cn
  }

  triggerOnSetSheetState = grid => {
    const { sheet, onSetSheetState } = this.props
    if (sheet && onSetSheetState) onSetSheetState({ grid })
    if (grid !== this.state.grid) this.setState({ grid })
  }

  onCellsChanged = changes => {
    const { grid, sheet, locale } = this.state
    const { onSetState, options } = this.props

    if (grid) {
      let patch = {}
      changes.forEach(({ cell, value, row, col }) => {
        let format = cell.format || cell.type
        if (["number", "percentage", "currency"].includes(format)) {
          value = coerceStringToJsNumber(value, locale)
          if (typeof value !== "number") {
            // if we let a string or something else in a Number field we will have "cast to embedded failed" error triggered by Mongoose
            value = NaN
          }
        }
        if (format === "percentage") {
          const valueString = value.toString()
          const decimalCount = (valueString.includes(".") ? valueString.split(".")[1].length || 0 : 0) + 2
          const maxFractionDigits =
            cell.options?.formatPercentageOptions?.maximumFractionDigits || options?.formatPercentageOptions?.maximumFractionDigits || 6
          value = roundAccurately(value / 100, decimalCount < maxFractionDigits ? decimalCount : maxFractionDigits)
        }
        if (row !== undefined && col !== undefined) grid[row][col].value = value
        if (cell.name) patch[cell.name] = value
        if (cell.field && cell.onSetState) cell.onSetState({ [cell.field]: value }) // mode v3: handler at cell level, mimics form input - used by PE commitment
      })
      if (sheet && sheet.values) {
        sheet.values = { ...sheet.values, ...patch }
        this.setState({ sheet })
      }
      this.triggerOnSetSheetState(grid) // mode v1: handler "onSetSheetState()" at sheet level, pass the sheet (aka grid)
      if (onSetState) onSetState(patch) // mode v2: handler "onSetState()" at sheet level, pass an object patch (used by EntityExtension)
    }
  }

  valueViewer = ({ value, cell }) => {
    let commentComponent
    if (cell.comment) {
      const hasCellOnClick = typeof cell.comment?.onClick === "function"
      const onClick = async event => {
        if (hasCellOnClick) {
          event.stopPropagation()
          await cell.comment.onClick({ value, cell })
        }
      }
      const tooltipText = cell.comment?.text || (typeof cell.comment === "string" ? cell.comment : "")
      const bsStyle = cell.comment?.style || "danger"
      const iconClassName = (hasCellOnClick ? "" : "c-default") + " icn-xxxs " + (cell.comment?.icon || "icn-triangle-corner-top-right")
      const btnClassName = "min-h-fit m-0 pd-0 absolute top-0 right-0 z-10 cell-comment-icon"

      commentComponent = tooltipText ? (
        <ButtonWithTooltip bsStyle={bsStyle} className={iconClassName} btnClassName={btnClassName} tooltip={tooltipText} onClick={onClick} />
      ) : (
        <CustomButton simple bsStyle={bsStyle} iconClassName={iconClassName} className={btnClassName} onClick={onClick} />
      )
    }

    return (
      <>
        {commentComponent}
        <div className="value-viewer">{value}</div>
      </>
    )
  }

  valueRenderer = cell => {
    const { options } = this.props

    let value = get(cell.obj, cell.field, cell.value)
    try {
      const { locale } = this.state
      if ((cell.format || cell.type) && locale) {
        let format = cell.format || cell.type
        if (format === "number") {
          if (isNaN(value)) return undefined
          return formatDecimal(value, locale, cell.options?.formatNumberOptions || options?.formatNumberOptions)
        } else if (format === "percentage") {
          if (isNaN(value)) return undefined
          return formatPercentage(value, locale, cell.options?.formatPercentageOptions || options?.formatPercentageOptions)
        } else if (format === "currency") {
          if (isNaN(value)) return undefined
          return formatCurrency(value, locale, cell.options?.formatCurrencyOptions || options?.formatCurrencyOptions)
        }
      }
      if (value instanceof Date) return formatDate(value, locale)

      if (value && typeof value === "object") {
        if (React.isValidElement(value)) return value
        return value?.toString() + " (missing format ???)"
      }
      return value
    } catch (error) {
      console.error("Exception in MathSheet.valueRenderer():", error)
      return value
    }
  }

  dataRenderer = cell => {
    if (cell.expr) return cell.expr
    if (cell.format === "percentage") {
      return (cell.value * 100).toFixed(cell.options?.formatPercentageOptions?.maximumFractionDigits || 4)
    }
    return cell.value
  }

  handleCopy = ({ event, data, start, end }) => {
    const { locale } = this.state
    const colMin = start.j
    const colMax = end.j
    const rowMin = start.i
    const rowMax = end.i

    let copiedString = ""

    for (let i = rowMin; i <= rowMax; i++) {
      for (let j = colMin; j <= colMax; j++) {
        const currentCellData = data[i][j]
        const { format, field } = currentCellData
        const cellValue = currentCellData.value ?? currentCellData.obj?.[field] ?? ""

        if (format === "number") {
          copiedString += formatDecimal(cellValue, locale)
        } else if (format === "percentage") {
          copiedString += formatDecimal(cellValue * 100, locale)
        } else {
          copiedString += cellValue
        }

        copiedString += j === colMax ? "" : "\t"
      }

      copiedString += i === rowMax ? "" : "\r\n"
    }

    // we cannot use utils.commons copyToClipboard() because
    // for whatever reason the lib react-datasheet calls this function 2 times very quickly
    // which is prevented by the browser
    if (window.clipboardData && window.clipboardData.setData) {
      window.clipboardData.setData("Text", copiedString)
    } else {
      event.clipboardData.setData("text/plain", copiedString)
    }
  }

  parsePaste = pastedString => {
    if (!pastedString) return [[]]

    const { locale } = this.state
    const rows = pastedString.replace(/\r/g, "").split("\n")
    const grid = []

    if (rows[rows.length - 1] === "") rows.pop()

    for (let i = 0; i < rows.length; i++) {
      const cols = rows[i].split("\t").map(col => coerceStringToJsNumber(col, locale))
      grid.push(cols)
    }
    return grid
  }

  render() {
    const { grid } = this.state

    return (
      <div className="math-sheet-component table-layout-initial">
        {grid && (
          <Datasheet
            data={grid}
            overflow="clip"
            handleCopy={this.handleCopy}
            parsePaste={this.parsePaste}
            valueViewer={this.valueViewer}
            dataRenderer={this.dataRenderer}
            valueRenderer={this.valueRenderer}
            onCellsChanged={this.onCellsChanged}
            isCellNavigable={cell => !cell.readOnly}
            attributesRenderer={() => undefined} // This is a workaround to force component rendering (for lists)
          />
        )}
      </div>
    )
  }
}

export default MathSheet
