import JSON5 from "json5"
import React from "react"
import AceEditor from "react-ace"
import { ButtonGroup, Col, Modal, Row } from "react-bootstrap"
//import "ace-builds/webpack-resolver" // this adds 10MB to the build (16 instead of 6)
import "ace-builds/src-noconflict/ext-searchbox"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/theme-github"
import { lowerCaseFirstLetter } from "basikon-common-utils"

import CustomButton from "@/_components/CustomButton"

import { loc } from "@/_services/localization"
import { getUsername } from "@/_services/userConfiguration"

function convertToJs(entity) {
  const string = JSON5.stringify(entity, { space: 2, quote: '"' })

  // this block is a hack to group colProps in one line. Can format way better but this works for me
  try {
    const json = string.split("\n")
    const json2 = []
    for (let i = 0; i < json.length; i++) {
      let line = json[i]
      const tline = line.trim()
      if (tline === "colProps: {" || /props_.*: {/.test(tline)) {
        while (++i < json.length) {
          const line2 = json[i].trim()
          if (line2 === "}" || line2 === "},") {
            line = line.substring(0, line.length - 1) // remove trailing comma
            line += " " + line2.trim()
            break
          } else {
            line += " " + line2.trim()
          }
        }

        // this entire block is buggy - needs rewriting
        // } else {
        // // fold small arrays too make json more visible, particularly layouts
        // const max = 50
        // if (tline.endsWith("[")) {
        // const findString = line.substring(0, line.length - line.trimLeft().length) + "],"
        // let j
        // let nbChars = 0
        // for (j = i + 1; j < json.length; j++) {
        // nbChars += json[j].trim().length
        // if (nbChars > max) break
        // if (json[j].endsWith("[")) {
        // // array in array => no folding
        // nbChars = max + 1
        // break
        // }
        // if (json[j] === findString) break
        // }
        // if (nbChars < max) {
        // // line += "\n"
        // i++
        // let first = true
        // for (; i <= j; i++) {
        // const line2 = json[i].trim()
        // if (i === j) {
        // line = line.substring(0, line.length - 1) // remove trailing comma
        // line += line2
        // } else {
        // if (!first) line += " "
        // line += line2
        // }
        // first = false
        // }
        // i--
        // }
        // }
      }
      json2.push(line)
    }
    return json2.join("\n")
  } catch (error) {
    console.error(error.stack)
  }

  return string
}

function convertToJson(entity) {
  return JSON.stringify(entity, null, 2)
}

export default class EntityModelModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // useYml: false,
      useJson: false,
    }
  }

  componentDidMount() {
    const { entity, showEntityFieldsButtons } = this.props

    // Select first model field button
    if (showEntityFieldsButtons) {
      const [firstKey] = Object.keys(entity).filter(it => entity[it])
      if (firstKey) this.setSelector(firstKey)
    }
  }

  openScript(name, addParam = "") {
    const username = getUsername()
    window.open(`/scripts?name=${name}&filter=${name}&all=1${addParam}&username=${username}`)
  }

  setSelector = field => {
    if (this.state.selector === field) this.setState({ selector: undefined })
    else this.setState({ selector: field })
  }

  render() {
    const { entity, close, entityName, pageName, workflowName, showScriptButtons, showEntityFieldsButtons } = this.props
    const { /*useYml, */ useJson, selector } = this.state

    const entityType = entity?.type || entity?.[Object.keys(entity)[0]]?.type

    const firstButton = pageName
      ? ["Page", () => this.openScript(pageName)]
      : ["Layout", () => this.openScript(lowerCaseFirstLetter(entityName) + "-layout")]

    const buttons = [
      firstButton,
      workflowName && ["Workflow", () => this.openScript(workflowName, entityType && "&entityType=" + entityType)],
      ["Extension", () => this.openScript("collection-extension", "&entityName=" + entityName)],
    ].filter(it => it)

    const displayEntity = selector ? entity[selector] : entity

    return (
      <Modal show={true} onHide={close} bsSize="large" className="entity-model-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc`Model`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-theme">
            <Col xs={12}>
              {showEntityFieldsButtons && (
                <ButtonGroup>
                  {Object.keys(entity)
                    .filter(it => entity[it])
                    .map(entityField => (
                      <CustomButton
                        fill={selector === entityField}
                        bsSize="xs"
                        bsStyle="default"
                        key={entityField}
                        onClick={() => this.setSelector(entityField)}
                      >
                        {entityField}
                      </CustomButton>
                    ))}
                </ButtonGroup>
              )}
              <CustomButton pullRight fill={useJson} bsSize="xsmall" bsStyle="default" onClick={() => this.setState({ useJson: !useJson })}>
                {loc`JSON`}
              </CustomButton>
              {showScriptButtons &&
                buttons.map(([title, onclick]) => (
                  <CustomButton key={title} pullRight bsSize="xsmall" bsStyle="default" className="flex-center" onClick={onclick}>
                    {loc(title)}
                    <i className="icn-external-link icn-xs ml-5px" />
                  </CustomButton>
                ))}
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <AceEditor
                focus={true}
                readOnly
                mode="json"
                theme="github"
                value={/*useYml ? convertToYaml("", displayEntity).join("\n") :*/ useJson ? convertToJson(displayEntity) : convertToJs(displayEntity)}
                name="entity-model"
                showGutter={true}
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                  copyWithEmptySelection: true,
                  useWorker: false, // disable syntax check as it does not support ESNext (async await etc.)
                }}
                width="100%"
                height="70rem"
                showPrintMargin={false}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <CustomButton simple onClick={close}>
            {loc`Close`}
          </CustomButton>
        </Modal.Footer>
      </Modal>
    )
  }
}
