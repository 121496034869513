import React, { Fragment } from "react"
import { Row, Col } from "react-bootstrap"
import { PROPS_, replaceVariables } from "basikon-common-utils"

import CustomButton from "@/_components/CustomButton"
import PanelInner from "@/_components/PanelInner"
import TableExtended from "@/_components/TableExtended"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import FormInputExtended from "@/_components/FormInputExtended"
import { filterColumns } from "@/_components/Table"

import { labelFromName } from "@/_services/utils"
import { loc } from "@/_services/localization"

class EntityExtensionSectionPanel extends React.Component {
  state = {}

  handleSetEntitySectionState = (fieldName, patch) => {
    const { obj = {}, onSetState } = this.props
    const subObj = { ...(obj[fieldName] || {}), ...patch }
    const patch2 = { [fieldName]: subObj }
    onSetState(patch2)
  }

  handleAddLine = fieldName => {
    const obj = [...((fieldName && this.props.obj ? this.props.obj[fieldName] : this.props.obj) || [])]
    obj.push({})
    this.props.onSetState(fieldName ? { [fieldName]: obj } : obj)
  }

  handleSetLineState = ({ index, patch, fieldName }) => {
    const obj = [...((fieldName ? this.props.obj[fieldName] : this.props.obj) || [])]
    obj[index] = { ...(obj[index] || {}), ...patch }
    this.props.onSetState(fieldName ? { [fieldName]: obj } : obj)
  }

  handleDuplicateLine = ({ index, fieldName }) => {
    const obj = [...((fieldName ? this.props.obj[fieldName] : this.props.obj) || [])]
    obj.splice(index, 0, { ...(obj[index] || {}) })
    this.props.onSetState(fieldName ? { [fieldName]: obj } : obj)
  }

  handleDeleteLine = ({ index, fieldName }) => {
    const obj = [...((fieldName ? this.props.obj[fieldName] : this.props.obj) || [])]
    obj.splice(index, 1)
    this.props.onSetState(fieldName ? { [fieldName]: obj } : obj)
  }

  handleSetComplexColumnState = ({ index, ccsKey, patch, fieldName }) => {
    const obj = [...((fieldName ? this.props.obj[fieldName] : this.props.obj) || [])]
    obj[index] = { ...(obj[index] || {}) }
    if (Array.isArray(patch)) {
      obj[index][ccsKey] = patch
    } else {
      obj[index][ccsKey] = { ...(obj[index]?.[ccsKey] || {}), ...patch }
    }
    this.props.onSetState(fieldName ? { [fieldName]: obj } : obj)
  }

  getFieldSchemaType = ({ schemaSection, fieldSchemaTypes, fieldKey }) => {
    if (!fieldKey) return

    const fieldSchema = schemaSection[fieldKey]
    const schemaSectionKeyType = fieldSchema.type || fieldSchema
    const { formInputProps } = fieldSchema // note that destructuration works on String so no need for type checking here
    if (["String", "Number", "Boolean", "Date", "[String]", "[Number]"].includes(schemaSectionKeyType) && formInputProps?.type !== "textarea") {
      return formInputProps?.inArray === false ? fieldSchemaTypes.FORM_INPUT : fieldSchemaTypes.HEADER_COL
    }

    if (Array.isArray(schemaSectionKeyType) && Array.isArray(schemaSectionKeyType[0]) && schemaSectionKeyType[0][0] === "Number") {
      return fieldSchemaTypes.COMPLEX_COL
    }

    if (Array.isArray(schemaSectionKeyType) && (schemaSectionKeyType[0] === "String" || schemaSectionKeyType[0] === "Number")) {
      return formInputProps?.inArray === false ? fieldSchemaTypes.FORM_INPUT : fieldSchemaTypes.HEADER_COL
    }

    return fieldSchemaTypes.COMPLEX_COL
  }

  // if fieldName is not null, then we are in an array nested within an object
  arraySchema = ({ schemaSection, readOnly, showAddButtonFromLevel, collapse, obj, fieldName, nextNestingLevel, modelPath, columns }) => {
    const { sortFieldsByType } = this.props

    // schema section is an array
    schemaSection = schemaSection[0]

    const headerColumns = []
    const fieldSchemaTypes = {
      FORM_INPUT: "formInput",
      HEADER_COL: "headerCol",
      COMPLEX_COL: "complexCol",
    }

    const fieldsKey = Object.keys(schemaSection)
    const fieldsSchemaType = {} // small perf optim
    if (sortFieldsByType) {
      for (const fieldKey of fieldsKey) fieldsSchemaType[fieldKey] = this.getFieldSchemaType({ schemaSection, fieldSchemaTypes, fieldKey })
      const fieldSchemaTypesOrder = {
        [fieldSchemaTypes.HEADER_COL]: 1,
        [fieldSchemaTypes.FORM_INPUT]: 2,
        [fieldSchemaTypes.COMPLEX_COL]: 3,
      }
      fieldsKey.sort((f1, f2) => fieldSchemaTypesOrder[fieldsSchemaType[f1]] - fieldSchemaTypesOrder[fieldsSchemaType[f2]])
    }
    const data = (Array.isArray(obj) ? obj : []).map((item, index) => {
      const tableItem = {},
        panels = {}
      let rowChildren = [] // to group form input fields in a single Row, otherwise their colProps would be useless

      // this iteration is needed to keep the order of fields as described in the collection extension
      for (let i = 0; i < fieldsKey.length; i++) {
        const fieldKey = fieldsKey[i]
        if (!fieldsSchemaType[fieldKey]) {
          fieldsSchemaType[fieldKey] = this.getFieldSchemaType({ schemaSection, fieldSchemaTypes, fieldKey })
        }
        const fieldSchemaType = fieldsSchemaType[fieldKey]

        const prevFieldKey = fieldsKey[i - 1]
        const prevFieldSchemaType =
          fieldsSchemaType[prevFieldKey] || this.getFieldSchemaType({ schemaSection, fieldSchemaTypes, fieldKey: prevFieldKey })

        const nextFieldKey = fieldsKey[i + 1]
        const nextFieldSchemaType =
          fieldsSchemaType[nextFieldKey] || this.getFieldSchemaType({ schemaSection, fieldSchemaTypes, fieldKey: nextFieldKey })

        if (fieldSchemaType === fieldSchemaTypes.FORM_INPUT) {
          const { type, formInputProps = {}, colProps } = schemaSection[fieldKey]
          const inPanel = formInputProps.inPanel && typeof formInputProps.inPanel === "string" ? formInputProps.inPanel : false
          const element = (
            <FormInputExtended
              key={fieldKey}
              modelPath={`${modelPath}[${index}]`}
              inArray
              readOnly={readOnly}
              obj={item}
              field={fieldKey}
              type={type}
              {...formInputProps}
              colProps={formInputProps.colProps || colProps || { xs: 12 }}
              onSetState={patch => this.handleSetLineState({ index, patch, fieldName })}
            />
          )

          // To maintain the design structure in this particular interface building
          // we handle manually the visual block separation
          const pseudoBlockClassName = "bt-solid border-1px border-gray pdt-theme"

          tableItem.children = tableItem.children || []
          if (nextFieldSchemaType === fieldSchemaTypes.FORM_INPUT || prevFieldSchemaType === fieldSchemaTypes.FORM_INPUT) {
            if (inPanel) {
              panels[inPanel] ??= []
              panels[inPanel].push(element)
            } else rowChildren.push(element)
          } else {
            const field = (
              <Row key={fieldKey}>
                <Col xs={12}>
                  <div className={pseudoBlockClassName}>
                    <Row>{element}</Row>
                  </div>
                </Col>
              </Row>
            )
            if (inPanel) {
              panels[inPanel] ??= []
              panels[inPanel].push(field)
            } else tableItem.children.push(field)
          }

          if (nextFieldSchemaType !== fieldSchemaTypes.FORM_INPUT && prevFieldSchemaType === fieldSchemaTypes.FORM_INPUT) {
            const rowChildrenItems = rowChildren.map(rowChildrenItem => rowChildrenItem)
            if (rowChildrenItems.length)
              tableItem.children.push(
                <Row key={fieldKey}>
                  <Col xs={12}>
                    <div className={pseudoBlockClassName}>
                      <Row>{rowChildrenItems}</Row>
                    </div>
                  </Col>
                </Row>,
              )
            rowChildren = []
          }
        }

        if (fieldSchemaType === fieldSchemaTypes.COMPLEX_COL) {
          let element
          // this object could be a section, or a rates table
          const fieldSchema = schemaSection[fieldKey]
          const fieldSchemaType = fieldSchema.type || fieldSchema
          if (Array.isArray(fieldSchemaType) && Array.isArray(fieldSchemaType[0]) && ["Number", "String"].includes(fieldSchemaType[0][0])) {
            // rates table
            const { formInputProps = {}, colProps } = schemaSection[fieldKey]
            element = (
              <Row key={fieldKey}>
                <FormInputExtended
                  modelPath={modelPath}
                  key={fieldKey}
                  inArray
                  readOnly={readOnly}
                  obj={item}
                  field={fieldKey}
                  type="ratesTable"
                  {...formInputProps}
                  colProps={formInputProps.colProps || colProps || { xs: 12 }}
                  onSetState={patch => this.handleSetLineState({ index, patch, fieldName })}
                />
              </Row>
            )
          } else if (schemaSection[fieldKey].formInputProps) {
            // those are fields at the "root" of the item (e.g. no section that could define a panel) => we show them without a panel
            const { formInputProps = {}, colProps } = schemaSection[fieldKey]
            element = (
              <Row key={fieldKey}>
                <FormInputExtended
                  modelPath={modelPath}
                  readOnly={readOnly}
                  obj={item}
                  field={fieldKey}
                  {...formInputProps}
                  colProps={formInputProps.colProps || colProps || { xs: 12 }}
                  onSetState={patch => this.handleSetLineState({ index, patch, fieldName })}
                />
              </Row>
            )
          } else {
            // section
            element = (
              <EntityExtensionSectionPanel
                modelPath={`${modelPath}[${index}].${fieldKey}`}
                key={fieldKey}
                title={labelFromName(fieldKey)}
                obj={item[fieldKey]}
                readOnly={readOnly}
                showAddButtonFromLevel={showAddButtonFromLevel}
                collapse={collapse}
                schemaSection={schemaSection[fieldKey]}
                nestingLevel={nextNestingLevel}
                onSetState={patch => this.handleSetComplexColumnState({ index, ccsKey: fieldKey, patch, fieldName })}
                columns={columns}
              />
            )
          }

          tableItem.children = tableItem.children || []
          tableItem.children.push(element)
        }

        if (fieldSchemaType === fieldSchemaTypes.HEADER_COL) {
          if (Array.isArray(columns)) {
            let isColumnDeclared
            for (let j = 0; j < columns.length; j++) {
              const column = columns[j]
              if ((typeof column === "string" && column === fieldKey) || (typeof column === "object" && column.name === fieldKey)) {
                isColumnDeclared = true
                break
              }
            }
            if (!isColumnDeclared) continue
          } else if (typeof columns === "string") {
            columns = columns.split(",")
          }

          // don't push again the same key in headers
          if (!headerColumns.includes(fieldKey)) headerColumns.push(fieldKey)

          const fieldSchema = schemaSection[fieldKey]
          const fieldSchemaType = fieldSchema.type || fieldSchema

          let type
          let multiple
          if (Array.isArray(fieldSchemaType) && (fieldSchemaType[0] === "String" || fieldSchemaType[0] === "Number")) multiple = true
          else if (fieldSchemaType === "Boolean") type = "checkbox"
          else if (fieldSchemaType === "Number") type = "number"
          else if (fieldSchemaType === "String") type = "text"
          else if (fieldSchemaType === "Date") type = "date"
          else type = "text"

          const formInputProps = { ...schemaSection[fieldKey].formInputProps, ...columns?.find(it => it.name === fieldKey) }
          if (formInputProps.select) formInputProps.select = replaceVariables(formInputProps.select, item)

          tableItem[fieldKey] = (
            <FormInputExtended
              modelPath={`${modelPath}[${index}]`}
              inArray
              readOnly={readOnly}
              obj={item}
              field={fieldKey}
              type={type}
              multiple={multiple}
              {...formInputProps}
              onSetState={patch => this.handleSetLineState({ index, patch, fieldName })}
            />
          )
        }

        if (!readOnly) {
          let showDelete = true
          let showDuplicate = true
          if (item.action) {
            if (typeof item.action === "object") {
              showDelete = item.action.showDelete
              showDuplicate = item.action.showDuplicate
            } else {
              showDelete = false
              showDuplicate = false
            }
          }
          tableItem.action = (
            <>
              {showDelete && <ButtonWithTooltip pullRight className="icn-xmark icn-xs" onClick={() => this.handleDeleteLine({ index, fieldName })} />}
              {showDuplicate && (
                <ButtonWithTooltip
                  pullRight
                  className="icn-duplicate icn-xs"
                  tooltip="Duplicate"
                  onClick={() => this.handleDuplicateLine({ index, fieldName })}
                />
              )}
            </>
          )
        }
      }
      const panelsFields = Object.keys(panels)
      if (panelsFields.length) {
        tableItem.children.push(
          ...panelsFields.map((panelName, i) => {
            const panel = panels[panelName]
            return (
              <PanelInner key={i} collapse={true} title={panelName}>
                <Row xs={12}>{panel}</Row>
              </PanelInner>
            )
          }),
        )
      }

      return tableItem
    })

    if (headerColumns.length === 0) {
      for (let i = 0; i < fieldsKey.length; i++) {
        const fieldKey = fieldsKey[i]
        const fieldSchema = schemaSection[fieldKey]
        if (fieldSchema.formInputProps?.inArray === false) continue
        // can't use fieldsSchemaType here because it hasn't been filled due to empty data
        const fieldSchemaType = this.getFieldSchemaType({ schemaSection, fieldSchemaTypes, fieldKey })
        if (fieldSchemaType === fieldSchemaTypes.HEADER_COL) {
          headerColumns.push(fieldKey)
        }
      }
    }

    const filteredColumns = filterColumns(
      headerColumns.map(key => {
        const { formInputProps = {} } = schemaSection[key]
        return {
          ...formInputProps,
          name: key,
          title: formInputProps.label ?? labelFromName(key),
        }
      }),
      columns,
    ).filter(column => {
      // this filter removes columns that are declared in props.columns but that don't exist
      // at the current depth level (otherwsise empty columns are added for each missing column)
      return Array.isArray(columns) ? headerColumns.includes(column.name) : true
    })

    if (filteredColumns.length === 0) {
      filteredColumns.push({
        name: "name",
      })
    }

    if (!readOnly) {
      filteredColumns.push({
        title: (!showAddButtonFromLevel || showAddButtonFromLevel < nextNestingLevel) && (
          <CustomButton pullRight bsSize="xs" bsStyle="primary" className="inline-flex-center" simple onClick={() => this.handleAddLine(fieldName)}>
            <i className="icn-plus icn-xs mr-5px" />
            {loc`Add`}
          </CustomButton>
        ),
        name: "action",
        sortable: false,
        className: "w-5",
      })
    }

    return <TableExtended columns={filteredColumns} data={data} pageInUrl={false} modelPath={modelPath} hover={this.props.hover} />
  }

  render() {
    const {
      title,
      readOnly,
      sectionProps,
      obj,
      onSetState,
      collapse,
      nestingLevel = 0,
      showAddButtonFromLevel = 0, // show all the add button
      viewerHook,
      modelPath,
      columns,
    } = this.props
    const nextNestingLevel = nestingLevel + 1
    const { schemaSection } = this.props
    if (!schemaSection || Object.keys(schemaSection).length === 0) return null

    const body = Array.isArray(schemaSection) ? (
      this.arraySchema({ schemaSection, readOnly, showAddButtonFromLevel, collapse, obj, nextNestingLevel, modelPath, columns })
    ) : (
      <Row>
        {Object.keys(schemaSection).map(fieldName => {
          const fieldSchema = schemaSection[fieldName]
          const modelNextPath = modelPath ? `${modelPath}.${fieldName}` : fieldName

          // this is to support the hidden prop on arrays and objects
          const props_field = PROPS_ + fieldName
          const isHidden = obj?.[props_field]?.hidden === true

          if (Array.isArray(fieldSchema)) {
            return isHidden ? (
              <Fragment key={fieldName}></Fragment>
            ) : (
              <Col xs={12} key={fieldName} className={nextNestingLevel > 1 ? "nested-array-row" : ""}>
                <legend>{labelFromName(fieldName)}</legend>
                {this.arraySchema({
                  schemaSection: fieldSchema,
                  readOnly,
                  collapse,
                  obj: obj?.[fieldName],
                  fieldName,
                  showAddButtonFromLevel,
                  nextNestingLevel,
                  modelPath: modelNextPath,
                  columns,
                })}
              </Col>
            )
          }

          // arrays are also objects but we treat them before reaching this item
          if (typeof fieldSchema === "object" && !fieldSchema.type) {
            return isHidden ? (
              <Fragment key={fieldName}></Fragment>
            ) : (
              <Col xs={12} key={fieldName}>
                <EntityExtensionSectionPanel
                  modelPath={modelNextPath}
                  key={fieldName}
                  collapse={collapse}
                  readOnly={readOnly}
                  showAddButtonFromLevel={showAddButtonFromLevel}
                  schemaSection={fieldSchema}
                  obj={obj?.[fieldName]}
                  title={labelFromName(fieldName)}
                  nestingLevel={nextNestingLevel}
                  onSetState={patch => this.handleSetEntitySectionState(fieldName, patch)}
                  columns={columns}
                />
              </Col>
            )
          }

          const fieldSchemaType = fieldSchema.type || fieldSchema
          let colProps = fieldSchema.colProps || fieldSchema.formInputProps?.colProps || {}
          colProps = { xs: 12, sm: 6, ...colProps }

          let type
          let multiple
          if (Array.isArray(fieldSchemaType) && Array.isArray(fieldSchemaType[0]) && fieldSchemaType[0][0] === "Number") {
            type = "ratesTable" // by default, but formInputProps might override this with "coefficentsTable"
            colProps = { ...colProps, xs: 12 }
          } else if (Array.isArray(fieldSchemaType) && (fieldSchemaType[0] === "String" || fieldSchemaType[0] === "Number")) {
            multiple = true
          } else if (fieldSchemaType === "String") {
            type = "text"
          } else if (fieldSchemaType === "Number") {
            type = "number"
          } else if (fieldSchemaType === "Date") {
            type = "date"
          } else if (fieldSchemaType === "Boolean") {
            type = "checkbox"
          } else {
            type = "text"
          }

          // this is used by collection-extension viewer
          let formInputProps = fieldSchema.formInputProps || {}

          if (viewerHook) {
            formInputProps = { ...fieldSchema.formInputProps }
            formInputProps.label = (
              <>
                <span onClick={() => viewerHook(fieldName, fieldSchema.formInputProps, "label")}>
                  {fieldSchema.formInputProps?.label || labelFromName(fieldName)}
                </span>
                <span className="btn-hover-display">
                  <i
                    className="icn-edit icn-xs text-success pull-right c-pointer"
                    onClick={() => viewerHook(fieldName, fieldSchema.formInputProps, "edit")}
                  />
                  <i
                    className="icn-plus icn-xs text-primary pull-right c-pointer"
                    onClick={() => viewerHook(fieldName, fieldSchema.formInputProps, "plus")}
                  />
                  <i
                    className="icn-xmark icn-xs text-danger pull-right c-pointer"
                    onClick={() => viewerHook(fieldName, fieldSchema.formInputProps, "minus")}
                  />
                </span>
              </>
            )
          }

          return (
            <FormInputExtended
              modelPath={modelPath}
              key={fieldName}
              readOnly={readOnly}
              obj={obj}
              colProps={colProps}
              field={fieldName}
              multiple={multiple}
              type={type}
              {...formInputProps}
              onSetState={onSetState}
            />
          )
        })}
      </Row>
    )

    return title === undefined ? (
      body
    ) : (
      <PanelInner title={title || ""} sectionProps={sectionProps} collapse={collapse}>
        {body}
      </PanelInner>
    )
  }
}

export default EntityExtensionSectionPanel
