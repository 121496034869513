import React, { Fragment } from "react"
import { Row, Col } from "react-bootstrap"
import ChartistGraph from "react-chartist"
import { formatDateTime } from "basikon-common-utils"

import { loc, getLocale } from "@/_services/localization"
import { debug, handleAccessibleOnKeyDown } from "@/_services/utils"

class StatsCard extends React.Component {
  render() {
    const {
      linkTo,
      bigIcon,
      summary,
      summaryIcon,
      summaryClassName,
      statsText,
      statsValue,
      statsIcon,
      statsIconLinkTo,
      statsIconText,
      chartType = "Line",
      chartSeries,
      className = "",
      textClassName = "",
      contentClassName = "",
      list = [],
      lists = [],
      noCard,
      metric,
      isLoadingValue,
      history,
    } = this.props

    const locale = getLocale()

    let iconText
    if (statsIconLinkTo) {
      const linkTo = statsIconLinkTo.startsWith("#") ? statsIconLinkTo.substring(1) : statsIconLinkTo
      iconText = (
        <object>
          <a
            href={linkTo}
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              history.push(linkTo)
            }}
            onKeyDown={event => handleAccessibleOnKeyDown({ event, history, linkTo })}
          >
            {statsIconText}
          </a>
        </object>
      )
    } else {
      iconText = loc(statsIconText)
    }

    const content = (
      <div className={`card stats-card ${noCard ? "no-card" : ""} ${className}`}>
        <div className={`content ${contentClassName}`}>
          <Row>
            {(bigIcon || chartSeries) && (
              <Col xs={chartSeries ? 4 : 3}>
                {bigIcon && <div className="big-icon">{bigIcon}</div>}
                {chartSeries && (
                  <div className="numbers text-left">
                    {statsText && <p>{loc(statsText)}</p>}
                    {isLoadingValue ? <i className="icn-circle-notch icn-spin icn-sm text-gray-darkest" /> : statsValue}
                  </div>
                )}
              </Col>
            )}
            <Col xs={bigIcon || chartSeries ? (chartSeries ? 8 : 9) : 12}>
              {chartSeries && (
                <ChartistGraph
                  className="ct-chart-stats-card"
                  type={chartType}
                  data={{ series: chartSeries }}
                  options={{
                    showPoint: false,
                    axisY: {
                      labelInterpolationFnc: () => null,
                    },
                    axisX: {
                      labelInterpolationFnc: () => null,
                    },
                  }}
                />
              )}
              {!chartSeries && (
                <div className={`numbers ${textClassName}`}>
                  <p>{loc(statsText)}</p>
                  {isLoadingValue ? <i className="icn-circle-notch icn-spin icn-sm text-gray-darkest" /> : statsValue}
                </div>
              )}
            </Col>
          </Row>

          {summary && (
            <Row>
              <Col xs={12} className={summaryClassName}>
                {summaryIcon && <i className={summaryIcon} />}
                {summary}
              </Col>
            </Row>
          )}

          {[...list, ...lists].length > 0 && (
            <Row className={chartSeries ? "" : "mt-3"}>
              {[...list, ...lists].map((line, index) => (
                <Fragment key={index}>
                  <Col xs={6} className="mt-10px">
                    {line.icon && <i className={line.icon} />}
                    {line.text && !line.textClassName && ` ${line.text}`}
                    {line.text && line.textClassName && !line.textLinkTo && <span className={line.textClassName}>{` ${line.text}`}</span>}
                    {line.text && line.textClassName && line.textLinkTo && (
                      <object>
                        <a
                          href={line.textLinkTo}
                          onClick={event => {
                            event.preventDefault()
                            event.stopPropagation()
                            history.push(line.textLinkTo)
                          }}
                          onKeyDown={event => handleAccessibleOnKeyDown({ event, history, linkTo: line.textLinkTo })}
                          className={line.textClassName}
                        >{` ${line.text}`}</a>
                      </object>
                    )}
                  </Col>
                  <Col xs={3} className={`mt-10px text-right ${line.value1ClassName}`}>
                    {line.value1Icon && <i className={line.value1Icon} />}
                    {line.value1 && ` ${line.value1}`}
                  </Col>
                  <Col xs={3} className={`mt-10px text-right ${line.value2ClassName}`}>
                    {line.value2Icon && <i className={line.value2Icon} />}
                    {line.value2 && ` ${line.value2}`}
                  </Col>
                </Fragment>
              ))}
            </Row>
          )}

          {debug && metric?.updateDate && (
            <div
              className="font-1 text-right mt-5px flex-center justify-content-end"
              onClick={event => {
                event.preventDefault()
                window.open(`/metric-record/${metric.key}`)
              }}
            >
              {loc("Update") + " " + formatDateTime(metric?.updateDate, locale)}
              {metric?.key && <i className="icn-external-link icn-xs c-pointer link ml-5px" />}
            </div>
          )}
          {debug && linkTo && <div className="font-1 text-right flex-center justify-content-end">{linkTo}</div>}
        </div>

        {(statsIcon || iconText) && (
          <div className="footer">
            <hr />
            <div className="stats inline-flex align-items-center">
              {statsIcon} {iconText}
            </div>
          </div>
        )}
      </div>
    )

    if (linkTo) {
      if (linkTo.startsWith("http")) {
        return (
          <div
            onClick={event => {
              event.stopPropagation()
              history.push(linkTo)
            }}
            className="text-color c-pointer stats-card-link-wrapper"
            tabIndex="0"
            onKeyDown={event => handleAccessibleOnKeyDown({ event, history, linkTo })}
          >
            {content}
          </div>
        )
      }

      const _linkTo = linkTo.startsWith("#") ? linkTo.substring(1) : linkTo
      return (
        <div
          onClick={event => {
            event.stopPropagation()
            history.push(_linkTo)
          }}
          tabIndex="0"
          onKeyDown={event => handleAccessibleOnKeyDown({ event, history, linkTo: _linkTo })}
          className="text-color c-pointer stats-card-link-wrapper"
        >
          {content}
        </div>
      )
    }

    return content
  }
}

export default StatsCard
