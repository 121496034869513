import axios from "axios"
import { PROPS_ } from "basikon-common-utils"
import debounce from "lodash.debounce"
import React from "react"

import Card from "@/_components/Card"
import EntityExtensionSectionPanel from "@/_components/EntityExtensionSectionPanel"
import MathSheet from "@/_components/MathSheetComponent"

import { getUriAndCacheResponse } from "@/_services/cache"
import { loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { runScriptLocally } from "@/_services/scripts"
import { DEFAULT_DEBOUNCE, labelFromName } from "@/_services/utils"

// this card will look at the "schema" (potentially extended with a collection-extension script) for a field "extensions: [{ sectionName1: { field1, field2 } }]"
// you can then decide how the extension is displayed, you have 3 modes
// mode #1: each section is a card, and each section field is a formInputExtended (this is used by Finadev)
// mode #2: each section is a sheet (this is used by Finadev)
// mode #3: one sheet for all sections (this is not used yet so probably buggy)

// This card should not be used anymore, or at least not *directly*, in favor of the card Layout
// The layout card supports specifiying a type Extension so you can embed this component inside a composite card

class EntityExtension extends React.Component {
  state = {}

  componentDidMount() {
    this.initAll(true)
  }

  componentDidUpdate(prevProps) {
    const { sheetTemplate, sectionSheetTemplate, schema, sections, section } = this.props

    const doLoadSheetTemplates = prevProps.sheetTemplate !== sheetTemplate || prevProps.sectionSheetTemplate !== sectionSheetTemplate
    if (prevProps.schema !== schema || doLoadSheetTemplates || prevProps.sections !== sections || prevProps.section !== section)
      this.initAll(doLoadSheetTemplates)
  }

  initAll = async doLoadSheetTemplates => {
    let { sheetTemplates, sectionSheetTemplates } = this.state
    let {
      schema,
      obj,
      handlers,
      section,
      serverRoute,
      sections,
      subExtension,
      sheetTemplate,
      sectionSheetTemplate,
      executeLayoutOnInit = true,
    } = this.props

    // init entitySchemas for handlers
    const handler = obj && handlers?.[obj]
    serverRoute = handler?.serverRoute || serverRoute

    // for compatibility - to remove but not to close from mep
    if (serverRoute && !serverRoute.startsWith("/api/")) serverRoute = "/api/" + serverRoute

    const entitySchema = schema || (serverRoute && (await getUriAndCacheResponse(serverRoute + "-schema")).schema)
    if (!sections && section) sections = section.split(",")
    let subExtensions = subExtension && subExtension.split(",")
    if (!subExtensions && !sections) {
      // all
      subExtensions = entitySchema.extensions && Object.keys(entitySchema?.extensions?.[0] || {})
    }

    const sandbox = {
      console,
      log: console.log,
      axios,
      min: (...args) => Math.min(...args) || undefined,
      max: (...args) => Math.max(...args) || undefined,
    }

    // load templates if any
    if (doLoadSheetTemplates) {
      const sheetTemplateNames = sheetTemplate?.split(",")
      if (sheetTemplateNames) {
        const promises = sheetTemplateNames.map(templateName => {
          return templateName ? runScriptLocally({ scriptName: templateName, dynArgs: sandbox }) : null
        })
        sheetTemplates = await Promise.all(promises)
      }
      const sectionSheetTemplateNames = sectionSheetTemplate?.split(",")
      if (sectionSheetTemplateNames) {
        const promises = sectionSheetTemplateNames.map(templateName => {
          return templateName ? runScriptLocally({ scriptName: templateName, dynArgs: sandbox }) : null
        })
        sectionSheetTemplates = await Promise.all(promises)
      }
    }

    // call execute
    this.setState({ entitySchema, subExtensions, sections, sheetTemplates, sectionSheetTemplates }, async () => {
      if (sheetTemplates) {
        // one template per subExtension, if any
        const promises = subExtensions.map((extensionName, index) => sheetTemplates[index] && this.handleSetEntityExtensionKeyState(index, {}))
        await Promise.all(promises)
      }
      if (sectionSheetTemplates) {
        // one template per subExtension, if any
        const promises = sections.map(
          (sectionName, index) => sectionSheetTemplates[index] && this.handleSetEntitySectionKeyState(index, {}, executeLayoutOnInit),
        )
        await Promise.all(promises)
      }
    })
  }

  getActualEntity = () => {
    const { handlers, obj, entity } = this.props
    if (obj && typeof obj === "string") {
      // this is used when we want to map another entity than the entity main page
      const handler = handlers[obj]
      if (!handler) addOops(loc("Invalid object $ in 'extension' card", obj))
      else {
        return handler.obj
      }
    }
    // default
    return entity
  }

  onSetStateSync = (patch, execLayout) => {
    // execComputations might be set by the layout script to trigger computation whenever a field is changed
    let { obj } = this.props
    const { handlers, onSetState, execComputations } = this.props
    if (obj && typeof obj === "string") {
      // this is used when we want to map another entity than the entity main page
      const handler = handlers[obj]
      if (!handler) addOops(loc("Invalid object $ in 'extension' card", obj))
      else {
        if (handler.obj) obj = handler.obj
        if (handler.onSetState) handler.onSetState(patch, execComputations)
      }
    } else {
      // this is used when we want to update the entity main page
      onSetState(patch, execComputations, execLayout)
    }
  }

  onSetStateDebounced = debounce(this.onSetStateSync, DEFAULT_DEBOUNCE)
  onSetState = patch => {
    this.onSetStateDebounced(patch)
  }

  // patch an extention sub key, e.g entity.extensions[0].section.field
  handleSetEntityExtensionKeyState = async (index, patch) => {
    let entityPatch
    if (patch) {
      const { subExtensions, sheetTemplates } = this.state
      const { parameters } = this.props

      const key = subExtensions[index]
      const entity = this.getActualEntity()
      const extensions = [...(entity.extensions || [{}])]
      extensions[0] = { ...(extensions[0] || {}) }
      extensions[0][key] = { ...(extensions[0][key] || {}), ...patch }

      // call execute if sheet template
      if (sheetTemplates?.[index]?.execute) {
        await sheetTemplates[index].execute({
          ...(parameters || {}),
          subExtension: extensions[0][key],
          entity,
          patch,
        })
      }

      entityPatch = { extensions }
    }

    this.onSetState(entityPatch)
  }

  // patch an entity sub key, e.g entity.section.field
  handleSetEntitySectionKeyState = async (index, patch, executeLayout = true) => {
    let entityPatch
    if (patch) {
      const { sections, sectionSheetTemplates } = this.state
      const { parameters } = this.props

      const key = sections[index]
      if (Array.isArray(patch)) {
        // NumbersTable case
        entityPatch = { [key]: patch }
      } else {
        let entity = this.getActualEntity()
        entity = { ...(entity || {}), [key]: { ...(entity?.[key] || {}), ...patch } }

        // call execute if sheet template
        if (sectionSheetTemplates?.[index]?.execute) {
          await sectionSheetTemplates[index].execute({
            ...(parameters || {}),
            section: entity[key],
            entity,
            patch,
          })
        }

        entityPatch = { [key]: entity[key] }
      }
    }

    if (executeLayout) this.onSetState(entityPatch)
    else this.onSetStateSync(entityPatch, false)
  }

  render() {
    const {
      title,
      titleSideText,
      debugInfo,
      readOnly,
      action,
      collapse,
      noCard,
      viewerHook,
      modelPath,
      columns,
      useFirstSectionAsTitle = true,
      className = "",
      showAddButtonFromLevel = 0, // show all Add buttons
      hover,
      sortFieldsByType,
    } = this.props
    const entity = this.getActualEntity() || {}
    const { entitySchema = {}, sheetTemplates = [], sectionSheetTemplates = [], sections = [], subExtensions = [] } = this.state
    const extension = (entity?.extensions && entity.extensions[0]) || {}

    if (!entitySchema?.extensions?.[0] && !sections[0]) return null
    if (!subExtensions[0] && !sections[0]) return null

    return (
      <Card
        debugInfo={debugInfo}
        noCard={noCard}
        collapse={collapse}
        action={action}
        // we need ?? and not || to allow disabling the title
        // this is needed when extensions are in layout cards
        // for the css to know when to remove the top border
        title={loc(title) ?? (useFirstSectionAsTitle ? loc(labelFromName(sections[0] || subExtensions[0])) : "")}
        titleSideText={titleSideText}
        className={"entity-extension " + className}
      >
        {/* Sections on entity */}
        {sections.map((sectionName, index) => {
          const sectionProps = entity[PROPS_ + sectionName]
          const schemaSection = entitySchema[sectionName]
          const obj = entity[sectionName]
          const sectionSheetTemplate = sectionSheetTemplates[index]

          return (
            <div key={sectionName} data-model-field-path={sectionName} className="entity-extension-section">
              {/* Use a sheet template to display the section */}
              {sectionSheetTemplate && (
                <>
                  {index > 0 && <legend>{loc(labelFromName(sectionName))}</legend>}
                  <MathSheet
                    sheetTemplate={sectionSheetTemplate}
                    obj={obj}
                    readOnly={readOnly}
                    onSetState={patch => this.handleSetEntitySectionKeyState(index, patch)}
                  />
                </>
              )}
              {/* Use FormInput for this section */}
              {!sectionSheetTemplate && schemaSection && (
                <EntityExtensionSectionPanel
                  obj={obj}
                  collapse
                  readOnly={readOnly}
                  showAddButtonFromLevel={showAddButtonFromLevel}
                  viewerHook={viewerHook}
                  sectionProps={sectionProps}
                  schemaSection={schemaSection}
                  modelPath={modelPath ? `${modelPath}.${sectionName}` : sectionName}
                  title={index > 0 ? loc(labelFromName(sectionName)) : undefined}
                  onSetState={patch => this.handleSetEntitySectionKeyState(index, patch)}
                  columns={columns}
                  hover={hover}
                  sortFieldsByType={sortFieldsByType}
                />
              )}
            </div>
          )
        })}

        {/* Sections on entity.extensions[0] */}
        {subExtensions.map((subExtensionName, index) => {
          const sectionProps = entity[PROPS_ + subExtensionName]
          const schemaSection = entitySchema.extensions?.[0][subExtensionName]
          const obj = extension[subExtensionName]
          const sheetTemplate = sheetTemplates[index]

          return (
            <div key={subExtensionName} data-model-field-path={subExtensionName} className="entity-subextension-section">
              {/* Use a sheet template to display the extension section */}
              {sheetTemplate && (
                <>
                  {(index > 0 || sections.length > 0) && <legend>{loc(labelFromName(subExtensionName))}</legend>}
                  <MathSheet
                    sheetTemplate={sheetTemplate}
                    obj={obj}
                    readOnly={entity.readOnly}
                    onSetState={patch => this.handleSetEntityExtensionKeyState(index, patch)}
                  />
                </>
              )}
              {/* Use FormInput for this extension section */}
              {!sheetTemplate && schemaSection && (
                <EntityExtensionSectionPanel
                  modelPath={modelPath ? `${modelPath}.${subExtensionName}` : subExtensionName}
                  title={index > 0 || sections.length > 0 ? loc(labelFromName(subExtensionName)) : undefined}
                  schemaSection={schemaSection}
                  sectionProps={sectionProps}
                  obj={obj}
                  readOnly={readOnly}
                  showAddButtonFromLevel={showAddButtonFromLevel}
                  onSetState={patch => this.handleSetEntityExtensionKeyState(index, patch)}
                  viewerHook={viewerHook}
                  columns={columns}
                  sortFieldsByType={sortFieldsByType}
                />
              )}
            </div>
          )
        })}
      </Card>
    )
  }
}

export default EntityExtension
