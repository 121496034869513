import React from "react"
import { Col, Row } from "react-bootstrap"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomButton from "@/_components/CustomButton"
import CustomCheckbox from "@/_components/CustomCheckbox"
import PanelInner from "@/_components/PanelInner"
import Table from "@/_components/Table"

import PersonContactModal from "@/person/PersonContactModal"

import { getListsValues } from "@/_services/lists"
import { loc } from "@/_services/localization"

class PersonContact extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      action: null,
      contactIndex: null,
      listPersonTitle: [],
    }
  }

  componentDidMount = () => {
    this.initListPersonTitle()
  }

  initListPersonTitle = async () => {
    this.setState({ listPersonTitle: await getListsValues("personTitle") })
  }

  editContact = contactIndex => {
    this.setState({
      contactIndex,
      action: "edit",
      showModal: true,
    })
  }

  deleteContact = index => {
    const { contacts = [], handleSetPersonState } = this.props
    contacts.splice(index, 1)
    handleSetPersonState({ contacts })
  }

  closeModal = () => {
    this.setState({
      contactIndex: undefined,
      showModal: false,
    })
  }

  newContact = () => {
    const { contacts = [] } = this.props

    this.setState({
      contactIndex: contacts ? contacts.length : 0,
      action: "create",
      showModal: true,
    })
  }

  findTitleLabel = title => {
    const { listPersonTitle } = this.state
    return listPersonTitle.find(personTitleValue => personTitleValue.value === title)?.label || title || ""
  }

  handleLineSelection = async (event, index) => {
    const { contacts = [], handleSetPersonState } = this.props
    contacts[index].isSelected = event.target.checked
    handleSetPersonState({ contacts })
  }

  onSave = contact => {
    const { contacts = [], handleSetPersonState } = this.props
    const { contactIndex } = this.state

    contacts[contactIndex] = contact

    // Only one contact can be default
    if (contact.isDefault) {
      contacts.forEach((c, index) => {
        if (index !== contactIndex) contacts[index].isDefault = false
      })
    }

    handleSetPersonState({ contacts }, true)
  }

  render() {
    const {
      contacts = [],
      readOnly,
      bsStyle = "primary",
      bsSize = "xs",
      defaultValues,
      title = "Contacts",
      show,
      collapse,
      selection,
      showAddButton = true,
      columns,
      rows,
    } = this.props
    const { contactIndex, showModal, action } = this.state

    const currentContact = contacts[contactIndex] || defaultValues

    let contactColumns = selection?.select ? [{ title: selection?.title ? loc(selection.title) : loc`Select`, name: "isSelected" }] : []
    contactColumns = contactColumns.concat(
      columns || [
        { title: loc`Name`, name: "name" },
        { title: loc`Position`, name: "position", select: "contactPosition" },
        { title: loc`Phone`, name: "phone" },
        { title: loc`Email`, name: "email" },
        { title: loc`Social networks`, name: "socialNetworks" },
        !readOnly && { title: loc`Actions`, name: "actions", className: "w-5" },
      ],
    )

    const data = contacts
      .filter(c => c && (c.firstName || c.lastName))
      .map((contact, index) => {
        const {
          isDefault,
          title = "",
          firstName = "",
          lastName = "",
          secondName = "",
          position,
          phone,
          mobile,
          email,
          linkedIn,
          facebook,
          instagram,
          isSelected,
        } = contact || {}

        return {
          isSelected: (
            <CustomCheckbox
              number={contact._id}
              disabled={readOnly}
              checked={isSelected}
              onChange={event => this.handleLineSelection(event, index)}
            />
          ),
          name: (
            <>
              {`${this.findTitleLabel(title)} ${firstName} ${lastName} ${secondName}`}
              {isDefault && (
                <CustomButton bsStyle="info" bsSize="xs" fill round className="ml-5px">
                  {loc`Default`}
                </CustomButton>
              )}
            </>
          ),
          position,
          phone: phone || mobile,
          email,
          socialNetworks: (
            <>
              <CustomButton
                className="p-0 m-0 btn-simple btn-linkedin"
                disabled={!linkedIn}
                onClick={() => linkedIn && window.open(linkedIn, "_blank")}
              >
                <i className="icn-linked-in icn-sm" />
              </CustomButton>
              <CustomButton
                className="p-0 m-0 btn-simple btn-facebook"
                disabled={!facebook}
                onClick={() => facebook && window.open(facebook, "_blank")}
              >
                <i className="icn-facebook icn-sm" />
              </CustomButton>
              <CustomButton className="p-0 m-0 btn-simple" disabled={!instagram} onClick={() => instagram && window.open(instagram, "_blank")}>
                <i className="icn-instagram icn-sm" />
              </CustomButton>
            </>
          ),
          actions: (
            <>
              <ButtonWithTooltip tooltip={loc`Edit`} bsSize="xs" className="icn-edit icn-xs" onClick={() => this.editContact(index)} />
              {!selection?.select && (
                <ButtonWithTooltip tooltip={loc`Delete`} bsSize="xs" className="icn-xmark icn-xs" onClick={() => this.deleteContact(index)} />
              )}
            </>
          ),
        }
      })

    if (readOnly && data.length === 0) return null

    return (
      <>
        <PanelInner
          collapse={collapse}
          title={loc(title)}
          className={show ? "" : "d-none"}
          buttons={
            !readOnly &&
            showAddButton && (
              <CustomButton bsStyle={bsStyle} className="inline-flex-center" bsSize={bsSize} fill pullRight onClick={this.newContact}>
                <i className="icn-plus icn-xs" />
              </CustomButton>
            )
          }
        >
          <Row>
            {data.length > 0 && (
              <Col xs={12}>
                <Table columns={contactColumns} data={data} overflowX />
              </Col>
            )}
          </Row>
        </PanelInner>
        {showModal && <PersonContactModal {...{ action, currentContact, closeModal: this.closeModal, onSave: this.onSave, readOnly, rows }} />}
      </>
    )
  }
}

export default PersonContact
