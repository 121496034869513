import React, { Component } from "react"
import { Modal, Row, Col } from "react-bootstrap"

import FormInput from "@/_components/FormInput"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import { getQuickAddRelations } from "@/_services/personUtils"
import { labelFromName } from "@/_services/utils"
import { loc } from "@/_services/localization"

class DiagramAddModal extends Component {
  state = { data: {}, quickAddRelations: [] }

  handleClose = async (what, isPlus) => {
    const { clickedPerson, onClose } = this.props
    if (what) {
      if (isPlus) what = "new|" + what
      else what = "existing|" + what
    }
    onClose(what && clickedPerson.registration, what)
  }

  async componentDidMount() {
    const { clickedPerson } = this.props
    const quickAddRelations = await getQuickAddRelations(clickedPerson)
    this.setState({ quickAddRelations })
  }

  render() {
    const { clickedPerson } = this.props
    const { quickAddRelations } = this.state
    const { type, trigram } = clickedPerson

    const whats = quickAddRelations.filter(
      it => (!it.canAddOnType || it.canAddOnType === type) && (!it.canAddOnTrigram || it.canAddOnTrigram === trigram),
    )
    // console.log("QAR", qar, type, whatsKind)

    return (
      <Modal show={true} onHide={() => this.handleClose(null)} backdrop="static" className="diagram-add-modal" bsSize="sm">
        <Modal.Header closeButton>
          <Modal.Title>{loc("Add")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {whats.map(({ code, label }, index) => {
            return (
              <Row key={index}>
                <Col xs={12}>
                  <FormInput readOnly={true} inArray value={label || labelFromName(code)} />
                  <ButtonWithTooltip className="icn-search icn-xs" onClick={() => this.handleClose(code, false)} pullRight />
                  <ButtonWithTooltip className="icn-plus icn-xs" onClick={() => this.handleClose(code, true)} pullRight />
                </Col>
              </Row>
            )
          })}
        </Modal.Body>
      </Modal>
    )
  }
}

export default DiagramAddModal
