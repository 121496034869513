import axios from "axios"
import React from "react"

import Card from "@/_components/Card"
import FormInput from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"

import { getTaxes } from "@/_services/coreUtils"
import { loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { getTenant } from "@/_services/userConfiguration"
import { generateRows } from "@/_services/utils"

class AccountingCatalogItemCard extends React.Component {
  state = {
    taxes: [],
    tenant: {},
    bankAccounts: [],
  }

  componentDidMount() {
    getTaxes().then(taxes => this.setState({ taxes }))
    this.getBankAccount()
  }

  handleSetAccountingCatalogItemState = async patch => {
    if (!patch) return
    const { handleSetAccountingCatalogItemState } = this.props

    if ("orgaRegistration" in patch) {
      patch.bankAccount = null
      const { tenant } = this.state
      const person = patch?.orgaRegistration ? (await axios.get(`/api/person/persons/${patch.orgaRegistration}`)).data || {} : tenant
      const bankAccounts = (person?.bankAccounts || []).filter(ba => ba && ba.bankIban && !ba.isInactive)
      this.setState({ bankAccounts })
    }

    handleSetAccountingCatalogItemState(patch)
  }

  getBankAccount = async () => {
    try {
      const { accountingCatalogItem } = this.props
      const tenant = (await axios.get(`/api/core/tenants/${getTenant()}`)).data || {}
      const person = accountingCatalogItem?.orgaRegistration
        ? (await axios.get(`/api/person/persons/${accountingCatalogItem.orgaRegistration}`)).data || {}
        : tenant
      const bankAccounts = (person?.bankAccounts || []).filter(ba => ba && ba.bankIban && !ba.isInactive)
      this.setState({ tenant, bankAccounts })
    } catch (error) {
      addOops(error)
    }
  }

  getBankIbanOptions = () => {
    const { accountingCatalogItem } = this.props
    const { bankAccounts } = this.state
    const bankIbanOptions = []

    for (let bankAccount of bankAccounts) {
      bankIbanOptions.push({
        value: bankAccount.bankIban,
        label: `${bankAccount.bankIban} ${bankAccount.name && bankAccount.name !== bankAccount.bankIban ? `(${bankAccount.name})` : ""}`,
      })
    }

    const selectedBankIban = accountingCatalogItem?.bankIban
    const hasIban = selectedBankIban && bankAccounts.find(ba => ba.bankIban === selectedBankIban)
    if (!hasIban && selectedBankIban) {
      bankIbanOptions.push({
        value: selectedBankIban,
        label: selectedBankIban,
      })
    }

    return bankIbanOptions
  }

  getField = (col, element = {}, onSetState) => {
    const { name, colProps, formInputProps, hidden } = col
    if (hidden) return
    const { readOnly, modelPath } = this.props
    const { taxes } = this.state
    const props = {
      key: name,
      readOnly,
      obj: element,
      onSetState,
      modelPath,
    }

    if (name === "type")
      return (
        <FormInput
          field="type"
          richValuesList
          select="accountingType"
          colProps={colProps ?? { xs: 12, sm: 4, md: 2 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "code") return <FormInput field="code" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "name") return <FormInput field="name" colProps={colProps ?? { xs: 12, sm: 6 }} {...props} {...formInputProps} />
    else if (name === "orgaRegistration")
      return (
        <FormInputExtended
          field="orgaRegistration"
          label="Organization"
          searchEntityName="Person"
          query={{ type: "O" }}
          colProps={colProps ?? { xs: 12, sm: 4, md: 2 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "startDate")
      return <FormInput field="startDate" type="date" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
    else if (name === "endDate")
      return <FormInput field="endDate" type="date" colProps={colProps ?? { xs: 12, sm: 4, md: 3 }} {...props} {...formInputProps} />
    else if (name === "catalogName")
      return (
        <FormInput
          field="catalogName"
          select="accountingCatalogName"
          colProps={colProps ?? { xs: 12, sm: 4, md: 2 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "gaap")
      return <FormInput field="gaap" select="accountingGaap" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "journal")
      return <FormInput field="journal" select="accountingJournal" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "dc")
      return <FormInput field="dc" label="D/C" select="accountingDc" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "account")
      return <FormInput field="account" select="accountingLedger" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "amountType")
      return <FormInput field="amountType" select="accountingAmount" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "dateType")
      return <FormInput field="dateType" select="accountingDate" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "secondaryAccountType")
      return (
        <FormInput
          field="secondaryAccountType"
          select="accountingSecondaryAccountType"
          colProps={colProps ?? { xs: 12, sm: 4, md: 2 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "taxCode") {
      const taxesValues = taxes?.map(tax => ({ value: tax.code, label: tax.label || loc(tax.code) }))
      return <FormInputExtended field="taxCode" select={taxesValues} colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    } else if (name === "paymentMode")
      return <FormInput field="paymentMode" select="paymentMode" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "elementType")
      return (
        <FormInput
          field="elementType"
          richValuesList
          select="elementType"
          colProps={colProps ?? { xs: 12, sm: 4, md: 2 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "elementCode")
      return (
        <FormInput
          field="elementCode"
          richValuesList
          select="elementCode"
          colProps={colProps ?? { xs: 12, sm: 4, md: 2 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "financialProduct")
      return (
        <FormInput
          field="financialProduct"
          select="financialProduct"
          colProps={colProps ?? { xs: 12, sm: 4, md: 2 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "contractType")
      return <FormInput field="contractType" select="contractType" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "cashflowType")
      return <FormInput field="cashflowType" select="cashflowType" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "invoiceType")
      return (
        <FormInput
          field="invoiceType"
          richValuesList
          select="invoiceType"
          colProps={colProps ?? { xs: 12, sm: 4, md: 2 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "assetType")
      return <FormInput field="assetType" select="assetType" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name === "creditLineType")
      return (
        <FormInput field="creditLineType" select="creditLineType" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
      )
    else if (name === "bankAccountType")
      return (
        <FormInput field="bankAccountType" select="bankAccountType" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
      )
    else if (name === "thirdPartyType")
      return (
        <FormInput
          field="thirdPartyType"
          label="Person type"
          select="personType"
          colProps={colProps ?? { xs: 12, sm: 4, md: 2 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "bankAccount")
      return (
        <FormInput
          field="bankAccount"
          select={this.getBankIbanOptions()}
          colProps={colProps ?? { xs: 12, sm: 4, md: 2 }}
          {...props}
          {...formInputProps}
        />
      )
    else if (name === "unpaidReason")
      return <FormInput field="unpaidReason" select="unpaidReason" colProps={colProps ?? { xs: 12, sm: 4, md: 2 }} {...props} {...formInputProps} />
    else if (name) return <FormInputExtended key={name} field={name} colProps={colProps ?? { xs: 12 }} {...props} {...formInputProps} />
  }

  render() {
    const { title, collapse, noCard, accountingCatalogItem, rows, debugInfo, modelPath, readOnly } = this.props

    const defaultRows = [
      ["type", "code", "name"],
      ["orgaRegistration", "startDate", "endDate", "catalogName"],
      [
        "gaap",
        "journal",
        "dc",
        "account",
        "amountType",
        "dateType",
        "secondaryAccountType",
        "taxCode",
        "paymentMode",
        "elementType",
        "elementCode",
        "financialProduct",
        "contractType",
        "cashflowType",
        "invoiceType",
        "assetType",
        "creditLineType",
        "bankAccountType",
        "thirdPartyType",
        "bankAccount",
        "unpaidReason",
      ],
    ].filter(it => it)

    return (
      <Card debugInfo={{ ...debugInfo, defaultRows }} title={title ? loc(title) : undefined} noCard={noCard} collapse={collapse}>
        {generateRows({
          getField: this.getField,
          rows: rows?.length ? rows : defaultRows,
          entity: accountingCatalogItem,
          handleSetState: this.handleSetAccountingCatalogItemState,
          modelPath,
          readOnly,
        })}
      </Card>
    )
  }
}

export default AccountingCatalogItemCard
