import { checkErrors, getEntityServerRoute, getErrors, getWarnings, lowerCaseFirstLetter } from "basikon-common-utils"
import React, { Suspense } from "react"
import { Col, Row } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import ButtonWithErrors from "@/_components/ButtonWithErrors"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import EntitySteps from "@/_components/EntitySteps"
import EntityWorkflowButtons from "@/_components/EntityWorkflowButtons"
import FormInput from "@/_components/FormInput"
const EntityModelModal = React.lazy(() => import("@/_components/EntityModelModal"))
const TagsComponentModal = React.lazy(() => import("@/_components/TagsComponentModal"))

import { getUriAndCacheResponse } from "@/_services/cache"
import { copyEntity, pasteEntity } from "@/_services/copyPaste"
import { setEntityFieldProps } from "@/_services/entity"
import { getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { isOffline } from "@/_services/offlineService"
import { getAndCachePersonName } from "@/_services/personUtils"
import { getIsAdmin, getOptions, getPinnedEntities, getUser, hasPermission, pinEntity, unpinEntity } from "@/_services/userConfiguration"
import { applyClasses, debug, entityModelBtnId, labelFromName, scrollToModelField } from "@/_services/utils"

class EntityWorkflowCard extends React.Component {
  constructor(props) {
    super(props)

    const { entity, entityName } = this.props
    const { registration } = entity
    const pinnedEntity = getPinnedEntities({ entityName, registration })?.[0]

    this.state = {
      warnings: [],
      errors: [],
      pinnedEntity,
      showTagsModal: false,
    }
  }

  componentDidMount() {
    const { statusList, tagsList } = this.props
    if (statusList) getList(statusList, () => this.setState({ listLoaded: true }))
    if (tagsList) {
      const _list = getList(tagsList, list => this.setState({ showTags: list?.values?.length > 0 }))
      if (_list) this.setState({ showTags: _list?.values?.length > 0 })
    }
    this.getOrganizationName()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entity !== this.props.entity) this.getOrganizationName()
  }

  async getOrganizationName() {
    const { showOrganization, entity } = this.props
    if (showOrganization && entity?.orgaRegistration) {
      const organizationName = await getAndCachePersonName(entity.orgaRegistration)
      this.setState({ organizationName })
    }
  }

  getChevrons = () => {
    if (this.props.chevrons) return this.props.chevrons
    const chevrons = []
    if (this.props.statusList) {
      const statusList = getList(this.props.statusList)
      if (statusList) {
        let inRange = false
        let first = true
        for (const item of statusList.values) {
          if (item.value === this.props.entity.status) inRange = true
          if (item.chevron) {
            let style = inRange || (!this.props.entity.status && first) ? item.style : undefined
            chevrons.push({
              title: typeof item.chevron === "string" ? item.chevron : item.label,
              //info: inRange,
              style,
            })
            inRange = false
            first = false
          }
        }
      }
    }
    return chevrons
  }

  handleSetState = patch => this.setState(patch)

  handleSave = async event => {
    let { entity, entityName, onSave, onSetState } = this.props
    if (checkErrors(entity, onSetState, { skipWorkflowErrors: true })) return

    event.preventDefault()
    this.setState({ isSaving: true })

    try {
      const _entity = await onSave()
      if (_entity) entity = _entity

      // Reset prev transition warnings and errors after saving successfully
      this.handleSetState({ warnings: [], errors: [] })
    } catch (error) {
      const errors = error?.response?.data?.errors || []
      const fieldProps = error?.response?.data?.fieldProps
      const hasFieldProps = fieldProps && Object.keys(fieldProps).length > 0

      if (errors.length || hasFieldProps) {
        if (hasFieldProps) setEntityFieldProps(entity, fieldProps)

        this.handleSetState({ errors })
        onSetState(entity)
      } else {
        const errorMessage = error?.response?.data?.message || error.message || loc`Error saving` + ` ${entityName?.toLowerCase() || ""}`
        this.handleSetState({ errors: [errorMessage] })
        onSetState(entity)
        addOops(error)
        if (error.stack) console.error("EntityWorkflowCard.handleSave", error.stack)
      }
    }

    this.setState({ isSaving: false })
  }

  handleCopy = () => {
    const { entity, copyPasteOptions } = this.props
    copyEntity(entity, copyPasteOptions)
    addNotification("Done")
  }

  // this paste feature was developed to copy paste Product entities.
  // It should work ok while in the same environment, but if you paste it from env1 to env2 (e.g. UAT to PROD)
  // Then you might have issues with registrations beging different between env (like Product.persons)
  // So think twice before activating showCopyPaste with other entities (talk to Thomas)
  handlePaste = async () => {
    const { entityName, serverRoute = getEntityServerRoute(entityName), onSetState, copyPasteOptions } = this.props
    const { schema } = await getUriAndCacheResponse(serverRoute + "-schema")
    let entity
    try {
      entity = await pasteEntity(schema, copyPasteOptions)
      entity.fromPaste = true
    } catch (error) {
      console.log("Paste error is", error)
      return addNotification("Invalid data", "warning")
    }
    onSetState(entity)
  }

  pinEntity = async () => {
    const { entity, entityName } = this.props
    const { registration } = entity
    const data = await pinEntity({ entityName, registration })
    this.setState({ pinnedEntity: data })
  }

  unpinEntity = async () => {
    const { entity, entityName } = this.props
    const { registration } = entity
    await unpinEntity({ entityName, registration })
    this.setState({ pinnedEntity: null })
  }

  render() {
    const { showEntityModelModal, isSaving, organizationName, warnings = [], errors = [], pinnedEntity, showTagsModal } = this.state
    const {
      entity,
      buttons,
      canSaveWhenOffline,
      onSave,
      hideSave,
      showCopyButton,
      showPasteButton,
      showTasks,
      showAuditBtn: showAuditBtnProps,
      titleIcon,
      titleIconStyle,
      titleColProps = { sm: { expandedWithAction: 9 } },
      tagsList,
      getModalEntity,
      history,
      debugInfo,
      entityName,
      saveDisabled,
      workflowName,
      showTags: propsShowTags,
      ...props
    } = this.props
    const { _id, registration, name, readOnly, reasons, tags } = entity

    let { onHandleTasks, isChanged } = this.props
    let { showTags } = this.state

    if (!onHandleTasks)
      onHandleTasks = () => {
        history.push("/tasks?entityType=" + this.props.entityName + "&entityRegistration=" + entity.registration)
      }

    // when there is no "onSave" we assume isChanged is not handled at all
    if (!onSave) isChanged = true

    const actualTagsList = tagsList && getList(tagsList)
    if (propsShowTags !== undefined) showTags = propsShowTags
    if (!tagsList || !actualTagsList?.values?.[0] || (!tags?.[0] && readOnly)) showTags = false

    const entitySteps = this.getChevrons()
    const showEntitySteps = /*props.showChevrons &&*/ entitySteps.length > 0

    const isAdmin = getIsAdmin()
    let showAuditBtn = (registration || _id) && hasPermission("read /api/core/audits")
    if (showAuditBtnProps !== undefined) showAuditBtn = showAuditBtn && showAuditBtnProps
    const showModelButton = isAdmin || getOptions("showModelButton")
    // even if button is hidden we accept Ctrl+M shortcut for non admin user, if they are impersonated
    // this is because we don't want simple users to view the model as it reveals too much
    // if the user is impersonated then we can assume actual user is a consultant
    const allowCtrlMShortcut = showModelButton || getUser()?.impersonatedBy

    const _canSaveWhenOffline = !canSaveWhenOffline && isOffline()
    const nbOfReasonsColumns = Math.floor(reasons?.length / 4) || undefined

    let title = props.title
    if (!title) {
      const entityTitle = labelFromName(entityName, { isTranslated: false })
      if (_id) {
        title = loc(entityTitle)
        if (registration) title += ` #${registration}`
        if (name && name !== registration) title += ` "${name}"`
      } else {
        title = loc(`New ${lowerCaseFirstLetter(entityTitle)}`)
      }
    }
    if (typeof title === "string") title = (organizationName ? organizationName + " - " : "") + title

    const fieldErrors = getErrors(entity, { loc, locale: getLocale() })
    const _errors = [...errors, ...(fieldErrors || [])]

    const fieldWarnings = getWarnings(entity)
    if (fieldErrors && fieldErrors?.length && fieldWarnings && fieldWarnings?.length)
      fieldErrors.forEach(it => {
        const index = fieldWarnings.findIndex(r => r?.modelFieldPath === it?.modelFieldPath)
        if (index !== -1) fieldWarnings.splice(index, 1)
      })
    const warningsWithoutErrors = [...warnings]
    if (errors?.length && warningsWithoutErrors?.length)
      errors.forEach(it => {
        const index = warningsWithoutErrors.findIndex(r => r === it)
        if (index !== -1) warningsWithoutErrors.splice(index, 1)
      })
    const _warnings = [...warningsWithoutErrors, ...(fieldWarnings || [])]

    const action = !_canSaveWhenOffline && (
      <div className="pull-right flex">
        {Array.isArray(buttons) &&
          buttons.map((button, index) => {
            return button.tooltip ? <ButtonWithTooltip key={index} {...button} /> : <CustomButton key={index} {...button} />
          })}

        {registration && !isOffline() && (
          <ButtonWithTooltip
            pullRight
            bsSize="xs"
            simple={false}
            bsStyle="default"
            fill={pinnedEntity}
            tooltip={loc(pinnedEntity ? "Unpin" : "Pin")}
            className="icn-thumbtack icn-xs"
            btnClassName="flex-center"
            onClick={pinnedEntity ? this.unpinEntity : this.pinEntity}
          />
        )}

        {showTags && !readOnly && (
          <ButtonWithTooltip
            pullRight
            bsSize="xs"
            simple={false}
            bsStyle="default"
            className="icn-tags icn-xs"
            btnClassName="flex-center"
            tooltip={loc`Tags`}
            onClick={() => this.setState({ showTagsModal: true })}
          />
        )}

        {showTasks && (
          <ButtonWithTooltip
            pullRight
            simple={false}
            className="icn-tasks icn-xs"
            btnClassName="flex-center"
            bsStyle="default"
            tooltip={loc`Tasks`}
            onClick={onHandleTasks}
          />
        )}

        {showCopyButton && navigator.clipboard?.writeText && (
          <ButtonWithTooltip
            className="icn-copy icn-xs"
            btnClassName="flex-center"
            bsStyle="default"
            simple={false}
            bsSize="xs"
            pullRight
            onClick={this.handleCopy}
          />
        )}

        {showPasteButton && navigator.clipboard?.readText && (
          <ButtonWithTooltip
            className="icn-paste icn-xs"
            btnClassName="flex-center"
            bsStyle="default"
            simple={false}
            bsSize="xs"
            pullRight
            onClick={this.handlePaste}
          />
        )}

        {showAuditBtn && (
          <ButtonWithTooltip
            pullRight
            bsSize="xs"
            simple={false}
            bsStyle="default"
            tooltip={loc`Audit`}
            className="icn-history icn-xs"
            btnClassName="flex-center"
            linkTo={`/audits?entityName=${entityName}&entityRegistration=${entity.registration || entity._id}`}
          />
        )}

        {allowCtrlMShortcut && (
          <ButtonWithTooltip
            pullRight
            bsSize="xs"
            simple={false}
            bsStyle="default"
            id={entityModelBtnId}
            className="icn-eye icn-xs"
            btnClassName="flex-center"
            tooltip={loc`View model`}
            style={showModelButton ? undefined : { display: "none" }}
            onClick={() => this.setState({ showEntityModelModal: true })}
          />
        )}
      </div>
    )

    const _title = (
      <>
        <div>
          {titleIcon && <i className={"mr-5px " + titleIcon + (titleIconStyle ? " text-" + titleIconStyle : "")} bsSize="xs" />}

          <span className="entity-workflow-card-title">{title}</span>

          {(!showEntitySteps || debug) && (
            <FormInput
              readOnly
              badge
              inArray
              obj={entity}
              field="status"
              fcClassName="c-default entity-workflow-card-status"
              select={props.statusList}
            />
          )}
        </div>
        {showTags && (
          <div className="float-clear leading-0 font-weight-normal">
            {tags?.map((tag, key) => {
              const tagItem = actualTagsList?.items?.[tag.tag] || {
                style: "default",
                label: tag.tag + " (???)",
              }
              if (!tagItem) return null
              return (
                <div key={key} className="d-inline-block mr-5px font-1-2">
                  <i className={`icn-tag icn-xs ${tagItem.style ? `text-${tagItem.style}` : ""}`} />
                  {loc(tagItem.label)}
                </div>
              )
            })}
          </div>
        )}
      </>
    )

    return (
      <Row className="entity-workflow-card">
        <Col xs={12}>
          <Card debugInfo={debugInfo} collapse={props.collapse} action={action} titleColProps={titleColProps} title={_title}>
            {showEntitySteps && <EntitySteps bgWhite steps={entitySteps} />}
            {_errors?.length > 0 && <ErrorsOrWarnings type="error" messages={_errors} noMarginBottom={_warnings?.length || reasons?.length} />}
            {_warnings?.length > 0 && <ErrorsOrWarnings type="warning" messages={_warnings} noMarginBottom={reasons?.length} />}
            {reasons?.length > 0 && (
              <Row>
                <Col xs={12}>
                  <ul className="entity-workflow-card-reasons" style={{ "--workflow-reasons-columns-nb": nbOfReasonsColumns }}>
                    {reasons.map((it, index) => (
                      <li className={_warnings.length ? "text-secondary" : "text-warning"} key={index}>
                        {it && (loc(it.label) || labelFromName(it.value))}
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            )}

            <EntityWorkflowButtons
              {...this.props}
              handleSetState={this.handleSetState}
              saveButton={
                !readOnly &&
                !hideSave &&
                onSave && (
                  <ButtonWithErrors
                    disabled={!isChanged || isSaving || _canSaveWhenOffline || saveDisabled}
                    bsStyle="primary"
                    className="inline-flex-center"
                    bsSize="small"
                    fill
                    type="submit"
                    errors={_errors}
                    onClick={this.handleSave}
                  >
                    {isSaving && <i className="icn-circle-notch icn-spin icn-xs mr-5px" />}
                    {loc`Save`}
                  </ButtonWithErrors>
                )
              }
            />
          </Card>
        </Col>

        {showEntityModelModal && (
          <Suspense fallback={null}>
            <EntityModelModal
              {...this.props}
              workflowName={workflowName}
              showScriptButtons
              entityName={entityName}
              entity={getModalEntity?.() || entity}
              showEntityFieldsButtons={!!getModalEntity}
              close={() => this.setState({ showEntityModelModal: false })}
            />
          </Suspense>
        )}

        {showTagsModal && (
          <Suspense fallback={null}>
            <TagsComponentModal
              tags={tags}
              tagsList={tagsList}
              onClose={() => this.setState({ showTagsModal: false })}
              onSetTags={tags => props.onSetState({ tags })}
            />
          </Suspense>
        )}
      </Row>
    )
  }
}

export default withRouter(EntityWorkflowCard)

function ErrorsOrWarnings({ type, messages = [], noMarginBottom }) {
  const nbOfColumns = Math.floor(messages?.length / 4) || undefined

  const isError = type === "error"

  const liClassName = applyClasses({
    "text-danger": isError,
    "text-warning": !isError,
  })
  const ulClassName = applyClasses({
    "entity-workflow-card-errors": isError,
    "entity-workflow-card-warnings": !isError,
    "mb-0": noMarginBottom,
  })

  const ulStyleKey = isError ? "--workflow-errors-columns-nb" : "--workflow-warnings-columns-nb"

  return (
    <Row>
      <Col xs={12}>
        <ul className={ulClassName} style={{ [ulStyleKey]: nbOfColumns }}>
          {messages.map((message, index) => {
            const _message = message.value || message
            const isArray = Array.isArray(_message)
            const isString = typeof _message === "string"
            const isMultiLine = isString && _message.includes("\n")

            return (
              <li
                key={index}
                className={liClassName}
                data-model-field-path-ref={message.modelFieldPath}
                onClick={() => message.modelFieldPath && scrollToModelField(message.modelFieldPath)}
              >
                {isString && !isMultiLine && <span>{loc(_message)}</span>}
                {isMultiLine &&
                  _message
                    .split("\n")
                    .filter(message => message?.trim())
                    .map((message, key) => (
                      <>
                        <span key={key}>{message?.trim()}</span>
                        <br />
                      </>
                    ))}
                {isArray && <span>{loc(..._message)}</span>}
                {!isString && !isMultiLine && !isArray && typeof _message === "object" && JSON.stringify(_message)}
              </li>
            )
          })}
        </ul>
      </Col>
    </Row>
  )
}
