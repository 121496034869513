import axios from "axios"
import { objectToSearchParam } from "basikon-common-utils"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import Card from "@/_components/Card"
import TaskModal from "@/_components/TaskModal"
import TasksComponent from "@/_components/TasksComponent"

import { loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { isOffline } from "@/_services/offlineService"
import { getOptions } from "@/_services/userConfiguration"

class TasksList extends React.Component {
  state = {
    tasks: [],
    loading: false,
  }

  componentDidMount() {
    this.getTasks()
  }

  getTasks = async () => {
    this.setState({ loading: true })

    const props = Object.assign({}, this.props, { history: undefined, location: undefined })

    try {
      const queryParams = objectToSearchParam({ range: "[0-9]", ...props.filters })
      const tasks = (await axios.get(`/api/core/tasks${queryParams}`)).data
      this.setState({ tasks })
    } catch (error) {
      addOops(error)
    } finally {
      this.setState({ loading: false })
    }
  }

  handleCheckbox = event => {
    const target = event.target
    this.setState({ [target.name]: target.checked })
  }

  handleShowTaskModal = id => this.setState({ showTaskModal: id })

  handleTaskModalClose = async task => {
    this.setState({ showTaskModal: false })
    if (task) this.getTasks()
  }

  render() {
    const { tasks = [], showTaskModal, loading } = this.state
    const { category = "To be handled by user", showHeaders = false, title, history, ...props } = this.props
    const columns =
      !Array.isArray(this.props.columns) || this.props.columns.length === 0
        ? [{ name: "done" }, { name: "type" }, { name: "creationDate", className: "w-20" }, { name: "subject" }, { name: "actions" }]
        : this.props.columns
    const showCalendar = getOptions("showCalendar")

    return (
      <Card
        className="tasks-list-component"
        collapse={this.props.collapse}
        title={
          <>
            {loc(title) || loc`Tasks`}

            {!isOffline() && showCalendar && (
              <ButtonWithTooltip pullRight bsStyle="default" bsSize="xs" tooltip={loc`See calendar`} onClick={() => history.push("/calendar")}>
                <i className="icn-calendar icn-xs" />
              </ButtonWithTooltip>
            )}

            <ButtonWithTooltip pullRight bsStyle="default" bsSize="xs" tooltip={loc`See all tasks`} onClick={() => history.push("/tasks")}>
              <i className="icn-tasks icn-xs" />
            </ButtonWithTooltip>

            {!isOffline() && (
              <ButtonWithTooltip pullRight bsStyle="default" bsSize="xs" tooltip={loc`Add task`} onClick={() => this.handleShowTaskModal(-1)}>
                <i className="icn-plus icn-xs" />
              </ButtonWithTooltip>
            )}

            {!isOffline() && (
              <ButtonWithTooltip pullRight bsStyle="default" bsSize="xs" tooltip={loc`Refresh`} onClick={this.getTasks}>
                <i className={`icn-reload icn-xs ${loading ? "icn-spin" : ""}`} />
              </ButtonWithTooltip>
            )}

            {showTaskModal && <TaskModal id={showTaskModal === -1 ? null : showTaskModal} onClose={this.handleTaskModalClose} {...props} />}
          </>
        }
        category={category && loc(category)}
      >
        <Row>
          <Col xs={12}>
            {!loading && (
              <TasksComponent
                tasks={tasks}
                getTasks={this.getTasks}
                columns={columns}
                pageInUrl={false}
                striped={false}
                showHeaders={showHeaders}
                loading={loading}
                handleShowTaskModal={this.handleShowTaskModal}
                {...props}
              />
            )}
          </Col>
        </Row>
      </Card>
    )
  }
}

export default withRouter(TasksList)
