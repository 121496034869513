import React from "react"
import { Modal, Row, Col } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"
import ButtonWithErrors from "@/_components/ButtonWithErrors"
import FormInputExtended from "@/_components/FormInputExtended"
import { checkErrors } from "basikon-common-utils"
import { loc } from "@/_services/localization"

class PersonEditAddressesModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      prevAddresses: [...(props.person.addresses || [{}])],
    }
  }

  handleClose = () => {
    const { handleSetEntityState } = this.props
    const { prevAddresses } = this.state
    handleSetEntityState({ addresses: prevAddresses })
    this.props.onClose()
  }

  handleAddAddress = () => {
    const { handleSetEntityState, person } = this.props
    const addresses = [...(person.addresses || [])]
    addresses.push({})
    handleSetEntityState({ addresses })
  }

  handleDeleteAddress = index => {
    const { handleSetEntityState, person } = this.props
    let addresses = [...(person.addresses || [])]
    addresses.splice(index, 1)
    handleSetEntityState({ addresses })
  }

  handleSave = () => {
    const { person, handleSetEntityState } = this.props

    if (checkErrors({ addresses: person.addresses }, handleSetEntityState)) return

    this.props.onClose()
  }

  handleSetFirst = index => {
    const { handleSetEntityState, person } = this.props
    let addresses = [...(person.addresses || [])]
    let tmp = addresses[0]
    addresses[0] = addresses[index]
    addresses[index] = tmp
    handleSetEntityState({ addresses })
  }

  render() {
    const { readOnly, modelPath, person, handleSetEntityState, extraInfo, language, region } = this.props
    let addresses = person.addresses

    if (!addresses || addresses.length === 0) addresses = [{}]

    return (
      <Modal show={true} onHide={() => this.handleClose()} bsSize="large" backdrop="static" className="person-edit-addresses-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc`Edit addresses`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {addresses.map((address, index) => {
            let handleSetPersonAddressState = patch => {
              if (patch) {
                addresses[index] = { ...(addresses[index] || {}), ...patch }

                handleSetEntityState({ addresses })
              }
            }
            return (
              <>
                {index > 0 && (
                  <legend>
                    <>
                      <div className="text-right pull-right">
                        <CustomButton bsSize="xs" simple pullRight onClick={() => this.handleDeleteAddress(index)}>
                          <i className="icn-xmark icn-xs" />
                        </CustomButton>
                        <CustomButton bsSize="xs" className="inline-flex-center" simple pullRight onClick={() => this.handleSetFirst(index)}>
                          <i className="icn-arrow-up icn-xs" />
                          {loc`Make it first/default`}
                        </CustomButton>
                      </div>
                    </>
                  </legend>
                )}
                <Row>
                  <Col xs={12}>
                    <FormInput
                      button
                      pullRight
                      mandatory
                      field="type"
                      obj={address}
                      readOnly={readOnly}
                      select="addressType"
                      modelPath={(modelPath ? `${modelPath}.` : "") + `addresses[${index}]`}
                      onSetState={patch => handleSetPersonAddressState(patch, index)}
                    />
                  </Col>
                </Row>
                <Row>
                  <FormInputExtended
                    singleRow
                    showDetails
                    obj={address}
                    extraInfo={extraInfo}
                    language={language}
                    region={region}
                    type="address"
                    readOnly={readOnly}
                    colProps={{ xs: 12, sm: 6 }}
                    modelPath={(modelPath ? `${modelPath}.` : "") + `addresses[${index}]`}
                    onSetState={patch => handleSetPersonAddressState(patch, index)}
                  />
                </Row>
              </>
            )
          })}
          {!readOnly && (
            <legend>
              <>
                <CustomButton bsStyle="primary" bsSize="small" className="inline-flex-center" fill onClick={this.handleAddAddress}>
                  <i className="icn-plus icn-xs mr-5px" />
                  {loc`Add address`}
                </CustomButton>
              </>
            </legend>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!readOnly && (
            <ButtonWithErrors bsStyle="primary" fill onClick={() => this.handleSave()}>
              {loc`Save`}
            </ButtonWithErrors>
          )}
          <CustomButton simple onClick={() => this.handleClose()}>
            {loc`Close`}
          </CustomButton>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default PersonEditAddressesModal
