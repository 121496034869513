import { formatBytes, formatDate, formatDateTime, formatPercentage, removeAllAccents } from "basikon-common-utils"
import React from "react"

import { getLabel } from "@/_services/lists"
import { getLocale } from "@/_services/localization"

export function getSearchParamName(searchFromUrl) {
  return typeof searchFromUrl === "string" ? searchFromUrl + "_search" : "search"
}

function match(str, search) {
  return removeAllAccents(str.toString()).toLowerCase().includes(removeAllAccents(search))
}

export function filterData(columns = [], data = [], search) {
  if (!search || typeof search !== "string") return data

  search = search.trim().toLowerCase()
  const locale = getLocale()
  const names = columns.map(col => col && col.name)

  return data.filter(row => {
    if (row.content) return true // Ignore nested rows when filtering

    for (const name of names) {
      let component = row[name]

      // Number
      if (component && !isNaN(component)) component = component.toString()

      // Text
      if (typeof component === "string") {
        // Match the label, not the value, especially if the "string" is from a column with a "select" prop.
        const column = columns.find(column => column === name || column.name === name)
        const select = column?.select || column?.formInputProps?.select
        if (select) {
          const label = getLabel(select, component)
          if (label && match(label, search)) return true
        } else if (match(component, search)) return true
      }

      // <Component/>
      if (React.isValidElement(component)) {
        const { registration, type, select, children, obj = {}, field } = component.props // <FormInput/> props
        let { value } = component.props

        if (!value && field) value = obj[field]
        if (!value && registration) value = registration // <PersonLink/>

        if (typeof children === "string" && match(children, search)) return true
        else if (Array.isArray(children)) {
          for (const child of children) {
            if (!child) continue
            if (typeof child === "string" && match(child, search)) return true
            if (typeof child.props?.children === "string" && match(child.props.children, search)) return true
          }
        }

        if (value) {
          if (type === "date") value = formatDate(value, locale)
          if (type === "datetime") value = formatDateTime(value, locale)
          if (type === "percentage") value = formatPercentage(value, locale)
          if (type === "bytes") value = formatBytes(value)
          if (type === "object" && (typeof value === "object" || Array.isArray(value))) value = JSON.stringify(value)
          if (select) {
            if (typeof select === "string") value = getLabel(select, value)
            if (Array.isArray(select)) {
              const option = select.find(o => o.value === value) || {}
              value = option.label || option.value || value
            }
          }

          if (match(value, search)) return true
        }
      }
    }
    return false
  })
}
