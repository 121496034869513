import axios from "axios"
import * as utilsCommon from "basikon-common-utils"
import * as debounce from "lodash.debounce"

import consoleService from "@/_services/console"
import { getListsValues, getListValueLabel } from "@/_services/lists"
import { loc, setLocale } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { addHeadScript, getHostConfig, removeHeadScript } from "@/_services/theming"
import {
  envIsDevelopment,
  envIsProduction,
  envIsStaging,
  envIsTest,
  envIsUat,
  getConfigAtPath,
  getEnvironment,
  getIsAdmin,
  getIsMainAdmin,
  getIsSuperAdmin,
  getTimeZone,
  getUser,
  getUserPermissions,
} from "@/_services/userConfiguration"
import { debug, scrollToModelField, searchParamToObject } from "@/_services/utils"

// from the public folder of implementations
const externalLibsBasePaths = {
  MobbSign: "/libs/mobbsign/",
}

const externalLibsOptions = {
  // https://developers.mobbeel.com/docs/mobbsign-web/mobbsign-web-sdk-overview.html
  // This is private npm package that creates its own UI because it needs to gather things like biometrics.
  // Doing it by ourselves would be very difficult and prone to errors.
  MobbSign: {
    src: `${externalLibsBasePaths.MobbSign}mobbsign.js`,
    useDangerousSandbox: true,
  },
}

const state = {
  scriptsCache: {},
}

/**
 * This function writes script tags in the html head block.
 * It means that external libs are immediately instanciated.
 * Make sure to call destroyExternalLibs once finished with them, to avoid leaks elsewhere in the app.
 * External libs must be very carefully evaluated before deciding to add them,
 * and most of them we should refuse to do so.
 */
async function fetchExternalLibs(externalLibs) {
  const { imp } = getHostConfig()
  const _externalLibs = Array.isArray(externalLibs) ? externalLibs : externalLibs.split(",")
  let useDangerousSandbox
  for (let i = 0; i < _externalLibs?.length; i++) {
    const extLibName = _externalLibs[i].trim()
    const extLibOptions = externalLibsOptions[extLibName]
    if (!extLibOptions?.src) continue
    if (extLibOptions.useDangerousSandbox) useDangerousSandbox = true
    addHeadScript({ src: `/imp/${imp}${extLibOptions.src}`, name: extLibName })
  }
  return { useDangerousSandbox }
}

/**
 * Same function as in the back-end but sync instead of async.
 */
function userHasPermission(permissionKey) {
  return getIsAdmin() || getIsMainAdmin() || getIsSuperAdmin() || getUserPermissions()?.[permissionKey]
}

let handleModal
function isModalClosed(state, prevState) {
  if (!state?.layout?.modal || !state?.modal || !prevState?.modal) return false
  return state.layout.modal && state.modal.show === false && prevState.modal.show === true
}

async function onHideModal(event, onHide) {
  // event is only provided when clicking on the close button of the modal
  // clicking in the backdrop does not provides it
  // however most of the time it is not useful
  if (typeof onHide === "function") await onHide({ event })
}

export async function wsGet(url, config) {
  return (await axios.get(url, config)).data
}

export async function wsPost(url, data, config) {
  return (await axios.post(url, data, config)).data
}

export async function wsPut(url, data, config) {
  return (await axios.put(url, data, config)).data
}

export async function wsPatch(url, data, config) {
  return (await axios.patch(url, data, config)).data
}

export async function wsDelete(url, config) {
  return (await axios.delete(url, config)).data
}

let codeSandbox
const iframeId = "script-sandbox"
function initCodeSandbox(staticArgs, params) {
  const { useDangerousSandbox } = params || {}
  const userTimeZone = getTimeZone()

  if (useDangerousSandbox) {
    if (window.codeSandbox?.log) return
    const fullExternalLibsBasePaths = {}
    const { imp } = getHostConfig()
    const extLibsNames = Object.keys(externalLibsBasePaths)
    extLibsNames.forEach(extLibName => (fullExternalLibsBasePaths[extLibName] = `/imp/${imp}${externalLibsBasePaths[extLibName]}`))
    window.codeSandbox = { externalLibsBasePaths: fullExternalLibsBasePaths }
  } else {
    // For uncertain reasons the codeSandbox can be reset by the browser
    // when going back and forth from a page using it.
    // Best guess is that the browser does some JIT work with the optimizing compiler
    // and sometimes decides to thrash the optimized code having the toolbox (but keeping the codeSandbox bare).
    // All that to say that we need to check the presence of one of the toolbox tool
    // instead of the codeSandbox itself.
    if (codeSandbox) {
      if (codeSandbox.log) return

      // The codeSandbox already exists but has been striped of the toolbox like described above.
      // We delete it before creating a new one.
      const existingIframeElement = document.getElementById(iframeId)
      if (existingIframeElement) existingIframeElement.parentElement.removeChild(existingIframeElement)
    }

    const rootElement = document.getElementById("root")
    const iframeElement = document.createElement("iframe")
    iframeElement.id = iframeId
    iframeElement.style.display = "none"
    rootElement.appendChild(iframeElement)
    codeSandbox = iframeElement.contentWindow
  }

  const toolbox = {
    console,
    log: consoleService.showLocalScriptLog === true ? console.log : () => {},
    logGroup: consoleService.showLocalScriptLog === true ? console.group : () => {},
    logGroupEnd: consoleService.showLocalScriptLog === true ? console.groupEnd : () => {},
    logError: consoleService.showLocalScriptError === true ? console.error : () => {},
    loc,
    setLocale,
    getRootUrl: () => window.location.origin,
    getUrlPathname: () => window.location.pathname,
    getUrlSearch: () => searchParamToObject(window.location.search),
    getEnvironment,
    isProduction: () => envIsProduction(), // legacy
    envIsProduction,
    envIsUat,
    envIsStaging,
    envIsDevelopment,
    envIsTest,
    ...utilsCommon,
    ...staticArgs,
    axios,
    wsGet,
    wsPost,
    wsPut,
    wsPatch,
    wsDelete,
    getListsValues,
    getListValueLabel,
    debounce,
    scrollToModelField,
    addNotification,
    handleModal,
    FormData,
    userHasPermission,
    formatDate: (date, locale, option) => utilsCommon.formatDate(date, locale, { timeZone: userTimeZone, ...(option || {}) }),
    formatDateTime: (date, locale, option) => utilsCommon.formatDateTime(date, locale, { timeZone: userTimeZone, ...(option || {}) }),
    formatCurrency: (number, locale, options) =>
      utilsCommon.formatCurrency(number, locale, {
        currencyDisplay: getConfigAtPath("currencyDisplay"),
        ...(options === 0
          ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
          : typeof options === "string"
          ? { currency: options }
          : options || {}),
      }),
  }

  if (useDangerousSandbox) {
    Object.keys(toolbox).forEach(key => (window.codeSandbox[key] = toolbox[key]))
  } else {
    Object.keys(toolbox).forEach(key => (codeSandbox[key] = toolbox[key]))
  }
}

function destroyCodeSandbox() {
  const existingIframeElement = document.getElementById(iframeId)
  if (existingIframeElement) existingIframeElement.parentElement.removeChild(existingIframeElement)
  codeSandbox = undefined
  destroyExternalLibs()
}

function destroyExternalLibs() {
  delete window.codeSandbox
  for (const externalLibName in externalLibsOptions) {
    delete window[externalLibName]
    removeHeadScript(externalLibName)
  }
}

async function importScript({ libName, importChain }) {
  const scriptCode = await getScript(libName, true)
  if (!scriptCode) return

  // Scripts located in sub-directories have the sub-directories path in their name.
  // However lib scripts are "namespaced" with their script name, which is this case would contain the illegal js character / (slash).
  // To support importing libs located in sub-directories,
  // we impose here by convention that the libs always retain internally their name without the path.
  // Example : `importScripts("libs/myLib")`
  // In the script of lib "myLib" the variable is named "myLib", not "libs/myLib"
  const internalLibName = libName.substring(libName.lastIndexOf("/") + 1)
  return runCodeInSandbox(scriptCode + `\n \nreturn ${internalLibName}`, null, { scriptName: libName, importChain, isLib: true })
}

/**
 * @param {object} params
 * @param {object} params.scriptName Root script name as stored in the state.
 * @param {object} params.importChain An empty object filled progressively with the list of imports. Used for debugging the import stack and detect loops.
 * @param {string[]} params.libNames List of libraries to import.
 * @returns Imports the libraries provided in arguments by returning their namespace object in an array in the same order as the list of names. If only one library name is provided, its namespace object is returned directly (not within an array).
 */
const importScripts = async params => {
  const { scriptName, importChain, libNames } = params || {}
  const libObjectsPromises = []
  const nbArgs = libNames.length

  for (let i = 0; i < nbArgs; i++) {
    const libName = libNames[i]
    if (importChain[scriptName]) {
      importChain[scriptName][libName] = true
    } else {
      importChain[scriptName] = { [libName]: true }
    }

    // TODO very hard
    // The algorithm to detect a loop is quite complex in the front-end side.
    // The difficulty is increased because the codeSandbox is reused for every calls
    // which means the function importScripts is mutated. The variable scriptName holds
    // the name of the last script that called importScripts.
    // One way to solve the last issue is to destroy and create again the codeSandbox every time
    // but this degrades performance. Object.assign, Object.create, .bind, etc. have been tried and stuff
    // around that have been tried, but to no avail.
    // As we are in the front-end, loop-protection is less necessary so we let go for now.

    // if (importChain[libName]?.[scriptName]) {
    //   if (libName === scriptName) {
    //     throw Error(`A library cannot import itself (${libName})`)
    //   } else {
    //     throw Error(`Import loop detected between libraries ${libName} and ${scriptName}`)
    //   }
    // }

    libObjectsPromises.push(importScript({ libName, importChain }))
  }

  const libObjects = await Promise.all(libObjectsPromises)
  return nbArgs === 1 ? libObjects[0] : libObjects
}

async function runCodeInSandbox(code, dynArgs, params) {
  const { scriptName, importChain = {}, isLib, useDangerousSandbox } = params || {}

  const targetCodeSandbox = useDangerousSandbox ? window.codeSandbox : codeSandbox || {}
  const evalTarget = useDangerousSandbox ? window : targetCodeSandbox

  // watch out : function syntax cannot be replaced by an arrow function because we use the arguments built-in variable (named here libNames)
  targetCodeSandbox.importScripts = async function () {
    return importScripts({ scriptName, importChain, libNames: arguments })
  }

  dynArgs && Object.keys(dynArgs).forEach(key => (targetCodeSandbox[key] = dynArgs[key]))

  // legacy scripts are the one using the execute format or don't declare both execute and res.json
  const isLegacyScript = isLib ? false : code.match(/(\(?{)? *execute *(:|})/g) || (!code.match(/res\.json/g) && !code.match(/res\.render/g))
  // if (isLegacyScript) console.log(`%c Script ${scriptName} uses the legacy format (execute) `, "background: #FFB4B4")
  return evalTarget.eval?.call(window, isLegacyScript ? code : "(async () => {" + code + "})()")
}

function removeScriptFromCache({ scriptName }) {
  delete state.scriptsCache[scriptName]
}

function reset({ itemsToReset = [] } = {}) {
  if (itemsToReset.length) {
    for (const scriptName of itemsToReset) {
      delete state.scriptsCache[scriptName]
    }
  } else {
    state.scriptsCache = {}
  }
  destroyCodeSandbox()
}

function deregister(name) {
  delete state.scriptsCache[name]
}

async function getScript(scriptName, acceptMissing) {
  // technique to prevent fetching the same script when called several times very quickly
  state.scriptsCache[scriptName] =
    state.scriptsCache[scriptName] ||
    new Promise((resolve, reject) =>
      axios
        .get(`/api/script/scripts/${scriptName}`)
        .then(({ data }) => resolve(data.code))
        .catch(error => {
          if (acceptMissing) return resolve()
          addOops(error)
          reject(error)
        }),
    )

  return state.scriptsCache[scriptName]
}

async function runScriptLocally({ scriptName, dynArgs, acceptMissing, silent }) {
  const scriptCode = await getScript(scriptName, acceptMissing)
  if (!scriptCode) return // Script can be missing

  try {
    return runCode(scriptCode, dynArgs, { scriptName })
  } catch (error) {
    console.error(error)
    if (!silent) addOops(error)
  }
}

async function runScript(scriptName, scriptCode, body = {}, silent) {
  let result
  const sandbox = {
    req: { body, user: getUser() || {} },
    res: { json: param => (result = param) },
  }

  try {
    await runCode(scriptCode, sandbox, { scriptName })
    return result
  } catch (error) {
    console.error(error)
    if (!silent) addOops(error)
  }
}

async function runCode(code, dynArgs, params) {
  const externalLibs = []
  // to also support the option compile-to ES5
  // we don't enforce the extLib declarations to be at the start of the script
  for (const extLibName in externalLibsOptions) {
    if (code.indexOf(`// extLib: ${extLibName}`) !== -1) externalLibs.push(extLibName)
  }
  const { useDangerousSandbox } = await fetchExternalLibs(externalLibs)

  params.useDangerousSandbox = useDangerousSandbox

  const user = getUser() || { profiles: [] }
  initCodeSandbox({ user, req: { user } }, { useDangerousSandbox })
  if (dynArgs?.req) dynArgs.req = { ...dynArgs.req, user }
  return runCodeInSandbox(code, dynArgs, params)
}

export async function executeLayout(parameters) {
  let { prevLayout, layoutScriptName, acceptMissing = true, defaultCards, dynArgs, entityType, ...params } = parameters

  if (!layoutScriptName) {
    addOops("Field 'layoutScriptName' is missing in parameters")
    return
  }

  if (entityType) layoutScriptName = `${layoutScriptName}-${entityType}`

  const layoutScript = await runScriptLocally({ scriptName: layoutScriptName, dynArgs, acceptMissing })

  if (!layoutScript) return defaultCards ? { cards: defaultCards } : undefined

  if (!layoutScript?.execute) {
    addOops("Layout script $ does not have an execute() function", layoutScriptName)
    return
  }

  params.cache = prevLayout?.cache || {}
  if (debug) console.log(`${layoutScriptName} parameters`, params)

  const layout = await layoutScript.execute(params)
  if (layout) layout.cache = params.cache
  return layout
}

export {
  deregister as deregisterScript,
  destroyExternalLibs,
  getScript,
  isModalClosed,
  onHideModal,
  removeScriptFromCache,
  reset as resetScripts,
  runCode,
  runScript,
  runScriptLocally,
}
