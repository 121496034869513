import axios from "axios"

import { getUiState, setUiState } from "@/_services/uiState"
import { getUserConfiguration } from "@/_services/userConfiguration"
import { appModes, checkIfInIframe, htmlTag, isSameOrigin, labelFromName, localStorageKeys } from "@/_services/utils"

import sassVars from "../_styles/sass-vars.module.scss"

const icns = sassVars.icns.split(",")
const defaultSvgIconsStyleString = icns
  .map(_svgIconName => {
    const svgIconName = _svgIconName.trim()
    return `--icn-${svgIconName}-mask-image: url("/icons/${svgIconName}.svg");`
  })
  .join("")

const rootStyleSheet = Array.from(document.styleSheets).find(styleSheet => styleSheet.title === "root-styles")
rootStyleSheet.insertRule(`:root { ${defaultSvgIconsStyleString} }`, rootStyleSheet.cssRules.length)

const hostConfig = {
  host: "hyperfront",
  imp: "standard",
  loginTenant: "",
  publicLogo: "basikon.svg",
  publicLogoUrl: "",
  manifestUrl: "",
  basePath: "",
  frameAncestors: null,
  loginTitle: "",
  loginButtonText: "",
  becomePartnerLinkText: "",
  becomePartnerFlowUrl: null,
  oidc: null,
  uiAuthConfig: null,
  mode: null, // flow
  parentOrigins: null,
  memoizeToken: true,
  loadFrontEnd: true,
  useManifest: true,
  windowTitle: "",
}

let manifest = {}
let userConfigPageRootTitle = ""
let currentCustomPageTitle = ""
let hasLoadedUserConfigPageTitle = false
let noDecoration = false
let userStyles = ""
let authStyles = ""
const userDataAttributes = {}
const authDataAttributes = {}
const fontsLoaded = []
const navControlsDisplayModeAttrKey = "data-nav-controls-display-mode"

export const userPreferencesTypes = {
  SEARCH_FILTER: "SEARCH_FILTER",
  CONTENT: "CONTENT",
  UI: "UI",
  PINNED_ITEM: "PINNED_ITEM",
}

export const userPreferencesUniqueGroupIds = {
  THEMING: "THEMING", // like localStorageKeys.UI_STATE.THEMING
}

const colorSchemes = {
  followSystem: "followSystem",
  dark: "dark",
  light: "light",
}

const colorSchemeList = [
  {
    value: colorSchemes.followSystem,
    label: "Follow system",
  },
  {
    value: colorSchemes.dark,
    label: "Dark",
  },
  {
    value: colorSchemes.light,
    label: "Light",
  },
]

const colorSchemeDefaultValues = {
  darkModeBrightness: 100,
  darkModeContrast: 90,
  darkModeSepia: 10,
}

let uiThemingPreferences = {
  _id: null,
  type: userPreferencesTypes.UI,
  groupId: userPreferencesUniqueGroupIds.THEMING,
  value: {
    colorScheme: "",
    ...colorSchemeDefaultValues,
  },
}

async function setHostConfig() {
  let { hostname } = window.location

  try {
    // If you want to test the render of a domain just comment out the hostname you want to render
    // hostname = "dev.hyperfront.com"
    // hostname = "uat1.hyperfront.io"
    // hostname = "uat2.hyperfront.io"
    // hostname = "uat3.hyperfront.io"

    // hostname = "uat.hyperfront.io"
    // hostname = "uat-arrawaj.hyperfront.io"
    // hostname = "uat-bmw-ie.hyperfront.io"
    // hostname = "uat-bpce.hyperfront.io"
    // hostname = "uat-calvin.hyperfront.io"
    // hostname = "uat-floa.hyperfront.io"
    // hostname = "uat-floa-flow.hyperfront.io"
    // hostname = "uat-hbges.hyperfront.io"
    // hostname = "uat-hbgde.hyperfront.io"
    // hostname = "uat-inifrance.hyperfront.io"
    // hostname = "uat-m3.hyperfront.io"
    // hostname = "uat-motor.hyperfront.io"
    // hostname = "uat-tagpay.hyperfront.io"
    // hostname = "uat-viatelease.hyperfront.io"
    // hostname = "uat-flexivan.hyperfront.io"
    // hostname = "uat-arkea.hyperfront.io"
    // hostname = "uat-kronos.hyperfront.io"

    // hostname = "finadev-guinee.hyperfront.io"
    // hostname = "cbs-mobile.arrawaj.ma"
    // hostname = "uat-arrawaj.hyperfront.io"
    // hostname = "corum.hyperfront.io"
    // hostname = "fiimat.hyperfront.io"
    // hostname = "leascorp.hyperfront.io"
    // hostname = "leaseandyou.com"
    // hostname = "www.leaseandyou.com"
    // hostname = "ordina.hyperfront.io"
    // hostname = "orion.hyperfront.io"
    // hostname = "solfiz.hyperfront.io"

    const { data } = await axios.get(`/api/core/hosts/${hostname}`)
    for (const key in hostConfig) if (data[key] !== undefined) hostConfig[key] = data[key]
    applyAuthentificationConfigurationStyles()
  } catch (err) {} // eslint-disable-line

  const { host, publicLogo, imp } = hostConfig
  const basePath = `/imp/${imp}/hosts/${host}/`
  hostConfig.publicLogoUrl = publicLogo !== "none" && `${basePath}${publicLogo}`
  hostConfig.manifestUrl = `${basePath}manifest.json`
  hostConfig.basePath = basePath

  // check whether we allow the app to be embedded in an iframe
  // same origin is always allowed (the app embeds itself)
  if (checkIfInIframe()) {
    if (hostConfig.frameAncestors !== "*" && !isSameOrigin()) throw Error()
    setAttribute("data-in-iframe", true)
  }

  // this is optional for the startup so we don't block here
  setTimeout(() => setManifest())

  return hostConfig
}

function getBrowserScrollBarWidth() {
  // some browsers now support OS scrollbar configuration in which it can be hidden when not in use
  const scrollbox = document.createElement("div")
  scrollbox.style.overflow = "scroll"
  document.body.appendChild(scrollbox)
  const scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth
  document.body.removeChild(scrollbox)
  return `--scrollbar-browser-width: ${scrollBarWidth}px;`
}

function setFlowPageHtmlStyle() {
  // For a better UX we always show the scroll bar so that there is no horizontal flickering.
  // It is also need in the device emulator for proper alignment on the right side.
  setAttribute("style", getBrowserScrollBarWidth() + "overflow-y: scroll")
}

async function setManifest() {
  try {
    // not using the manifest means that the web app can't be "installed" by modern browsers
    const { manifestUrl, basePath, useManifest, windowTitle } = hostConfig
    addHeadLink({ rel: "apple-touch-icon", href: `${basePath}apple-touch-icon.png` })
    addHeadLink({ rel: "shortcut icon", href: `${basePath}favicon.ico` })
    addHeadLink({ rel: "icon", href: `${basePath}favicon-32x32.png`, sizes: "32x32", type: "image/png" })
    addHeadLink({ rel: "icon", href: `${basePath}favicon-16x16.png`, sizes: "16x16", type: "image/png" })

    if (useManifest !== false) {
      addHeadLink({ rel: "manifest", href: manifestUrl })
      manifest = (await axios.get(manifestUrl)).data || {}
    }

    document.title = manifest.name || windowTitle || ""
  } catch (err) {} // eslint-disable-line
}

// We don't allow to replace the whole manifest config.
// The guideline is that tenants should have a base configuration proper to themselves,
// that can then be partially updated to precise things a bit.
// As long as we don't render server-side these kind of settings in the head of the DOM
// we will always have a small flicker at initialisation time between
// our standard configuration, the base tenant configuration and the additional tenant one coming after.
function setFavicon(faviconUrl) {
  const faviconLinks = document.querySelectorAll("link[rel~='icon']")

  // We don't bother allowing the different sizes, we just replace them with the one provided,
  // so it is better to provide the biggest version to it can be scaled down by the browser.
  if (faviconLinks.length === 0) {
    const link = document.createElement("link")
    document.getElementsByTagName("head")[0].appendChild(link)
    link.rel = "icon"
    link.href = faviconUrl
  }

  for (let i = 0; i < faviconLinks.length; i++) {
    const faviconLink = faviconLinks[i]
    faviconLink.href = faviconUrl
  }
}

function getHostConfig() {
  return hostConfig
}

function addHeadScript({ src, name }) {
  const scriptTag = document.createElement("script")
  scriptTag.setAttribute("id", `script-${name}`)
  scriptTag.setAttribute("name", name)
  scriptTag.setAttribute("src", src)
  document.head.appendChild(scriptTag)
}

function removeHeadScript(name) {
  const scriptElem = document.getElementById(`script-${name}`)
  if (scriptElem) scriptElem.remove()
}

function addHeadLink(args = {}) {
  const linkTag = document.createElement("link")
  for (const arg in args) linkTag.setAttribute(arg, args[arg])
  document.head.appendChild(linkTag)
}

function addHeadStyle({ styleString, tagId }) {
  if (tagId) {
    const existingStyleTag = document.getElementById(tagId)
    if (existingStyleTag) {
      existingStyleTag.innerText = styleString
      return
    }
  }

  const styleTag = document.createElement("style")
  if (tagId) styleTag.setAttribute("id", tagId)
  styleTag.innerText = styleString
  document.head.appendChild(styleTag)
}

function isValidStyle(styleValue, styleKey) {
  return (
    (typeof styleValue === "string" && styleValue !== "") ||
    typeof styleValue === "number" ||
    typeof styleValue === "boolean" ||
    (["fonts", "iconsMap", "svgIcons"].includes(styleKey) && Array.isArray(styleValue))
  )
}

function setAttribute(attribute, value, tag) {
  const _tag = tag || htmlTag
  _tag.setAttribute(attribute, value)
}

/**
 * To use when logging out because it sets the authStyles back.
 */
function removeUserConfigurationStyles({ resetPageTitle = true } = {}) {
  if (resetPageTitle) document.title = manifest.name || hostConfig.windowTitle || ""
  userConfigPageRootTitle = ""
  hasLoadedUserConfigPageTitle = false

  setAttribute("style", authStyles)

  const htmlTagAttributes = htmlTag.getAttributeNames()
  for (let i = 0; i < htmlTagAttributes.length; i++) {
    const attributeName = htmlTagAttributes[i]
    // the "data-rtl" is managed in the user configuration because we need it to be dynamic when changing the locale
    if (attributeName.indexOf("data-") === 0 && !["data-rtl", "data-no-decoration", "data-in-iframe"].includes(attributeName)) {
      if (userDataAttributes[attributeName] && authDataAttributes[attributeName]) {
        setAttribute(attributeName, authDataAttributes[attributeName])
      } else if (!authDataAttributes[attributeName]) {
        delete userDataAttributes[attributeName]
        htmlTag.removeAttribute(attributeName)
      }
    }
  }
  // The data-ucsl attribute might yet be mounted so it doesn't appear yet in htmlTagAttributes so we manually remove it.
  htmlTag.removeAttribute("data-ucsl")
  // When the user has user ALT + T to toggle this feature, it does not get removed properly so we force it
  htmlTag.removeAttribute(navControlsDisplayModeAttrKey)
}

/**
 * Note : this function removes previously set user data attributes
 * so that calling this function sets a clean config in the same time as removing the previous one.
 * This is not the same use case as calling removeUserConfigurationStyles.
 * Here the user is still connected but just changes the styles.
 */
async function applyUserConfigurationStyles({ implementation } = {}) {
  const userConfiguration = getUserConfiguration()

  if (userConfiguration?.title) userConfigPageRootTitle = userConfiguration.title
  hasLoadedUserConfigPageTitle = true
  setPageTitle(currentCustomPageTitle)
  userStyles = getBrowserScrollBarWidth()

  // This cannot be written in css files as webpack will otherwise try (unsuccessfully) to parse the path.
  // The url() declaration must also be written here for the same reason.

  const { styles } = userConfiguration || {}

  const hasCustomFonts = isValidStyle(styles?.["fonts"], "fonts")
  if (hasCustomFonts) {
    await loadFonts({ fonts: styles["fonts"] })
  }

  for (const userDataAttributeKey in userDataAttributes) {
    delete userDataAttributes[userDataAttributeKey]
    htmlTag.removeAttribute(userDataAttributeKey)
  }

  let navControlsDisplayMode

  for (const style in styles) {
    const styleValue = styles[style]
    if (!isValidStyle(styleValue, style)) continue

    if (style === "globalDisplayMode") userDataAttributes["data-global-display-mode"] = styleValue
    if (style === "buttonsDisplayMode") userDataAttributes["data-buttons-display-mode"] = styleValue
    if (style === "kpiDisplayMode") userDataAttributes["data-kpi-display-mode"] = styleValue
    if (style === "searchBarDisplayMode") userDataAttributes["data-searchbar-display-mode"] = styleValue
    if (style === "tableStatusBadgeDisplayMode") userDataAttributes["data-table-status-badge-display-mode"] = styleValue
    if (style === "borderStyle") userDataAttributes["data-border-style"] = styleValue
    if (style === "navControlsLinksDisplayMode") userDataAttributes["data-nav-controls-links-display-mode"] = styleValue
    if (style === "entityWorkflowCardPosition") userDataAttributes["data-entity-workflow-card-position"] = styleValue
    if (style === "navControlsDisplayMode") {
      // This is a special case.
      // For the accessibility to work properly when nav controls are configured to start at the top,
      // we must be able to fallback to the configured value when no value is stored in the local storage.
      userDataAttributes[navControlsDisplayModeAttrKey] = styleValue
      navControlsDisplayMode = styleValue
    }
    if (style === "navControlsLinkActiveDisplayMode") userDataAttributes["data-nav-controls-link-active-display-mode"] = styleValue
    if (style === "inputStyle") userDataAttributes["data-input-style"] = styleValue
    if (style === "appControlsDisplayMode") userDataAttributes["data-app-controls-display-mode"] = styleValue

    if (style === "navControlsLinksTextColor") userStyles += `--nav-controls-text-color: ${styleValue};`
    if (style === "navControlsShowBorderRight") userStyles += `--nav-controls-border-right: var(--app-controls-border-bottom);`
    if (style === "navControlsLinkActiveBackground") userStyles += `--nav-controls-link-active-background: ${styleValue};`
    if (style === "navControlsLinkHoverActiveBackground") userStyles += `--nav-controls-nav-hover: ${styleValue};`
    if (style === "navControlsLinkHoverEmphasisBorderColor") userStyles += `--nav-controls-link-hover-emphasis-border-color: ${styleValue};`
    if (style === "navControlsLinkActiveIconColor") userStyles += `--nav-controls-link-active-icon-color: ${styleValue};`
    if (style === "navControlsLinkActiveTextColor") userStyles += `--nav-controls-link-active-text-color: ${styleValue};`
    if (style === "navControlsBackground") userStyles += `--nav-controls-background: ${styleValue};`
    if (style === "navControlsLogoPartnerOrTenantBackground") userStyles += `--nav-controls-logo-partner-or-tenant-background: ${styleValue};`
    if (style === "baseFontSize") userStyles += `--font-size-base: ${styleValue};`
    if (style === "navControlsCategoryFontSize") userStyles += `--nav-controls-category-font-size: ${styleValue};`
    if (style === "navControlsCategoryFontWeight") userStyles += `--nav-controls-category-font-weight: ${styleValue};`
    if (style === "navControlsCategoryTextTransform") userStyles += `--nav-controls-category-text-transform: ${styleValue};`
    if (style === "cardTitleTextTransform") userStyles += `--card-title-text-transform: ${styleValue};`
    if (style === "textDefaultColor") userStyles += `--text-color: ${styleValue};`
    if (style === "primaryColor") userStyles += `--primary-color: ${styleValue};`
    if (style === "primaryStatesColor") userStyles += `--primary-states-color: ${styleValue};`
    if (style === "bodyBackground") userStyles += `--body-background: ${styleValue};`
    if (style === "appControlsBackground") userStyles += `--app-controls-background: ${styleValue};`
    if (style === "rowHoverBackgroundColor") userStyles += `--row-background-hover-color: ${styleValue};`
    if (style === "notificationBackgroundColor") userStyles += `--notification-background-color: ${styleValue};`
    if (style === "inputLabelFocusColor") userStyles += `--input-label-focus-color: ${styleValue};`
    if (style === "inputBorderColor") userStyles += `--input-border-color: ${styleValue};`
    if (style === "inputBorderFocusColor") userStyles += `--input-border-focus-color: ${styleValue};`
    if (style === "linkColor") userStyles += `--link-color: ${styleValue};`
    if (style === "infoColor") userStyles += `--info-color: ${styleValue};`
    if (style === "infoStatesColor") userStyles += `--info-states-color: ${styleValue};`
    if (style === "successColor") userStyles += `--success-color: ${styleValue};`
    if (style === "successStatesColor") userStyles += `--success-states-color: ${styleValue};`
    if (style === "warningColor") userStyles += `--warning-color: ${styleValue};`
    if (style === "warningStatesColor") userStyles += `--warning-states-color: ${styleValue};`
    if (style === "dangerColor") userStyles += `--danger-color: ${styleValue};`
    if (style === "dangerStatesColor") userStyles += `--danger-states-color: ${styleValue};`
    if (style === "mutedColor") userStyles += `--muted-color: ${styleValue};`
    if (style === "mutedStatesColor") userStyles += `--muted-states-color: ${styleValue};`

    if (style === "svgIcons") setSvgIconsStyle({ svgIcons: styleValue })

    if (!hasCustomFonts && style === "fontFace") await loadFontFace({ fontFaceFile: styleValue, implementation })
    if (style === "fontFamily") {
      userStyles += `--font-family: ${styleValue};`
    }
  }

  setAttribute("style", userStyles)

  // ucsl : User Config Styles Loaded
  userDataAttributes["data-ucsl"] = true
  for (const userDataAttributeKey in userDataAttributes) {
    setAttribute(userDataAttributeKey, userDataAttributes[userDataAttributeKey])
  }

  toggleNavControlsDisplayMode({ recall: true, navControlsDisplayMode })
}

function toggleNavControlsDisplayMode({ recall, navControlsDisplayMode } = {}) {
  userDataAttributes[navControlsDisplayModeAttrKey] = recall
    ? getUiState(localStorageKeys.UI_STATE.THEMING.NAV_CONTROLS_DISPLAY_MODE) || navControlsDisplayMode
    : userDataAttributes[navControlsDisplayModeAttrKey] === "top"
    ? "left"
    : "top"

  if (userDataAttributes[navControlsDisplayModeAttrKey]) {
    setUiState(localStorageKeys.UI_STATE.THEMING.NAV_CONTROLS_DISPLAY_MODE, userDataAttributes[navControlsDisplayModeAttrKey])
    setAttribute(navControlsDisplayModeAttrKey, userDataAttributes[navControlsDisplayModeAttrKey])
  }

  // We emit this event to let know that a UI change occurred,
  // so that developers can use it should they need to trigger changes related to that.
  setTimeout(() => window.dispatchEvent(new CustomEvent("resize")), 400)
}

function getNavControlsDisplayMode() {
  return userDataAttributes[navControlsDisplayModeAttrKey]
}

async function loadFontFace({ family, fontFaceFile, implementation, descriptors }) {
  const fontName = family || fontFaceFile.substring(0, fontFaceFile.indexOf("."))
  if (fontsLoaded.find(fontLoaded => fontLoaded === fontName)) return

  try {
    const { imp } = hostConfig
    const basePath = `/imp/${implementation || imp}/fonts/`
    const font = new FontFace(fontName, `url(${basePath + fontFaceFile})`, descriptors)
    await font.load()
    document.fonts.add(font)
    fontsLoaded.push(fontName)
    // don't fail if the font is not found so that the theming can continue to load
    // eslint-disable-next-line no-empty
  } catch (err) {}
}

async function loadFonts({ fonts }) {
  if (!Array.isArray(fonts)) return
  return Promise.all(
    fonts.map(font => {
      return loadFontFace({
        family: font.family,
        fontFaceFile: font.src,
        descriptors: {
          style: font.style,
          weight: font.weight,
        },
      })
    }),
  )
}

function setSvgIconsStyle({ svgIcons }) {
  const styleString = svgIcons
    .map(({ iconName, maskImageUrl }) => {
      const cssVar = `--icn-${iconName}-mask-image`
      return `${cssVar}: url(${maskImageUrl}); .icn-${iconName}::before { background-color: currentColor; content: ""; mask-image: var(${cssVar});}`
    })
    .join("")

  rootStyleSheet.insertRule(`:root { ${styleString} }`, rootStyleSheet.cssRules.length)
}

async function applyAuthentificationConfigurationStyles() {
  const { uiAuthConfig } = hostConfig

  if (uiAuthConfig && Object.keys(uiAuthConfig).length > 0) {
    const locale = localStorage.getItem(localStorageKeys.LOCALE) || navigator.language
    const { loginTitle, loginButtonText, becomePartnerLinkText } = uiAuthConfig

    if (typeof loginTitle === "object" && Object.keys(loginTitle).length > 0) {
      uiAuthConfig.loginTitle = loginTitle[locale] || loginTitle[locale.substring(0, 2)] || ""
    }

    if (typeof loginButtonText === "object" && Object.keys(loginButtonText).length > 0) {
      uiAuthConfig.loginButtonText = loginButtonText[locale] || loginButtonText[locale.substring(0, 2)] || ""
    }

    if (typeof becomePartnerLinkText === "object" && Object.keys(becomePartnerLinkText).length > 0) {
      uiAuthConfig.becomePartnerLinkText = becomePartnerLinkText[locale] || becomePartnerLinkText[locale.substring(0, 2)] || ""
    }

    const hasCustomFonts = isValidStyle(uiAuthConfig?.["fonts"], "fonts")
    if (hasCustomFonts) {
      await loadFonts({ fonts: uiAuthConfig["fonts"] })
    }

    for (const style in uiAuthConfig) {
      const styleValue = uiAuthConfig[style]
      if (!isValidStyle(styleValue, style)) continue

      if (style === "globalDisplayMode") authDataAttributes["data-global-display-mode"] = styleValue
      if (style === "buttonsDisplayMode") authDataAttributes["data-buttons-display-mode"] = styleValue
      if (style === "authentificationDisposition") authDataAttributes["data-authentication-disposition"] = styleValue
      if (style === "borderStyle") authDataAttributes["data-border-style"] = styleValue
      if (style === "inputStyle") authDataAttributes["data-input-style"] = styleValue
      if (style === "authentificationFormContrast") {
        authDataAttributes["data-authentication-contrast"] = styleValue
        if (styleValue === "transparent") authStyles += `--authentication-form-background-color: ${styleValue};`
      }

      if (style === "authentificationBackground") authStyles += `--authentication-background: ${styleValue};`
      if (style === "authentificationOverlayBackground") authStyles += `--authentication-overlay-background: ${styleValue};`
      if (style === "authentificationFormBackgroundColor") authStyles += `--authentication-form-background-color: ${styleValue};`
      if (style === "primaryColor") authStyles += `--primary-color: ${styleValue};`
      if (style === "primaryStatesColor") authStyles += `--primary-states-color: ${styleValue};`
      if (style === "inputLabelFocusColor") authStyles += `--input-label-focus-color: ${styleValue};`
      if (style === "inputBorderColor") authStyles += `--input-border-color: ${styleValue};`
      if (style === "inputBorderFocusColor") authStyles += `--input-border-focus-color: ${styleValue};`
      if (style === "linkColor") authStyles += `--link-color: ${styleValue};`
      if (style === "infoColor") authStyles += `--info-color: ${styleValue};`
      if (style === "infoStatesColor") authStyles += `--info-states-color: ${styleValue};`
      if (style === "successColor") authStyles += `--success-color: ${styleValue};`
      if (style === "successStatesColor") authStyles += `--success-states-color: ${styleValue};`
      if (style === "warningColor") authStyles += `--warning-color: ${styleValue};`
      if (style === "warningStatesColor") authStyles += `--warning-states-color: ${styleValue};`
      if (style === "dangerColor") authStyles += `--danger-color: ${styleValue};`
      if (style === "dangerStatesColor") authStyles += `--danger-states-color: ${styleValue};`
      if (style === "mutedColor") authStyles += `--muted-color: ${styleValue};`
      if (style === "mutedStatesColor") authStyles += `--muted-states-color: ${styleValue};`
      if (style === "baseFontSize") authStyles += `--font-size-base: ${styleValue};`

      if (style === "svgIcons") setSvgIconsStyle({ svgIcons: styleValue })

      if (!hasCustomFonts && style === "fontFace") await loadFontFace({ fontFaceFile: styleValue })
      if (style === "fontFamily") {
        authStyles += `--font-family: ${styleValue};`
      }
    }

    for (const authDataAttributeKey in authDataAttributes) {
      setAttribute(authDataAttributeKey, authDataAttributes[authDataAttributeKey])
    }
  }

  setAttribute("style", authStyles)
}

function setNoDecoration(value) {
  setAttribute("data-no-decoration", value)
  noDecoration = value
}

function getNoDecoration() {
  return noDecoration
}

/**
 * The loading order of components is a bit messy.
 * The cases we need to treat are :
 *  - Loading after user logs in : the document can already have the title set by the manifest and the user config provides a different one
 *  - Hard loading directly on a page
 *  - The page is loaded dynamically by ContentComponent and the content provides a pageTitle
 *  - The user logs out
 *
 * Currently the redirection performed by react-router-dom is not handled properly for content pages.
 * If the user logs in and gets redirected (with query param ?redirect=) then the page title won't be set as intended.
 */
function setPageTitle(_pageTitle = "") {
  if (hostConfig.mode === appModes.FLOW) {
    currentCustomPageTitle = _pageTitle || manifest.name || hostConfig.windowTitle || ""
    document.title = _pageTitle
    return
  }

  currentCustomPageTitle = _pageTitle
  if (!hasLoadedUserConfigPageTitle) return

  const pageRootTitle = userConfigPageRootTitle || manifest.name || hostConfig.windowTitle || ""
  if (pageRootTitle) {
    const { pathname } = window.location

    if (pathname.startsWith("/authentication") || (pathname.startsWith("/content") && !currentCustomPageTitle)) {
      document.title = pageRootTitle
      return
    }

    const pageTitle =
      currentCustomPageTitle ||
      pathname
        .split("/")
        .map((p, index) => {
          if (index === 1) return labelFromName(p.replaceAll("-", " "))
          return p
        })
        .join(" ")
        .trim()

    document.title = pageTitle ? `${pageRootTitle || ""} | ${pageTitle}` : pageRootTitle
  }
}

let darkReaderModule

/**
 * We can't import it due to a babel bug and we can't upgrade babel, probably due to our lag in version upgrades.
 * This has the good side effect of not having to manage it for the offline mode.
 * The DarkReader is loaded from index.html.
 */
const darkReader = {
  isEnabled: () => darkReaderModule?.isEnabled(),
  enable: options => darkReaderModule?.enable(options),
  disable: () => darkReaderModule?.disable(),
  auto: options => darkReaderModule?.auto(options),
}

function isSystemDarkModeEnabled() {
  return matchMedia("(prefers-color-scheme: dark)").matches
}

async function initUiThemingUserPreferences({ user, userPreferences }) {
  try {
    const { username, ssoId } = user || {}
    if (!username || ssoId) return

    uiThemingPreferences = userPreferences?.find(
      ({ type, groupId }) => type === userPreferencesTypes.UI && groupId === userPreferencesUniqueGroupIds.THEMING,
    )
    if (!uiThemingPreferences) return

    const { darkModeSepia, darkModeContrast, darkModeBrightness, colorScheme } = uiThemingPreferences?.value || {}
    const colorSchemePayload = {
      brightness: darkModeBrightness,
      contrast: darkModeContrast,
      sepia: darkModeSepia,
    }
    await parseColorScheme({ colorScheme, colorSchemePayload })
  } catch (error) {
    // do nothing, this is not important that it fails
  }
}

async function parseColorScheme({ colorScheme, colorSchemePayload }) {
  if (colorScheme === colorSchemes.dark) {
    darkReaderModule = await import("@/_services/darkreader")
    darkReader.enable(colorSchemePayload)
  }
  if (colorScheme === colorSchemes.followSystem) {
    if (isSystemDarkModeEnabled()) darkReaderModule = await import("@/_services/darkreader")
    darkReader.auto(colorSchemePayload)
  }
  if (!colorScheme || colorScheme === colorSchemes.light) {
    darkReader.disable()
  }
}

function getUiThemingUserPreferences() {
  return uiThemingPreferences
}

function setUiThemingUserPreferences(_iduiThemingPreferences) {
  return (uiThemingPreferences = _iduiThemingPreferences)
}

export {
  addHeadLink,
  addHeadScript,
  addHeadStyle,
  applyUserConfigurationStyles,
  colorSchemeDefaultValues,
  colorSchemeList,
  colorSchemes,
  darkReader,
  getHostConfig,
  getNavControlsDisplayMode,
  getNoDecoration,
  getUiThemingUserPreferences,
  initUiThemingUserPreferences,
  isSystemDarkModeEnabled,
  parseColorScheme,
  removeHeadScript,
  removeUserConfigurationStyles,
  setAttribute,
  setFavicon,
  setFlowPageHtmlStyle,
  setHostConfig,
  setManifest,
  setNoDecoration,
  setPageTitle,
  setUiThemingUserPreferences,
  toggleNavControlsDisplayMode,
}
