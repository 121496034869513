import React from "react"
import { Col, Row } from "react-bootstrap"
import axios from "axios"

import Card from "@/_components/Card"
import Table from "@/_components/Table"
import { filterColumns } from "@/_components/Table"
import { loc } from "@/_services/localization"
import { getEntityServerRoute, getEntityStatusListName, getEntityHistoryTypeListName } from "basikon-common-utils"

class EntityHistoryCard extends React.Component {
  state = {
    history: [],
    entityStatusListName: [],
    entityHistoryTypeListName: [],
  }

  componentDidMount() {
    this.fetchHistory()
  }

  componentDidUpdate(prevProps) {
    const { entity, entityName } = this.props
    if (entity !== prevProps.entity || entityName !== prevProps.entityName) {
      this.fetchHistory()
    }
  }

  fetchHistory = async () => {
    const { entity, entityName } = this.props
    const { history, registration } = entity || {}
    if (!history && entityName && registration) {
      const serverRoute = getEntityServerRoute(entityName)
      const entityStatusListName = getEntityStatusListName(entityName)
      const entityHistoryTypeListName = getEntityHistoryTypeListName(entityName)
      const { data } = await axios.get(`${serverRoute}/${registration}/history`)
      this.setState({ history: data, entityStatusListName, entityHistoryTypeListName })
    }
  }

  render() {
    const { history = [], entityStatusListName = [], entityHistoryTypeListName = [] } = this.state
    const { card = {}, entity, collapse, entityName, title, columns, filter, debugInfo } = this.props
    const { card: name, props = {} } = card
    const { colProps = { xs: 12 } } = props

    if (!entity.registration || !entityName) return null

    const histories = this.props.history || history

    const defaultcolumns = ["type", "code", "description", "sourceStatus", "targetStatus", "_insertDate", "_insertUser"]

    const data = histories.map(history => ({
      type: history.type,
      code: history.code,
      description: history.description,
      sourceStatus: history.sourceStatus,
      targetStatus: history.targetStatus,
      _insertUser: history._insertUser,
      _insertDate: history._insertDate,
    }))

    return (
      <Row>
        <Col key={name} {...colProps}>
          <Card debugInfo={{ ...debugInfo, defaultcolumns }} title={loc(title || "History")} collapse={collapse}>
            <Row>
              <Col xs={12}>
                <Table
                  columns={filterColumns(
                    [
                      { title: "Type", name: "type", select: entityHistoryTypeListName },
                      { title: "Code", name: "code" },
                      { title: "Description", name: "description" },
                      { title: "From status", name: "sourceStatus", select: entityStatusListName, formInputProps: { badge: true } },
                      { title: "To status", name: "targetStatus", select: entityStatusListName, formInputProps: { badge: true } },
                      { title: "Date", name: "_insertDate", type: "datetime" },
                      { title: "Username", name: "_insertUser" },
                    ],
                    columns?.length ? columns : defaultcolumns,
                  )}
                  data={data}
                  overflowX
                  filter={filter}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default EntityHistoryCard
