import React from "react"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"

const EntityModelModal = React.lazy(() => import("@/_components/EntityModelModal"))

export default class CardAction extends React.Component {
  state = {
    showModal: false,
  }

  render() {
    const { showModal } = this.state
    const { data, modal, ...props } = this.props

    return (
      <>
        <ButtonWithTooltip {...props} onClick={modal && (() => this.setState({ showModal: true }))} />
        {modal && showModal && <EntityModelModal entity={data} close={() => this.setState({ showModal: false })} />}
      </>
    )
  }
}
