import axios from "axios"
import { capitalizeFirstLetter, labelFromName, replaceVariables } from "basikon-common-utils"
import { cloneDeep, get } from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"

import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import TableExtended from "@/_components/TableExtended"

import { getUriAndCacheResponse } from "@/_services/cache"
import { loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"

class TableContent extends React.Component {
  state = {
    entities: [],
    selectedEntities: null,
    selectedRows: null,
  }

  componentDidMount() {
    this.getSchema()
  }

  componentDidUpdate(prevProps) {
    const { serverRoute } = this.props
    if (serverRoute !== prevProps.serverRoute) this.getSchema()
  }

  async getEntities() {
    const { entity, serverRoute } = this.props
    if (!serverRoute) return

    try {
      this.setState({ entities: (await axios(replaceVariables(serverRoute, entity))).data })
    } catch (error) {
      addOops(error)
    }
  }

  handleSetExpanded = expanded => {
    if (expanded) this.getEntities()
  }

  handleRowClick = async row => {
    let { selectedEntities, selectedRows } = this.state
    if (row && row.id) {
      selectedEntities = selectedEntities || new Set()
      selectedRows = selectedRows || new Set()
      if (selectedEntities.has(row.id)) {
        selectedEntities.delete(row.id)
        selectedRows.delete(row)
      } else {
        selectedEntities.add(row.id)
        selectedRows.add(row)
      }
    }
    this.setState({ selectedEntities, selectedRows })
  }

  handleAction = async (url, reloadAfterSuccess) => {
    let { selectedRows } = this.state
    try {
      await axios.post(url, Array.from(selectedRows))
      if (reloadAfterSuccess) setTimeout(() => window.location.reload(), 500)
    } catch (error) {
      addOops(error)
    }
  }

  handleButtonClick = async index => {
    const { buttons = [], history } = this.props
    const { url, method, body, successMessage, reloadAfterSuccess, linkTo } = buttons[index] || {}

    if (url && method) {
      this.setState({ [`loadingButton${index}`]: true })

      try {
        if (method === "POST") await axios.post(url, body)
        else await axios.get(url)

        if (successMessage) addNotification(successMessage)
        if (reloadAfterSuccess) setTimeout(() => window.location.reload(), 500)
      } catch (error) {
        addOops(error)
      }

      this.setState({ [`loadingButton${index}`]: false })
    }

    if (linkTo) history.push(linkTo)
  }

  getSchema = async () => {
    const { serverRoute, section } = this.props
    if (!section) return
    const sectionSchema = serverRoute && (await getUriAndCacheResponse(serverRoute + "-schema")).schema
    this.setState({ sectionSchema: get(sectionSchema, section) })
  }

  // autoColumns implements a set of rules to minimise the need of redundant or unnecessary definition in the configurations.
  autoColumns(columns) {
    const autoColumns = cloneDeep(columns || [])

    // Checking schema validity
    if (!this.state.sectionSchema) return autoColumns
    let { sectionSchema } = this.state
    if (Array.isArray(sectionSchema)) sectionSchema = sectionSchema[0]

    // If no columns are defined, add all the fields from the schema section.
    if (!autoColumns.length) {
      const fieldsKey = Object.keys(sectionSchema).filter(key => !["actions"].includes(key) && !key.startsWith("_") && !key.startsWith("props_"))
      for (const key of fieldsKey) if (!Array.isArray(sectionSchema[key])) autoColumns.push({ name: key })
    }

    return autoColumns.map(column => {
      if (typeof column === "string") column = { name: column } // columns can either be [{name: "registration"}] or ["registration"] for quicker definition
      const { name } = column
      if (!name || !sectionSchema[name]) return
      const field = sectionSchema[name]?.type ? sectionSchema[name] : { type: sectionSchema[name] } // Force field to be an object with the type inside

      // --------------------------------------------------------------------------------------------
      // Defaults based on schema
      // --------------------------------------------------------------------------------------------

      switch (field.type) {
        case "String":
          column.type = "text"
          break
        case "Boolean":
          column.type = "checkbox"
          break
        case "Date":
          column.type = "date"
          break
        case "Number":
          column.type = "number"
          break
        default:
          column.type = "text"
      }

      column = {
        ...field,
        ...column,
        formInputProps: { ...(field.formInputProps || {}), ...(column.formInputProps || {}) },
        title: column.title || field.formInputProps?.label || labelFromName(name),
        readOnly: this.props.readOnly && column.readOnly !== false,
      }

      // --------------------------------------------------------------------------------------------
      // Define searchEntityName based on the field names
      // --------------------------------------------------------------------------------------------

      // Auto detection of person fields -> set searchEntityName prop automatically if not already defined
      for (const registrationType of ["partner", "orga", "prospect", "payer", "payee", "person", "sales", "supplier"])
        if (name === `${registrationType}Registration`) {
          if (typeof column.formInputProps.searchEntityName === "undefined") {
            column.title ||= loc(registrationType.replace(/^\w/, match => match.toUpperCase()))
            column.formInputProps.searchEntityName = "Person"
          }
        }

      // Auto detection of fund fields -> set the searchEntityName prop automatically if not already defined
      for (const registrationType of ["fund", "funder", "fundee"])
        if (name === `${registrationType}Registration`) {
          if (typeof column.formInputProps.searchEntityName === "undefined") {
            column.title ||= loc(registrationType.replace(/^\w/, match => match.toUpperCase()))
            column.formInputProps.searchEntityName = "Fund"
          }
        }

      // Auto detection for other entities fields
      for (const registrationType of [
        "assetLot",
        "asset",
        "batch",
        "event",
        "camt",
        "amendment",
        "bankOperation",
        "contract",
        "deal",
        "project",
        "cashflow",
        "posting",
        "postingEvent",
        "invoice",
        "contractLot",
        "creditLine",
        "agreement",
        "inventory",
      ])
        if (name === `${registrationType}Registration`)
          if (typeof column.formInputProps.searchEntityName === "undefined")
            column.formInputProps.searchEntityName = capitalizeFirstLetter(registrationType)

      return column
    })
  }

  render() {
    const { entities } = this.state
    let {
      title,
      collapse,
      titleLinkTo,
      multiSelectionActions,
      buttons = [],
      pageInUrl = false,
      data,
      columns,
      rows = [],
      noCard,
      onRowChange,
      section,
      entity,
    } = this.props
    if (multiSelectionActions && !Array.isArray(multiSelectionActions)) multiSelectionActions = [multiSelectionActions]

    const actions = multiSelectionActions?.map((it, key) => {
      let {
        label,
        uri, // TODO: Remove me after release 22/11/2022
        url,
        reloadAfterSuccess,
        style,
      } = it
      if (!url && uri) url = uri // TODO: Remove me after release 22/11/2022

      return (
        <CustomButton
          key={key}
          bsStyle={style || "primary"}
          bsSize="small"
          pullRight
          fill
          onClick={() => this.handleAction(url, reloadAfterSuccess)}
          disabled={!this.state.selectedEntities?.size}
        >
          {loc(label)}
        </CustomButton>
      )
    })

    // Define `section` to populate `columns` & `data` automatically, like in Extension cards
    if (section) {
      // If panels have been declared, by default we exclude the fields from the columns that will already be in the panels.
      // Fields can be force-shown in both columns and panels by defining the columns manually in the layout.
      const excludedFieldsFromColumns = []
      for (const panel of rows.filter(r => r.type === "panel"))
        for (let i = 0; i < panel.rows.length; i++) {
          panel.rows[i] = this.autoColumns(panel.rows[i] || [])
          excludedFieldsFromColumns.push(...panel.rows[i].map(r => r.name))
        }
      columns = this.autoColumns(columns || [], Array.from(new Set(excludedFieldsFromColumns)))
      data = get(entity, section)
    }

    const content = (
      <>
        <TableExtended
          filter={true}
          pageInUrl={pageInUrl}
          data={data || entities}
          onRowClick={multiSelectionActions && this.handleRowClick}
          onRowChange={onRowChange}
          selectedEntities={this.state.selectedEntities}
          {...this.props}
          columns={columns}
        />

        {buttons?.length >= 1 && (
          <Row>
            <Col xs={12}>
              {buttons.map((button, index) => (
                <CustomButton {...button} key={index} loading={this.state[`loadingButton${index}`]} onClick={() => this.handleButtonClick(index)} />
              ))}
            </Col>
          </Row>
        )}
      </>
    )

    const childProps = {
      title: loc(title),
      collapse,
      titleLinkTo,
      action: actions,
      className: "table-content",
      onSetExpanded: isExpanded => isExpanded && this.getEntities(),
    }

    return <Card {...childProps} noCard={noCard} content={content} />
  }
}

export default TableContent
