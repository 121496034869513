import React, { Suspense } from "react"
import axios from "axios"

import CustomButton from "@/_components/CustomButton"

const SepaModal = React.lazy(() => import("@/billing/SepaModal.jsx"))

import { loc } from "@/_services/localization"
import { addOops, addNotification } from "@/_services/notification"
import { objectToSearchParam, YYYYMMDD } from "basikon-common-utils"

class GeneratePaymentFileButton extends React.Component {
  state = {
    loading: false,
    showSepaModal: false,
  }

  handleSepaModalClose = async params => {
    const { getSepas } = this.props

    if (params && params.date) {
      this.setState({ loading: true })
      try {
        params.date = YYYYMMDD(params.date)
        params.iban = params.bankAccounts
          .filter(b => b)
          .map(a => a.bankIban)
          .join(",")
        delete params.bankAccounts

        const sepas = (await axios.get("/api/billing/run-sepa" + objectToSearchParam(params))).data

        addNotification(loc`${sepas.length} payment files generated`)
        if (sepas.length > 0 && getSepas) getSepas()
      } catch (error) {
        addOops(error)
      }
    }
    this.setState({ showSepaModal: false, loading: false })
  }

  render() {
    const { loading, showSepaModal } = this.state

    return (
      <>
        <CustomButton
          fill
          pullRight
          bsSize="small"
          bsStyle="primary"
          loading={loading}
          className="inline-flex align-items-center"
          image="icn-money-check icn-sm mr-5px"
          label={loc`Generate payment file`}
          onClick={() => this.setState({ showSepaModal: true })}
        />
        {showSepaModal && (
          <Suspense fallback={null}>
            <SepaModal loading={loading} onClose={this.handleSepaModalClose} />
          </Suspense>
        )}
      </>
    )
  }
}

export default GeneratePaymentFileButton
