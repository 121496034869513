import axios from "axios"
import { objectToSearchParam } from "basikon-common-utils"
import React from "react"

import Card from "@/_components/Card"
import PersonLink from "@/_components/PersonLink"
import Table, { filterColumns } from "@/_components/Table"

import { getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { formatCurrency } from "@/_services/utils"

class EntityInvoices extends React.Component {
  state = {
    invoices: [],
    loading: false,
  }

  componentDidMount() {
    getList("invoicePr", () => this.setState({ loaded: true }))
    getList("invoiceType", () => this.setState({ loaded: true }))
    getList("paymentMode", () => this.setState({ loaded: true }))
    getList("paymentCondition", () => this.setState({ loaded: true }))
  }

  componentDidUpdate(prevProps, prevState) {
    const { expanded, fetched } = this.state

    const queryParams = this.getQueryParams()
    const hasExpanded = expanded && expanded !== prevState.expanded

    if ((expanded || hasExpanded) && !fetched && queryParams) this.setState({ fetched: true }, () => this.getInvoices(queryParams))
  }

  getQueryParams = () => {
    const { query, entityName, entityRegistration, invoiceType } = this.props
    if (!entityName && !entityRegistration) return

    let queryParams = {}
    if (entityName === "Asset" && entityRegistration) queryParams.lineAsset = entityRegistration
    if (entityName === "Contract" && entityRegistration) queryParams.contractRegistration = entityRegistration
    if (entityName === "AssetLot" && entityRegistration) queryParams.assetLotRegistration = entityRegistration
    if (entityName === "Maintenance" && entityRegistration) queryParams.maintenanceRegistration = entityRegistration
    if (entityName === "ContractLot" && entityRegistration) queryParams.contractLotRegistration = entityRegistration

    if (!Object.keys(queryParams).length) return // Avoid fetching invoices if there is no entity

    if (invoiceType) queryParams.type = invoiceType

    if (query) queryParams = { ...queryParams, ...query } // Apply additional query params by config if needed

    return queryParams
  }

  getInvoices = async queryParams => {
    this.setState({ loading: true })

    try {
      const invoices = (await axios.get("/api/billing/invoices" + objectToSearchParam(queryParams))).data
      this.setState({ invoices, loading: false })
    } catch (error) {
      addOops(error)
      this.setState({ loading: false })
    }
  }

  render() {
    const { invoices = [], loading } = this.state
    const { title = "Invoices", collapse, columns = [] } = this.props
    const locale = getLocale()

    return (
      <Card title={loc(title)} collapse={collapse} onSetExpanded={expanded => this.setState({ expanded })}>
        <Table
          filter
          loading={loading}
          pageInUrl={false}
          columns={filterColumns(
            [
              { title: "Payable/Receivable", name: "pr", select: "invoicePr" },
              { title: "Type", name: "type", select: "invoiceType" },
              { title: "Registration", name: "registration", linkTo: "/invoice/{registration}" },
              { title: "Invoice number", name: "invoiceNumber" },
              { title: "Name", name: "name" },
              { title: "Status", name: "status", select: "invoiceStatus", badge: true },
              { title: "Due date", name: "dueDate", type: "date" },
              { title: "Payment date", name: "paymentDate", type: "date" },
              { title: "Issue date", name: "issueDate", type: "date" },
              { title: "Payment mode", name: "paymentMode", select: "paymentMode" },
              { title: "Payment condition", name: "paymentCondition", select: "paymentCondition" },
              { title: "Amount (excl. tax)", name: "amountExclTax" },
              { title: "Amount", name: "amountInclTax" },
              { title: "Payer", name: "payer" },
              { title: "Payee", name: "payee" },
            ],
            columns,
          )}
          data={invoices.map(invoice => {
            const { persons = [] } = invoice

            const payer = persons.find(p => p.role === "PAYER") || {}
            const payee = persons.find(p => p.role === "PAYEE") || {}

            return {
              ...invoice,
              amountExclTax: formatCurrency(invoice.amountExclTax, locale, invoice.currency),
              amountInclTax: formatCurrency(invoice.amountInclTax, locale, invoice.currency),
              payer: <PersonLink registration={payer.personRegistration}>{payer.name}</PersonLink>,
              payee: <PersonLink registration={payee.personRegistration}>{payee.name}</PersonLink>,
            }
          })}
        />
      </Card>
    )
  }
}

export default EntityInvoices
