import axios from "axios"
import { formatBytes, objectToSearchParam } from "basikon-common-utils"
import { sanitize } from "dompurify"
import cloneDeep from "lodash.clonedeep"
import * as debounce from "lodash.debounce"
import marked from "marked"
import React, { Suspense } from "react"
import { Carousel, Col, Grid, Row } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"

import Card from "@/_components/Card"
import CardAction from "@/_components/CardAction"
import CustomButton from "@/_components/CustomButton"
import FormContent from "@/_components/FormContent"
import FormInput from "@/_components/FormInput"
import { contentTypes } from "@/_components/LayoutCard"
import PanelInner from "@/_components/PanelInner"
import StatsCard from "@/_components/StatsCard"
import TableContent from "@/_components/TableContent"
import TableExtended from "@/_components/TableExtended"
import TasksList from "@/_components/TasksListComponent"
import WidgetErrorBoundary from "@/_components/WidgetErrorBoundary"
const EntityModelModal = React.lazy(() => import("@/_components/EntityModelModal"))
const ChartistCard = React.lazy(() => import("@/_components/ChartistCard"))
const EChartCard = React.lazy(() => import("@/_components/EChartCard"))
const ContractContent = React.lazy(() => import("@/_components/ContractContent"))
const MapContent = React.lazy(() => import("@/_components/MapContent"))
const OpenapiComponent = React.lazy(() => import("@/_components/OpenapiComponent"))
const PivotContent = React.lazy(() => import("@/_components/PivotContent"))
const AiContent = React.lazy(() => import("@/_components/AiContent"))

import { getList } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { setPageTitle, userPreferencesTypes } from "@/_services/theming"
import { getOptions, getUserConfiguration, getUsername, getUserPreferences } from "@/_services/userConfiguration"
import { applyClasses, entityModelBtnId, hasQueryParamsChanged, mergeQueryParams, searchParamToObject } from "@/_services/utils"

const ContractCalculator = React.lazy(() => import("@/financing/ContractCalculator"))

// we use one global variable for drag and drop
let dnd = null

class ContentComponent extends React.Component {
  state = {
    isLoadingContent: false,
    isFetchingContent: false,
    widgetLoading: {},
    toolboxWidgets: [],
    editMode: this.props.name === "new",
    showToolbox: true,
  }

  componentDidMount = async () => {
    const isUserContent = this.props.match.url.startsWith("/user-content")
    let toolboxWidgets = []
    if (isUserContent) {
      try {
        toolboxWidgets = (await axios.get("/api/script/runs/user-content-config")).data
      } catch (error) {
        addOops(error)
      }
      await this.loadDashboards()
      document.addEventListener("keydown", this.handleKeyboardShortcuts, false)
    }

    this.setState({ isUserContent, isLoadingContent: true, toolboxWidgets }, this.getContent)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyboardShortcuts, false)
  }

  loadDashboards = async () => {
    const userPreferences = getUserPreferences()
    const dashboardValues = userPreferences
      .filter(({ type }) => {
        return type === userPreferencesTypes.CONTENT
      })
      .map(preference => ({
        value: preference.groupId,
        data: preference.value,
      }))
    dashboardValues.push({ value: "new", label: "<" + loc`New` + ">" }, { value: "copy", label: "<" + loc`Copy` + ">" })
    this.setState({ dashboardValues })
  }

  componentDidUpdate(prevProps) {
    const {
      content,
      name,
      __v,
      entity,
      location: { pathname },
      getContentOnUrlChange = true,
    } = this.props
    if (this.state.content?.props?.alwaysShowFilter) return

    if (prevProps.location.pathname !== pathname && !this.state.isLoadingContent) {
      this.setState({ isLoadingContent: true })
    }

    // added test on pathname for particulars cases when we switch between content having different queryParams hasQueryParamsChanged return true
    const hasChanged =
      getContentOnUrlChange && hasQueryParamsChanged(this.props, prevProps, ["search", "filter"]) && prevProps.location.pathname === pathname
    const hasEntityChanged =
      (entity && entity.status && entity.status !== prevProps?.entity?.status) || (entity && prevProps?.entity?._updateDate !== entity?._updateDate) // second condition is when we execute transition but we don't change status

    if (
      JSON.stringify(prevProps.content) !== JSON.stringify(content) ||
      prevProps.name !== name ||
      prevProps.__v !== __v ||
      hasChanged ||
      hasEntityChanged
    ) {
      this.getContent()
    }
  }

  fetchContent = async url => {
    const { history } = this.props
    const { data: content, headers } = await axios.get(url)
    const isMarkdown = headers["content-type"]?.includes("text/markdown;")

    // Redirect to another page/content if { redirectTo: "/path" }
    if (Object.keys(content).length === 1 && content.redirectTo) return history.push(content.redirectTo)

    content.rows?.map(row => row?.map(widget => this.loadWidget(widget)))
    if (content.type?.toLowerCase() === "table") this.applyTableProps({ content })

    this.setState({ content, isLoadingContent: false, isFetchingContent: false, isMarkdown })
    const contentPageTitle = content?.pageTitle || content.content?.pageTitle
    if (contentPageTitle) setPageTitle(contentPageTitle)
  }

  fetchContentDebounced = debounce(this.fetchContent, 250)

  // the page argument is especially use for service pagination
  // (pagination provided by API)
  getContent = async ({ page, search, withQueryParams } = {}) => {
    const { name, location, history, acceptMissing } = this.props
    const { isUserContent, contentSearch, content } = this.state

    if (isUserContent) {
      if (name === "copy") {
        this.setState({
          isLoadingContent: false,
          preference: undefined,
        })
      } else if (name === "new") {
        this.setState({
          editMode: true,
          isLoadingContent: false,
          content: {
            rows: [
              [
                {
                  colProps: { lg: 3 },
                  content: {
                    type: contentTypes.KPI,
                    props: { text: "Drop here" },
                  },
                },
              ],
            ],
          },
        })
      } else if (name) {
        const editMode = searchParamToObject(location.search).editMode ? true : false
        this.setState({ content, isLoadingContent: true, isFetchingContent: true })
        const username = getUsername()
        const {
          data: [preference],
        } = await axios.get(
          `/api/core/users/${username}/preferences` +
            objectToSearchParam({
              groupId: name,
              type: userPreferencesTypes.CONTENT,
            }),
        )
        const content = preference?.value
        if (content) {
          content.rows?.forEach(row => row?.forEach(widget => this.loadWidget(widget)))
        } else {
          if (!acceptMissing) addNotification({ message: loc`Cannot find user content ${name}`, level: "warning" })
        }
        this.setState({ editMode, content, preference, name, newName: name, isLoadingContent: false, isFetchingContent: false })
      }
    } else {
      if (name) {
        try {
          this.setState({ isFetchingContent: true })
          let url = `/api/script/runs/${name}`

          if (withQueryParams) {
            let params = {}
            if (page) params = { ...params, page }
            if (typeof search === "string") {
              params = { ...params, search, page: 1 }
            } else if (typeof search === "object") {
              params = { ...params, ...search, page: 1 }
            }
            const queryParams = mergeQueryParams(location.search, params)
            history.replace(`/content/${name}${queryParams}`)

            url += queryParams
          } else {
            url += url.indexOf("?") !== -1 ? location.search && location.search.replace("?", "&") : location.search

            if (page) {
              url += (url.indexOf("?") !== -1 ? "&" : "?") + `page=${page}`
              if (contentSearch) url += (url.indexOf("?") !== -1 ? "&" : "?") + `search=${contentSearch}`
            }

            if (typeof search === "string") {
              url += (url.indexOf("?") !== -1 ? "&" : "?") + `search=${search}`
              this.setState({ contentSearch: search })
            } else if (typeof search === "object") {
              if (url.indexOf("?") !== -1) {
                url += objectToSearchParam(search).replace("?", "&")
              } else url += objectToSearchParam(search)
            }
          }

          content?.props?.debounce ? await this.fetchContentDebounced(url) : await this.fetchContent(url)
        } catch (error) {
          this.setState({ content: null, isFetchingContent: false })
          if (!acceptMissing) addOops(error)
        }
      } else if (this.props.content) {
        this.setState({ content: this.props.content, isLoadingContent: false, isFetchingContent: false })
        if (this.props.content?.pageTitle) setPageTitle(this.props.content.pageTitle)
      }
    }
  }

  async loadWidget(widget) {
    const { content, contentPath } = widget

    if (contentPath || content) {
      if (contentPath) this.setState({ widgetLoading: { ...this.state.widgetLoading, [contentPath]: true } })

      try {
        if (content) widget.content = content
        else {
          widget.content = (await axios.get(contentPath)).data
          if (widget.content?.rows) widget.content.rows.forEach(row => row && row.forEach(w => this.loadWidget(w)))
        }

        if (widget.content && widget.content.type?.toLowerCase() === "chartist") {
          this.fixChartistWidgetLabels(widget)
        } else if (widget.content && widget.content.type?.toLowerCase() === "table") {
          this.applyTableProps(widget)
        }

        if (contentPath) this.setState({ widgetLoading: { ...this.state.widgetLoading, [contentPath]: false } })
      } catch (error) {
        addOops(error)
        widget.content = { type: "error" }

        if (contentPath) this.setState({ widgetLoading: { ...this.state.widgetLoading, [contentPath]: false } })
      }
      this.setState({ content: { ...this.state.content } })
    }
  }

  async fixChartistWidgetLabels(widget) {
    const props = widget && widget.content && widget.content.props
    if (props && props.list && (props.legendLabels || (props.data && props.data.labels))) {
      const list = getList(props.list, () => this.fixChartistWidgetLabels(widget))
      if (props.data && props.data.labels) props.data.labels = props.data.labels.map(it => list.labels[it] || it)
      if (props.legendLabels) props.legendLabels = props.legendLabels.map(it => list.labels[it] || it)
    }
    const content = { ...this.state.content }
    this.setState({ content })
  }

  // TODO: this code must be centralized in Table component, not here
  formatTableRow = (columns, row) => {
    columns = columns.filter(col => col?.name && (col?.type || col?.formInputProps || col?.linkTo))

    for (const col of columns) {
      let { name, type, formInputProps = {} } = col
      let value = row[name]

      if (!type) {
        if (value && typeof value === "object") value = value.toString()
        type = "text"
      }
      type = type.trim().toLowerCase()

      if (type === "string") type = "text"
      if (type === "boolean") type = "checkbox"
      if (type === "amount") type = "currency"

      if (type === "bytes") row[name] = formatBytes(value)
      else if (["text", "string", "object"].includes(type) && !formInputProps.select) row[name] = value
      else row[name] = <FormInput readOnly inArray type={type} value={value} {...formInputProps} />
    }
  }

  applyTableProps = widget => {
    const props = widget && widget.content && widget.content.props

    if (props && Array.isArray(props.columns)) {
      if (Array.isArray(props.data)) {
        // Level 1
        for (const row of props.data) {
          this.formatTableRow(props.columns, row)

          if (row.children?.columns && row.children?.data) {
            // Level 2
            const data = []
            for (const subRow of row.children?.data) {
              this.formatTableRow(row.children?.columns, subRow)

              if (subRow.children?.columns && subRow.children?.data) {
                // Level 3
                const subData = []
                for (const subRow2 of subRow.children?.data) {
                  this.formatTableRow(subRow.children?.columns, subRow2)
                  subData.push(subRow2)
                }

                subRow.children = (
                  <TableExtended
                    data={subData}
                    pageInUrl={false}
                    filter={subRow.children?.filter}
                    actions={subRow.children?.actions}
                    columns={subRow.children?.columns}
                    pageSize={subRow.children?.pageSize}
                    rowsCount={subRow.children?.rowsCount}
                    showRowsCount={subRow.children?.showRowsCount}
                  />
                )
              }

              data.push(subRow)
            }
            row.children = (
              <TableExtended
                data={data}
                pageInUrl={false}
                filter={row.children?.filter}
                columns={row.children.columns}
                actions={row.children?.actions}
                pageSize={row.children?.pageSize}
                rowsCount={row.children?.rowsCount}
                showRowsCount={row.children?.showRowsCount}
              />
            )
          }
        }
      }
    }
  }

  findTagAttributeRecursively(target, attributeName) {
    // stop looking up the DOM hierarchy when the top-most container of the HTML widget is reached
    if (target?.getAttribute("class")?.indexOf("card-html") > -1) return
    const attributeValue = target?.getAttribute(attributeName)
    return attributeValue ? { target, attributeValue } : this.findTagAttributeRecursively(target?.parentNode, attributeName)
  }

  handleKeyboardShortcuts = event => {
    if (!this.state.editMode) return

    const { keyCode, metaKey, ctrlKey } = event
    const isMac = window.navigator.platform.match("Mac")
    const CTRL = isMac ? metaKey : ctrlKey

    const S = keyCode === 83

    // CTRL + S => Save script
    if (CTRL && S) this.handleSave()
  }

  async handleHtmlClick(event, props) {
    if (!event.target) return

    const { entity, onSetState } = this.props

    if (props.onClickHandlers) {
      const { attributeValue: onClickHandlerName, target } = this.findTagAttributeRecursively(event.target, "onclickhandler") || {}
      if (!onClickHandlerName) return
      const onClickHandler = props.onClickHandlers[onClickHandlerName]
      if (typeof onClickHandler === "function") {
        const onClickPatch = await onClickHandler({ pageState: cloneDeep(entity), event, target })
        if (onClickPatch) onSetState(onClickPatch)
      }
    } else {
      const { attributeValue: href } = this.findTagAttributeRecursively(event.target, "href") || {}
      if (href?.startsWith("/")) {
        const { history } = this.props
        event.preventDefault()
        history.push(href)
      }
    }
  }

  renderLoader = () => {
    if (!getUserConfiguration()) return null
    const defaultKpiNumber = getOptions("ContentComponent.defaultKpiNumber") ?? 4
    if (defaultKpiNumber === 0) return null

    const widgets = []
    const sm = 12 / defaultKpiNumber
    for (let i = 0; i < defaultKpiNumber; i++) {
      widgets.push(
        <Col xs={6} sm={sm} key={i}>
          <div className="card skeleton-placeholder-card"></div>
        </Col>,
      )
    }

    return <Row>{widgets}</Row>
  }

  // beware: this function is called from both row and cell
  handleDrop = e => {
    // console.log("hhh", "DROP", dnd)
    e.stopPropagation() // avoid double onDrop()
    const {
      content: { rows },
      toolboxWidgets,
    } = this.state
    const newRows = [...rows]
    if (dnd?.context === "RESIZE") {
      const { left, width } = dnd
      const relativeMouseX = e.clientX - left
      const ratio = (relativeMouseX - 5) / width
      newRows[dnd.sourceRow] = [...newRows[dnd.sourceRow]]
      newRows[dnd.sourceRow][dnd.sourceCol] = { ...newRows[dnd.sourceRow][dnd.sourceCol] }
      newRows[dnd.sourceRow][dnd.sourceCol].colProps = { ...newRows[dnd.sourceRow][dnd.sourceCol].colProps }

      // for now we assume screen size "lg"
      const { xs, sm, md, lg = md || sm || xs } = newRows[dnd.sourceRow][dnd.sourceCol].colProps
      // console.log("hhh", xs, lg)
      // if (lg === 3) {
      // if (ratio < 0.0333) newLg = 1
      // else if (ratio < 0.0666) newLg = 2
      // else if (ratio < 1) newLg = 3
      // else if (ratio < 1.3333) newLg = 4
      // else if (ratio < 1.6666) newLg = 5
      let newLg = 1 + Math.floor(ratio * lg)
      // console.log("hhh", window.innerHeight, ratio, lg, newLg)
      if (newLg < 1) newLg = 1
      if (newLg > 12) newLg = 12
      newRows[dnd.sourceRow][dnd.sourceCol].colProps.lg = newLg
    } else if (dnd?.context === "ADD") {
      if (newRows[dnd.targetRow]) newRows[dnd.targetRow] = [...newRows[dnd.targetRow]]
      else newRows[dnd.targetRow] = []
      if (dnd.name === "NEWLINE") {
        // console.log("hhh", newRows)
        newRows.splice(dnd.targetRow, 0, [
          {
            content: {
              type: contentTypes.KPI,
              props: { text: "Drop here" },
            },
            colProps: { lg: 3 },
          },
        ])
      } else if (dnd.name === "DELETE") {
        if (dnd.targetCol >= 0) {
          newRows[dnd.targetRow].splice(dnd.targetCol, 1)
        } else {
          newRows.splice(dnd.targetRow, 1)
        }
      } else {
        const widget = { ...toolboxWidgets.find(widget => widget.name === dnd.name) }
        widget.colProps ||= { lg: 3 }
        this.loadWidget(widget)
        if (dnd.targetCol >= 0) {
          // replace cell
          newRows[dnd.targetRow][dnd.targetCol] = widget
        } else {
          // add cell
          newRows[dnd.targetRow].push(widget)
        }
      }
    } else if (dnd?.context === "COL") {
      if (dnd.name === "DELETE") {
        newRows[dnd.sourceRow] = [...newRows[dnd.sourceRow]]
        newRows[dnd.sourceRow].splice(dnd.sourceCol, 1)
        if (newRows[dnd.sourceRow].length === 0) newRows.splice(dnd.sourceRow, 1)
      } else if (dnd.targetCol >= 0) {
        // swap cells, but keep colProps
        newRows[dnd.targetRow] = [...newRows[dnd.targetRow]]
        newRows[dnd.sourceRow] = [...newRows[dnd.sourceRow]]
        // console.log("hhh", dnd.targetRow, dnd.targetCol)
        const x = { ...newRows[dnd.sourceRow][dnd.sourceCol] }
        if (newRows[dnd.targetRow][dnd.targetCol]?.colProps) x.colProps = newRows[dnd.targetRow][dnd.targetCol].colProps
        if (newRows[dnd.targetRow][dnd.targetCol])
          newRows[dnd.sourceRow][dnd.sourceCol] = {
            ...newRows[dnd.targetRow][dnd.targetCol],
            colProps: newRows[dnd.sourceRow][dnd.sourceCol].colProps,
          }
        else if (newRows[dnd.sourceRow][dnd.sourceCol]) newRows[dnd.sourceRow].splice(dnd.sourceCol, 1)
        newRows[dnd.targetRow][dnd.targetCol] = x
      } else {
        // console.log("hhh", dnd, newRows[dnd.sourceRow])
        // drop widget at the right of a line => move it there
        newRows[dnd.sourceRow] = [...newRows[dnd.sourceRow]]
        const widget = newRows[dnd.sourceRow][dnd.sourceCol]
        newRows[dnd.sourceRow].splice(dnd.sourceCol, 1)
        if (newRows[dnd.targetRow]) newRows[dnd.targetRow] = [...newRows[dnd.targetRow]]
        else newRows[dnd.targetRow] = []
        newRows[dnd.targetRow].push(widget)
      }
    } else if (dnd?.context === "ROW" && dnd.sourceRow >= 0) {
      if (dnd.name === "DELETE") {
        newRows.splice(dnd.sourceRow, 1)
      } else if (dnd.targetCol >= 0) {
        newRows.splice(dnd.sourceRow, 1)
        newRows.splice(dnd.targetRow - (dnd.targetRow > dnd.sourceRow ? 1 : 0), 0, rows[dnd.sourceRow])
      }
    }

    // remove empty rows
    const filteredRows = newRows.filter(it => it.length > 0)

    const content = { ...this.state.content, rows: filteredRows }
    const undoStack = [...(this.state.undoStack || []), rows]
    this.setState({ content, isChanged: true, undoStack, redoStack: [] })
    dnd = null

    this.resize()
  }

  handleSetWidgetContentPropsState = (rowIndex, colIndex, patch, callback) => {
    const {
      content: { rows },
    } = this.state
    const newRows = [...rows]
    newRows[rowIndex] = [...newRows[rowIndex]]
    newRows[rowIndex][colIndex] = { ...newRows[rowIndex][colIndex] }
    newRows[rowIndex][colIndex].content = { ...newRows[rowIndex][colIndex].content }
    // console.log("hhh-handleSetWidgetContentPropsState", patch)
    newRows[rowIndex][colIndex].content.props = { ...newRows[rowIndex][colIndex].content.props, ...patch }
    const content = { ...this.state.content, rows: newRows }
    const undoStack = [...(this.state.undoStack || []), rows]
    this.setState({ content, isChanged: true, undoStack, redoStack: [] }, callback)
  }

  renderRow = (row, rowIndex) => {
    const { widgetLoading, editMode } = this.state
    // const editMode = debug

    return (
      <Row
        key={rowIndex}
        draggable={editMode}
        style={{
          cursor: editMode ? (dnd?.sourceCol >= 0 ? "grabbing" : "grab") : undefined,
          // background: "lightblue",
        }}
        onDragStart={() => {
          dnd = { context: "ROW", sourceRow: rowIndex }
        }}
        onDragOver={e => {
          if (dnd?.context === "RESIZE" && dnd.sourceRow === dnd.targetRow) {
            // this happens when resize and the cursor is after last widget in the row
            e.preventDefault()
          }
          if (dnd?.context === "ADD") {
            e.preventDefault()
          }
          if (dnd?.context === "ROW" && dnd.sourceRow !== dnd.targetRow - 1 && dnd.sourceRow !== dnd.targetRow) {
            e.preventDefault()
          }
          if (dnd?.context === "COL" && dnd.sourceRow !== dnd.targetRow) {
            e.preventDefault()
          }
        }}
        onDragEnter={e => {
          if (dnd?.context) {
            dnd.targetRow = rowIndex
            dnd.targetCol = undefined
            e.stopPropagation()
            // console.log("hhh", "ENTER ROW", dnd.targetRow, dnd.targetCol)
          }
        }}
        onDrop={this.handleDrop}
      >
        {Array.isArray(row) &&
          row.map((widget, colIndex) => {
            return widgetLoading[widget.contentPath] ? (
              <Col key={colIndex} className={widget.colClassName} {...(widget.colProps || {})}>
                <StatsCard />
              </Col>
            ) : (
              this.renderCol(widget, rowIndex, colIndex, colIndex === row.length - 1)
            )
          })}
      </Row>
    )
  }

  renderCol = (widget, rowIndex, colIndex) => {
    const { editMode } = this.state
    // const editMode = debug

    if (Array.isArray(widget?.content?.rows)) {
      return (
        <Col key={colIndex} className={widget.colClassName} {...(widget.colProps || {})}>
          {widget?.content?.rows.map((row, rowIndex) => this.renderRow(row, rowIndex))}
        </Col>
      )
    }

    let widgetComponent = this.renderWidget(widget, rowIndex, colIndex)

    if (editMode) {
      widgetComponent = (
        <div
          draggable={editMode}
          style={{
            cursor: dnd?.sourceCol >= 0 ? (dnd.context === "RESIZE" ? "w-resize" : "grabbing") : "grab",
          }}
          onDragStart={e => {
            dnd = { context: "COL", sourceRow: rowIndex, sourceCol: colIndex }
            e.stopPropagation() // avoid ROW also capturing the drag start
            // console.log("hhh", e.clientX, e.target.offsetParent.getBoundingClientRect().left + e.target.offsetWidth)
            const width = e.target.offsetWidth
            const left = e.target.getBoundingClientRect().left
            const relativeMouseX = e.clientX - left
            // console.log("hhh", e.clientX, left, relativeMouseX, width, width - 10 < relativeMouseX && relativeMouseX < width)
            if (width - 15 < relativeMouseX && relativeMouseX < width + 5) {
              // resize
              dnd.context = "RESIZE"
              dnd.left = left
              dnd.width = width
            }
          }}
          onDragOver={e => {
            // console.log("hhh", "OVER", dnd)
            if (dnd?.context === "RESIZE" && dnd.sourceRow === dnd.targetRow && dnd.sourceCol <= dnd.targetCol) {
              e.preventDefault()
            }
            if (dnd?.context === "ADD") {
              e.preventDefault()
            }
            // cannot drop on self and cannot drop on next
            if (dnd?.context === "COL" && (dnd.sourceRow !== dnd.targetRow || dnd.sourceCol !== dnd.targetCol)) {
              // console.log("hhh", "YES")
              e.preventDefault()
            } else {
              // console.log("hhh", "NO", dnd)
            }
          }}
          onDragEnter={e => {
            if (dnd?.context) {
              dnd.targetRow = rowIndex
              dnd.targetCol = colIndex
              e.stopPropagation()
              // console.log("hhh", "ENTER CELL", dnd.targetRow)
            }
          }}
          onDrop={this.handleDrop}
        >
          {widgetComponent}
        </div>
      )
    }

    return widgetComponent ? (
      <Col
        key={colIndex}
        {...(widget.colProps || { xs: 12 })}
        className={applyClasses({
          [widget.colClassName || ""]: true,
          "content-component": true,
        })}
      >
        <WidgetErrorBoundary>
          <Suspense fallback={null}>{widgetComponent}</Suspense>
        </WidgetErrorBoundary>
      </Col>
    ) : null
  }

  renderWidget = (widget, rowIndex, colIndex) => {
    const { editMode, isMarkdown } = this.state
    // const editMode = debug

    if (Object.keys(widget?.content || {}).length === 0 || widget.content?.props?.hidden) return null

    const { title, history, location, onSetState, entity, noCard, execComputations, handlers, serverRoute } = this.props
    const props = { ...(widget.content?.props || {}), history, location, noCard, execComputations, handlers, serverRoute }

    if (editMode) delete props.linkTo

    const { titleLinkTo, withQueryParams, actions = [] } = props

    let { collapse } = this.props
    if (collapse === undefined && typeof props.collapse === "boolean") collapse = props.collapse

    // the toLowerCase should be removed one day and force the type to begin with an uppercase for easier search in the code
    const widgetContentType = widget.content?.type?.toLowerCase()
    let widgetComponent

    if (widgetContentType === contentTypes.OPENAPI) {
      widgetComponent = (
        <Card>
          <OpenapiComponent {...props} collapse={collapse} />
        </Card>
      )
    }

    if (widgetContentType === contentTypes.ECHART) {
      const { title, noCard, option, style, linkTo, history, table, list, metric, isLoadingChart } = props
      widgetComponent = (
        <EChartCard
          title={title}
          style={style}
          noCard={noCard}
          option={option}
          linkTo={linkTo}
          history={history}
          table={table}
          list={list}
          collapse={collapse}
          debugInfo={{ card: contentTypes.ECHART, props: widget.content?.props }}
          metric={metric}
          isLoadingChart={isLoadingChart}
        />
      )
    }
    if (widgetContentType === contentTypes.CHARTIST) widgetComponent = <ChartistCard {...props} collapse={collapse} />
    if (widgetContentType === contentTypes.PIVOT)
      widgetComponent = (
        <PivotContent
          {...props}
          handleSetState={(patch, callback) => this.handleSetWidgetContentPropsState(rowIndex, colIndex, patch, callback)}
          collapse={collapse}
        />
      )
    if (widgetContentType === contentTypes.AI) {
      widgetComponent = (
        <AiContent
          {...props}
          handleSetState={(patch, callback) => this.handleSetWidgetContentPropsState(rowIndex, colIndex, patch, callback)}
          collapse={collapse}
        />
      )
    }
    if (widgetContentType === contentTypes.TASKS_LIST) widgetComponent = <TasksList {...props} collapse={collapse} />
    if (widgetContentType === contentTypes.MAP) widgetComponent = <MapContent {...props} collapse={collapse} />

    if (widgetContentType === contentTypes.CALCULATOR) {
      widgetComponent = <ContractCalculator title={(widget.content.props || {}).title} collapse={collapse} {...props} />
    }

    if (widgetContentType === contentTypes.CONTRACT_SUMMARY) {
      widgetComponent = <ContractContent title={(widget.content.props || {}).title} collapse={collapse} {...props} />
    }

    if (widgetContentType === contentTypes.KPI) {
      widgetComponent = (
        <StatsCard
          bigIcon={props.iconClassName && <i className={props.iconClassName} />}
          linkTo={props.linkTo}
          statsText={props.text}
          statsValue={props.value}
          statsIcon={props.statsIconClassName && <i className={props.statsIconClassName} />}
          statsIconText={props.statsIconText}
          statsIconLinkTo={props.statsIconLinkTo}
          {...props}
        />
      )
    }

    if (widgetContentType === contentTypes.ERROR) {
      widgetComponent = (
        <StatsCard bigIcon={<i className="icn-bug icn-xl text-danger" />} statsText={props.title || "Error!"} statsValue={props.message} />
      )
    }

    if (widgetContentType === contentTypes.CAROUSEL) {
      widgetComponent = (
        <Carousel>
          {props.items?.map((item, key) => {
            return (
              <Carousel.Item key={key}>
                <img
                  className="d-block w-100"
                  src={item.src}
                  alt={item.alt}
                  style={{
                    maxHeight: props.imagesMaxHeight,
                    minHeight: props.imagesMinHeight,
                  }}
                />
                <Carousel.Caption>
                  <h3 className="carousel-title">{loc(item.title)}</h3>
                  {item.text && <p className="carousel-sub-title">{loc(item.text)}</p>}
                </Carousel.Caption>
              </Carousel.Item>
            )
          })}
        </Carousel>
      )
    }

    if (widgetContentType === contentTypes.HTML) {
      widgetComponent = (
        <Card
          linkTo={props.linkTo}
          noCard={noCard}
          title={loc(props.title)}
          category={props.category}
          content={props.html}
          collapse={collapse}
          isHtmlContent
          isMarkdown={isMarkdown}
          onHtmlClick={e => this.handleHtmlClick(e, props)}
          {...props}
        />
      )
    }

    if (widgetContentType === contentTypes.TABLE) {
      widgetComponent = (
        <TableContent
          title={loc(title || widget.title || widget.content.props?.title)}
          collapse={collapse}
          titleLinkTo={titleLinkTo}
          pageInUrl={withQueryParams}
          searchFromUrl={withQueryParams}
          changePage={props.servicePagination && (page => this.getContent({ page, withQueryParams }))}
          launchSearch={props.serviceSearch && (search => this.getContent({ search, withQueryParams }))}
          onFilterEnter={props.serviceFilter && (search => this.getContent({ search, withQueryParams }))}
          loading={this.state.isLoadingContent}
          entity={entity}
          onSetState={onSetState}
          {...props}
        />
      )
    }

    if (widgetContentType === contentTypes.FORM) {
      widgetComponent = (
        <Card
          noCard={noCard}
          collapse={collapse}
          titleLinkTo={titleLinkTo}
          title={loc((widget.content.props || {}).title)}
          action={actions?.length > 0 && actions.map((action, index) => <CardAction key={index} {...action} />)}
        >
          <FormContent {...props} onSetState={onSetState} obj={entity} />
        </Card>
      )
    }

    if (widgetContentType === contentTypes.LEGEND && props.text && !props.linkTo) {
      widgetComponent = <legend className={`mb-3 bt-0 content-legend ${props.className || ""}`}>{loc(props.text)}</legend>
    }

    if (widgetContentType === contentTypes.LEGEND && props.text && props.linkTo) {
      widgetComponent = (
        <Link to={props.linkTo}>
          <legend className={`mb-3 bt-0 content-legend ${props.className || ""}`}>{loc(props.text)}</legend>
        </Link>
      )
    }

    if (widgetContentType === contentTypes.LINK && props.text) {
      const linkContent = (
        <div className={`link ${props.linkClassName || ""}`}>
          <div className="link-icon">
            <i className={props.iconClassName} />
          </div>
          <div className="link-text">{props.text}</div>
        </div>
      )
      widgetComponent = props.linkTo?.startsWith("http") ? (
        <a href={props.linkTo} target={props.linkTarget}>
          {linkContent}
        </a>
      ) : (
        <Link to={props.linkTo}>{linkContent}</Link>
      )
    }

    return widgetComponent
  }

  openModelModal = () => {
    this.setState({ showModelModal: true })
  }

  resize = () => {
    // we need this to force echart redraw
    setTimeout(() => window.dispatchEvent(new Event("resize")), 150)
  }

  undo = () => {
    const redoStack = [...(this.state.redoStack || [])]
    redoStack.push(this.state.content.rows)
    const undoStack = [...this.state.undoStack]
    const rows = undoStack.pop()
    const content = { ...this.state.content, rows }
    this.setState({ content, undoStack, redoStack })
    this.resize()
  }

  redo = () => {
    const undoStack = [...(this.state.undoStack || [])]
    undoStack.push(this.state.content.rows)
    const redoStack = [...this.state.redoStack]
    const rows = redoStack.pop()
    const content = { ...this.state.content, rows }
    this.setState({ content, undoStack, redoStack })
    this.resize()
  }

  getModelEntity = () => {
    const {
      content: { rows },
    } = this.state
    const contentRows = rows.map(it =>
      it.map(it => {
        const it2 = { ...it }
        if (it2.contentPath) delete it2.content
        return it2
      }),
    )
    return { "Dashboard rows": contentRows, "With content": rows, state: this.state }
  }

  canSave = () => {
    const { isChanged, name, newName, dashboardValues } = this.state
    if (newName !== name && dashboardValues.find(it => it.value === newName)) {
      // name is changed and newName exists => cannot save
      return false
    }
    // console.log("hhh", isChanged, name !== newName)
    const canSave = isChanged || (newName && newName !== "new" && name !== newName)
    return canSave
  }

  handleSave = async () => {
    const rows = this.getModelEntity()["Dashboard rows"]
    const { newName, preference = { type: userPreferencesTypes.CONTENT } } = this.state

    const canSave = this.canSave()
    if (!canSave) return

    preference.groupId = newName
    preference.value = { rows }
    try {
      const username = getUsername()
      const { data: newPreference } = await axios.post(`/api/core/users/${username}/preferences`, preference)
      this.setState({ preference: newPreference, isChanged: false })
      addNotification("Saved")
    } catch (error) {
      addOops(error)
    }
    this.loadDashboards()
    const { history } = this.props
    history.replace("/user-content/" + newName + "?editMode=1")
  }

  handleDelete = async () => {
    const { preference } = this.state
    try {
      const username = getUsername()
      await axios.delete(`/api/core/users/${username}/preferences/${preference._id}`)
      this.setState({ name: undefined, newName: undefined, preference: undefined })
      this.loadDashboards()
      const { history } = this.props
      history.replace("/user-content/new?editMode=1")
      addNotification("Saved")
    } catch (error) {
      addOops(error)
    }
  }

  handleSetNameState = patch => {
    const { name } = patch
    const { history } = this.props
    history.push("/user-content/" + name + "?editMode=1")
    // console.log("hhh-handleSetNameState", { ...this.state })
    this.setState({ name, newName: name, undoStack: null, redoStack: null })
  }

  render() {
    let {
      content,
      isLoadingContent,
      isMarkdown,
      toolboxWidgets,
      undoStack,
      redoStack,
      showModelModal,
      editMode,
      name,
      newName,
      dashboardValues,
      preference,
      showToolbox,
    } = this.state
    const { isMainContent } = this.props
    // const editMode = debug

    if (!content || !content.rows) {
      if (content?.content) content = content.content

      if (isMarkdown) {
        content = { rows: [[{ colProps: { xs: 12 }, content: { type: "html", props: { html: content && sanitize(marked(content)) } } }]] }
      } else {
        content = { rows: [[{ colProps: { xs: 12 }, content }]] }
      }
    }

    let expandedContent = isLoadingContent ? this.renderLoader() : content.rows.map((row, rowIndex) => this.renderRow(row, rowIndex))

    if (editMode) {
      const canSave = this.canSave()
      const canDelete =
        newName !== "new" && newName !== "copy" && preference?.groupId === newName && dashboardValues?.find(it => it.value === newName)
      expandedContent = (
        <Row>
          <Col
            lg={showToolbox ? 10 : 12}
            draggable={true}
            style={{
              cursor: dnd?.sourceCol >= 0 ? "grabbing" : "grab",
              // background: "lightgreen",
            }}
            onDragOver={e => {
              // console.log("hhh", "OVER")
              if (dnd?.context === "ADD" || dnd?.context === "COL") {
                e.preventDefault()
              }
            }}
            onDragEnter={() => {
              if (dnd?.context === "ADD" || dnd?.context === "COL") {
                dnd.targetRow = content.rows.length
                dnd.targetCol = undefined
                // console.log("hhh", "ENTER CONTENT", dnd.targetRow)
              }
            }}
            onDrop={this.handleDrop}
          >
            {expandedContent}
          </Col>
          {showToolbox && (
            <Col lg={2}>
              <Card
                title="Load"
                action={
                  <>
                    <CustomButton
                      bsStyle={redoStack?.length ? "primary" : "default"}
                      bsSize="xs"
                      pullRight
                      simple
                      onClick={redoStack?.length && this.redo}
                    >
                      <i className="icn-undo icn-sm" style={{ webkitTransform: "scaleX(-1)", transform: "scaleX(-1)" }} />
                    </CustomButton>
                    <CustomButton
                      bsStyle={undoStack?.length ? "primary" : "default"}
                      bsSize="xs"
                      pullRight
                      simple
                      onClick={undoStack?.length && this.undo}
                    >
                      <i className="icn-undo icn-sm" />
                    </CustomButton>
                    <CustomButton bsSize="xs" id={entityModelBtnId} pullRight simple onClick={this.openModelModal}>
                      <i className="icn-eye icn-sm" />
                    </CustomButton>
                  </>
                }
              >
                <FormInput inArray obj={this.state} field="name" select={dashboardValues} onSetState={this.handleSetNameState} />
                <PanelInner title="Save" collapse={false}>
                  <FormInput obj={this.state} field="newName" inArray onSetState={patch => this.setState(patch)} />
                  <CustomButton simple bsSize="xs" bsStyle="primary" onClick={this.handleSave} disabled={!canSave}>
                    {newName === name || name === "new" ? loc`Save` : loc`Rename`}
                  </CustomButton>
                  <CustomButton
                    simple
                    bsSize="xs"
                    pullRight
                    bsStyle="danger"
                    onClick={this.handleDelete}
                    disabled={!canDelete}
                  >{loc`Delete`}</CustomButton>
                </PanelInner>
                <PanelInner title={loc`Widgets`}>
                  {toolboxWidgets.map((widget, key) => {
                    const isDelete = widget.name === "DELETE"
                    return (
                      <div
                        key={key}
                        draggable={true}
                        style={{ cursor: editMode ? (dnd?.sourceCol >= 0 ? "grabbing" : "grab") : undefined }}
                        onDragStart={() => {
                          dnd = { context: "ADD", name: widget.name }
                        }}
                        onDragOver={
                          isDelete
                            ? e => {
                                e.preventDefault()
                              }
                            : undefined
                        }
                        onDragEnter={
                          isDelete
                            ? () => {
                                if (dnd?.context) {
                                  dnd.name = widget.name
                                }
                              }
                            : undefined
                        }
                        onDrop={this.handleDrop}
                      >
                        <i className={widget.icon} />
                        {widget.name}
                      </div>
                    )
                  })}
                </PanelInner>
              </Card>
            </Col>
          )}
          {editMode && (
            <div
              className="card-control-btn"
              onClick={() => {
                this.setState({ showToolbox: !showToolbox })
                this.resize()
              }}
            >
              <i className={"icn-chevron-" + (showToolbox ? "right" : "left") + " icn-sm"} />
            </div>
          )}
          {showModelModal && (
            <Suspense fallback={null}>
              <EntityModelModal showEntityFieldsButtons entity={this.getModelEntity()} close={() => this.setState({ showModelModal: false })} />
            </Suspense>
          )}
        </Row>
      )
    }

    return isMainContent ? (
      <Grid fluid data-no-padding={content?.noPadding}>
        {expandedContent}
      </Grid>
    ) : (
      expandedContent
    )
  }
}

export default withRouter(ContentComponent)
