import React from "react"
import AccountingCatalogItemCard from "@/accounting/AccountingCatalogItemCard"

const cardNames = {
  INFORMATION: "Information",
}

function getCardComponent({ card: cardName, entity, handleSetEntityState, props = {} } = {}) {
  if (cardName === cardNames.INFORMATION) {
    return (
      <AccountingCatalogItemCard
        title={props.title}
        collapse={props.collapse}
        readOnly={props.readOnly}
        noCard={props.noCard}
        accountingCatalogItem={entity}
        modelPath={props.modelPath}
        rows={props.rows}
        handleSetAccountingCatalogItemState={handleSetEntityState}
        debugInfo={{ card: cardName, props: { ...props } }}
      />
    )
  }
}

const _cardNames = Object.values(cardNames)

const accountingCatalogItem = {
  // <EntityPage/>
  cardNames: _cardNames,
  getCardComponent,
  defaultCards: ["Workflow", ..._cardNames],

  // <EntitiesPage/>
  columns: [
    { title: "Registration", name: "registration", linkTo: `/accounting-catalog-item/{registration}` },
    { title: "Organization", name: "organization.name", hidden: true },
    { title: "Status", name: "status", select: "accountingCatalogItemStatus", badge: true },
    { title: "Type", name: "type" },
    { title: "Code", name: "code" },
    { title: "Name", name: "name" },
  ],
  searchFields: [
    [
      { field: "registration", colProps: { xs: 12, md: 3 }, regex: true },
      {
        field: "orgaRegistration",
        label: "Organization",
        searchEntityName: "Person",
        query: { type: "O" },
        colProps: { xs: 12, md: 6, lg: 3 },
        regex: true,
      },
      { field: "status", select: "accountingCatalogItemStatus", multiple: true, colProps: { xs: 12, md: 6 } },
    ],
    [
      { field: "type", colProps: { xs: 12, md: 3 }, regex: true },
      { field: "code", colProps: { xs: 12, md: 3 }, regex: true },
      { field: "name", colProps: { xs: 12, md: 6 }, regex: true },
    ],
  ],
}

export default accountingCatalogItem
