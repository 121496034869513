import React from "react"

import { formatPercentage } from "basikon-common-utils"
import { getLocale } from "@/_services/localization"

export default class ProgressBar extends React.Component {
  handleChange = event => {
    const { onChange } = this.props
    if (onChange) onChange(event.target.value / 100)
  }

  render() {
    const { completionRate = 0, editable } = this.props

    const percentString = formatPercentage(completionRate, getLocale())
    const percent = completionRate * 100

    return editable ? (
      <>
        <label className="pull-right">{percentString}</label>
        <input type="range" className="outline-accessible" value={percent} min={0} max={100} onChange={this.handleChange} />
      </>
    ) : (
      <div className="progress-bar">
        <div className="progress-bar-filler" style={{ width: `${percent}%` }}></div>
        <div className="text-white progress-bar-text">{percentString}</div>
      </div>
    )
  }
}
