import React, { Component } from "react"
import StatsCard from "@/_components/StatsCard"

class WidgetErrorBoundary extends Component {
  state = {}

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({ error, info })
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <StatsCard bigIcon={<i className="icn-bug text-danger" />} statsValue="Error!" statsText={this.state.error.message} />
    }
    return this.props.children
  }
}

export default WidgetErrorBoundary
