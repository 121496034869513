import axios from "axios"
import { getFilterFunction } from "basikon-common-utils"
import React from "react"
import { Row } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import Card from "@/_components/Card"
import EntitySearchModal from "@/_components/EntitySearchModal"
import FormInput from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"
import Table from "@/_components/Table"

import PersonModal from "@/person/PersonModal"

import { getLabel, getList, getValues } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { getClientSignerRegistration, getPerson } from "@/_services/personUtils"
import { hasPermission } from "@/_services/userConfiguration"

/**
 * @prop {String|[String]}  nextAvailableRoles     List of the next available roles to be shown in the new record "role" dropdown if the card is not readOnly
 *                                                 Ex: ["CLIENT", "COCLIENT"] OR "CLIENT,COCLIENT"
 */
class AllPersonsComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      users: [],
      personRegistrations: [],
      role: undefined,
      index: undefined,
      loading: false,
      fetched: false,
      expanded: !props.collapse,
      showPersonModal: false,
      showPersonsSearchModal: false,
    }
  }

  componentDidMount() {
    const { expanded } = this.state
    if (expanded) this.getPersonUsernames()

    const roleListName = this.getPersonRoleListName()
    if (roleListName) getList(roleListName, () => this.setState({ roleListLoaded: true }))
  }

  componentDidUpdate() {
    const { loading, fetched, expanded } = this.state

    if (expanded && !fetched && !loading) this.getPersonUsernames()
    else if (this.hasPersonsChanged() && !loading) this.setState({ fetched: false }, this.getPersonUsernames)
  }

  getPersonRoleListName = () => this.props.roles || this.props.roleList || "personRole"

  hasPersonsChanged = () => {
    const { personRegistrations = [] } = this.state
    const { entity = {} } = this.props
    const { persons = [] } = entity || {}

    if (!personRegistrations.length) return

    const newPersonRegistrations = (persons || []).map(p => p?.personRegistration).filter(r => r)
    for (const personRegistration of newPersonRegistrations) if (!personRegistrations.includes(personRegistration)) return true
  }

  getPersonUsernames = async () => {
    const { entity, hideUserLinks } = this.props
    const { persons = [] } = entity || {}

    if (hideUserLinks) return

    const canFetchUsers = hasPermission("read /api/core/users")
    if (!canFetchUsers) return

    this.setState({ loading: true })

    const personRegistrations = Array.from(new Set(persons.map(p => p.personRegistration).filter(r => r)))
    if (!personRegistrations.length) return

    try {
      const users = (await axios.get(`/api/core/users?person=${personRegistrations.join(",")}&projection=-_id,username,personRegistration`)).data
      this.setState({ users })
    } catch (error) {} // eslint-disable-line

    this.setState({ fetched: true, loading: false, personRegistrations })
  }

  handleSetEntityState = (patch, localExecComputations) => {
    // execComputations might be set by the layout script to trigger computation whenever a field is changed
    let { handleSetEntityState, execComputations } = this.props
    execComputations = execComputations || localExecComputations
    handleSetEntityState(patch, execComputations)
  }

  handleSetPersonState = (index, patch) => {
    const { entity = {} } = this.props
    const { persons = [] } = entity

    const copyPersons = persons.map(person => ({ ...person })) // Copy persons to keep prevState

    if (copyPersons[index]) copyPersons[index] = { ...copyPersons[index], ...patch }
    else copyPersons.push(patch)

    this.handleSetEntityState({ persons: copyPersons })
  }

  handleSetRolePersonState = (role, patch) => {
    const { entity = {} } = this.props
    const { persons = [] } = entity

    const personRoleListName = this.getPersonRoleListName()
    const personRoles = getValues(personRoleListName)

    const personRole = personRoles.find(pr => pr.value === role)
    if (!personRole) return addOops(loc("Role $ does not exist in the list $", role, personRoleListName))

    let copyPersons = persons.map(person => ({ ...person })) // Copy persons to keep prevState
    if (personRole.multiple) {
      if (Array.isArray(patch)) {
        copyPersons = copyPersons.filter(person => person.role !== role)
        for (let i = 0; i < patch.length; i++) {
          copyPersons.push({ role, personRegistration: patch[i].value, person: null })
        }
      } else {
        if (Array.isArray(patch[role])) {
          // Add newly added persons
          for (let personRegistration of patch[role]) {
            const person = copyPersons.find(p => p.personRegistration === personRegistration)
            if (!person) copyPersons.push({ role, personRegistration })
          }

          // Remove persons
          for (const [index, copyPerson] of copyPersons.entries()) {
            if (copyPerson.role !== role) continue

            if (!patch[role].includes(copyPerson.personRegistration)) {
              copyPersons.splice(index, 1)
            }
          }
        } else if (!copyPersons.find(person => person.personRegistration === patch[role] && person.role === role)) {
          copyPersons.push({ role, personRegistration: patch[role], person: null })
        }
      }
    } else {
      for (let i = 0; i < copyPersons.length; i++) {
        if (copyPersons[i].role === role) {
          copyPersons.splice(i, 1)
          break
        }
      }
      copyPersons.push({ role, personRegistration: patch[role], person: null })
    }

    this.handleSetEntityState({ persons: copyPersons })
  }

  handleDeletePerson = index => {
    const { entity = {} } = this.props
    const { persons = [] } = entity

    const copyPersons = persons.map(person => ({ ...person })) // Copy persons to keep prevState
    const execComputations = copyPersons[index]?.role === "PARTNER"

    copyPersons.splice(index, 1)
    this.handleSetEntityState({ persons: copyPersons }, execComputations)
  }

  handlePersonsSearchModalClose = async person => {
    if (person) {
      const { entity = {} } = this.props
      const { persons = [] } = entity
      const copyPersons = persons.map(person => ({ ...person })) // Copy persons to keep prevState

      const { index } = this.state
      copyPersons[index] = { ...copyPersons[index], personRegistration: person.registration, person }

      let execComputations
      if (copyPersons[index]?.role === "PARTNER") execComputations = true
      else if (copyPersons[index]?.role === "CLIENT") {
        const signerRegistration = await getClientSignerRegistration(person.registration)
        if (signerRegistration) {
          const signer = await getPerson(signerRegistration)
          const signerIndex = copyPersons.findIndex(p => p.role === "SIGNER")

          const signerPerson = { role: "SIGNER", person: signer, personRegistration: signer.registration }
          if (signerIndex === -1) copyPersons.push(signerPerson)
          else copyPersons[signerIndex] = signerPerson
        }
      }

      this.handleSetEntityState({ persons: copyPersons }, execComputations)
    }
    this.setState({ showPersonsSearchModal: false })
  }

  handlePersonModalClose = person => {
    if (person) {
      const { index } = this.state
      this.handleSetPersonState(index, person)
    }
    this.setState({ showPersonModal: false })
  }

  getNextAvailableRoles = () => {
    const { nextAvailableRoles } = this.props
    const roleListName = this.getPersonRoleListName()

    if (!nextAvailableRoles) return roleListName
    if (Array.isArray(nextAvailableRoles)) {
      return nextAvailableRoles.map(role => ({
        value: role,
        label: getLabel("personRole", role),
      }))
    }

    if (nextAvailableRoles) {
      return nextAvailableRoles.split(",").map(role => ({
        value: role,
        label: getLabel("personRole", role),
      }))
    }
  }

  render() {
    const { index, role, modalPersonIsProspect, showPersonModal, showPersonsSearchModal, users = [], expanded } = this.state
    let {
      debugInfo,
      entity = {},
      formDisplay,
      collapse,
      readOnly,
      showSearchAction = true,
      showEditAction = true,
      showDeleteAction = true,
      title = "All persons",
      customGetEntities,
      customSearch,
      customQuickSearch,
      noCard,
      history,
      isProspect,
      advancedSearch,
      rolesColProps,
    } = this.props
    const { persons = [] } = entity
    if (!showDeleteAction && !showSearchAction) readOnly = true
    if (readOnly === undefined) readOnly = entity.readOnly

    const includes = this.state.filter ? getFilterFunction(this.state.filter, getLocale()) : () => true
    const checkPersonIsIncluded = ({ role, person }) => {
      if (!person) return true
      const roleLabel = getLabel("personRole", role)
      return includes(roleLabel) || includes(role) || includes(person.name) || includes(person.registration)
    }

    const personsWithIndex = persons.map((pe, index) => ({ ...pe, index }))
    const allPersons = readOnly || !showSearchAction ? [...personsWithIndex] : [...personsWithIndex, { isProspect }] // add a slot to be able to add a new person
    const filteredPersons = allPersons.filter(checkPersonIsIncluded)

    const roleListName = this.getPersonRoleListName()
    const roleListValues = getValues(roleListName, () => this.setState({ rolesLoaded: true })) || []
    const rolesObject = {}
    roleListValues.forEach(role => {
      if (role.multiple) {
        rolesObject[role.value] = allPersons.filter(it => it.role === role.value).map(person => person.personRegistration)
      } else {
        const personItem = allPersons.find(it => it.role === role.value)
        rolesObject[role.value] = personItem?.personRegistration
      }
    })

    const content = (
      <>
        {formDisplay && (
          <Row>
            {Object.keys(rolesObject).map(role => {
              const isMultiple = Array.isArray(rolesObject[role])
              // roleColProps can be an object with roles as keys
              // or directly an object with colProps if there is no need to specify them by roles
              const roleColProps = (rolesColProps?.xs ? rolesColProps : rolesColProps?.[role]) || {
                xs: 12,
                md: isMultiple ? 12 : 6,
                lg: isMultiple ? 6 : 3,
              }

              return (
                <FormInputExtended
                  multiple={isMultiple}
                  key={role}
                  colProps={roleColProps}
                  readOnly={readOnly}
                  obj={rolesObject}
                  field={role}
                  label={getLabel(roleListName, role)}
                  searchEntityName={isProspect ? "Prospect" : "Person"}
                  onSetState={patch => this.handleSetRolePersonState(role, patch)}
                />
              )
            })}
          </Row>
        )}

        {!formDisplay && (
          <>
            <Table
              id="all-persons"
              pageInUrl={false}
              columns={[
                { title: "Role", name: "role", className: "col-sm-4" },
                { title: "Person", name: "person", className: "col-sm-5" },
                { title: "Action", name: "action", className: "col-sm-3 text-right" },
              ]}
              data={filteredPersons.map((entityPerson, index) => {
                const { role, personRegistration, person, isProspect } = entityPerson
                const { name, firstName, lastName } = person || {}
                const { username } = users.find(user => user.personRegistration === personRegistration) || {}

                let personDisplay
                if (name) personDisplay = name
                else {
                  if (firstName) personDisplay = firstName
                  if (lastName) {
                    if (personDisplay) personDisplay += ` ${lastName}`
                    else personDisplay = lastName
                  }
                }
                if (personRegistration) {
                  if (personDisplay) personDisplay += ` (${personRegistration})`
                  else personDisplay = personRegistration
                }

                const lcEntityName = isProspect ? "prospect" : "person"

                let roleFieldSelect = roleListName
                if (!readOnly && showSearchAction && index === allPersons.length - 1) roleFieldSelect = this.getNextAvailableRoles()

                return {
                  role: (
                    <FormInput
                      inArray
                      field="role"
                      obj={entityPerson}
                      select={roleFieldSelect}
                      placeholder={loc`Add role` + "..."}
                      readOnly={readOnly || (role && personRegistration)}
                      onSetState={patch => this.handleSetPersonState(entityPerson.index, patch)}
                    />
                  ),
                  person: personRegistration && (
                    <Link to={`/${lcEntityName}/${personRegistration}`}>
                      <FormInput inArray readOnly value={personDisplay} />
                    </Link>
                  ),
                  action: {
                    className: "td-actions text-right",
                    content: (
                      <>
                        {username && personRegistration && (
                          <ButtonWithTooltip
                            tooltip={loc`User`}
                            data-test="open-user-profile-btn"
                            className="icn-user icn-xs"
                            bsStyle="primary"
                            btnClassName="inline-flex-center"
                            onClick={() => history.push(`/user/${username}`)}
                          />
                        )}
                        {!readOnly && role && showSearchAction && showDeleteAction && (
                          <ButtonWithTooltip
                            tooltip={loc`Search ${lcEntityName}`}
                            data-test="search-person-btn"
                            className="icn-search icn-xs"
                            btnClassName="inline-flex-center"
                            onClick={() =>
                              this.setState({ index: entityPerson.index, showPersonsSearchModal: true, role, modalPersonIsProspect: isProspect })
                            }
                          />
                        )}
                        {personRegistration && showEditAction && (
                          <ButtonWithTooltip
                            data-test="edit-person-btn"
                            className="icn-edit icn-xs"
                            btnClassName="inline-flex-center"
                            onClick={() => this.setState({ index: entityPerson.index, showPersonModal: true })}
                          />
                        )}
                        {!readOnly && role && showDeleteAction && (
                          <ButtonWithTooltip
                            tooltip={loc`Delete ${lcEntityName}`}
                            bsStyle="danger"
                            data-test="delete-person-btn"
                            className="icn-xmark icn-xs"
                            btnClassName="inline-flex-center"
                            onClick={() => this.handleDeletePerson(entityPerson.index)}
                          />
                        )}
                      </>
                    ),
                  },
                }
              })}
            />

            {showPersonsSearchModal && (
              <EntitySearchModal
                query={{ role }}
                advancedSearch={advancedSearch}
                onClose={this.handlePersonsSearchModalClose}
                entityName={modalPersonIsProspect ? "Prospect" : "Person"}
                customSearch={customSearch && (query => customSearch(query, { personIndex: index, role }))}
                customGetEntities={customGetEntities && (query => customGetEntities(query, { personIndex: index, role }))}
                customQuickSearch={customQuickSearch && ((search, query) => customQuickSearch(search, query, { personIndex: index, role }))}
              />
            )}

            {showPersonModal && <PersonModal readOnly={readOnly} person={persons[index]} onClose={this.handlePersonModalClose} />}
          </>
        )}
      </>
    )

    return (
      <Card
        className="all-persons-component"
        debugInfo={debugInfo}
        collapse={collapse}
        noCard={noCard}
        title={loc(title)}
        onSetExpanded={() => this.setState({ expanded: !expanded })}
        action={
          !formDisplay && (
            <FormInput
              inArray
              fcClassName="pull-right w-25 max-h-22px"
              placeholder="Filter"
              obj={this.state}
              field="filter"
              onSetState={patch => this.setState(patch)}
            />
          )
        }
      >
        {content}
      </Card>
    )
  }
}

export default withRouter(AllPersonsComponent)
