import axios from "axios"
import { convertDateStringsToDates, toQueryString } from "basikon-common-utils"
import sassVars from "../_styles/sass-vars.module.scss"

import { formatAxiosError } from "@/_services/axios"
import consoleService from "@/_services/console"
import { loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { getAppVersion, localStorageKeys } from "@/_services/utils"
// import { isLocalhost } from "@/_services/serviceWorker"
import { getListsValues } from "@/_services/lists"
import { getIsAdmin, getOptions, getUsername } from "@/_services/userConfiguration"

const cacheName = "hyperfront-sw-cache"

// these include parameters must be kept in sync
// so that searching in the service worker cache the keys match
export const contractsIncludes = "partnerName,salesName,assetsNames"
export const contractIncludes = "assets,persons,fundings,documents,collaterals,financingServices"

const state = {
  offlineStateUpdateFn: null,
  offlineSince: null,
  counter: null,
  history: null,
}

function registerOfflineStateUpdateFn(offlineStateUpdateFn, history) {
  state.offlineStateUpdateFn = offlineStateUpdateFn
  state.history = history
}

function isActivated() {
  return window.location.origin.startsWith("https://") && getOptions("offlineSupport") // || isLocalhost
}

function setOffline(_isOffline, force) {
  if (!force && !isActivated()) return // do nothing
  if (consoleService.showOfflineLog) console.log("setOffline()", _isOffline)
  if (_isOffline) {
    if (state.offlineSince) return // already offline
    state.offlineSince = new Date()
    if (typeof state.offlineStateUpdateFn === "function") state.offlineStateUpdateFn(state.offlineSince)
    addNotification("You are now offline and using cached data", "warning", "tc")
    if (!force) offlinePing()
  } else {
    if (!state.offlineSince) return // already online
    state.offlineSince = null
    if (typeof state.offlineStateUpdateFn === "function") state.offlineStateUpdateFn()
    offlineExecuteActions(true)
  }
}

function isOffline() {
  return state.offlineSince ? true : false
}

async function offlinePing() {
  if (isOffline()) {
    try {
      await axios.get("/api/core/ping")
      setOffline(false)
    } catch {} // eslint-disable-line
    setTimeout(offlinePing, 2000)
  }
}

function getNextRegistration() {
  state.counter = (state.counter || 0) + 1
  let registration = state.counter.toString()
  while (registration.length < 8) registration = "0" + registration
  console.log("---- offlineService: getNextRegistration() returns", "TMP" + registration)
  return "TMP" + registration
}

function offlinePush(action) {
  const offlineActions = readLocalStorage()
  action.username = getUsername()

  switch (action.method) {
    // post is for contractPage, assets, persons
    case "post": {
      // set tmp registration if none
      let registration = action.data.registration || action.data._id
      if (!registration) {
        registration = getNextRegistration()
        action.data.registration = registration
        action.data._id = registration
      }

      action.key = action.url + "/" + registration

      // keep only one action with same key (or _update will be wrong the second time)
      const index = offlineActions.findIndex(it => it.key === action.key)
      if (index >= 0) {
        offlineActions[index] = action
      } else {
        offlineActions.push(action)
      }

      // keep some flag for debug purpose
      //action.data._fromOfflineCache = true

      // sort actions to get contract at the end, so that tmp registration get updated if needed
      // this is required in special case when user saves a contract a first time while offline, then adds a new asset.
      // In that case this new asset would be after the contract that references it
      offlineActions.sort(it => (it.key.startsWith("/api/financing/contracts") ? 1 : -1))

      break
    }
    case "patch": {
      // patch is for tasks
      action.key = action.url
      offlineActions.push(action)

      break
    }
    default: {
      throw Error("offlineService: offlinePush(): invalid method", action.method)
    }
  }

  writeLocalStorage(offlineActions)
  return { ...action.data }
}

async function offlineExecuteActions(messageFromWorker) {
  if (consoleService.showOfflineLog) console.log("offlineService: execute()")
  if (!isActivated()) return // do nothing

  let offlineActions = readLocalStorage()

  // remove any action with wrong username (just in case user switched at some point)
  offlineActions = offlineActions.filter(it => it.username === getUsername())

  const offlineActionsErrors = []
  const nbActions = offlineActions.length
  if (nbActions === 0) {
    if (messageFromWorker) addNotification("You are now online", "success", "tc")
  } else {
    const allConvertedTmpRegistration = []
    while (offlineActions.length > 0) {
      const offlineAction = offlineActions[0]
      console.log("---- offlineExecuteActions execute", offlineAction)

      const tmpRegistration =
        offlineAction.data.registration && offlineAction.data.registration.startsWith("TMP0000") ? offlineAction.data.registration : null
      if (tmpRegistration) {
        delete offlineAction.data.registration
        delete offlineAction.data._id
      }

      let savedData
      try {
        savedData = (await axios(offlineAction)).data
      } catch (error) {
        if (error.response) {
          // server was reached but returned an error (probably out of date, or bug)
          offlineActions[0].error = error
          offlineActionsErrors.push(offlineActions[0])
          console.log("offlineExecuteActions() error", offlineActions[0])
        } else {
          // server could not be reached (probably a network error caused by poor network)
          console.log("offlineExecuteActions() error", error)
          offlineActions[0].trials = (offlineActions[0].trials || 0) + 1
          addOops(error)
          setOffline(true)
          break
        }
      }

      offlineActions.shift()

      // patch following "foreign keys" if some registration has been allocated
      if (tmpRegistration) {
        const savedRegistration = savedData.registration
        allConvertedTmpRegistration.push({ tmpRegistration, savedRegistration })
        for (const { data } of offlineActions) {
          if (data.assets) {
            data.assets.forEach(asset => {
              if (asset.assetRegistration === tmpRegistration) asset.assetRegistration = savedRegistration
              if (asset.asset && asset.asset.registration === tmpRegistration) asset.asset.registration = savedRegistration
            })
          }
          if (data.persons) {
            data.persons.forEach(person => {
              if (person.personRegistration === tmpRegistration) person.personRegistration = savedRegistration
              if (person.person && person.person.registration === tmpRegistration) person.person.registration = savedRegistration
            })
          }
        }
      }

      // reload key to update workbox cache with newly saved data
      let key = offlineAction.key
      if (tmpRegistration) {
        key = key.substring(0, offlineAction.key.lastIndexOf("/")) + "/" + savedData.registration
      }
      await axios.get(key)
    }

    // notify user
    if (offlineActionsErrors.length === 0) {
      const action = { label: "Got it", callback: () => setTimeout(window.location.reload()) }
      addNotification("You are now online and server has been updated with your changes", "success", "tc", action)
    } else {
      let message = loc`Error occured while synchronizing your data to the server`
      const errorMessages = offlineActionsErrors.map(actionError => {
        const entityType = actionError.key.startsWith("/api/financing/contracts")
          ? loc`Contract`
          : actionError.key.startsWith("/api/asset/assets")
          ? loc`Asset`
          : actionError.key.startsWith("/api/person/persons")
          ? loc`Person`
          : actionError.key.startsWith("/api/core/tasks")
          ? loc`Task`
          : loc`???`
        return entityType + " " + actionError.data.registration + " (" + formatAxiosError(actionError.error) + ")"
      })
      message += errorMessages.map(it => "\n" + it)
      const action = { label: "Got it", callback: () => setTimeout(window.location.reload()) }
      addNotification(message, "error", "tc", action)

      // save bad records in another key
      let errorStorage = JSON.parse(localStorage.getItem(localStorageKeys.OFFLINE_MODE.STORAGE_ERRORS)) || []
      while (errorStorage.length > 5) errorStorage.splice(errorStorage.length - 1, 1)
      errorStorage = [offlineActions].concat(errorStorage)
      localStorage.setItem(localStorageKeys.OFFLINE_MODE.STORAGE_ERRORS, JSON.stringify(errorStorage))
      offlineActions = []
    }
    writeLocalStorage(offlineActions)

    const { history } = state
    console.log("---- offlineService current history is", history?.location.pathname)
    for (const it of allConvertedTmpRegistration) {
      const { tmpRegistration, savedRegistration } = it
      if (history?.location.pathname.endsWith("/" + tmpRegistration)) {
        const newHistory = history?.location.pathname.replace(tmpRegistration, savedRegistration)
        console.log("---- offlineService change history to", newHistory)
        history.replace(newHistory)
        break
      }
    }
  }
  preload()
}

async function preload() {
  if (isOffline()) return
  if (!isActivated()) return // do nothing
  console.log("offlineService: preload()")

  const currentAppVersion = getAppVersion()
  const prevAppVersion = localStorage.getItem(localStorageKeys.OFFLINE_MODE.STORAGE_VERSION)
  let force = false
  if (currentAppVersion !== prevAppVersion) {
    force = true
    localStorage.setItem(localStorageKeys.OFFLINE_MODE.STORAGE_VERSION, currentAppVersion)
  }

  const workboxCache = await window.caches.open(cacheName)

  // this makes sure these lists are available when offline
  getListsValues(
    [
      "taskType",
      "assetType",
      "contractStatus",
      "contractType",
      "contractTag",
      "companyRegistrationType",
      "individualRegistrationType",
      "financialProduct",
      "financialScheme",
      "country",
      "personType",
      "personRole",
      "personTitle",
      "personStatus",
      "personRelation",
      "personTag",
      "dealDocumentType",
      "dealElementStatus",
      "contractDocumentType",
      "contractElementStatus",
      "purpose",
      "subPurpose",
      "addressType",
      "frequency",
      "activityCode",
    ].join(","),
    { storeForOfflineUse: true },
  )

  // load URIs that don't update often only if not yet loaded
  for (const uri of [
    "/api/core/indexes",
    "/api/core/taxes",
    "/api/script/scripts/person-layout",
    "/api/script/scripts/contract-layout",
    "/api/script/scripts/contract-computation",
    "/api/script/scripts/contract-computation-MIC", // specific Finadev
    "/api/script/scripts/personRegistration-format-check",
    "/api/script/runs/products-catalog",
    "/api/financing/agreements/products-catalog",
    "/api/script/runs/calendars",
  ]) {
    if (!(await workboxCache.match(uri))) axios.get(uri)
  }

  axios.get("/api/person/persons")
  axios.get("/api/core/tasks")

  // preload 10 first contracts and their data, if they have changed
  // the include param must be kept in sync with DealsOrContractsPage
  const contracts = (await axios.get(`/api/financing/contracts${toQueryString({ include: contractsIncludes })}`)).data
  let count = 0
  const { preloadContractsCount = 10, preloadIcons } = getOptions("offlineSupport")
  if (Array.isArray(preloadIcons) && preloadIcons?.length) {
    const icns = sassVars.icns.split(",")
    for (let i = 0; i < icns?.length; i++) {
      const icn = icns[i].trim()
      if (preloadIcons.includes(icn)) {
        axios.get(`/icons/${icn}.svg`)
      }
    }
  }

  for (const contract of contracts) {
    if (count++ >= preloadContractsCount) break
    const contractKey = `/api/financing/contracts/${contract.registration}`
    const contractsUrl = `/api/financing/contracts/${contract.registration}${toQueryString({ include: contractIncludes })}`
    const cachedResponse = await workboxCache.match(contractsUrl)
    const cachedContract = cachedResponse && (await cachedResponse.json())
    const isoDate = contract._updateDate && contract._updateDate.toISOString()
    if (!force && cachedContract && cachedContract._updateDate === isoDate) {
      console.log("offlineService: preload() contract is up to date", contract.registration)
    } else {
      console.log("offlineService: preload() contract needs preloading", contract.registration, cachedContract && cachedContract._updateDate, isoDate)
      axios.get(contractsUrl)
      axios.get(contractKey + "/transitions?type=" + contract.type)
      axios.get(contractKey + "/documents")
      for (const { personRegistration, role } of contract.persons) {
        if (personRegistration) {
          axios.get("/api/person/persons/" + personRegistration)
          // axios.get("/api/person/persons/" + personRegistration + "/ascendants")
          // axios.get("/api/person/persons/" + personRegistration + "/hierarchy")
          axios.get("/api/person/persons/" + personRegistration + "/transitions?type=I")
          if (getIsAdmin()) axios.get("/api/core/users?person=" + personRegistration)
          if (role === "PARTNER") {
            axios.get("/api/financing/agreements/current-for-person/" + personRegistration)
          }
        }
      }
      for (const { assetRegistration } of contract.assets) {
        if (assetRegistration) axios.get("/api/asset/assets/" + assetRegistration)
      }
    }
  }
}

function readLocalStorage() {
  return JSON.parse(localStorage.getItem(localStorageKeys.OFFLINE_MODE.STORAGE_NAME)) || []
}

function writeLocalStorage(actions) {
  return localStorage.setItem(localStorageKeys.OFFLINE_MODE.STORAGE_NAME, JSON.stringify(actions))
}

// this API is to store in a secondary cache (e.g. on top of not the workbox cache)
// only entities that can be changed while offline should be saved through this API
async function axiosOrPush(offlineAction) {
  if (isOffline()) {
    return offlinePush(offlineAction)
  } else {
    try {
      return (await axios(offlineAction)).data
    } catch (error) {
      if (isActivated() && !error.response) {
        console.log("==== assume offline", offlineAction)
        setOffline(true)
        return offlinePush(offlineAction)
      } else {
        throw error
      }
    }
  }
}

// this API is not for "general" caching, general caching on GETs is done by workbox
// this API should be used only in coordination with axiosorPush(), only for entities that can be modified when offline
// (e.g. contract, contract persons, assets)
async function axiosGetData(url) {
  if (isOffline()) {
    const offlineActions = readLocalStorage()

    const contractsApiPath = "/api/financing/contracts"

    // we want exact matches here to avoid paths like /api/financing/contracts/:contractId/documents
    if (url === contractsApiPath || url.startsWith(`${contractsApiPath}?`)) {
      // prepend any TMP contract
      const workboxCache = await window.caches.open(cacheName)
      const cachedResponse = await workboxCache.match(url)
      if (cachedResponse) {
        let cachedContracts = await cachedResponse.json()
        for (const action of offlineActions) {
          if (action.key.startsWith(`${contractsApiPath}/TMP0000`)) {
            convertDateStringsToDates(action.data)
            cachedContracts = [action.data, ...cachedContracts]
          }
        }
        return cachedContracts
      }
    } else {
      // return last cached if any
      for (let i = offlineActions.length - 1; i >= 0; i--) {
        if (url.startsWith(offlineActions[i].key) && !offlineActions[i].trials) {
          convertDateStringsToDates(offlineActions[i].data)
          return offlineActions[i].data
        }
      }
    }
  }

  return (await axios.get(url)).data
}

export { axiosGetData, axiosOrPush, isOffline, offlineExecuteActions, readLocalStorage, registerOfflineStateUpdateFn, setOffline }
