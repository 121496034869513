import axios from "axios"
import { checkErrors, dateNoTime, getEntityClientRoute, getEntityName } from "basikon-common-utils"
import React from "react"
import { Col, Modal } from "react-bootstrap"

import ButtonWithErrors from "@/_components/ButtonWithErrors"
import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"
import ProgressBar from "@/_components/ProgressBar"
import { savePositionForTask } from "@/_components/TasksComponent"

import { loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { isOffline } from "@/_services/offlineService"
import { getOrgaRegistration, getUsername } from "@/_services/userConfiguration"
import { generateRows } from "@/_services/utils"

export const defaultModalRows = [
  [
    "type",
    "entityRegistration",
    "startDate",
    "endDate",
    "creationDate",
    "dueDate",
    "withIcs",
    "location",
    "project",
    "ownerUsername",
    "profile",
    "subject",
    "description",
    "completionRate",
    "sendNotificationFlag",
  ],
]

class TaskModal extends React.Component {
  constructor(props) {
    super(props)

    const { entityRegistration, entityType, project } = this.props

    this.state = {
      task: {
        entityRegistration,
        entityType,
        project,
        ownerUsername: getUsername(),
        orgaRegistration: getOrgaRegistration(),
        creationDate: dateNoTime(new Date()),
        type: "TASK",
      },
      sendNotificationFlag: true,
    }
  }

  componentDidMount() {
    const { id, newTaskDateRange } = this.props

    if (id) this.getTask(id)
    else if (newTaskDateRange) this.handleSetDateTimeState(newTaskDateRange)
  }

  getTask = async id => {
    try {
      const task = (await axios.get("/api/core/tasks/" + id)).data
      this.setState({ task })
    } catch (error) {
      addOops(error)
    }
  }

  handleClose = async isSave => {
    let { sendNotificationFlag, task } = this.state
    const { onClose } = this.props

    if (isSave) {
      if (checkErrors(task, this.handleSetTaskState)) return

      this.setState({ loading: true })

      try {
        task = (await axios.post(`/api/core/tasks?notification=${sendNotificationFlag}`, task)).data
      } catch (error) {
        addOops(error)
      }

      this.setState({ loading: false })
    }
    onClose(isSave && task)
  }

  handleSetDateTimeState = patch => {
    if (patch.startDate && !("endDate" in patch)) {
      const { task } = this.state
      const { startDate, endDate } = task
      if (startDate && endDate) {
        const delta = patch.startDate.getTime() - (startDate._d?.getTime() || startDate.getTime())
        patch.endDate = new Date((task.endDate._d?.getTime() || task.endDate.getTime()) + delta)
      } else if (!endDate) {
        patch.endDate = new Date(patch.startDate.getTime() + 30 * 60 * 1000)
      }
    }
    this.handleSetTaskState(patch)
  }

  handleSetTaskState = patch => {
    if (!patch) return

    if (patch.type === "APPOINTMENT" && !patch.startDate) {
      const { newTaskDateRange } = this.props
      if (newTaskDateRange) {
        patch.startDate = newTaskDateRange.startDate
        patch.endDate = newTaskDateRange.endDate
      } else {
        const d = new Date()
        patch.startDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours() + 1, 0)
        patch.endDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours() + 1, 30)
      }
    }
    const task = { ...this.state.task, ...patch }
    this.setState({ task })
  }

  handleSetTaskProjectState = patch => {
    if (patch) {
      const { task } = this.state

      if (patch.project) {
        const [entityType, entityRegistration] = patch.project.split("|")
        patch.entityType = entityType
        patch.entityRegistration = entityRegistration
        patch.project = entityRegistration
      }

      this.setState({ task: { ...task, ...patch } })
    }
  }

  getOwnerOptions = async value => {
    if (!value?.trim()) return []
    const users = (await axios.get("/api/core/usernames?username=" + value)).data
    return users.map(it => {
      let label = it.username
      if (it.firstName || it.lastName || it.email) {
        label += " ("
        let name = [it.firstName, it.lastName].filter(it => it).join(" ")
        if (it.email) name += name ? " <" + it.email + ">" : it.email
        label += name
        label += ")"
      }
      return { value: it.username, label }
    })
  }

  getProjectOptions = async value => {
    const globalResults = value ? (await axios.get("/api/core/global-search?search=" + value)).data : []
    return globalResults.map(it => ({
      value: it.type + "|" + it.registration,
      label: loc(it.descr, ...it.values),
    }))
  }

  handleSendNotificationFlagSetState = patch => {
    if (patch) {
      this.setState({ sendNotificationFlag: Object.values(patch)[0] })
    }
  }

  getField = col => {
    const { name, colProps, formInputProps, hidden } = col
    if (hidden) return

    const { task = {} } = this.state
    let { readOnly } = this.props
    const { type } = task
    const isAppointment = type === "APPOINTMENT"
    if (isOffline()) readOnly = true

    const props = {
      key: name,
      field: name,
      readOnly,
      obj: task,
      colProps: colProps || { xs: 12 },
      onSetState: this.handleSetTaskState,
      ...formInputProps,
    }

    if (name === "type") return <FormInput button select="taskType" {...props} />
    else if (name === "entityRegistration")
      return (
        <>
          {task.entityType && task.entityRegistration && (
            <FormInput
              {...props}
              readOnly={true}
              label={getEntityName({ clientRoute: `/${task.entityType.toLowerCase()}` })}
              value={task.entityRegistration}
              linkTo={`${getEntityClientRoute(task.entityType)}/${task.entityRegistration}`}
            />
          )}
        </>
      )
    else if (name === "startDate")
      return (
        <FormInput type="date" label="Start" timeFormat={true} {...props} colProps={colProps || { xs: 6 }} onSetState={this.handleSetDateTimeState} />
      )
    else if (name === "endDate")
      return (
        <FormInput label="End" type="date" timeFormat={true} {...props} colProps={colProps || { xs: 6 }} onSetState={this.handleSetDateTimeState} />
      )
    else if (name === "creationDate")
      return <FormInput type="date" {...props} colProps={colProps || { xs: 6 }} onSetState={this.handleSetTaskState} />
    else if (name === "dueDate")
      return <>{!isAppointment && <FormInput type="date" {...props} colProps={colProps || { xs: 6 }} onSetState={this.handleSetTaskState} />}</>
    else if (name === "withIcs")
      return <>{isAppointment && <FormInput type="checkbox" label="Send email invite" {...props} colProps={colProps || { xs: 6 }} />}</>
    else if (name === "location") return <>{isAppointment && <FormInput {...props} onSetState={this.handleSetDateTimeState} />}</>
    else if (name === "project") return <FormInput mandatory select={this.getProjectOptions} {...props} onSetState={this.handleSetTaskProjectState} />
    else if (name === "ownerUsername")
      return <FormInput label="Owner" select={this.getOwnerOptions} {...props} colProps={colProps || { xs: 12, sm: 6 }} />
    else if (name === "profile") return <FormInput select="userProfile" {...props} colProps={colProps || { xs: 12, sm: 6 }} />
    else if (name === "subject") return <FormInput mandatory {...props} />
    else if (name === "description") return <FormInput type="textarea" {...props} />
    else if (name === "completionRate")
      return (
        <Col key={name} {...(colProps || { xs: 12 })} className="mb-theme">
          <ProgressBar
            editable={!readOnly}
            completionRate={task.completionRate}
            onChange={completionRate => this.handleSetTaskState({ completionRate })}
          />
        </Col>
      )
    else if (name === "sendNotificationFlag")
      return (
        <FormInput
          type="checkbox"
          {...props}
          obj={this.state}
          label={loc`Send notification`}
          showLabel={false}
          colProps={colProps || { xs: 4, xsOffset: 8 }}
          onSetState={this.handleSendNotificationFlagSetState}
        />
      )
    else if (name) return <FormInputExtended {...props} />
  }

  render() {
    const { loading, task, errors } = this.state
    const { _id } = task
    let { readOnly, hideSavePosition, rows } = this.props

    if (isOffline()) readOnly = true

    return (
      <Modal show={true} onHide={() => this.handleClose()} backdrop="static" className="task-modal">
        <Modal.Header closeButton>
          <Modal.Title>{_id ? loc`Edit` : loc`New task`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {generateRows({
            getField: this.getField,
            rows: rows?.length ? rows : defaultModalRows,
            entity: task,
            readOnly,
          })}
        </Modal.Body>

        <Modal.Footer>
          {!hideSavePosition && (
            <CustomButton simple className="inline-flex-center" onClick={() => savePositionForTask(task)}>
              <i className="icn-map-marker icn-xs mr-5px" />
              {loc`Save position`}
            </CustomButton>
          )}
          {!readOnly && (
            <ButtonWithErrors bsStyle="primary" className="inline-flex-center" fill onClick={() => this.handleClose(true)} errors={errors}>
              {loading && <i className="icn-circle-notch icn-spin icn-sm mr-5px" />}
              {!loading && loc`Save`}
            </ButtonWithErrors>
          )}
          <CustomButton simple onClick={() => this.handleClose()} label="Close" />
        </Modal.Footer>
      </Modal>
    )
  }
}

export default TaskModal
