import axios from "axios"
import { replaceVariables } from "basikon-common-utils"
import cloneDeep from "lodash.clonedeep"
import get from "lodash.get"
import React from "react"
import { Col, ControlLabel, Modal, Row } from "react-bootstrap"

import ButtonWithErrors from "@/_components/ButtonWithErrors"
import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"

import { loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { labelFromName } from "@/_services/utils"

class WorkflowCommentModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: cloneDeep(this.props.entity),
      reasons: [],
      loadingReasons: false,
    }
  }

  componentDidMount() {
    this.getReasons()
  }

  getReasons = async previous => {
    const { transition = {}, entity = {}, onClose } = this.props
    const { showCommentModal = {} } = transition

    if (typeof showCommentModal.reasons === "string" && showCommentModal.reasons[0] === "/") {
      try {
        if (!previous) this.setState({ loadingReasons: true })
        // showCommentModal.reasons can be a script returning [{ value, label ]]
        // but we also support direct entity GET, returning [{ registration, name }]
        const { reasons: url, method = "POST" } = showCommentModal
        const previousQueryParam = `${url.includes("?") ? "&" : "?"}previous=${previous}`
        const reasons = (await axios({ url: replaceVariables(`${url}${previous ? previousQueryParam : ""}`, entity), method, data: entity })).data

        const statePatch = { reasons: [], loadingReasons: false }
        if (!previous) statePatch.data = {}
        for (let i = 0; i < reasons.length; i++) {
          const reason = reasons[i]
          statePatch.reasons.push({
            ...reason,
            value: reason.value ?? reason.registration ?? reason._id,
            label: reason.label ?? reason.name,
          })

          // Hard-coded value
          if (reason.value !== undefined && reason.formInputProps?.value !== undefined) statePatch.data[reason.value] = reason.formInputProps.value

          // Default value
          if (reason.value && reason.defaultValue && statePatch.data[reason.value] === undefined) statePatch.data[reason.value] = reason.defaultValue
        }

        if (statePatch.reasons.length > 0) this.setState(statePatch)
        else {
          // we need to return something truthy in order to continue processing the transition
          // because returning nothing means that the modal has been dismissed by the user without action
          onClose([])
        }
      } catch (error) {
        addOops(error)
        this.setState({ loadingReasons: false })
      }
    }
  }

  handleClose = async isSave => {
    const { transition = {}, onClose } = this.props
    const { showCommentModal = {} } = transition

    if (isSave) {
      const { data } = this.state
      let reasons = (Array.isArray(showCommentModal.reasons) ? showCommentModal.reasons : this.state.reasons) || []

      if (showCommentModal.checkListMode) {
        // the user has to check all checkbox to validate the modale
        const unchecked = reasons.filter(it => !data[it.value])
        if (unchecked.length > 0) {
          this.setState({ errors: [loc("Please confirm the check list")] })
          return
        }
        reasons = []
      } else {
        const { showCommentModal = {} } = transition
        const { singleSelection = false } = showCommentModal

        if (singleSelection) {
          const selectedReason = reasons.find(p => p.value?.toString() === data.reason?.toString())

          if (selectedReason) reasons = [selectedReason]
          else {
            this.setState({ errors: [loc("Please choose at least one option")] })
            return
          }
        } else {
          reasons = reasons
            .map(reason => ({
              ...reason,
              data: get(data, reason.value) || (data?.reasons || []).find(r => r === reason.value),
            }))
            .filter(reason => reason.data)
        }
        // comment is handled as the last reason
        if (data.comment) reasons.push({ value: "comment", label: data.comment, data: data.comment })
      }
      const nextReasons = reasons.filter(it => it.next)
      if (nextReasons.length) {
        return this.getReasons(nextReasons.map(it => it.value).join(","))
      }
      onClose(reasons)
    }
    onClose()
  }

  handleSetState = patch => {
    const data = { ...this.state.data, ...patch }
    this.setState({ data })
  }

  render() {
    const { data, loadingReasons } = this.state
    const { transition } = this.props
    const { showCommentModal } = transition
    const { title = "Comments", singleSelection = false, saveButtonTitle, closeButtonTitle, listTitle, xl } = showCommentModal

    if (!showCommentModal || loadingReasons) return null

    const reasons = (Array.isArray(showCommentModal.reasons) ? showCommentModal.reasons : this.state.reasons) || []
    const showModalBody = (reasons && Array.isArray(reasons) && reasons.length > 0) || showCommentModal.comment || showCommentModal.message

    const useSelectThreshold = reasons.find(it => it.formInputProps) ? 100000 : 10

    // Hide reasons label if reasons contain form inputs
    const showReasonsLabel = showCommentModal.comment && !reasons.find(r => r.formInputProps)

    return (
      <Modal
        show={true}
        onHide={() => this.handleClose(false)}
        backdrop="static"
        className="workflow-comment-modal"
        dialogClassName={xl ? "modal-xl" : ""}
      >
        <Modal.Header closeButton>
          <Modal.Title>{loc(title)}</Modal.Title>
        </Modal.Header>

        {showModalBody && (
          <Modal.Body>
            <Row>
              <Col xs={12}>
                {Array.isArray(reasons) && reasons.length > 0 && (
                  <>
                    {reasons.length <= useSelectThreshold && (
                      <Row>
                        {showReasonsLabel && (
                          <Col xs={12}>
                            <ControlLabel>{loc`Predefined reasons`}</ControlLabel>
                          </Col>
                        )}

                        {reasons.map((reason, key) => {
                          return (
                            <Col xs={12} key={key} {...reason.colProps}>
                              {singleSelection ? (
                                <FormInput
                                  inArray
                                  type="radio"
                                  obj={data}
                                  field="reason"
                                  option={reason.value.toString()}
                                  label={reason.label || labelFromName(reason.value)}
                                  onSetState={this.handleSetState}
                                />
                              ) : (
                                <FormInputExtended
                                  obj={data}
                                  field={reason.value}
                                  label={reason.label || labelFromName(reason.value)}
                                  onSetState={this.handleSetState}
                                  type={reason.formInputProps?.type || "checkbox"}
                                  showLabel={reason.formInputProps?.showLabel ?? false}
                                  {...reason.formInputProps}
                                />
                              )}
                            </Col>
                          )
                        })}
                      </Row>
                    )}

                    {reasons.length > useSelectThreshold && singleSelection && (
                      <FormInput obj={data} field="reason" select={reasons} onSetState={this.handleSetState} label={listTitle || "Reason"} />
                    )}

                    {reasons.length > useSelectThreshold && !singleSelection && (
                      <FormInputExtended
                        multiple
                        obj={data}
                        field="reasons"
                        select={reasons}
                        onSetState={this.handleSetState}
                        label={listTitle || "Reasons"}
                      />
                    )}
                  </>
                )}
              </Col>
            </Row>

            {showCommentModal.comment && (
              <Row>
                <Col xs={12}>
                  <FormInput obj={data} field="comment" type="textarea" onSetState={this.handleSetState} />
                </Col>
              </Row>
            )}

            {showCommentModal.message && (
              <Row>
                <Col xs={12}>{loc(showCommentModal.message)}</Col>
              </Row>
            )}
          </Modal.Body>
        )}

        <Modal.Footer>
          <ButtonWithErrors bsStyle="primary" fill onClick={() => this.handleClose(true)} errors={this.state.errors}>
            {saveButtonTitle ? loc(saveButtonTitle) : loc`Save`}
          </ButtonWithErrors>
          <CustomButton simple bsSize="md" onClick={() => this.handleClose()}>
            {closeButtonTitle ? loc(closeButtonTitle) : loc`Close`}
          </CustomButton>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default WorkflowCommentModal
