import React from "react"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import Card from "@/_components/Card"
import MathSheet from "@/_components/MathSheetComponent"

import { loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { runScriptLocally } from "@/_services/scripts"

class SheetCard extends React.Component {
  state = {
    sheet: undefined,
  }

  componentDidMount() {
    this.getSheetTemplate()
  }

  componentDidUpdate(prevProps) {
    const { entity } = this.props

    if (entity?.isChanged !== prevProps.entity?.isChanged || entity?._updateDate !== prevProps.entity?._updateDate) {
      this.executeSheet()
    }
  }

  getActualEntity = () => {
    let { handlers, obj, entity } = this.props
    if (obj && typeof obj === "string") {
      // this is used when we want to map another entity than the entity main page
      const handler = handlers[obj]
      if (!handler) addOops(loc("Invalid object $ in 'extension' card", obj))
      else {
        return handler.obj
      }
    }
    return entity // default
  }

  getSheetTemplate = async () => {
    let { sheetTemplate } = this.props
    if (!sheetTemplate) return

    try {
      const sheet = await runScriptLocally({ scriptName: sheetTemplate, acceptMissing: true })
      this.setState({ sheet }, () => this.executeSheet())
    } catch (error) {
      addOops(error)
    }
  }

  executeSheet = async patch => {
    const { sheet } = this.state
    const { parameters } = this.props

    const entity = this.getActualEntity()

    if (sheet && sheet.execute) {
      const entityPatch = await sheet.execute({ ...(parameters || {}), entity: { ...entity, ...(patch || {}) }, patch })
      this.onSetState(entityPatch)
    }
  }

  onSetState = patch => {
    // execComputations might be set by the layout script to trigger computation whenever a field is changed
    let { handlers, obj, onSetState, handleSetEntityState, execComputations } = this.props
    onSetState = onSetState || handleSetEntityState // retro compat
    if (obj && typeof obj === "string") {
      // this is used when we want to map another entity than the entity main page
      const handler = handlers[obj]
      if (!handler) addOops(loc("Invalid object $ in 'extension' card", obj))
      else {
        if (handler.obj) obj = handler.obj
        if (handler.onSetState) handler.onSetState(patch, execComputations)
      }
    } else {
      // this is used when we want to update the entity main page
      onSetState(patch, execComputations)
    }
  }

  render() {
    const { sheet } = this.state
    const { debugInfo, title, collapse, readOnly, noCard, showRefreshButton, datapoints } = this.props
    const entity = this.getActualEntity()
    const content = (
      <MathSheet sheetTemplate={sheet} obj={entity} datapoints={datapoints} readOnly={readOnly} onSetState={patch => this.executeSheet(patch)} />
    )

    let action
    if (showRefreshButton) {
      action = (
        <ButtonWithTooltip
          pullRight
          fill
          bsSize="small"
          bsStyle="default"
          className="icn-reload icn-xs"
          btnClassName="inline-flex-center"
          data-test="sheet-refresh-btn"
          tooltip="Refresh"
          onClick={() => this.executeSheet()}
        />
      )
    }

    return (
      <Card debugInfo={debugInfo} noCard={noCard} title={title} collapse={collapse} className="sheet-card" action={action}>
        {content}
      </Card>
    )
  }
}

export default SheetCard
