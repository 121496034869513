import { YYYYMMDD } from "./dateUtils.mjs"
import { getFilterFunction } from "./miscUtils.mjs"

function traverseAndFlatten(currentNode, target, flattenedKey) {
  for (const key in currentNode) {
    // eslint-disable-next-line no-prototype-builtins
    if (!currentNode.hasOwnProperty(key)) continue

    const newKey = flattenedKey === undefined ? key : flattenedKey + "." + key

    const value = currentNode[key]
    if (typeof value === "object") {
      traverseAndFlatten(value, target, newKey)
    } else {
      target[newKey] = value
    }
  }
}

/**
 * Example :
 * Input : { toto: { tata: "tata", tutu: { titi: "titi" } } }
 * Ouput : { "toto.tata": "tata", "toto.tutu.titi": "titi" }
 * @param {object} obj
 * @returns
 */
function flattenObject(obj) {
  const flattenedObj = {}
  traverseAndFlatten(obj, flattenedObj)
  return flattenedObj
}

/**
 * @deprecated Use "toQueryString()" instead. This function will be removed in future versions.
 * @param {object} object
 * @param {string} prefix
 * @param {string} paramSeparator
 * @param {string} fieldValueSeparator
 * @returns Returns a URL query string representation of the given object. For instance { str: "text", arr: [1, 2], date: new Date() } would yield "?str=text&arr=1,2&date=20210914".
 */
function objectToSearchParam(object = {}, prefix = "?", paramSeparator = "&", fieldValueSeparator = "=") {
  const str = Object.keys(object)
    .map(field => {
      let value = object[field]

      if (value === undefined || value === null) return
      if (typeof value === "string" && !value.trim()) return
      if (Array.isArray(value) && value.length === 0) return

      if (Array.isArray(object[field])) value = value.join(",")
      else if (value instanceof Date) value = YYYYMMDD(value)
      else if (typeof value === "string") value = value.trim()

      return `${field}${fieldValueSeparator}${value}`
    })
    .filter(it => it)
    .join(paramSeparator)
  return prefix && str ? prefix + str : str
}

/**
 * This is a simpler equivalent of [lodash get](https://github.com/lodash/lodash/blob/master/get.js).
 * @param {object} obj Object to search
 * @param {string} path Path to the value in the object which can be nested like prop1.0.prop2
 * @returns The value of the property found at the specified path
 */
function getObjProp(obj = {}, path = "") {
  const paths = path.split(".")
  let current = obj
  let i

  for (i = 0; i < paths.length; i++) {
    if (current[paths[i]] === undefined) {
      return undefined
    } else {
      current = current[paths[i]]
    }
  }

  return current
}

/**
 * This function allows to get only the values we want from an object, for instance
 * to perform comparison between the new state and previous state of an object.
 * @param {object} obj Object to search
 * @param {string[]} paths List of paths to get values from
 * @returns A string composed of the different values assembled from the props
 */
function getValuesString(obj = {}, paths = []) {
  let jsonString = ""
  let i
  for (i = 0; i < paths.length; i++) {
    jsonString += getObjProp(obj, paths[i]) ?? ""
  }
  return jsonString
}

/**
 * This method creates a shallow copy of a portion of a given array, filtered down to just the elements from the given array that match all of the specified filter criteria.
 * @param {object} objects an array of objects to filter
 * @param {filter} filters an object containing the fields to filter on and their respective values. This filter support match many values by adding comma separator in their value
 * @param {locale} locale
 * @returns A filtered array (an empty array in case no match).
 */
function filterObjectsByFieldsValues(objects, filters, locale) {
  return objects.filter(obj => {
    return Object.keys(filters).every(key => {
      if (filters[key] && filters[key].trim() !== "") {
        const includes = getFilterFunction(filters[key], locale)
        return includes(obj[key])
      }
      return true
    })
  })
}

/**
 * Recursively replaces values for keys with the specified value in a nested object.
 * @param {object} obj - The object to process.
 * @param {string} keyToReplace - The key name to replace the values for.
 * @param {*} newValue - The new value to replace the old values.
 * @returns {object} - The updated object with replaced values.
 */
function replaceValuesForKey(obj, keyToReplace, newValue) {
  for (const key in obj) {
    if (typeof obj[key] === "function") continue

    if (typeof obj[key] === "object" && obj[key] !== null) {
      replaceValuesForKey(obj[key], keyToReplace, newValue) // Recursively call for nested objects
    } else if (key === keyToReplace) {
      obj[key] = newValue
    }
  }
  return obj
}

/**
 * Check if obj is an object but not an array neither a Date
 * @param {any} obj
 * @returns
 */
function isObject(obj) {
  return typeof obj === "object" && !Array.isArray(obj) && !(obj instanceof Date) && obj !== null
}

export { flattenObject, objectToSearchParam, getObjProp, getValuesString, filterObjectsByFieldsValues, replaceValuesForKey, isObject }
