import React from "react"
import { Modal, Row } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"

import { toBankAccountOptions } from "@/_services/coreUtils"
import { loc } from "@/_services/localization"

class PersonModal extends React.Component {
  state = {
    person: {},
  }

  componentDidMount() {
    const { person = {} } = this.props
    this.setState({ person })
  }

  handleSetPersonState = patch => {
    if (patch) {
      const { person } = this.state
      this.setState({ person: { ...person, ...patch } })
    }
  }

  handleSave = () => {
    const { person } = this.state
    const { onClose } = this.props

    onClose(person)
  }

  render() {
    const { person = {} } = this.state
    const { onClose, readOnly } = this.props

    const personObj = person.person || {}
    const lcEntityName = person.isProspect ? "prospect" : "person"
    const { registration, bankAccounts = [] } = personObj

    return (
      <Modal show={true} onHide={onClose} backdrop="static" className="person-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc`Edit person`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <FormInput readOnly obj={personObj} field="registration" linkTo={`/${lcEntityName}/${registration}`} colProps={{ xs: 12, sm: 4 }} />
            <FormInput readOnly obj={personObj} field="name" linkTo={`/${lcEntityName}/${registration}`} colProps={{ xs: 12, sm: 4 }} />
            <FormInput
              readOnly={readOnly}
              obj={person}
              field="role"
              select="personRole"
              onSetState={this.handleSetPersonState}
              colProps={{ xs: 12, sm: 4 }}
            />
          </Row>
          <Row>
            <FormInput
              obj={person}
              label="IBAN"
              field="personIBAN"
              readOnly={readOnly}
              colProps={{ xs: 12, sm: 8 }}
              onSetState={this.handleSetPersonState}
              select={toBankAccountOptions(bankAccounts.filter(bankAccount => bankAccount && !bankAccount.isInactive))}
            />
            <FormInput
              obj={person}
              readOnly={readOnly}
              label="Reference"
              field="personReference"
              colProps={{ xs: 12, sm: 4 }}
              onSetState={this.handleSetPersonState}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {!readOnly && <CustomButton fill bsStyle="primary" label="Save" onClick={this.handleSave} />}
          <CustomButton simple label="Cancel" onClick={() => onClose()} />
        </Modal.Footer>
      </Modal>
    )
  }
}

export default PersonModal
