import React from "react"
import { Col } from "react-bootstrap"

import FormInput from "@/_components/FormInput"
import PanelInner from "@/_components/PanelInner"
import TableExtended from "@/_components/TableExtended"
import FormInputExtended from "@/_components/FormInputExtended"
import { filterColumns } from "@/_components/Table"
import { generateRows } from "@/_services/utils"
import { loc } from "@/_services/localization"

const defaultRows = [
  ["bankIban", "bankBic", "bankName"],
  ["bankOwner", "bankOpeningDate", "bankPreferredWithdrawalDate", "externalReference"],
  [
    {
      name: "sepaMandates",
      columns: ["rumName", "rumReference", "rumStatus", "rumDateOfSignature", "rumPlaceOfSignature", "rumType", "rumFrequency"],
    },
  ],
]

class PersonBankingInformation extends React.Component {
  getField = col => {
    const { name, colProps, formInputProps, hidden, title, collapse, columns } = col
    if (hidden) return

    let {
      rows,
      readOnly,
      modelPath,
      bankAccount,
      handleSetPersonBankAccountState,
      showBic = true,
      showBankName = true,
      showBankAccountOwner = true,
      showBankAccountOpeningDate = true,
      showPreferredWithdrawalDate = true,
      showExternalReference = false,
      showSepaMandates = false,
      showSepaMandateAdd = true,
      showSepaMandateDelete = true,
      showSepaMandateDuplicate = true,
    } = this.props

    const props = {
      key: name,
      obj: bankAccount,
      field: name,
      modelPath,
      readOnly,
      onSetState: handleSetPersonBankAccountState,
      ...formInputProps,
    }
    if (colProps || formInputProps?.colProps) props.colProps = colProps || formInputProps.colProps

    const isRowsConfigured = rows?.length > 0

    if (name === "name") return <FormInput colProps={{ xs: 12, md: 10 }} mandatory {...props} />
    else if (name === "isInactive") return <FormInput type="checkbox" label="Inactive" colProps={{ xs: 12, md: 2 }} {...props} />
    else if (name === "sepaCreditorIdentifier") return <FormInput colProps={{ xs: 12, sm: 5 }} label={loc`SEPA creditor identifier`} {...props} />
    else if (name === "collectionMethods") return <FormInputExtended multiple select="paymentMode" colProps={{ xs: 12, sm: 7 }} {...props} />
    else if (name === "bankIban") return <FormInputExtended debounce type="iban" label={loc`IBAN`} colProps={{ xs: 12, sm: 5 }} {...props} />
    else if (name === "bankBic") {
      // Show "bankBic" if coming from "rows" config even if "showBic" is false
      if (isRowsConfigured && !showBic) showBic = true

      if (showBic) return <FormInput label={loc`BIC code`} colProps={{ xs: 12, sm: 3 }} {...props} />
    } else if (name === "bankName") {
      // Show "bankName" if coming from "rows" config even if "showBankName" is false
      if (isRowsConfigured && !showBankName) showBankName = true

      if (showBankName) return <FormInput label={loc`Bank name`} colProps={{ xs: 12, sm: 4 }} {...props} />
    } else if (name === "bankOwner") {
      // Show "bankOwner" if coming from "rows" config even if "showBankAccountOwner" is false
      if (isRowsConfigured && !showBankAccountOwner) showBankAccountOwner = true

      if (showBankAccountOwner) return <FormInput label={loc`Bank account owner`} colProps={{ xs: 12, sm: 5 }} {...props} />
    } else if (name === "bankOpeningDate") {
      // Show "bankOpeningDate" if coming from "rows" config even if "showBankAccountOpeningDate" is false
      if (isRowsConfigured && !showBankAccountOpeningDate) showBankAccountOpeningDate = true

      if (showBankAccountOpeningDate) return <FormInput label={loc`Opening date`} type="date" colProps={{ xs: 12, sm: 3 }} {...props} />
    } else if (name === "bankPreferredWithdrawalDate") {
      // Show "bankPreferredWithdrawalDate" if coming from "rows" config even if "showPreferredWithdrawalDate" is false
      if (isRowsConfigured && !showPreferredWithdrawalDate) showPreferredWithdrawalDate = true

      if (showPreferredWithdrawalDate)
        return <FormInput label={loc`Preferred withdrawal date`} select="bankPreferredWithdrawalDate" colProps={{ xs: 12, sm: 4 }} {...props} />
    } else if (name === "externalReference") {
      // Show "externalReference" if coming from "rows" config even if "showExternalReference" is false
      if (isRowsConfigured && !showExternalReference) showExternalReference = true

      if (showExternalReference) return <FormInput label={loc`External reference`} colProps={{ xs: 12, sm: 4 }} {...props} />
    } else if (name === "sepaMandates") {
      // Show "sepaMandates" if coming from "rows" config even if "showSepaMandates" is false
      if (isRowsConfigured && !showSepaMandates) showSepaMandates = true

      if (showSepaMandates) {
        bankAccount.sepaMandates ??= []
        const mandatesTableProps = { formInputProps: { inArray: false }, colProps: { xs: 12, md: 3, lg: 3 } }

        return (
          <Col xs={12}>
            <PanelInner title={loc(title || "SEPA mandates")} collapse={collapse ?? true}>
              <TableExtended
                data={bankAccount.sepaMandates}
                showAdd={showSepaMandateAdd}
                showDelete={showSepaMandateDelete}
                showDuplicate={showSepaMandateDuplicate}
                entity={{}}
                filter={false}
                pageInUrl={false}
                onSetState={handleSetPersonBankAccountState}
                onRowChange={({ row, patch }) => {
                  const mandates = [...bankAccount.sepaMandates]
                  mandates[row._index] = { ...mandates[row._index], ...patch }
                  return handleSetPersonBankAccountState({ sepaMandates: mandates })
                }}
                columns={filterColumns(
                  [
                    { title: loc("Name"), name: "rumName", field: "rumName", type: "text" },
                    { title: loc("Reference"), name: "rumReference", field: "rumReference", type: "text" },
                    { title: loc("Status"), name: "rumStatus", field: "rumStatus", select: "sepaMandateStatus" },
                    { title: loc("Signature date"), name: "rumDateOfSignature", field: "rumDateOfSignature", type: "date", ...mandatesTableProps },
                    { title: loc("Signature place"), name: "rumPlaceOfSignature", field: "rumPlaceOfSignature", type: "text", ...mandatesTableProps },
                    { title: loc("Type"), name: "rumType", field: "rumType", select: "rumType", ...mandatesTableProps },
                    { title: loc("Frequency"), name: "rumFrequency", field: "rumFrequency", select: "rumFrequency", ...mandatesTableProps },
                  ],
                  columns,
                )}
              />
            </PanelInner>
          </Col>
        )
      }
    } else if (name) return <FormInputExtended {...props} />
  }

  render() {
    const { bankAccount, handleSetPersonBankAccountState, rows } = this.props

    return generateRows({
      getField: this.getField,
      rows: rows?.length ? rows : defaultRows,
      entity: bankAccount,
      handleSetState: handleSetPersonBankAccountState,
    })
  }
}

export default PersonBankingInformation
