import axios from "axios"
import { arrayIntersects, replaceVariables } from "basikon-common-utils"
import React from "react"
import { Col, Row } from "react-bootstrap"

import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import DocumentComponent from "@/_components/DocumentComponent"
import FormInput from "@/_components/FormInput"
import PanelInner from "@/_components/PanelInner"

import { getUriAndCacheResponse } from "@/_services/cache"
import { getListsValues } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { getUiState, setUiState } from "@/_services/uiState"
import { getConfigAtPath, getProfiles, getTenant, getUserPermissions } from "@/_services/userConfiguration"
import { localStorageKeys, toArray } from "@/_services/utils"

const atcHint = "-atc-"

/**
 * @prop {string}   serverRoute           Server route
 * @prop {string}   entityName            Entity name
 * @prop {string}   entityRegistration    Entity registration
 * @prop {string}   documentTypesList     Document types list name or an array of document types
 * @prop {object}   entity                Entity object
 * @prop {array}    roles                 List of roles (strings or objects)
 * @prop {object}   colProps              Bootstrap <Col/> props
 * @prop {object}   btnColProps           Bootstrap button <Col/> props
 * @prop {boolean}  noCard                To unwrap documents from a <Card/> component
 * @prop {string}   title                 Title of the section
 * @prop {boolean}  collapse              To collapse the <Card/> component
 * @prop {array}    documents             List of documents
 * @prop {object}   debugInfo             Debug info
 */
class DocumentsComponent extends React.Component {
  constructor(props) {
    super(props)

    const { documents, serverRoute } = props
    const pageConfig = getConfigAtPath("documents.modal") || {}

    const {
      showUploadedDocumentsButton = true,
      showMandatoryDocumentsButton = true,
      showMetadataButton = true,
      // This option supposes that customers have to manage files themselves with the Object Storage screens and APIs
      // and has potentially usage impacts on our infrastructure, so default must be false
      showFileReferenceButton = false,
    } = pageConfig

    const userPermissions = getUserPermissions() || {}
    const readPermission = userPermissions[`read ${serverRoute}/documents`]
    const writePermission = userPermissions[`write ${serverRoute}/documents`]
    const deletePermission = userPermissions[`delete ${serverRoute}/documents`]
    const documentsPermissions = {
      read: readPermission,
      write: writePermission || readPermission,
      delete: deletePermission || writePermission || readPermission,
    }

    this.state = {
      isLoading: true,
      statuses: [],
      documentTypes: [],
      form: {},
      documents: documents || [{ value: "x", label: "No docs given" }],
      hideEmptyDocs: false,
      hideOptionalDocs: false,
      showMetadata: false,
      listDocumentStatus: [],
      listDocumentMeta: [],
      listPersonRole: [],
      showFileReferenceButton,
      showUploadedDocumentsButton,
      showMandatoryDocumentsButton,
      showMetadataButton,
      objectStorageContainers: [],
      documentsPermissions,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.documentTypesList !== this.props.documentTypesList) {
      this.getDocumentTypes()
    }
  }

  componentDidMount() {
    this.getDocuments()
    this.getDocumentTypes()
    this.getObjectStorageContainers()

    this.setState({
      hideEmptyDocs: getUiState(localStorageKeys.UI_STATE.DOCUMENTS.HIDE_EMPTY_DOCS),
      hideOptionalDocs: getUiState(localStorageKeys.UI_STATE.DOCUMENTS.HIDE_OPTIONAL_DOCS),
      showMetadata: getUiState(localStorageKeys.UI_STATE.DOCUMENTS.SHOW_METADATA),
    })

    getListsValues("documentStatus,documentMeta,personRole").then(lists => {
      const [listDocumentStatus, listDocumentMeta, listPersonRole] = lists
      this.setState({ listDocumentStatus, listDocumentMeta, listPersonRole })
    })
  }

  getObjectStorageContainers = async () => {
    const { showFileReferenceButton } = this.state
    if (!showFileReferenceButton) return

    const tenant = getTenant()
    const containers = (await axios.get("/api/external/object-storage/containers")).data
    this.setState({
      objectStorageContainers: containers.map(({ name }) => {
        return {
          value: name,
          label: name.substr(`${tenant}${atcHint}`.length),
        }
      }),
    })
  }

  toggleHideEmptyDocs = () => {
    const hideEmptyDocs = !this.state.hideEmptyDocs
    setUiState(localStorageKeys.UI_STATE.DOCUMENTS.HIDE_EMPTY_DOCS, hideEmptyDocs)
    this.setState({ hideEmptyDocs })
  }

  toggleHideOptionalDocs = () => {
    const hideOptionalDocs = !this.state.hideOptionalDocs
    setUiState(localStorageKeys.UI_STATE.DOCUMENTS.HIDE_EMPTY_DOCS, hideOptionalDocs)
    this.setState({ hideOptionalDocs })
  }

  toggleshowDocumentsMetadata = () => {
    const showMetadata = !this.state.showMetadata
    setUiState(localStorageKeys.UI_STATE.DOCUMENTS.SHOW_METADATA, showMetadata)
    this.setState({ showMetadata })
  }

  getDocuments = async () => {
    const { serverRoute, entityRegistration } = this.props

    if (serverRoute && entityRegistration) {
      try {
        this.setState({ isLoading: true })
        const documents = (await axios.get(`${serverRoute}/${entityRegistration}/documents`)).data
        this.setState({ documents })
      } catch (error) {
        addOops(error)
      } finally {
        this.setState({ isLoading: false })
      }
    }
  }

  getDocumentTypes = async () => {
    const { entity, serverRoute, entityRegistration, documentTypesList } = this.props
    const entityType = entity?.type
    const filterByEntityType = entityType
      ? (values = []) =>
          values.filter(
            v =>
              !v.types ||
              (v.types &&
                v.types
                  .split(",")
                  .map(t => t && t.trim())
                  .includes(entityType)),
          )
      : values => values

    let apiUrl = `${serverRoute}/${entityRegistration}/check-mandatory-documents?listOnly=true&`
    if (Array.isArray(documentTypesList)) {
      apiUrl += `listValues=${JSON.stringify(replaceVariables(documentTypesList, entity))}`
    } else {
      apiUrl += `listName=${replaceVariables(documentTypesList, entity)}`
    }
    const documentTypes = await getUriAndCacheResponse(apiUrl)
    this.setState({ documentTypes: filterByEntityType(documentTypes) })
  }

  downloadDocument = async (event, id) => {
    event.stopPropagation()
    event.preventDefault()
    const { documents = [] } = this.state
    const { serverRoute, entityRegistration } = this.props

    const doc = documents.find(d => d._id === id)
    if (!doc) return

    try {
      const response = await axios.get(doc.url || `${serverRoute}/${entityRegistration}/documents/${id}/content`, {
        responseType: "blob",
      })

      let filename = doc.name
      if (!filename) {
        // Try to find out the filename from the content disposition `filename` value
        const disposition = response.headers["content-disposition"].replace(/\\"/g, "")
        const matches = /"([^"]*)"/.exec(disposition)
        filename = matches != null && matches[1] ? matches[1] : "file.docx"
      }

      // The actual download
      const blob = new Blob([response.data])
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = filename

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      addNotification(`Document downloaded`)
    } catch (error) {
      addOops(error)
    }
  }

  uploadDocument = async ({ event, type, _id, groupIndex, containerName, fileStorageName, isFileReference }) => {
    const { serverRoute, entityRegistration } = this.props
    const { documentTypes } = this.state

    try {
      let document

      const files = Array.from(event?.target?.files || [])
      if (files.length) {
        const fileName = files[0].name,
          fileExtension = fileName?.split(".").pop(),
          documentType = type && documentTypes.find(d => d.value === type),
          allowedExtensions = documentType?.format?.split(",") || ["*"],
          isFileExtensionAllowed = fileExtension && (allowedExtensions.includes("*") || allowedExtensions.includes(fileExtension))
        if (!isFileExtensionAllowed) {
          // Error message kanged from FormInput.jsx:validateUploadFiles
          addOops(`${loc("Forbidden document extension")} "${fileExtension}". ${loc("Accepted formats")}: ${allowedExtensions.join(", ")}`)
          this.setState({ uploadProgressStatus: null })
          return
        }

        if (_id) this.setState({ uploadProgressStatus: _id })

        this.resetInputFiles()

        const formData = new FormData()
        formData.append("file", files[0])
        if (_id) formData.append("_id", _id)
        formData.append("type", type)
        formData.append("name", fileName)
        if (groupIndex !== undefined) formData.append("groupIndex", groupIndex)

        document = (
          await axios.post(`${serverRoute}/${entityRegistration}/documents`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
        ).data
      } else if (isFileReference && containerName && fileStorageName) {
        document = (
          await axios.post(`${serverRoute}/${entityRegistration}/documents`, {
            _id,
            type,
            name: fileStorageName,
            fileStorageName,
            containerName,
            groupIndex,
            isFileReference,
          })
        ).data
      }

      if (!document) return

      let { documents = [] } = this.state
      if (_id) {
        const docIndex = documents.findIndex(d => d._id === _id)
        if (docIndex !== -1) documents[docIndex] = document
      } else {
        documents = [...documents]
        documents.push(document)
      }
      this.setState({ documents })
      addNotification(`File uploaded`)
    } catch (error) {
      addOops(error)
    }
    this.setState({ uploadProgressStatus: null })
  }

  resetInputFiles = () => {
    const inputFiles = document.querySelectorAll("input[type=file]")
    for (let inputFile of inputFiles) if (inputFile && inputFile.value) inputFile.value = ""
  }

  handleDocumentDelete = async id => {
    const { documents = [] } = this.state
    const { serverRoute, entityRegistration } = this.props

    const docIndex = documents.findIndex(doc => doc._id === id)
    if (docIndex >= 0) {
      try {
        await axios.delete(`${serverRoute}/${entityRegistration}/documents/${id}`)

        documents.splice(docIndex, 1)
        this.setState({ documents })

        addNotification(`File deleted`)
      } catch (error) {
        addOops(error)
      }
    }
    this.resetInputFiles()
  }

  patchDocument = async (id, patch) => {
    const { documents = [] } = this.state
    const { serverRoute, entityRegistration } = this.props

    if (id) {
      try {
        const updatedDocument = (await axios.patch(`${serverRoute}/${entityRegistration}/documents/${id}`, patch)).data

        const index = documents.findIndex(d => d._id === updatedDocument._id)
        documents[index] = updatedDocument
        this.setState({ documents })
      } catch (error) {
        addOops(error)
      }
    }
  }

  hideDoc = (doc, isMandatory) => {
    const { hideEmptyDocs, hideOptionalDocs } = this.state
    const isUploaded = !!doc._id
    return (hideEmptyDocs && !isUploaded) || (hideOptionalDocs && !isMandatory)
  }

  handleDocumentSearch = patch => {
    const { form } = this.state
    this.setState({ form: { ...form, ...patch } })
  }

  getDocsBadge = nbExistingDocs => {
    return nbExistingDocs > 0 ? <span className="font-weight-normal font-1-5">({nbExistingDocs})</span> : undefined
  }

  render() {
    const {
      serverRoute,
      entityRegistration,
      roles = [],
      entity = {},
      colProps = { xs: 12, sm: 6 },
      btnColProps,
      noCard,
      title,
      titleSideText,
      collapse,
      debugInfo,
      canUpload,
      inModal,
    } = this.props
    let {
      documents = [],
      documentTypes = [],
      uploadProgressStatus,
      form,
      hideEmptyDocs,
      hideOptionalDocs,
      showMetadata,
      listDocumentStatus,
      listDocumentMeta,
      listPersonRole,
      isLoading,
      showFileReferenceButton,
      objectStorageContainers,
      showUploadedDocumentsButton,
      showMandatoryDocumentsButton,
      showMetadataButton,
      documentsPermissions,
    } = this.state
    const { searchedDocType, searchedDocName } = form
    const isSearching = searchedDocType || searchedDocName

    const docTypeSearchFilter = docType => {
      const _searchedDocType = searchedDocType?.toLowerCase()
      return _searchedDocType
        ? docType.label?.toLowerCase().includes(_searchedDocType) || docType.value?.toLowerCase().includes(_searchedDocType)
        : true
    }

    const nbOfDocuments = documents.length
    const nbOfDocumentTypes = documentTypes.length
    const showSearch = nbOfDocuments >= 20 || nbOfDocumentTypes >= 20

    const docNameSeachFilter = doc => (searchedDocName ? doc.name?.toLowerCase().includes(searchedDocName.toLowerCase()) : true)

    const userProfiles = getProfiles()
    const documentStatuses = listDocumentStatus.filter(s => !s.profiles || arrayIntersects(toArray(s.profiles), userProfiles))

    const docs = documentTypes
      .filter(docTypeSearchFilter)
      .filter(t => (t.multiple === undefined || t.multiple === false || t.multiple === "false") && !t.group)
      .map((documentType, key) => {
        const { label, value, isMandatory, description } = documentType

        // even though there are not multiple there could be multiple instance added (for example several saved prints)
        let docs = documents
          .filter(docNameSeachFilter)
          .filter(it => it.type === value)
          .sort((it1, it2) => (it1._id < it2._id ? -1 : it1._id === it2.__id ? 0 : 1))

        if (docs.length === 0) docs = searchedDocName ? null : [{}]

        return docs ? (
          docs
            .map(doc => {
              return this.hideDoc(doc, isMandatory) ? null : (
                <DocumentComponent
                  key={key}
                  doc={doc}
                  type={value}
                  typeLabel={label}
                  description={description}
                  title={doc.name}
                  canUpload={canUpload}
                  isMandatory={isMandatory}
                  colProps={colProps}
                  btnColProps={btnColProps}
                  serverRoute={serverRoute}
                  readOnly={entity?.readOnly}
                  documentStatuses={documentStatuses}
                  listDocumentMeta={listDocumentMeta}
                  showMetadata={showMetadata}
                  showFileReferenceButton={showFileReferenceButton}
                  objectStorageContainers={objectStorageContainers}
                  entityRegistration={entityRegistration}
                  patchDocument={this.patchDocument}
                  uploadDocument={this.uploadDocument}
                  downloadDocument={this.downloadDocument}
                  deleteDocument={this.handleDocumentDelete}
                  uploadProgressStatus={doc._id && uploadProgressStatus === doc._id}
                  inModal={inModal}
                  documentsPermissions={documentsPermissions}
                />
              )
            })
            .filter(it => it)
        ) : (
          <></>
        )
      })
      .flat()

    const hasDocs = docs.length > 0

    const multipleDocPanels = documentTypes
      .filter(docTypeSearchFilter)
      .filter(t => (t.multiple === true || t.multiple === "true") && !t.group)
      .map((type, index) => {
        const { value, label, isMandatory, description } = type

        let nbExistingDocs = 0
        const docs = [...documents, { type: value }]
          .filter(docNameSeachFilter)
          .filter(doc => doc.type === value)
          .map((doc, colKey) => {
            if (doc._id) nbExistingDocs++
            return this.hideDoc(doc) ? null : (
              <DocumentComponent
                doc={doc}
                key={colKey}
                type={value}
                typeLabel={label}
                description={description}
                colProps={colProps}
                canUpload={canUpload}
                serverRoute={serverRoute}
                readOnly={entity?.readOnly}
                documentStatuses={documentStatuses}
                listDocumentMeta={listDocumentMeta}
                showMetadata={showMetadata}
                showFileReferenceButton={showFileReferenceButton}
                objectStorageContainers={objectStorageContainers}
                title={doc.name || `<${loc`none`}>`}
                entityRegistration={entityRegistration}
                patchDocument={this.patchDocument}
                uploadDocument={this.uploadDocument}
                downloadDocument={this.downloadDocument}
                deleteDocument={this.handleDocumentDelete}
                uploadProgressStatus={doc._id && uploadProgressStatus === doc._id}
                isMandatory={isMandatory}
                inModal={inModal}
                documentsPermissions={documentsPermissions}
              />
            )
          })
          .filter(it => it)

        if (docs.length) {
          return (
            <PanelInner
              key={index}
              title={label}
              collapse={isSearching ? undefined : true}
              titleSideText={this.getDocsBadge(nbExistingDocs)}
              onSetExpanded={expanded => this.setState({ [`expanded-${index}`]: expanded })}
              className="documents-multiple"
            >
              <Row>{docs}</Row>
            </PanelInner>
          )
        }
      })

    const groupRoleDocPanels = roles
      .map(role => (typeof role === "string" ? role : role.role))
      .filter(role => documentTypes.find(t => t.group === role))
      .map((role, groupIndex) => {
        let nbExistingDocs = 0
        const docs = documentTypes
          .filter(docTypeSearchFilter)
          .filter(t => t.group === role)
          .map((docType, key) => {
            const { value, label, isMandatory, description } = docType
            const doc = documents.filter(docNameSeachFilter).find(d => d.type === value && d.groupIndex === groupIndex) || {}

            if (searchedDocName && Object.keys(doc).length === 0) return null
            if (doc._id) nbExistingDocs++

            return this.hideDoc(doc) ? null : (
              <DocumentComponent
                doc={doc}
                key={key}
                type={value}
                typeLabel={label}
                description={description}
                colProps={colProps}
                canUpload={canUpload}
                serverRoute={serverRoute}
                groupIndex={groupIndex}
                readOnly={entity?.readOnly}
                documentStatuses={documentStatuses}
                listDocumentMeta={listDocumentMeta}
                showMetadata={showMetadata}
                showFileReferenceButton={showFileReferenceButton}
                objectStorageContainers={objectStorageContainers}
                title={doc.name || `<${loc`none`}>`}
                entityRegistration={entityRegistration}
                patchDocument={this.patchDocument}
                uploadDocument={this.uploadDocument}
                downloadDocument={this.downloadDocument}
                deleteDocument={this.handleDocumentDelete}
                uploadProgressStatus={doc._id && uploadProgressStatus === doc._id}
                isMandatory={isMandatory}
                inModal={inModal}
                documentsPermissions={documentsPermissions}
              />
            )
          })
          .filter(it => it)

        if (docs.length) {
          const roleLabel = listPersonRole.find(personRole => personRole.value === role)?.label
          const roleOccurrence = groupIndex + 1
          const { persons = [] } = entity

          let occurrence = 1
          let rolePersonName
          for (let personItem of persons) {
            if (personItem.role === role) {
              if (occurrence === roleOccurrence) {
                if (personItem.person) {
                  if (personItem.person.name) rolePersonName = personItem.person.name
                  else {
                    if (personItem.person.firstName) rolePersonName = personItem.person.firstName
                    if (personItem.person.lastName) {
                      if (rolePersonName) rolePersonName += ` ${personItem.person.lastName}`
                      else rolePersonName = personItem.person.lastName
                    }
                  }
                  break
                }
              } else occurrence++
            }
          }

          return (
            <PanelInner
              key={groupIndex}
              title={`${roleLabel} - ${rolePersonName || roleOccurrence}`}
              collapse={isSearching ? undefined : true}
              titleSideText={this.getDocsBadge(nbExistingDocs)}
              className="documents-role-group"
            >
              <Row>{docs}</Row>
            </PanelInner>
          )
        }
        return null
      })
      .filter(g => g)

    const otherGroups = Array.from(
      new Set(
        documentTypes
          .filter(docTypeSearchFilter)
          .filter(t => t.group && !roles.find(r => r === t.group || r.role === t.group))
          .map(t => t.group),
      ),
    )

    const groupDocPanels = otherGroups
      .map((group, groupIndex) => {
        let nbExistingDocs = 0
        const docs = documentTypes
          .filter(t => t.group === group)
          .map((docType, key) => {
            const { value, label, isMandatory, description } = docType
            const doc = documents.filter(docNameSeachFilter).find(d => d.type === value && d.groupIndex === groupIndex) || {}

            if (searchedDocName && Object.keys(doc).length === 0) return null
            if (doc._id) nbExistingDocs++

            return this.hideDoc(doc) ? null : (
              <DocumentComponent
                doc={doc}
                key={key}
                type={value}
                description={description}
                typeLabel={label}
                colProps={colProps}
                canUpload={canUpload}
                serverRoute={serverRoute}
                groupIndex={groupIndex}
                readOnly={entity?.readOnly}
                documentStatuses={documentStatuses}
                listDocumentMeta={listDocumentMeta}
                showMetadata={showMetadata}
                showFileReferenceButton={showFileReferenceButton}
                objectStorageContainers={objectStorageContainers}
                title={doc.name || `<${loc`none`}>`}
                entityRegistration={entityRegistration}
                patchDocument={this.patchDocument}
                uploadDocument={this.uploadDocument}
                downloadDocument={this.downloadDocument}
                deleteDocument={this.handleDocumentDelete}
                uploadProgressStatus={doc._id && uploadProgressStatus === doc._id}
                isMandatory={isMandatory}
                inModal={inModal}
                documentsPermissions={documentsPermissions}
              />
            )
          })
          .filter(it => it)

        if (docs.length) {
          return (
            <PanelInner
              key={groupIndex}
              title={loc(group)}
              collapse={isSearching ? undefined : true}
              titleSideText={this.getDocsBadge(nbExistingDocs)}
              className="documents-group"
            >
              <Row>{docs}</Row>
            </PanelInner>
          )
        }
        return null
      })
      .filter(g => g)

    const documentTogglesColProps = { xs: 12, sm: 6 }
    if (!showSearch) documentTogglesColProps.md = 3

    const content = (
      <>
        <Row>
          {showSearch && (
            <>
              <FormInput
                label="Search documents by type"
                value={searchedDocType}
                colProps={{ xs: 12, sm: 6 }}
                field="searchedDocType"
                onSetState={this.handleDocumentSearch}
                action={() => this.handleDocumentSearch({ searchedDocType: null })}
                actionIcon={searchedDocType ? "icn-xmark icn-xs text-gray" : " "}
              />

              <FormInput
                label="Search documents by name"
                value={searchedDocName}
                colProps={{ xs: 12, sm: 6 }}
                field="searchedDocName"
                onSetState={this.handleDocumentSearch}
                action={() => this.handleDocumentSearch({ searchedDocName: null })}
                actionIcon={searchedDocName ? "icn-xmark icn-xs text-gray" : " "}
              />
            </>
          )}
          {(showUploadedDocumentsButton || showMandatoryDocumentsButton || showMetadataButton) && (
            <Col xs={12} className="mb-theme">
              {showUploadedDocumentsButton && (
                <CustomButton bsStyle="info" bsSize="small" fill={hideEmptyDocs} onClick={this.toggleHideEmptyDocs}>
                  {loc("Uploaded documents")}
                </CustomButton>
              )}
              {showMandatoryDocumentsButton && (
                <CustomButton bsStyle="info" bsSize="small" fill={hideOptionalDocs} onClick={this.toggleHideOptionalDocs}>
                  {loc("Mandatory documents")}
                </CustomButton>
              )}
              {showMetadataButton && (
                <CustomButton bsStyle="info" bsSize="small" fill={showMetadata} onClick={this.toggleshowDocumentsMetadata}>
                  {loc("Show metadata")}
                </CustomButton>
              )}
            </Col>
          )}
        </Row>

        {hasDocs && <div className="divider mb-theme"></div>}

        {isLoading ? (
          <div className="text-center">
            <i className="icn-circle-notch icn-spin icn-sm text-gray-darkest" />
          </div>
        ) : (
          <>
            {hasDocs && <Row className="mb-theme">{docs}</Row>}
            {groupDocPanels}
            {groupRoleDocPanels}
            {multipleDocPanels}
          </>
        )}
      </>
    )

    return (
      <Card className="documents" noCard={noCard} title={loc(title)} titleSideText={titleSideText} collapse={collapse} debugInfo={debugInfo}>
        {content}
      </Card>
    )
  }
}

export default DocumentsComponent
