import React from "react"
import { Col, Row } from "react-bootstrap"
import axios from "axios"
import { formatDate } from "basikon-common-utils"

import Card from "@/_components/Card"
import Table from "@/_components/Table"
import PanelOuter from "@/_components/PanelOuter"

import { loc, getLocale } from "@/_services/localization"
import { convertToYaml, handleAccessibleOnKeyDown } from "@/_services/utils"

class EntityAuditsCard extends React.Component {
  state = {
    audits: [],
    panels: [],
  }

  componentDidMount() {
    this.fetchAudits()
  }

  componentDidUpdate(prevProps) {
    const { entityName, withDetails } = this.props
    if (entityName !== prevProps.entityName || withDetails !== prevProps.withDetails) {
      this.fetchAudits()
    }
  }

  fetchAudits = async () => {
    const { entity, entityName, withDetails } = this.props
    const { audits, registration } = entity || {}
    if (!audits && entityName && registration) {
      const projection = withDetails ? "&projection=when,who,what,whatArgs,details" : ""
      const { data } = await axios.get(`/api/core/audits?entityName=${entityName}&entityRegistration=${registration}${projection}`)
      this.setState({ audits: data })
    }
  }

  togglePanel = index => {
    this.setState(state => {
      const key = `details-${index}`
      return state.panels.includes(key) ? { panels: state.panels.filter(k => k !== key) } : { panels: [...state.panels, key] }
    })
  }

  render() {
    const { panels = [] } = this.state
    const { card = {}, entity, collapse, entityName, title } = this.props
    const { card: name, props = {} } = card
    const { colProps = { xs: 12 } } = props
    const locale = getLocale()

    if (!entity.registration || !entityName) return null

    const audits = this.props.audits || this.state.audits
    const columns = [
      { title: "What", name: "what" },
      { title: "When", name: "when", type: "datetime" },
      { title: "Who", name: "who" },
      { title: "Entity", name: "entity" },
      { title: "", name: "actions" },
    ]
    const data = audits?.map((audit, index) => {
      const { when, who, details = {} } = audit
      const { what, whatArgs } = audit
      let whatString = loc(what.split("$"), ...(whatArgs || []).map(it => loc(it)))
      if (whatString.includes("ERROR")) {
        whatString = (
          <div className="inline-flex align-items-center">
            <i className="text-danger icn-exclamation-circle icn-xs mr-5px" />
            {whatString}
          </div>
        )
      }

      if (details.length > 0) {
        whatString = (
          <div className="inline-flex align-items-center">
            {whatString}
            <i
              tabIndex="0"
              className="icn-info-circle icn-xs ml-5px"
              style={{ cursor: "pointer" }}
              onKeyDown={event => {
                handleAccessibleOnKeyDown({ event, fn: () => this.togglePanel(index) })
              }}
              onClick={() => this.togglePanel(index)}
            />
            {panels.includes(`details-${index}`) && (
              <PanelOuter expanded={true}>
                <pre>
                  {details.map(detail => {
                    if (Array.isArray(detail)) {
                      let [first, ...rest] = detail
                      rest.forEach((x, i) => {
                        if (x instanceof Date) rest[i] = formatDate(x, locale)
                      })
                      if (first === "SET") first = 'Changed "$" from "$" to "$"'
                      else if (first === "APPEND") first = 'Append to "$"'
                      else if (first === "DELETE") first = 'Deleted "$"'
                      let rest2 = rest.filter(r => !(typeof r === "object"))
                      let main = loc(first.split("$"), ...rest2) + "\n"
                      let rest3 = rest.filter(r => typeof r === "object")
                      if (rest3.length === 1) return [main, convertToYaml("", rest3[0]).join("\n")]
                      if (rest3.length === 2) return [main, convertToYaml("", { [loc`From`]: rest3[0], [loc`To`]: rest3[1] }).join("\n")]
                      else return main
                    } else {
                      return convertToYaml("", detail).join("\n")
                    }
                  })}
                </pre>
              </PanelOuter>
            )}
          </div>
        )
      }

      return {
        what: whatString,
        when,
        who,
      }
    })

    return (
      <Row>
        <Col key={name} {...colProps}>
          <Card title={loc(title || "Audits")} collapse={collapse}>
            <Row>
              <Col xs={12}>
                <Table columns={columns} data={data} overflowX />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default EntityAuditsCard
