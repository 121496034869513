import React from "react"

class EntitySteps extends React.Component {
  render() {
    const { bgWhite, steps = [] } = this.props

    return (
      <div className="entity-steps">
        {steps.map((step, index) => {
          const { title, info, content, disabled, hidden = false } = step
          const style = disabled ? "gray" : step.style

          if (!hidden) {
            return (
              <div key={index} className={`entity-step bg-${style} `}>
                <div className={`step-before border-left-${style}`}></div>

                {title && <div className="step-title">{title}</div>}
                {info && <div className="step-info">{info}</div>}

                {content}

                <div className={`step-after ${bgWhite ? "border-left-white" : `border-left-${style}`}`}></div>
              </div>
            )
          }
        })}
      </div>
    )
  }
}

export default EntitySteps
