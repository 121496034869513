import axios from "axios"
import { dateDiffDays, dateNoTime, formatDate, formatDateTime, formatMilliseconds } from "basikon-common-utils"
import React, { Suspense } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "react-router-dom"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomCheckbox from "@/_components/CustomCheckbox"
import FormInput from "@/_components/FormInput"
import ProgressBar from "@/_components/ProgressBar"
import Table, { filterColumns } from "@/_components/Table"
const AlertModal = React.lazy(() => import("@/_components/AlertModal.jsx"))
const MapMarkerModal = React.lazy(() => import("@/_components/MapMarkerModal.jsx"))

import { getListsValues } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { axiosOrPush, isOffline, readLocalStorage } from "@/_services/offlineService"
import { getIsAdmin, getUsername } from "@/_services/userConfiguration"

const taskColumns = [
  { title: loc`Done`, name: "done" },
  { title: loc`Type`, name: "type" },
  { title: loc`Created by`, name: "createdBy" },
  { title: loc`Creation date`, name: "creationDate" },
  { title: loc`Start date`, name: "startDate", hidden: true },
  { title: loc`End date`, name: "endDate", hidden: true },
  { title: loc`Scheduled`, name: "due" },
  { title: loc`Subject`, name: "subject" },
  { title: loc`Owner`, name: "owner" },
  { title: loc`Completion`, name: "completion" },
  { title: loc`Time to complete`, name: "timeToComplete", hidden: true },
  { title: loc`Remaining time`, name: "remainingTime", hidden: true },
  { name: "actions" },
]

class TasksComponent extends React.Component {
  state = {
    tasks: null,
    tableData: null,
    columns: null,
    alert: null,
    showMapMarkerModal: false,
    remainingTimeInterval: null,
    taskTypeList: null,
  }

  componentDidMount = async () => {
    const {
      tasks,
      columns,
      showTimeToComplete,
      showRemainingTime,
      showCreationDate = true,
      showStartDate,
      showEndDate,
      showDue = true,
      showDone = true,
      showActions = true,
      showCreatedBy = true,
      showType = true,
      showOwner = true,
    } = this.props

    let remainingTimeInterval
    for (let i = 0; i < taskColumns.length; i++) {
      const taskColumn = taskColumns[i]
      if (taskColumn.name === "done") taskColumns[i].hidden = !showDone
      if (taskColumn.name === "type") taskColumns[i].hidden = !showType
      if (taskColumn.name === "creationDate") taskColumns[i].hidden = !showCreationDate
      if (taskColumn.name === "startDate") taskColumns[i].hidden = !showStartDate
      if (taskColumn.name === "endDate") taskColumns[i].hidden = !showEndDate
      if (taskColumn.name === "due") taskColumns[i].hidden = !showDue
      if (taskColumn.name === "timeToComplete") taskColumns[i].hidden = !showTimeToComplete
      if (taskColumn.name === "createdBy") taskColumns[i].hidden = !showCreatedBy
      if (taskColumn.name === "owner") taskColumns[i].hidden = !showOwner
      if (taskColumn.name === "remainingTime") {
        taskColumns[i].hidden = !showRemainingTime
        if (showRemainingTime) remainingTimeInterval = setInterval(() => this.computeRemainingTime(), 1000)
      }
      if (taskColumn.name === "actions") taskColumns[i].hidden = !showActions
    }

    this.setState({ tasks, columns: filterColumns(taskColumns, columns), remainingTimeInterval }, () => this.formatTableData())
  }

  componentWillUnmount = () => {
    const { remainingTimeInterval } = this.state
    const { showRemainingTime } = this.props
    if (showRemainingTime && remainingTimeInterval) clearInterval(remainingTimeInterval)
  }

  componentDidUpdate(prevProps) {
    if (this.props.tasks && prevProps.tasks !== this.props.tasks) {
      const tasks = this.props.tasks.map(task => ({ ...task }))
      if (isOffline()) {
        const offlineActions = readLocalStorage()
        for (const action of offlineActions) {
          if (action.key.startsWith("/api/core/tasks/")) {
            const id = action.key.substring("/api/core/tasks/".length)
            const task = tasks.find(it => it._id === id)
            if (task) Object.assign(task, action.data)
          }
        }
      }

      this.setState({ tasks }, () => {
        this.formatTableData()
        this.computeRemainingTime()
      })
    }
  }

  formatTableData = async () => {
    const {
      handleShowTaskModal,
      setDoneWhenOpened,
      showTimeToComplete,
      showCreationDate = true,
      showStartDate,
      showEndDate,
      showDue = true,
      showDone = true,
      showActions = true,
      canCheckDone,
      canEditTask,
    } = this.props
    const { tasks } = this.state
    const currentUsername = getUsername()
    const locale = getLocale()
    const now = dateNoTime(new Date())
    const listTaskType = await getListsValues("taskType")

    const tableData = tasks?.map((task, key) => {
      const { registration, entityType, entityRegistration, creationDate, startDate, endDate, _insertUser, ownerUsername, timeToComplete } = task
      const timeToCompleteIsNaN = isNaN(timeToComplete)

      let lockProps
      if (!ownerUsername) {
        lockProps = {
          toolTip: loc(`Task is not locked. Lock task`),
          color: "info",
          img: "icn-lock-open icn-xs",
          handleLockTask: task._id && (() => this.handleLockTask(task)),
        }
      } else if (ownerUsername === currentUsername) {
        lockProps = {
          toolTip: loc(`You have locked this task. You may unlock it`),
          color: "success",
          img: "icn-lock icn-xs",
          handleLockTask: task._id && (() => this.handleLockTask(task)),
        }
      } else if (ownerUsername && ownerUsername !== currentUsername) {
        lockProps = {
          toolTip: loc(`Task is locked by ${ownerUsername}`),
          color: "warning",
          img: "icn-lock icn-xs",
        }
      }

      const taskType = listTaskType?.find(taskTypeValue => taskTypeValue?.value === task?.type)
      const isWhere = task.where && task.where.coordinates && task.where.coordinates.length > 0
      const dueDateStyle = isWhere
        ? "text-primary"
        : !task.done && task.dueDate && task.dueDate < now
        ? "text-danger"
        : !task.done && task.dueDate && this.isSameDay(task.dueDate, now)
        ? "text-warning"
        : ""
      const overlayText = isWhere ? "View map" : task.dueDate ? loc`Scheduled` + " " + formatDate(task.dueDate, locale) : null
      let type
      if (taskType?.icon) {
        type = <i className={taskType.icon + " " + dueDateStyle} onClick={isWhere ? () => this.handleMapMarker(task) : null} />
        if (overlayText) {
          type = (
            <OverlayTrigger placement="top" overlay={<Tooltip id="view">{overlayText}</Tooltip>}>
              {type}
            </OverlayTrigger>
          )
        }
      } else {
        type = <div className={dueDateStyle}>{taskType?.label || task.type || ""}</div>
      }

      let clientRouteName
      if (entityType) {
        switch (entityType) {
          case "CreditLine":
            clientRouteName = "credit-line"
            break
          case "BankAccount":
            clientRouteName = "bank-account"
            break
          case "BankOperation":
            clientRouteName = "bank-operation"
            break
          case "Person":
            clientRouteName = "task/" + task.registration
            break
          default:
            clientRouteName = entityType.toLowerCase()
            break
        }
      }

      let clientRoute = entityRegistration && `/${clientRouteName || "contract"}/${entityRegistration}`
      if (entityType && entityType === "Person") clientRoute = entityRegistration && `/${clientRouteName || "contract"}`

      if (clientRoute === window.location.pathname) clientRoute = undefined

      const tableItemData = {
        // registration: entityRegistration + "-" + number,
        registration,
        type,
        createdBy: _insertUser,
        subject: clientRoute ? (
          <Link to={clientRoute} onClick={setDoneWhenOpened && (() => this.handleClickDone(task._id, !task.done))}>
            {task.subject}
          </Link>
        ) : (
          loc(task.subject)
        ),
        owner: ownerUsername,
        completion: <ProgressBar completionRate={task.completionRate} />,
      }

      if (showDone) {
        let disabled = ownerUsername && ownerUsername !== currentUsername && !getIsAdmin()
        if (typeof canCheckDone === "boolean") disabled = !canCheckDone

        tableItemData.done = <CustomCheckbox number={key} checked={task.done} disabled={disabled} onClick={() => this.handleClickAlert(task)} />
      }

      if (showCreationDate) {
        tableItemData.creationDate = creationDate && formatDateTime(creationDate, locale)
      }

      if (showStartDate) {
        tableItemData.startDate = startDate && formatDateTime(startDate, locale)
      }

      if (showEndDate) {
        tableItemData.endDate = endDate && formatDateTime(endDate, locale)
      }

      if (showDue) {
        tableItemData.due =
          task.type === "APPOINTMENT" ? (
            <div className={dueDateStyle}>
              <FormInput bsStyle={dueDateStyle} readOnly inArray obj={task} field="startDate" type="datetime" />
            </div>
          ) : (
            <div className={dueDateStyle}>
              <FormInput bsStyle={dueDateStyle} readOnly inArray obj={task} field="dueDate" type="date" />
            </div>
          )
      }

      if (showActions) {
        let showEdit = true
        if (typeof canEditTask === "function") showEdit = canEditTask(task)

        tableItemData.actions = {
          className: "td-actions text-right",
          content: (
            <>
              <ButtonWithTooltip className="icn-edit icn-xs" tooltip={loc`Edit`} onClick={() => handleShowTaskModal(task._id)} hidden={!showEdit} />

              {!isOffline() && showActions && (
                <ButtonWithTooltip
                  className={lockProps.img}
                  tooltip={lockProps.toolTip}
                  bsStyle={lockProps.color}
                  onClick={lockProps.handleLockTask}
                />
              )}
            </>
          ),
        }
      }

      if (showTimeToComplete) tableItemData.timeToComplete = !timeToCompleteIsNaN && formatMilliseconds(timeToComplete * 1000)

      return tableItemData
    })

    this.setState({ tableData })
  }

  // we isolate this function to increase performance
  computeRemainingTime = () => {
    const { tasks, tableData } = this.state

    if (!tasks || !tableData) return

    for (let i = 0; i < tasks.length; i++) {
      const { done, startDate, timeToComplete } = tasks[i]

      if (!tableData[i] || isNaN(timeToComplete)) continue
      if (done) {
        tableData[i].remainingTime = loc("Done")
        continue
      }

      const timeDiff = dateDiffDays(new Date(), startDate) * 24 * 60 * 60
      tableData[i].remainingTime =
        timeDiff < 0
          ? loc("Future date")
          : timeToComplete < timeDiff
          ? loc("Expired")
          : formatMilliseconds((timeToComplete - (new Date().getTime() / 1000 - startDate.getTime() / 1000)) * 1000)
    }

    this.setState({ tableData })
  }

  handleLockTask = async task => {
    if (!task?._id) return

    const { getTasks } = this.props
    const currentUsername = getUsername()
    try {
      let patch
      if (!task.ownerUsername) {
        patch = { ownerUsername: currentUsername }
      } else if (task.ownerUsername === currentUsername) {
        patch = { ownerUsername: null }
      }

      await axios.patch(`/api/core/tasks/${task._id}`, patch)
    } catch (error) {
      addOops(error)
    }

    if (getTasks) await getTasks()
  }

  handleClickAlert = async task => {
    if (!task.done && task.type === "VISIT") {
      this.setState({
        alert: (
          <Suspense fallback={null}>
            <AlertModal
              warning
              onConfirm={() => {
                this.setState({ alert: null })
                savePositionForTask(task, this.handleClickDone)
              }}
              onCancel={() => this.setState({ alert: null })}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="info"
              cancelBtnCssClass="btn-simple"
              confirmBtnText={loc`Yes, save my position!`}
              cancelBtnText={loc`Not yet`}
              showCancel
            >
              {loc`Save your current position?`}
            </AlertModal>
          </Suspense>
        ),
      })
    } else {
      this.handleClickDone(task._id, !task.done)
    }
  }

  handleClickDone = async (_id, done, where) => {
    if (!_id) return
    //const { getTasks } = this.props
    const { tasks = [] } = this.state
    const payload = {
      done,
      completionRate: done ? 1 : 0,
      where,
    }

    try {
      await axiosOrPush({
        method: "patch",
        url: "/api/core/tasks/" + _id,
        data: payload,
      })
    } catch (error) {
      addOops(error)
    }
    //if (getTasks) await getTasks()
    this.setState({ tasks: tasks.map(t => (t._id === _id ? { ...t, ...payload } : t)) })
    this.formatTableData()
  }

  handleMapMarker = task => {
    this.setState({ showMapMarkerModal: task })
  }

  isSameDay = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
  }

  render() {
    const { pageInUrl = true, striped = true, showHeaders = true, ...props } = this.props
    const { columns, showMapMarkerModal, alert, tableData } = this.state

    return (
      <div className="tasks-component overflow-x-auto">
        {tableData && <Table {...{ pageInUrl, striped, showHeaders, ...props }} data={tableData} columns={columns} />}

        {alert}

        {showMapMarkerModal && (
          <Suspense fallback={null}>
            <MapMarkerModal
              disabled
              coordinates={showMapMarkerModal.where.coordinates}
              markers={[
                {
                  location: { coordinates: showMapMarkerModal.where.coordinates },
                  color: "green",
                  description: showMapMarkerModal.subject,
                },
              ]}
              onClose={() => this.setState({ showMapMarkerModal: null })}
            />
          </Suspense>
        )}
      </div>
    )
  }
}

export function savePositionForTask(task, cb) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const where = {
          type: "POINT",
          coordinates: [Math.round(position.coords.latitude * 1000000) / 1000000, Math.round(position.coords.longitude * 1000000) / 1000000],
        }
        addNotification(`Geolocalization has been saved`)
        if (cb) {
          cb(task._id, !task.done, where)
        } else {
          if (task._id) {
            try {
              axiosOrPush({
                method: "patch",
                url: `/api/core/tasks/${task._id}`,
                data: { where },
              })
            } catch (error) {
              addOops(error)
            }
          }
        }
      },
      () => {
        addNotification("Geolocalization is off", "warning")
      },
    )
  }
}

export default TasksComponent
